import React from 'react'
import PropTypes from 'prop-types'

function ModalBox (props) {

  const handleApprove = () => {
    let toNavigate
    if (props.title === 'Custom Content'){
      toNavigate = '#description'
    } else if (props.title === 'Rules') {
      toNavigate = '#rules'
    } else {
      toNavigate = ''
    }
    props.approve(toNavigate)
  }

  return props.show ? (
    <div className="react-modal">
      <div className="back-shadow" onClick={() => props.decline()}>
      </div>
      <div className="modal-content react-modal-content">
        <div className="modal-body">
          <div style={styles.closeButton} onClick={() => props.decline()}>
            <span style={styles.close}> Close</span>
            <span style={styles.closeIcon} className='fa fa-times' />
          </div>
          <div className="confirmation-message">
            {props.title}
            {props.children}
          </div>
        </div>
        <div className="modal-footer" style={styles.modalFooter}>
          <div style={styles.footerButton}>
            <button className="btn btn-primary" onClick={(e) => handleApprove(e)}>
              {props.approveButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const styles = {
  closeButton: {
    cursor: 'pointer',
    float: 'right',
    position: 'relative',
    top: "-35px",
    left: "15px",
    color: "#fdfdfd",
  },
  modalFooter: {
    backgroundColor: '#fff',
    borderTop: '#fff',
  },
  footerButton: {
    textAlign: 'center'
  },
  close: {
    marginRight: 5
  },
  closeIcon: {
    color: '#fff'
  }
}

ModalBox.propTypes = {
  title: PropTypes.string,
  approve: PropTypes.func,
  decline: PropTypes.func,
  show: PropTypes.bool,
  hideModalInParent: PropTypes.func,
  id: PropTypes.number,
  className: PropTypes.string,
  approveButtonText: PropTypes.string
}

export default ModalBox;
