export const noDeletionClassName = `tinymce-template-no-deletion`

export const noDeletionParagraph = `<p class='${noDeletionClassName}'><br></p>`

const headerWithoutNewLine = `<p class='tinymce-template-header'>Header</p>`

const header = `${headerWithoutNewLine}${noDeletionParagraph}`

const pageHeader = `<div class='tinymce-template-title-content-div'>
    <div class='tinymce-template-image-div'>
      <p><img src='/company_logo_placeholder.png' width='140' /></p>
    </div>
    <div class='tinymce-template-content-container'>
      <p class='tinymce-template-title-sub-info'>SUB INFO</p>
      <h1 class='tinymce-template-title'>Title</h1>
    </div>
  </div>
  ${noDeletionParagraph}`

const collapsableHeader = `<div class='tinymce-template-collapsable-header'>
    ${headerWithoutNewLine}
    <p class='tinymce-template-collapsing-button'>
      Hide
    </p>
  </div>`

const subHeader = `<p class='tinymce-template-sub-header'>Sub Header</p>${noDeletionParagraph}`

const contentWithoutNewLine = `<p class='tinymce-template-content'>
    Please change your content here. You can also add sub-header headers inside it.
  </p>`

const content = `${contentWithoutNewLine}${noDeletionParagraph}`

const headerFooterDiv = `<div class='tinymce-template-header-footer-section'>
    ${headerWithoutNewLine}
    ${contentWithoutNewLine}
  </div>
  ${noDeletionParagraph}`

const collapsableDiv = `<div class='tinymce-template-collapsing-container'>
  ${collapsableHeader}
  <div class='tinymce-template-collapsing-div'>
    ${contentWithoutNewLine} <p class='tinymce-template-content'>Include everything within this box if this content needs to get hidden.</p>
  </div>
</div>
${noDeletionParagraph}`

const divWithImage = `<div class='tinymce-template-image-content-div'>
  <div class='tinymce-template-image-div'>
    <p><img src='/company_logo_placeholder.png' width='195' /></p>
  </div>
  <div class='tinymce-template-content-container'>
    ${content}
  </div>
</div>
${noDeletionParagraph}`

const hyperlink = `<div class='tinymce-template-hyperlink-container'>
  <a href='#' target='_blank' rel='noopener' class='tinymce-template-hyperlink'>Hyperlink →</a>
</div>
${noDeletionParagraph}`

export const templates = [
  { title: 'Collapsable Section', description: 'Provides a section that can be collapsed.', content: collapsableDiv },
  { title: 'Content', description: 'Provides styled content.', content: content },
  { title: 'Header', description: 'Provides simple header.', content: header },
  { title: 'Header/Footer Section', description: 'Provides a div with grayish color.', content: headerFooterDiv },
  { title: 'Hyperlink', description: 'Provides hyperlink with right arrow.', content: hyperlink },
  { title: 'Page Header', description: 'Provides a div with image and header.', content: pageHeader },
  { title: 'Sub Header', description: 'Provides a sub header.', content: subHeader },
  { title: 'Section With Image', description: 'Provides a section with image that can be collapsed.', content: divWithImage },
]
