import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'

class ClaimCustomizeContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      screenContents: props.customizable_screen_content,
      selectedOption: props.content_options[0],
      showContent: Object.keys(props.customizable_screen_content).length > 0
    }
  }

  componentDidUpdate() {
    tinymce.get('claims-customize-content').setContent(this.screenContentText())
  }

  screenContentText() {
    const { screenContents, selectedOption } = this.state
    return screenContents[selectedOption] || ''
  }

  onContentSelectChange(value) {
    this.setState(prevState => (
      {...prevState, ...{ selectedOption: value }}
    ))
  }

  saveCustomContent() {
    const { screenContents, selectedOption, showContent } = this.state
    let data
    let savedContent = ''
    if (showContent) {
      savedContent = tinymce.activeEditor ? tinymce.activeEditor.getContent() : screenContents[selectedOption]
    }
    data = {
      disable_customizable_content: !showContent,
      selected_option: selectedOption,
      screen_content: savedContent,
    }

    let url = $('form.claim-configuration-form').attr('action')
    url += '/update_content'

    $.ajax({
      method: 'PUT',
      url: url,
      data: data,
      success: (response) => {
        toastr.success(response.message)
        this.setState(prevState => ({ 
          screenContents: {
              ...prevState.screenContents,
              [prevState.selectedOption]: savedContent
            }
          })
        )
      },
      failure: (error) => {
        toastr.error(error.message)
      }
    })
  }

  onShowContentChange(checked) {
    this.setState({
      showContent: checked
    })
  }

  renderTinyMce() {
    const contentOptions = this.props.content_options.map(option => [option.titleize(), option])
    const showHideClass = this.state.showContent ? '' : 'conceal'
    return (
      <div className={`content-editor-div ${showHideClass}`}>
        <div className='form-group' style={{paddingTop: '90px'}}>
          <div className='customize-contents-sub-var-wrapper' style={{width: '20%'}}>
            <p>Select content to edit </p>
            <Selectpicker
              name='customize-content-select'
              optionsForSelect={contentOptions}
              onChange={(value) => this.onContentSelectChange(value)}
              liveSearch='false'
              defaultValue={this.state.selectedOption}
            />
          </div>
        </div>
        <div className='customize-tinymce-content'>
          <textarea
            className="form-control"
            id='claims-customize-content'
            name='customize_content'
            aria-hidden="true"
            value={this.screenContentText()}
            onChange={() => {}}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='row col-sm-12'>
        <div className='tinymce-header-buttons'>
          <h4>Customize Content</h4>
          <SwitchInput
            name='show_content'
            id='show_content'
            checked={this.state.showContent}
            onChange={(checked) => this.onShowContentChange(checked)}
          />
          <div className='pull-right'>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.saveCustomContent()}
            >Save</button>
          </div>
        </div>
        {this.renderTinyMce()}
      </div>
    )
  }
}

export default ClaimCustomizeContent;
