import React from 'react'

class DownloadBenefitUsage extends React.Component {
  handleHref () {
    const leavePlanId = this.props.leave_plan_id
    
    let link = document.createElement('a');
    link.setAttribute("type", "hidden");
    link.href = `/admin/shared_leave_plans/${leavePlanId}/download_benefit_usage`;
    document.body.appendChild(link);

    link.click();
    link.parentNode.removeChild(link);
  }

  render() {
    return(
      <a
        href="#"
        className='btn btn-primary pull-right'
        onClick={this.handleHref.bind(this)}
      >
        Download Benefits
      </a>
    )
  }
}

export default DownloadBenefitUsage;
