import React from 'react'

class MultiStateToggle extends React.Component {
  constructor(props) {
    super(props)
  }

  showHelperText (event, helperText) {
    const rect = event.target.getBoundingClientRect()
    const tooltipCss = {
      'position': 'fixed',
      'left': rect.x,
      'top': rect.y + 15,
      'z-index': 3
    }
    $('.helper-tooltip').removeClass('hidden')
    $('.helper-tooltip-text').html(helperText)
    $('.eligible-tooltip').css(tooltipCss)
    return null
  }

  hideHelperText () {
    $('.helper-tooltip').addClass('hidden')
    return null
  }

  renderLabels() {
    const { labelClass, wrapperClass } = this.props;
    const { labels } = this.props;
    const btnClass = `btn btn-secondary ${labelClass} btn-sm`
    return (
      <div className={`btn-group btn-group-toggle ${wrapperClass}`} data-toggle="buttons">
        {labels.map((label, idx) => {
          let activeBtnClass = label.checked ? `${btnClass} active` : btnClass
          return(
            <label
              className={activeBtnClass}
              key={idx}
              onClick={() => this.props.handleClick(label.value)}
              onMouseOver={(e) => this.showHelperText(e, label.helperText)}
              onMouseLeave={() => this.hideHelperText()}
            >
              <input type="radio" name="options" value={label.value} checked={label.checked}/> {label.text}
            </label>
          )
        })}
      </div>
    )
  }

  render() {
    return this.renderLabels()
  }
}

export default MultiStateToggle

