import React from 'react'

class DashboardWeekLabels extends React.Component {
  fetchPrevData () {
    const prevDate = moment(this.props.first_date).add(-1, 'weeks').format('YYYY-MM-DD')
    this.fetchNewData(prevDate)
  }

  fetchNextData () {
    const nextDate = moment(this.props.first_date).add(1, 'weeks').format('YYYY-MM-DD')
    this.fetchNewData(nextDate)
  }

  fetchNewData (date) {
    $.getJSON('/leave_plan_benefits/dashboard_benefit_items', {input_date: date, leave_plan_id: this.props.leave_plan_id}, (data) => {
      this.props.changeData(data)
    })
  }

  renderPrevNav () {
    if (this.props.showPrevNav) {
      return (
        <i onClick={this.fetchPrevData.bind(this)} className='prev-week fa fa-angle-left next-prev' />
      )
    }
  }

  renderDateLabel (label, index) {
    if (index > 0) {
      return (
        <div key={label.date} className='date-label'>
          { moment(label.date).format(gon.default_date_format_string.toUpperCase()) }
        </div>
      )
    }
  }

  renderNextNav () {
    if (this.props.showNextNav) {
      return (
        <i onClick={this.fetchNextData.bind(this)} className='next-week fa fa-angle-right next-prev' />
      )
    }
  }

  render () {
    return (
      <div className='week-dates-labels-div'>
        <div className='first-date-labels-div'>
          { this.renderPrevNav() }
          <div className='date-label first'>
            { moment(this.props.label_dates[0].date).format(gon.default_date_format_string.toUpperCase()) }
          </div>
        </div>
        <div className='date-labels-div'>
          { this.props.label_dates.map((label, index) => this.renderDateLabel(label, index)) }
        </div>
        <div className='next-week-container'>
          { this.renderNextNav() }
        </div>
      </div>
    )
  }
}

export default DashboardWeekLabels
