import { TableCell } from '@material-ui/core';
import React from 'react';

const CustomRulesHandler = ({ row, openRulesModal, setOpenRulesModal, setRulesContent, setRedirectTo, handleModal }) => {

  const fetchRulesContent = (id) => {
    $.ajax({
      method: 'GET',
      url: `/admin/leave_benefits/${id}/rule`,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response) => {
        setRulesContent(response.data)
      },
      error: (err) => {
        console.log({ err })
      }
    })
  }

  const handleRules = (url, id) => {
    setOpenRulesModal(true);
    setRedirectTo(url);
    fetchRulesContent(id);
    handleModal('rules');
  }

  return (
    <TableCell style={{ fontSize: 14}}>
      <div onClick={() => handleRules(row.url, row.id)} style={{cursor: 'pointer', textDecoration: 'underline'}}>
        {row.rules ? "Benefit Rule" : ''}
      </div>
    </TableCell>
  )
}

export default CustomRulesHandler;
