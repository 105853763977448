import React, { useState } from "react";
import PropTypes from "prop-types";
import CompanyContentForm from "./CompanyContentForm";
import ContentExportTable from "./ContentExportTable";

const ERROR_THEAD = ["Column Name", "Row Number", "Error details"];

function ContentExports (props) {
  let [state, setstate] = useState({
    fileDownloadLists: props.fileDownloadList,
    fileDownload: {},
  })

  function deleteItemHandler (fileDownloadId) {
    const fileDownloadLists = state.fileDownloadLists.filter(
      (fl) => fl.id != fileDownloadId
    );
    setstate({ ...state, fileDownloadLists: fileDownloadLists });
  }

  function downloadItemHandler (fileDownloadId) {
    const fileDownloadLists = state.fileDownloadLists.filter(
      (fl) => fl.id != fileDownloadId
    );
    setstate({ ...state, fileDownloadLists: fileDownloadLists });
  }

  function reloadItemHandler (item) {
    setstate(prevState => {
      return {
        fileDownloadLists: prevState.fileDownloadLists.map((fileDownloadList) => {
          return fileDownloadList.id === item.id ? item : fileDownloadList
        }),
      }
    })
  }

  let formSubmitHandler = (data) => {
    setstate(prevState => ({
      ...state,
      fileDownloadLists: [data, ...prevState.fileDownloadLists],
    }))
  }

  return (
    <div>
      <div className='row company-content-div'>
        <CompanyContentForm
          action={props.action}
          onFormSubmit={formSubmitHandler}
          companyId={props.companyId}
          companyName={props.companyName}
          jobCategoryOptions={props.jobCategoryOptions}
        />
      </div>
      <div className="company-download-container">
        <ContentExportTable
          tHead={props.headers}
          tBody={state.fileDownloadLists}
          action={props.action}
          title={props.title}
          onDeleteItem={deleteItemHandler}
          onReloadItem={reloadItemHandler}
        />
      </div>
    </div>
  );
}

ContentExports.propTypes = {
  fileDownloadList: PropTypes.array,
  action: PropTypes.string,
  title: PropTypes.string,
  headers: PropTypes.array,
  companyId: PropTypes.number,
  companyName: PropTypes.string,
  jobCategoryOptions: PropTypes.array,
};

export default ContentExports;
