import React, { useState } from "react";
import Modal from "../../shared/Modal";
import FileDownloadButton from "./FileDownloadButton";
import FileDeleteButton from "../file_uploads/FileDeleteButton";
import PropTypes from 'prop-types';

function ContentExportTable (props) {
  let [state, setstate] = useState({
    itemId: null,
    showConfirmationModal: false,
    disableButton: false,
    downloadItem: false,
    csv: false,
    zip: false,
  });

  let formatDate = (stringDate) => {
    return moment(stringDate).format(
      gon.default_date_format_string.toUpperCase()
    );
  };

  let showConfirmationModal = (id) => {
    setstate({ ...state, showConfirmationModal: true, itemId: id });
  };

  let showDownloadModal = (id, type) => {
    if (type === 'Download Content') {
      setstate({ ...state, showConfirmationModal: true, itemId: id, downloadItem: true, zip: true });
    } else {
      setstate({ ...state, showConfirmationModal: true, itemId: id, downloadItem: true, csv: true });
    }
  };

  let hideConfirmationModal = () => {
    setstate({ ...state, showConfirmationModal: false, downloadItem: false, csv: false, zip: false });
  };

  function handleDownload () {
    handleDownloadButton();
    $.ajax({
      url: `${props.action}/${state.itemId}/download_file`,
      type: "GET",
      success: (response) => {
        if (response.download_url) {
          window.location = response.download_url;
        }
        toastr.success('File downloaded successfully');
        setDownloadButtonEnable();
      },
      error: () => {
        toastr.error('Error Downloading File.');
        setDownloadButtonEnable();
      },
    });
  }

  function handleDownloadButton () {
    let downloadButton = $(`.download-company-content-${state.itemId}`)
    downloadButton.html("Processing...");
    downloadButton.attr("disabled", true);
  }

  function handleHref () {
    let link = document.createElement('a');
    link.setAttribute("type", "hidden");
    link.href = `${props.action}/${state.itemId}/download_file`;
    document.body.appendChild(link);

    handleDownloadButton();
    link.click();
    link.parentNode.removeChild(link);
    setDownloadButtonEnable();
    toastr.success('File downloaded successfully');
  }

  function handleDelete () {
    let deleteButton = $(`.delete-employee-file-${state.itemId}`)
    deleteButton.html("Processing...");
    deleteButton.attr("disabled", true);
    $.ajax({
      url: `${props.action}/${state.itemId}`,
      type: "DELETE",
      success: (response) => {
        toastr.success(response.message);
        setDeleteButtonEnable();
        props.onDeleteItem(response.deleted_id);
      },
      error: () => {
        toastr.error('File could not deleted');
        setDeleteButtonEnable();
      },
    });
  };

  function setDownloadButtonEnable () {
    let downloadButton = $(`.download-company-content-${state.itemId}`)
    downloadButton.attr("disabled", false);
    downloadButton.html("Download");
  };

  function setDeleteButtonEnable () {
    let downloadButton = $(`.delete-employee-file-${state.itemId}`)
    downloadButton.attr("disabled", false);
    downloadButton.html("Delete");
  };

  function tableHead () {
    return (
      <thead>
        <tr>
          {props.tHead.map((head) => {
            return <th key={head}>{head}</th>;
          })}
        </tr>
      </thead>
    );
  };

  function reloadItem (itemId) {
    $.ajax({
      url: `/admin/company_content_exports/${itemId}`,
      type: "GET",
      success: (response) => {
        props.onReloadItem(response.data);
        toastr.success("Successfully reloaded!");
      },
      error: () => {
        toastr.error('Something went wrong!');
      },
    });
  }

  function renderFilterTypes (filterType, index) {
    return (
      <div key={index}>
        <b>{`${filterType['type']}:`}</b>
        { filterType['name'].map(name => <i key={name} className="content-job-category">{name}</i>) }
      </div>)
  }

  function tableBody () {
    const statusMapper = {
      processing: "Processing",
      completed: "Completed",
      canceled_with_errors: "Canceled With Errors",
    };
    return (
      <tbody>
        {props.tBody.map((body) => {
          let disableButton = body.process_status === "processing";
          return (
            <tr key={body.id}>
              <td>{body.file_name}</td>
              <td>{body.content_type}</td>
              <td>
                {body.filter_types.map(renderFilterTypes)}
              </td>
              <td>{body.created_by}</td>
              <td>{formatDate(body.created_at)}</td>
              <td>{statusMapper[body.process_status]}</td>
              <td>
                <FileDownloadButton
                  handleClick={showDownloadModal}
                  value={body.id}
                  type={body.content_type}
                  disable={disableButton || state.disableButton}
                />
                <FileDeleteButton
                  handleClick={showConfirmationModal}
                  value={body.id}
                  disable={disableButton || state.disableButton}
                />
                {body.process_status === 'processing' ?
                  <span
                    style={{ marginLeft: 10 }}
                    onClick={() => reloadItem(body.id)}
                  >
                    <i className="fa fa-refresh"
                      style={{
                        color: "green",
                        fontSize: '20px',
                        cursor: 'pointer'
                      }}></i>
                  </span> :
                  null
                }
              </td>
            </tr>
          )
        })
        }
      </tbody>
    )
  }

  function renderTable () {
    if (props.tBody.length) {
      return (
        <table className="table table-hover">
          {tableHead()}
          {tableBody()}
        </table>
      );
    } else {
      return [
        <table className="table table-hover">{tableHead()}</table>,
        <p className="text-center">No contents to download yet</p>,
      ];
    };
  };

  function actionAfterApprove() {
    if (!state.downloadItem) return handleDelete;
    if (!state.zip) return handleHref;
    return handleDownload;
  }

  function renderModal () {
    let afterApprove = actionAfterApprove();
    let modalMsg = state.downloadItem ? 'Are you sure you want to download the file ?' : 'Are you sure you want to delete the file ?'
    let title = state.downloadItem ? 'This action will download the file.' : 'This action will delete the file'

    return (
      <Modal
        show={state.showConfirmationModal}
        hideModalInParent={hideConfirmationModal}
        title={title}
        approve={() => afterApprove()}
      >
        <p>{modalMsg}</p>
      </Modal>
    );
  };

  function renderDownloadDetails () {
    return (
      <div className="company-content-export-table">
        <div className="content-export-table">{renderTable()}</div>
        {state.showConfirmationModal && renderModal()}
      </div>
    );
  }

  return renderDownloadDetails();

}

ContentExportTable.propTypes = {
  tBody: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  tHead: PropTypes.array,
  title: PropTypes.string,
  onDeleteItem: PropTypes.func,
  onReloadItem: PropTypes.func,
  action: PropTypes.string,
}

export default ContentExportTable;
