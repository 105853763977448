import React from 'react'
import SharedTaskTable from './containers/sharedTaskTable'
import EditTaskModal from './containers/editTaskModal'
import ScheduleModalBox from '../../employee/schedules/common_components/ScheduleModalBox'

class SharedTasks extends React.Component {


  constructor(props) {
    super()

    this.state = {
      sharedTasks: props.data,
      taskDetail: {
        show: false,
        description: '',
        image_url: '',
        name: '',
        itemFiles: ''
      },
      editTaskDetail: {
        show: false,
        task: {}
      },
      sortBy: {
        column: "name",
        reverse: false
      }
    }
  }

  componentWillUnmount () {
    PubSub.unsubscribe('fetchTaskItems')
  }

  componentDidMount () {
    this.subscribeEvents()
  }

  subscribeEvents () {
    PubSub.subscribe('fetchTaskItems', () => {
      this.fetchTaskItems()
    })
  }

  fetchTaskItems () {
    $.getJSON(`/admin/shared_leave_plans/${this.props.leave_plan_id}/task_items`, (response) => {
      this.setState({
        sharedTasks: response.shared_tasks
      })
    })
  }

  fetchTaskDetail (task) {
    $.getJSON(`/timeline_items/${task.id}/fetch_details`, (data) => {
      const taskDetail = {
        description: data.description,
        image_url: data.image_url,
        show: true,
        item: {
          name: data.name,
          date: task.due_date,
          check_status: task.completed,
          timeline_id: task.id
        },
        itemFiles: data.item_files
      }
      gon.company_variables = {...gon.company_variables, ...data.statutory_substitutions_options}
      this.setState({taskDetail})
    })
  }

  editTask (task) {
    const editTaskDetail = {
      show: true,
      task: task
    }

    this.setState({editTaskDetail})
  }

  findTaskIndex (task_id) {
    const task = $.grep(this.state.sharedTasks, (task) => {
      return parseInt(task.id) === parseInt(task_id)
    })[0]
    return this.state.sharedTasks.indexOf(task)
  }

  manageUpdatedTask (task) {
    let sharedTasks = this.state.sharedTasks
    const index = this.findTaskIndex(task.id)
    sharedTasks[index] = task
    this.setState({sharedTasks})
  }

  updateTask (params) {
    this.hideEditTaskModal()
    $.post(`/timeline_items/${params.id}/update_eligibility`, params, (response) => {
      if(response.success) {
        toastr.success('Successfully Updated.')
        this.manageUpdatedTask(response.task)
        PubSub.publish('fetchLeavePlanHistories')
      } else {
        toastr.error(response.error_message, 'Error')
      }
    })
  }

  cancelUpdate () {
    this.hideEditTaskModal()
  }

  hideEditTaskModal () {
    const editTaskDetail = {
      show: false,
      benefit: {}
    }

    this.setState({editTaskDetail})
  }

  showEditTaskModal () {
    if (this.state.editTaskDetail.show) {
      return (
        <EditTaskModal
          detail={this.state.editTaskDetail}
          approve={this.updateTask.bind(this)}
          cancel={this.cancelUpdate.bind(this)} />
      )
    }
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
    })
  }

  handleItemClose () {
    const taskDetail = {
      show: false,
      description: '',
      image_url: '',
      name: ''
    }
    this.setState({taskDetail})
  }

  showTaskDetail () {
    if (this.state.taskDetail.show) {
      return (
        <ScheduleModalBox
          item={this.state.taskDetail.item}
          handleItemClose={this.handleItemClose.bind(this)}
          onTimelineCheck={this.onTimelineCheck.bind(this)}
          item_type='timeline_event'
          description={this.state.taskDetail.description}
          image_url={this.state.taskDetail.image_url}
          isAdmin={true}
          type='timeline'
          itemFiles={this.state.taskDetail.itemFiles}
        />
      )
    }
  }

  onTimelineCheck (data) {
    let sharedTasks = this.state.sharedTasks
    let timeline_task_id = data.timeline_id
    const index = this.findTaskIndex(timeline_task_id)
    let selected_task = sharedTasks[index]
    if(selected_task)
      sharedTasks[index].completed = data.check_status
    this.setState({sharedTasks})
    PubSub.publish('fetchLeavePlanHistories')
  }

  getSortedTasks (tasks, sortBy) {
    const dates = ["due_date"]
    const numbers = []
    const booleans = ['completed']
    const sortByColumn = sortBy.column
    const sorted = tasks.sort (((task1, task2) => {
      const sortValue = dates.indexOf(sortByColumn) >= 0 ?
        this.sortDate(task1[sortByColumn], task2[sortByColumn]) :
        numbers.indexOf(sortByColumn) >= 0 ?
          this.sortNumber(task1[sortByColumn], task2[sortByColumn]) :
          booleans.indexOf(sortByColumn) >= 0 ?
            this.sortBoolean(task1[sortByColumn], task2[sortByColumn]) :
            this.sortString(task1[sortByColumn], task2[sortByColumn])
      return sortValue
    }).bind(this))
    return sortBy.reverse ? sorted.reverse() : sorted
  }

  sortDate (d1, d2) {
    d1 = new Date(d1)
    d2 = new Date(d2)
    return d1-d2
  }

  sortNumber (n1, n2) {
    n1 = parseFloat(n1) || 0
    n2 = parseFloat(n2) || 0
    return n1-n2
  }

  sortString (str1, str2) {
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()
    if (str1<str2) {
      return -1
    } else if (str2<str1) {
      return 1
    }
    return 0
  }

  sortBoolean (bool1, bool2) {
    return bool1-bool2
  }

  render() {
    return(
      <div className="shared-benefits-table">
        <SharedTaskTable
          sharedTasks={this.getSortedTasks(this.state.sharedTasks, this.state.sortBy)}
          classes={[{
                        element: "completed",
                        class: "completed sortable"
                      },{
                        element: "eligibility",
                        class: "eligibility sortable"
                      },{
                        element: "name",
                        class: "task-name sortable"
                      },{
                        element: "due_date",
                        class: "due_date sortable"
                      }, {
                        element: "priority",
                        class: "priority sortable"
                      }, {
                        element: 'button',
                        class: 'button'
                      }
                      ]}
          fetchTaskDetail={this.fetchTaskDetail.bind(this)}
          editTask={this.editTask.bind(this)}
          sort={this.sort.bind(this)}
          sortBy={this.state.sortBy}
          onTimelineCheck={this.onTimelineCheck.bind(this)}
        />
        { this.showTaskDetail() }
        { this.showEditTaskModal() }
      </div>
    )
  }
}

export default SharedTasks;