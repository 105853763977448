import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'

const FORMAT = 'YYYY-MM-DD'

class EditBundleCategoryVendor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.bundleCategoryVendor.id,
      bundleCategoryId: props.bundleCategoryVendor.bundle_category_id,
      vendorId: props.bundleCategoryVendor.vendor_id,
      description: props.bundleCategoryVendor.description,
      name: props.bundleCategoryVendor.name,
      short_code: props.bundleCategoryVendor.short_code,
      disableButton: false,
    }
  }

  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  getValidationState() {
    this.setState({ disableButton: true })

    if(!(/^[a-z0-9-]*$/.test(this.state.short_code))) {
      toastr.error("Short code includes lowercase, number and special character '-'")
      this.setState({ disableButton: false })
    } else {
      this.editBundleVendor()
    }
  }

  editBundleVendor() {
    const { id, bundleCategoryId, vendorId, description, name, short_code } = this.state
    const params = {
      bundle_category_id: bundleCategoryId,
      vendor_id: vendorId,
      description: description,
      name: name,
      short_code: short_code
    }
    let method = 'PUT'
    let url = `/admin/bundle_category_vendors/${id}`
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
        this.setState({ disableButton: false })
      }
    })
  }

  render() {
    const { description, name, disableButton, short_code } = this.state
    const { bundleCategoryVendor, bundleCategories, vendors } = this.props
    return (
      <div>
        <h3 className="admin-header vendor-bundle-edit">Edit Bundle Vendor</h3>
        <div className="row">
          <div className="pull-right">
            <li className="last-updated-info" style={{ listStyle: "none" }}>
              <em>Updated at { moment(bundleCategoryVendor.updated_at).format(FORMAT) }</em>
            </li>
          </div>
        </div>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={ name }
            onChange={ (e) => this.handleChange('name', e.target.value) }
          />
        </div>
        <div className="form-group">
          <label>Short Code</label>
          <p><em>Include lowercase, number and special character '-' only</em></p>
          <input
            type="text"
            className="form-control"
            name="short_code"
            value={ short_code }
            onChange={ (e) => this.handleChange('short_code', e.target.value) }
          />
        </div>
        <div className="form-group">
          <label>Bundle Category</label>
          <div>
            <Selectpicker
              className="select-picker form-control"
              name="bundleCategoryId"
              defaultValue={ bundleCategoryVendor.bundle_category.id.toString() }
              optionsForSelect={ bundleCategories.map((options) => [options.name, options.id]) }
              onChange={ (value) => { this.handleChange('bundleCategoryId', value) } }
            />
          </div>
        </div>

        <div className="form-group">
          <label>Vendor</label>
          <div>
            <Selectpicker
              className="select-picker form-control"
              name="vendorId"
              defaultValue={ bundleCategoryVendor.vendor.id.toString() }
              optionsForSelect={ vendors.map((options) => [options.name, options.id]) }
              onChange={ (value) => { this.handleChange('vendorId', value) } }
            />
          </div>
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            type="text"
            className="form-control"
            name="description"
            value={ description }
            onChange={ (e) => this.handleChange('description', e.target.value) }
          />
        </div>
        <br/>
        <div className="form-group last-item">
          <div className="row">
            <div className="col-xs-5">
              <button className="btn btn-primary"
                      onClick={ () => this.getValidationState() }
                      disabled={ disableButton }>Save
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditBundleCategoryVendor
