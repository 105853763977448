import React from 'react'
import CompanyCategoryModal from './CompanyCategoryModal'
import Datepicker from '../../shared/inputs/datepicker'
import Selectpicker from '../../shared/inputs/selectpicker'
import { sortString } from '../../shared/GenericTasks'

const CURRENT_DATE = moment(new Date()).format('YYYY-MM-DD')

class CompanyNoteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedNoteDetails: this.props.isCreate,
      noteFields: {
        eventDate: CURRENT_DATE,
        categoryId: '',
        note: '',
        sharedNoteId: ''
      },
      noteCategory: {
        categories: this.props.categoryOptions,
        showModal: false,
        isAdd: false,
        operatedCategory: {
          categoryId: '',
          value: ''
        },
        isCategoriesModified: false
      }
    }
  }

  componentDidMount() {
    if (!this.state.fetchedNoteDetails) {
      this.loadEditableNote()
    }
  }

  loadEditableNote() {
    let { companyId, companyNoteId } = this.props
    let url = `/admin/companies/${companyId}/company_notes/${companyNoteId}/edit`
    let method = 'GET'
    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        this.setState({
          fetchedNoteDetails: true,
          noteFields: {
            eventDate: response.data.event_date,
            categoryId: response.data.category_id,
            note: response.data.note,
            sharedNoteId: response.data.id
          },
          noteCategory: {
            ...this.state.noteCategory,
            operatedCategory: {
              categoryId: response.data.category_id,
              value: response.data.category_name
            }
          }
        })
      }
    })
  }

  submitForm(e) {
    e.preventDefault()

    let { companyId, companyNoteId, moveToSharedTable } = this.props
    let { eventDate, note, categoryId } = this.state.noteFields
    let noteCategory = this.state.noteCategory

    let method = 'POST'
    let url = `/admin/companies/${companyId}/company_notes`
    let params = {
      event_date: eventDate,
      note: note,
      company_note_category_id: categoryId
    }

    if (!this.props.isCreate) {
      method = 'PUT'
      url = `/admin/companies/${companyId}/company_notes/${companyNoteId}`
    }

    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          toastr.success(response.message)
          moveToSharedTable(response.data, noteCategory)
        } else {
          toastr.error(response.message)
        }
      }
    })
  }

  modifyCategoryList(category) {
    let { noteCategory } = this.state
    let update_categories = ''
    if (noteCategory.isAdd) {
      update_categories = noteCategory.categories.concat([category])
    } else {
      update_categories = noteCategory.categories
        .filter((item) => item[1] != category[1])
        .concat([category])
    }
    this.setState({
      noteCategory: {
        ...this.state.noteCategory,
        categories: update_categories,
        showModal: false,
        isCategoriesModified: true
      }
    })
  }

  closePrompt() {
    let { noteCategory } = this.state
    this.props.cancelNoteModal(noteCategory)
  }

  addCategory(e) {
    e.preventDefault()
    this.setState({
      noteCategory: {
        ...this.state.noteCategory,
        showModal: true,
        isAdd: true,
        operatedCategory: {
          categoryId: '',
          value: ''
        }
      }
    })
  }

  editCategory(e) {
    e.preventDefault()
    let { operatedCategory } = this.state.noteCategory
    if (operatedCategory.value.length) {
      this.setState({
        noteCategory: {
          ...this.state.noteCategory,
          showModal: true,
          isAdd: false,
          categoryId: ''
        }
      })
    }
    else {
      toastr.error('Select the Category.')
    }
  }

  modalHeader() {
    let title = this.props.viewDetail ? 'Details' : this.props.isCreate ? 'Add Note' : 'Update Note'
    return (
      <div className="modal-header">
        <div className='item-header-container'>
          <div className='item-header'>
            <div className='name'>
              {title}
            </div>
          </div>
        </div>
      </div>
    )
  }

  stateChangedHandler(key, value) {
    let noteFields = this.state.noteFields
    if (key == 'categoryId') {
      let selectedOption = this.state.noteCategory.categories.filter((category) => value == category[1])[0]

      this.setState({
        noteCategory: {
          ...this.state.noteCategory,
          operatedCategory: {
            categoryId: selectedOption[1],
            value: selectedOption[0]
          }
        },
        noteFields: {
          ...this.state.noteFields,
          categoryId: selectedOption[1]
        }
      })

    } else {
      noteFields[key] = value
      this.setState({ noteFields })
    }
  }

  hideCategoryModal() {
    this.setState({
      noteCategory: {
        ...this.state.noteCategory,
        showModal: false
      }
    })
  }

  renderCategoryModal() {
    let { noteCategory } = this.state
    let companyId = this.props.companyId
    let companyNoteId = this.state.noteFields.companyNoteId
    return noteCategory.showModal ?
      <CompanyCategoryModal
        hideCategoryModal={this.hideCategoryModal.bind(this)}
        noteCategory={noteCategory}
        companyId={companyId}
        companyNoteId={companyNoteId}
        modifyCategoryList={this.modifyCategoryList.bind(this)}
      /> : null
  }

  sortedCategories() {
    let { categories } = this.state.noteCategory
    return categories.sort((category1, category2) => { return sortString(category1[0], category2[0]) })
  }

  renderNoteModal() {
    const { eventDate, note } = this.state.noteFields
    const categories = this.sortedCategories()
    const categoryId = this.state.noteFields.categoryId
    return (
      <div className="react-modal">
        <div className="back-shadow" onClick={(e) => this.closePrompt(e)}>
        </div>
        <div className="modal-content react-modal-content">
          {this.modalHeader()}
          <div className="modal-body leave-plan-benefit-modal">
            <form className='leave-plan-benefit-override-form' action='#' onSubmit={this.submitForm.bind(this)}>
              <div className='form-group'>
                <label className='label-control'> Date: </label>
                <Datepicker
                  name='event_date'
                  defaultValue={eventDate}
                  endDate={new Date()}
                  onChange={(value) => this.stateChangedHandler('eventDate', value)}
                  placeholder={gon.user_preferred_date_format_string}
                  typeable={false}
                />
              </div>
              <div className='form-group'>
                <label className='label-control'> Category: </label>
                <div className='selecpicker-wrapper'>
                  <Selectpicker
                    name='status'
                    defaultValue={categoryId}
                    optionsForSelect={categories}
                    onChange={(value) => this.stateChangedHandler('categoryId', value)}
                    placeholder='Select Category'
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='label-control'></label>
                <button onClick={(e) => this.addCategory(e)}>
                  Add
                </button>
                <button onClick={(e) => this.editCategory(e)}>
                  Edit
                </button>
              </div>
              <div className='form-group'>
                <label className='label-control'> Note: </label>
                <textarea
                  type='text'
                  className='form-control'
                  required='required'
                  onChange={(e) => this.stateChangedHandler('note', e.target.value)}
                  value={note}
                  style={{ height: '200px', width: '600px' }}
                />
              </div>
              <div className="buttons">
                <button className="cancel btn btn-danger" type='reset' onClick={() => this.closePrompt()}>
                  Cancel
                </button>
                <button className="submit btn btn-primary" type='submit'>
                  {this.props.isCreate ? 'Add Note' : 'Update Note'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (this.state.fetchedNoteDetails ?
      <div>
        {this.renderNoteModal()}
        {this.renderCategoryModal()}
      </div> : null)
  }
}
export default CompanyNoteModal
