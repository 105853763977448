import React from 'react'

class DashboardBenefitsSummaryToggle extends React.Component {
  buildView() {
    if (this.props.graphView == 'table') {
      this.view = {
        detailView: 'inactive',
        summaryView: 'inactive',
        tableView: 'active'
      }
    } else if (this.props.graphView == 'detail') {
      this.view = {
        detailView: 'active',
        summaryView: 'inactive',
        tableView: 'inactive'
      }
    } else {
      this.view = {
        detailView: 'inactive',
        summaryView: 'active',
        tableView: 'inactive'
      }
    }
  }

  toggleToSummaryView () {
    if (this.view.summaryView === 'inactive') {
      this.props.toggleView('graphView', 'summary')
    }
  }

  toggleToDetailView () {
    if (this.view.detailView === 'inactive') {
      this.props.toggleView('graphView', 'detail')
    }
  }

  toggleToTableView () {
    if (this.view.tableView === 'inactive') {
      this.props.toggleView('graphView', 'table')
    }
  }

  renderTableButton () {
    if (this.props.showAvailableBalances) {
      return (
        <div
          className={`dashboard-benefit-view right-button ${this.view.tableView}`}
          onClick={this.toggleToTableView.bind(this)}
        >
          Table
        </div>
      )
    }
  }

  render () {
    this.buildView()
    const detailButtonClass = this.props.showAvailableBalances ? 'center-button' : 'right-button'

    return (
      <div className='view-toggle'>
        <div
          className={`dashboard-benefit-view left-button ${this.view.summaryView}`}
          onClick={this.toggleToSummaryView.bind(this)}
        >
          Summary
        </div>
        <div
          className={`dashboard-benefit-view ${detailButtonClass} ${this.view.detailView}`}
          onClick={this.toggleToDetailView.bind(this)}
        >
          Details
        </div>
        {this.renderTableButton()}
      </div>
    )
  }
}

export default DashboardBenefitsSummaryToggle
