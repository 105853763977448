import React, { useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import Selectpicker from '../../shared/inputs/selectpicker'


function IpAdminForm(props) {
  const initialFormValue = props.mode === 'add' ? {} : props.initialFormData
  const { register, handleSubmit, watch, errors, control } = useForm({ defaultValues: initialFormValue });
  const [disableSave, setDisableSave] = useState(false)
  const formRef = useRef(null);

  const onSubmit = (data) => {
    let method = 'POST'
    let url = `/admin/implementation_partners/${props.partnerId}/implementation_partner_admins`
    setDisableSave(true)
    if (props.mode == 'edit'){
      method = 'PUT'
      url = `/admin/implementation_partners/${props.partnerId}/implementation_partner_admins/${props.initialFormData.id}/`
    }

    $.ajax({
      method: method,
      url: url,
      data: data,
      success: (response) => {
        toastr.success(response.message)
        props.updateAdmins(response.data, props.mode)
        setDisableSave(false)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
        setDisableSave(false)
      }
    })
  };

  return (
    <div className="react-modal">
      <div className="back-shadow"></div>
      <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button" onClick={props.hideIpFrom}>×</button>
            <h4 className="modal-title">IP Admins</h4>
          </div>
            <form className='generalised-form' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className="modal-body">
              <div className="form-group">
                <label>First Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="implementation_partner_admin[user_attributes][profile_attributes][first_name]"
                    required={true}
                    ref={register}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Middle Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="implementation_partner_admin[user_attributes][profile_attributes][middle_name]"
                    required={false}
                    ref={register}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="implementation_partner_admin[user_attributes][profile_attributes][last_name]"
                    required={true}
                    ref={register}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="implementation_partner_admin[user_attributes][email]"
                    required={true}
                    ref={register}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Phone</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="implementation_partner_admin[phone]"
                    required={true}
                    ref={register}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="buttons">
                <button className="btn btn-primary" disabled={disableSave}>
                  { props.mode == 'add' ? 'Save and Send Invite' : 'Save' }
                </button>
              </div>
            </div>
            </form>
        </div>
    </div>
  )
}

export default IpAdminForm;
