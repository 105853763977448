import React from 'react'
import DataTable from '../task_items_table/DataTable'
import Pagination from '../pagination/pagination'

class StatutoryTaskTemplateAssociationView extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      isIpa: props.isIpa,
      itemsPerpage: 30,
      association: false,
      associatedItems: props.items.map(item => {
        item.associate = true
        return item
      }),
      tempAssociatedItems: [],
      nonAssociatedItems: props.nonAssociatedItems.map(item => {
        item.associate = false
        return item
      }),
      items: props.items.map(item => {
        item.associate = true
        return item
      }),
      pageNumber: 1,
      totalPages: parseInt(props.items.length/30) + props.items.length%30 > 0 && 1,
      sortBy: {
        column: props.defaultSortBy,
        reverse: false
      }
    }
  }

  componentDidMount() {
    sessionStorage.setItem(this.props.sessionStore, '[]')
    const tempAssociatedItems = this.state.associatedItems.filter(item => {
      return !item.disable_association
    }).map(item => item.id)
    this.setState({tempAssociatedItems})
  }

  handleSelectAll (e) {
    let checked = e.currentTarget.checked
    var {tempAssociatedItems} = this.state
    var items = this.state.items.map(item => {
      if (!item.disable_association) {
        item.associate = checked
        if (checked) {
          if (tempAssociatedItems.indexOf(item.id) < 0) {
            tempAssociatedItems.push(item.id)
          }
        }
      }
      return item
    })
    if (!checked) {
      tempAssociatedItems = []
    }
    this.setState({items: items, tempAssociatedItems: tempAssociatedItems})
  }

  allSelected () {
    var selectableItems = this.state.items.filter(item => {
      return !item.disable_association
    })
    return selectableItems.length === this.state.tempAssociatedItems.length
  }

  selectedItems () {
    length = this.state.tempAssociatedItems.length
    return length > 0 ?
      (
        `(${length} ${this.props.associationWith.pluralize(length, 'noun')} Selected)`
      ) : ''
  }

  submitSearchForm (e) {
    e.preventDefault()
    const query = $(e.currentTarget).find('input').val()
    if(query.trim()){
      $.getJSON(`${this.props.associationUrl}/search_associations`, {q: query, association_with: this.props.associationWith}, (response) => {
        var items = response.items.map(item => {
          if (this.itemAssociated(item)) {
            item.associate = true
          }
          return item
        })
        this.setState({
          pageNumber: 1,
          search: query.trim().length > 0,
          totalPages: parseInt(items.length/this.state.itemsPerpage) + (items.length%this.state.itemsPerpage > 0 && 1),
          items: items
        })
      })
    } else {
      this.handleOnAssociateButtonClick()
    }
  }

  itemAssociated (item) {
    let itemIds = this.state.associatedItems.map(item => item.id)
      .concat(this.state.tempAssociatedItems)
      .uniq()
    return itemIds.indexOf(item.id) >= 0
  }

  handleOnAssociateButtonClick () {
    var items = this.state.associatedItems.concat(this.state.nonAssociatedItems)
    this.setState({
      association: true,
      search: false,
      items: items.map(item => {
        if (this.itemAssociated(item)) {
          item.associate = true
        }
        return item
      }),
      pageNumber: 1,
      totalPages: parseInt(items.length/this.state.itemsPerpage) + (items.length%this.state.itemsPerpage > 0 && 1)
    })
  }

  cancelAssociation () {
    var items = this.state.associatedItems
    const nonAssociatedItems = this.state.nonAssociatedItems.map(item => {
      item.associate = false
      return item
    })
    this.setState({
      association: false,
      items: items,
      search: false,
      tempAssociatedItems: items.filter(item => {
        return !item.disable_association
      }).map(item => item.id),
      nonAssociatedItems: nonAssociatedItems,
      pageNumber: 1,
      totalPages: parseInt(items.length/this.state.itemsPerpage) + (items.length%this.state.itemsPerpage > 0 && 1)
    })
  }

  getCurrentItems() {
    if (this.state.association && !this.state.search) {
      return this.getSortedItems(this.state.associatedItems)
        .concat(this.getSortedItems(this.state.nonAssociatedItems))
    } else {
      return this.getSortedItems(this.state.items)
    }
  }

  getSortedPaginatedItems () {
    return this.getPaginatedItems(this.getCurrentItems())
  }

  getSortedItems (items) {
    const sortBy = this.state.sortBy
    const dates = ["updated_at"]
    const sortByColumn = sortBy.column
    const sorted = items.sort (((item1, item2) => {
      const sortValue = dates.indexOf(sortByColumn) === -1 ?
        this.sortString(item1[sortByColumn], item2[sortByColumn]) :
        this.sortDate(item1[sortByColumn], item2[sortByColumn])
      return sortValue !== 0 ? sortValue : this.sortId(item1.id, item2.id)
    }).bind(this))
    return sortBy.reverse ? sorted.reverse() : sorted
  }

  sortDate (d1, d2) {
    d1 = new Date(d1)
    d2 = new Date(d2)
    return d1 - d2
  }

  sortString (str1, str2) {
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()
    if (str1 < str2) {
      return -1
    } else if (str2 < str1) {
      return 1
    }
    return 0
  }

  sortId (id1, id2) {
    return id1 - id2
  }

  getPaginatedItems(items) {
    const pageNumber = this.state.pageNumber
    var paginatedItems = items.slice((pageNumber - 1) * this.state.itemsPerpage, (pageNumber - 1) * this.state.itemsPerpage + this.state.itemsPerpage)
    if (items.length && !paginatedItems.length) {
      totalPages = parseInt(items.length/this.state.itemsPerpage) + (items%this.state.itemsPerpage > 0 && 1)
      this.setState({pageNumber: totalPages, totalPages: totalPages})
      paginatedItems = items.slice((totalPages - 1) * this.state.itemsPerpage, (totalPages - 1) * this.state.itemsPerpage + this.state.itemsPerpage)
    }
    return paginatedItems
  }

  saveAssociation () {
    $.post(`${this.props.associationUrl}/create_task_template_associations`, {association_with: this.props.associationWith, association_ids: this.state.tempAssociatedItems}, (response) => {
      this.updateItems(response.associated_items, 1)
      toastr.success(response.success_message)
    })
  }

  handleDelete (id) {
    $.ajax({
      method: 'DELETE',
      url: `${this.props.associationUrl}/delete_task_template_association`,
      data: {item_id: id, association_with: this.props.associationWith},
      dataType: 'json',
      success: (response) => {
        this.updateItems(response.associated_items, this.state.pageNumber)
        toastr.success(response.success_message)
      }
    })
  }

  updateItems (associatedIds, pageNumber) {
    gon.associated_items_count = associatedIds.length
    if (gon.associated_item_class) {
      if (gon.associated_items_count > 0) {
        if (this.props.associationWith === 'Planning Guide') {
          gon.associated_item_class = '.guide-association'
          $('li.benefit-association').addClass('hide')
        } else {
          gon.associated_item_class = '.benefit-association'
          $('li.guide-association').addClass('hide')
        }
      }
      else {
        gon.associated_item_class = '.association'
        $('li.association').removeClass('hide')
      }
    }
    const items = this.state.associatedItems.concat(this.state.nonAssociatedItems)
    let associatedItems = items.filter(item => {
      return associatedIds.indexOf(item.id) >= 0
    }).map(item => {
      item.associate = true
      return item
    })
    let nonAssociatedItems = items.filter(item => {
      return associatedIds.indexOf(item.id) < 0
    }).map(item => {
      item.associate = false
      return item
    })
    this.setState({
      items: associatedItems,
      associatedItems: associatedItems,
      nonAssociatedItems: nonAssociatedItems,
      tempAssociatedItems: associatedItems.filter(item => {
        return !item.disable_association
      }).map(item => item.id),
      association: false,
      pageNumber: pageNumber,
      totalPages: parseInt(associatedItems.length/this.state.itemsPerpage) + (associatedItems.length%this.state.itemsPerpage > 0 && 1)
    })
  }

  renderAssociationHeader () {
    return this.state.association ? (
      <div className='col-xs-12 association-header'>
        <div className='row'>
          <div className='col-xs-4 select-all-association'>
            <input
              type='checkbox'
              className='switch'
              checked={this.allSelected()}
              value='1'
              onChange={this.handleSelectAll.bind(this)}
            />
            <span>{`Select All ${this.selectedItems()}`}</span>
          </div>
          <div className='col-xs-3'>
            <form className='row pull-right' action='#' onSubmit={this.submitSearchForm.bind(this)}>
              <div className='input-group'>
                <input className='form-control' placeholder='Search Text'/>
                <span className='input-group-btn'>
                  <button className='btn btn-default' type='submit'>
                    <i className='fa fa-search'/>
                  </button>
                </span>
              </div>
            </form>
          </div>
          <div className='col-xs-5'>
            <div className='row'>
              <div className='col-xs-6'>
                <button className='btn btn-danger pull-right' onClick={this.cancelAssociation.bind(this)}>
                  Cancel
                </button>
              </div>
              <div className='col-xs-6'>
                <button className='btn btn-primary pull-right save-associations' onClick={this.saveAssociation.bind(this)}>
                  {`Save ${this.props.associationWith} Associations`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) :
    (
      !this.state.isIpa && <div className='col-xs-12 association-header'>
        <button className='btn btn-primary pull-right' onClick={this.handleOnAssociateButtonClick.bind(this)}>
          {`Associate ${this.props.associationWith}s`}
        </button>
      </div>
    )
  }

  handleAssociate (itemId) {
    var { items, tempAssociatedItems, associatedItems, nonAssociatedItems } = this.state
    if (tempAssociatedItems.indexOf(itemId) >= 0) {
      tempAssociatedItems.splice($.inArray(itemId, tempAssociatedItems), 1)
    } else {
      tempAssociatedItems.push(itemId)
    }
    this.setState({
      tempAssociatedItems,
      items: items.map(item => {
        item.associate = tempAssociatedItems.indexOf(item.id) >= 0
        return item
      }),
      associatedItems: associatedItems.map(item => {
        item.associate = tempAssociatedItems.indexOf(item.id) >= 0
        return item
      }),
      nonAssociatedItems: nonAssociatedItems.map(item => {
        item.associate = tempAssociatedItems.indexOf(item.id) >= 0
        return item
      }),
    })
  }

  sort (column, reverse) {
    const sortBy = {column: column, reverse: reverse}
    this.setState({sortBy})
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    })
  }

  showPagination () {
    return this.state.totalPages && this.state.totalPages > 1
  }

  paginationClass() {
    return this.showPagination() ? "pagination-on" : ""
  }

  render () {
    return (
      <div>
        <div className={`items-table-pagination ${this.paginationClass()}`}>
          <Pagination
            totalItems={this.getCurrentItems().length}
            changePageNumber={this.changePageNumber.bind(this)}
            pageNumber={this.state.pageNumber}
            itemsPerPage={30}
          />
        </div>
        { this.renderAssociationHeader() }
        <DataTable
          {...this.props}
          items={this.getSortedPaginatedItems()}
          canAssociate={this.state.association}
          handleAssociate={this.handleAssociate.bind(this)}
          sort={this.sort.bind(this)}
          handleDelete={this.handleDelete.bind(this)}
          showPagination={this.showPagination.bind(this)}
        />
      </div>
    )
  }
}

export default StatutoryTaskTemplateAssociationView;
