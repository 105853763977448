import React from 'react'
import CustomFilterForm from './CustomFilterForm'

const HEADERS = ['Name', 'Associtable With', 'Question / Eligibility Field Name', 'Question / Data Type', 'Options', 'Created At', 'Updated At', 'Action']
const FORMAT = 'YYYY-MM-DD'

class CompanyCustomFilters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddCCFModal: false,
      showDeleteModal: false,
      showEditCCFModal: false,
      companyCustomFilters: props.companyCustomFilters,
      availableLocations: props.availableLocations,
      companyJobCategories: props.companyJobCategories,
      companyLeaveScenarios: props.companyLeaveScenarios,
      selectedCustomFilter: {},
      question: null,
      addMore: false,
      showPlusButton: false,
      formElementInputCounter: 0
    }
  }

  showAddCCFModal () {
    this.setState({
      showAddCCFModal: true
    })
  }

  showEditCCFModal (customFilter) {
    this.setState({
      showEditCCFModal: true,
      showAddCCFModal: false,
      selectedCustomFilter: customFilter
    })
  }

  handleDeleteModalClose () {
    this.setState({
      showDeleteModal: false
    })
  }

  handleDelete (ccf, e) {
    e.stopPropagation()

    this.setState({showDeleteModal: true, deletableCCF: ccf})
  }

  handleDeleteCCF (ccf) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/companies/custom_filters/${ccf.id}`,
      success: (response) => {
        if (response.success) {
          this.setState({companyCustomFilters: this.state.companyCustomFilters.filter( (c) => {return (c !== ccf) })})
          toastr.success(`Successfully deleted ${ccf.name}.`)
        } else {
          toastr.error(`Sorry, ${ccf.name} could not be deleted.`)
        }
      }
    })

    this.setState({showDeleteModal: false})
  }

  showDeleteModal () {
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ () => this.handleDeleteModalClose() }
            >
              ×
            </button>
            <h4 className="modal-title">
              Delete confirmation
            </h4>
          </div>
          <div className="modal-body">
            Are you sure you want to delete?
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary" onClick={ () => this.handleDeleteCCF(this.state.deletableCCF) }>
                Ok
              </button>
              <button className="btn btn-primary" onClick={ () => this.handleDeleteModalClose() }>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  closeModalHandler (key, value) {
    this.setState({[key]: value})
  }

  addedRecordHandler (customFilter) {
    this.setState(prevState => {
      return {
        companyCustomFilters: [customFilter, ...prevState.companyCustomFilters],
        showAddCCFModal: false
      }
    })
  }

  updatedRecordHandler (customFilter) {
    this.setState(prevState => {
      return {
        companyCustomFilters: prevState.companyCustomFilters.map((filter, index) => {
          if (filter.id === customFilter.id) {
            return customFilter
          } else {
            return filter
          }
        }),
        showEditCCFModal: false
      }
    })
  }

  addCCFModal () {
    return (
      <CustomFilterForm
        isNew={true}
        availableLocations={this.state.availableLocations}
        companyJobCategories={this.state.companyJobCategories}
        companyLeaveScenarios={this.state.companyLeaveScenarios}
        customFilter={{}}
        onModalClose={(e) => this.closeModalHandler('showAddCCFModal', false)}
        onSubmit={this.addedRecordHandler.bind(this)}
      />
    )
  }

  editCCFModal () {
    const {selectedCustomFilter, availableLocations, companyJobCategories, companyLeaveScenarios} = this.state
    const customFilter = {
      id: selectedCustomFilter.id,
      name: selectedCustomFilter.name,
      associatedTo: selectedCustomFilter.associated_to,
      selectedLocations: selectedCustomFilter.associated_locations,
      selectedReportsToLocations: selectedCustomFilter.associated_reports_to_locations,
      question: selectedCustomFilter.question,
      dataType: selectedCustomFilter.data_type,
      requiredForEmployee: selectedCustomFilter.required_for_employee,
      status: selectedCustomFilter.status,
      retired_at: selectedCustomFilter.retired_at,
      multiselectOptions: selectedCustomFilter.multiselect_options,
      selectedJobCategories: selectedCustomFilter.job_category_ids,
      selectedCompanyLeaveScenarios: selectedCustomFilter.company_leave_scenario_ids,
      customizedForEFile: selectedCustomFilter.customized_for_company_eligibility_file
    }
    return (
      <CustomFilterForm
        isEdit={true}
        availableLocations={availableLocations}
        companyJobCategories={companyJobCategories}
        companyLeaveScenarios={companyLeaveScenarios}
        customFilter={customFilter}
        onModalClose={(e) => this.closeModalHandler('showEditCCFModal', false)}
        onSubmit={this.updatedRecordHandler.bind(this)}
      />
    )
  }

  render() {
    const { companyCustomFilters, showAddCCFModal, showEditCCFModal, showDeleteModal } = this.state

    return (
      <div>
        <div className="pull-right">
          <button className="btn btn-primary form-control" onClick={ () => this.showAddCCFModal() }> Add new </button>
        </div>
        <table className='table table-hover'>
          <thead>
            <tr>{ HEADERS.map((header, idx) => <th key={ idx }> { header } </th> ) }</tr>
          </thead>
          <tbody>
            {
              companyCustomFilters.sort().map((ccf, idx) =>  {
                return(
                  <tr key={ idx } onClick={ () => this.showEditCCFModal(ccf) }>
                    <td> { ccf.name } </td>
                    <td> { ccf.associated_to.map((assoc, idx) => { return(<ul key={ idx }> <li> { assoc }</li> </ul>) }) } </td>
                    <td> { ccf.question } </td>
                    <td> { ccf.data_type.camelize() } </td>
                    <td> { ccf.multiselect_options.map((opt) => { return(<ul key={ opt.id }> <li> { opt.name } </li> </ul>)}) } </td>
                    <td> { moment(ccf.created_at).format(FORMAT) } </td>
                    <td> { moment(ccf.updated_at).format(FORMAT) } </td>
                    <td> <button className="btn btn-danger" onClick={ (e) => this.handleDelete(ccf, e) }> Delete</button> </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        { showAddCCFModal ? this.addCCFModal() : null }
        { showEditCCFModal ? this.editCCFModal() : null }
        { showDeleteModal ? this.showDeleteModal() : null }
      </div>
    )
  }

}

export default CompanyCustomFilters
