import React, { useState } from "react";
import { Box, Button, Container } from "@material-ui/core";
import AuthLayout from "./AuthLayout";
import { useStyles } from "./useStyles";
import FormInput from "./FormInput";

const ForgetPassword = () => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const classes = useStyles()

  const handleSubmit = (e) => {
    e.preventDefault();
    let method = 'POST'
    let url = '/users/password'

    if (validateInputs()) {
      setIsSubmitted(true)
      $.ajax({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ user: { email } }),
        success: (response) => {
          window.location = response.redirect_to
          toastr.success(response.message)
          setIsSubmitted(false)
        },
        error: (err) => {
          toastr.error(JSON.parse(err.responseText).message)
          setIsSubmitted(false)
        }
      })
    }
  }

  const validateInputs = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = true;
    if (!emailRegex.test(email.toLowerCase())) {
      isValid = false;
      setIsEmailValid(isValid);
    } else {
      setIsEmailValid(true);
    }

    return isValid;
  };

  return (
    <AuthLayout>
      <Container maxWidth="sm">
        <div className={classes.registrationDiv}>
          <div className={classes.headings}>
            <span className={classes.heading1}><span className="underline">Reset Password</span></span>
          </div>
          <form name="form" onSubmit={handleSubmit}>
            <div className={classes.formDiv}>
              <FormInput
                type="text"
                name="login"
                formLabel="Email"
                placeholder="Work Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!isEmailValid}
                helperText={"You must enter a valid email address"}
              />

              <Box display="flex" style={{ gap: 10 }}>
                Already have an account?
                <a className={classes.forgetPassword} href="/users/sign_in">
                  Sign in
                </a>

              </Box>
              <div className={classes.formGroup}>
                <Button style={{ fontFamily: "MontserratRegular", fontSize: "13px" }} className="primary-login-button" type="submit" disabled={isSubmitted}>
                  Send me password reset instructions
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </AuthLayout >
  )
}

export default ForgetPassword;
