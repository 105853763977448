export const fontFormats =  "Andale Mono=andale mono,times;"+
  "Arial=arial,helvetica,sans-serif;"+
  "Arial Black=arial black,avant garde;"+
  "Book Antiqua=book antiqua,palatino;"+
  "Comic Sans MS=comic sans ms,sans-serif;"+
  "Courier New=courier new,courier;"+
  "Georgia=georgia,palatino;"+
  "Graphik Super=graphik super;"+
  "Graphik Black=graphik black;"+
  "Graphik Bold=graphik bold;"+
  "Graphik Semibold=graphik semibold;"+
  "Graphik Medium=graphik medium;"+
  "Graphik Regular=graphik regular;"+
  "Graphik Light=graphik light;"+
  "Graphik Extralight=graphik extralight;"+
  "Graphik Thin=graphik thin;"+
  "Helvetica=helvetica;"+
  "Impact=impact,chicago;"+
  "Symbol=symbol;"+
  "Tahoma=tahoma,arial,helvetica,sans-serif;"+
  "Terminal=terminal,monaco;"+
  "Times New Roman=times new roman,times;"+
  "Trebuchet MS=trebuchet ms,geneva;"+
  "Verdana=verdana,geneva;"+
  "Webdings=webdings;"+
  "Wingdings=wingdings,zapf dingbats"
