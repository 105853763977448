import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import {
  colorMaps,
  fontFormats,
  templates,
  BASE_URL,
} from './constants';
import {
  buildCustomPreview,
  insertBlankLineIfNecessary,
  preventBackspaceIfNecessary,
  registerCustomPreviewButton,
} from './helper'

const TinymceEditor = ({
  editorRef,
  content,
  onChange,
  showPreview,
  substitutionPreview,
  substitutionWidth
}) => {
  const iframeDescriptionDiv = useRef(null);

  useEffect(() => {
    if (showPreview) {
      buildPreviewIframe()
    }
  }, [showPreview, content])

  const buildPreviewIframe = () => {
    let iframe = document.createElement('iframe')
    let height = !!substitutionPreview ? substitutionWidth.toString() : '500'
    iframe.setAttribute('style', `height: ${height}px; width: 95%; background-color: white;`)
    iframe.setAttribute('frameborder', '0')
    iframeDescriptionDiv.current.innerHTML = ''
    iframeDescriptionDiv.current.appendChild(iframe)
    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(buildCustomPreview(content))
    iframe.contentWindow.document.close()
  }

  const getTinymceCss = () => {
    const css = "assets/custom_tinymce.css, assets/graphik.css, assets/custom_tinymce_editor.css"
    // if (showNewSkin) css += ", assets/montserrat.css, assets/custom_tinymce_latest.css"
    return css
  }

  const getEphoxUrl = () => {
    switch (gon.rails_environment) {
      case 'production':
        return `https://app.leavelogic.com`
      case 'preprod':
        return `https://preprod.leavelogic.com`
      default:
        return `https://integration.leavelogic.com`
    }
  }

  const ephoxUrl = getEphoxUrl()
  const setup = (editor) => {
    registerCustomPreviewButton(editor)
  }

  if (showPreview) {
    return (
      <div className='iframe-description-container' ref={iframeDescriptionDiv}>
      </div>
    )
  }

  return (
    <Editor
      tinymceScriptSrc={`${BASE_URL}/assets/tinymce.min.js`}
      onInit={(evt, editor) => {
        editorRef.current = editor
        editor.getDoc().body.style.fontFamily = 'Arial'
        editor.getDoc().body.style.fontSize = '12pt'
      }}
      onBeforeExecCommand={insertBlankLineIfNecessary}
      onKeyDown={preventBackspaceIfNecessary}
      initialValue={content}
      cache_suffix={`?v=5.6.1`}
      init={{
        setup: setup,
        theme: 'silver',
        templates: templates,
        toolbar_items_size: 'small',
        content_css: getTinymceCss(),
        toolbar: 'bold italic underline strikethrough | link image template | alignleft aligncenter alignright alignjustify | formatselect | fontselect | fontsizeselect | bullist numlist | outdent indent| undo redo | blockquote removeformat | subscript superscript | forecolor backcolor | code a11ycheck hr table | searchreplace previewButton help fullscreen',
        pagebreak_separator: '<p class=\'page-separator\'>&nbsp;</p>',
        width: "95%",
        plugins: ['link', 'preview', 'powerpaste', 'advcode', 'a11ychecker', 'lists','advlist', 'autolink', 'hr', 'searchreplace', 'fullscreen', 'help', 'table', 'visualblocks', 'visualchars', 'image', 'imagetools', 'tinymcespellchecker', 'linkchecker', 'media', 'mediaembed', 'template'],
        imagetools_toolbar: "editimage | imageoptions",
        fontsize_formats: "8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt",
        branding: false,
        resize: false,
        relative_urls: false,
        remove_script_host: false,
        link_context_toolbar: true,
        powerpaste_html_import: "prompt",
        extended_valid_elements : "span[!style]",
        images_upload_url: `/admin/companies/${gon.company_id}/upload_image`,
        images_upload_timeout: 5000,
        spellchecker_rpc_url: `${ephoxUrl}/ephox-spelling/`,
        linkchecker_service_url: `${ephoxUrl}/ephox-hyperlinking/`,
        mediaembed_service_url: `${ephoxUrl}/ephox-hyperlinking/`,
        imagetools_proxy: `${ephoxUrl}/ephox-image-proxy/1/image`,
        mediaembed_max_width: 450,
        spellchecker_language: 'en',
        document_base_url: BASE_URL,
        font_formats: fontFormats,
        color_map: colorMaps,
        plugin_preview_width : "900",
        height: 500,
      }}
    />
  )
}

export default TinymceEditor
