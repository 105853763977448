import React from 'react'

class IntercomToggleButton extends React.Component {

  constructor () {
    super ()

    this.state = {
      showIntercomWidget: true
    }
  }

  toggleIntercomWidget () {
    const { showIntercomWidget } = this.state
    this.setState({
      showIntercomWidget: !showIntercomWidget
    }, () => this.toggleWidget())
  }

  toggleWidget () {
    $('#intercom-container').toggle(this.state.showIntercomWidget)
  }

  renderIntercomIcon() {
    const chevronClass = this.state.showIntercomWidget ? 'fa-chevron-down' : 'fa-chevron-up'
    return (
      <i className={`intercom-toggle-button fa ${chevronClass}`} onClick={() => this.toggleIntercomWidget()}/>
    )
  }

  render () {
    return (gon.hide_in_app_chat) ? '' : this.renderIntercomIcon()
  }
}

export default IntercomToggleButton