import React from 'react'
import ListItems from './ListItems'
import Modal from '../../shared/Modal'
import Pagination from '../pagination/pagination'
import BulkEditModal from '../../shared/Table/components/bulkEditModal'

const confirmationMsg = {
  retired: 'The Office will be retired. Are you sure you want to continue ?',
  active: 'The office will be activated. Are you sure you want to continue?',
  in_review: 'The office will be in In Review. Are you sure you want to continue?',
  draft: 'The office will be in In Draft. Are you sure you want to continue?',
  delete: 'Are you sure you want to delete it ?'
}

class OfficeLocations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: '/admin/offices',
      allOffices: props.allOffices,
      allSelected: false,
      offices: props.offices,
      modal: {
        show: false,
        confirmationType: '',
        id: ''
      },
      selectedOffice: {
        id: '',
        newStatus: ''
      },
      currentPage: 1,
      totalCount: props.totalCount,
      showBulkEditModal: false,
      editData: {},
      bulkItems: [],
    }
  }

  changeStatus (id, newStatus) {
    this.setState({
      modal: {
        show: true,
        confirmationType: newStatus,
        id: id
      },
      selectedOffice: {
        id: id,
        newStatus: newStatus
      }
    })
  }

  handleAssociation (e, id, itemIndex)  {
    e.stopPropagation()

    let { offices, bulkItems, allSelected, selectedItems, totalCount } = this.state
    let allChecked = false
    offices[itemIndex].associate = !offices[itemIndex].associate

    if (e.target.checked){
      bulkItems.push(id)
      allChecked = allSelected || bulkItems.length == totalCount
    } else {
      bulkItems.splice($.inArray(id, bulkItems), 1)
    }

    this.setState({
      ...this.state,
      offices: offices,
      bulkItems: bulkItems,
      allSelected: allChecked
    })
  }

  showModal () {
    if (this.state.modal.show) {
      return (
        <Modal
          {...this.state.modal}
          hideModalInParent={this.hideModal.bind(this)}
          id={this.state.modal.id}
          approve={this.confirmationApproval()} >
          <span>{ this.confirmationMessage() }</span>
        </Modal>
      )
    }
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{
        show: false,
        confirmationType: '',
        id: null
      }
    }
    this.setState({
      modal
    })
  }

  confirmationMessage () {
    return confirmationMsg[this.state.modal.confirmationType]
  }

  confirmationApproval () {
    return this.editStatus.bind(this)
  }

  editStatus () {
    let url = ''
    let method= ''

    if (this.state.selectedOffice.newStatus !='delete') {
      method='POST'
      url = `${this.props.baseLink}/${this.state.selectedOffice.id}/update_status?status=${this.state.selectedOffice.newStatus}`
    } else {
      method='DELETE'
      url = `${this.props.baseLink}/${this.state.selectedOffice.id}`
    }

    $.ajax({
      method: method,
      url: url,
      success: (res) => {
        toastr.success(res.msg)
        let { offices } = this.state

        if (method == 'DELETE') {
          offices = offices.filter( (office,index) => office.id != this.state.selectedOffice.id)
        } else {
          $.each(offices, function (i, item) {
            if (item.id == res.data.id) {
              offices[i] = res.data
              return false
            }
          })
        }
        this.setState({
          offices: offices
        })
      },
      error: (res) => {
        toastr.error(res.responseJSON.msg)
      }
    })

  }

  changePageNumber (selectedPageNumber) {
    let url = window.location.pathname
    url += window.location.search

    let params = {
      page: selectedPageNumber
    }

    $.ajax(({
      cache: false,
      url: url,
      dataType: 'json',
      data: params,
      success: (response) => {
        const { bulkItems } = this.state

        const offices = response.offices.map(item => {
          item.associate = bulkItems.indexOf(item.id) > -1
          return item
        })

        this.setState({
          offices: offices,
          currentPage: selectedPageNumber
        })
      }
    }))
  }

  buildEditData (data) {
    const statusField = data.status ? {status: data.status} : {}
    return {
      ...statusField
    }
  }

  showBulkEditModal () {
    if (this.state.showBulkEditModal) {
      return <BulkEditModal
        itemsCount={this.state.bulkItems.length}
        handleBulkEditModal={this.cancelBulkEdit.bind(this)}
        handleBulkEdit={this.handleBulkEdit.bind(this)}
        data={this.buildEditData(this.state.editData)} />
    }
  }

  cancelBulkEdit () {
    this.setState({
      ...this.state,
      showBulkEditModal: false,
      editData: {},
      //bulkItems:[]
    })
  }

  receivedSelectedItems (selectedItems) {
    const url = `${this.props.baseLink}/bulk_editable_items`
    $.getJSON(url, {ids: selectedItems }, (res) => {
      this.setState({
        showBulkEditModal: true,
        editData: res.data,
        bulkItems: selectedItems
      })
    })
  }

  handleBulkEdit (params) {
    $.displaySpinner('.modal-body')
    $('.confirmation-buttons').prop('disabled', true)
    jQuery.ajax({
      type: 'PUT',
      url: `${this.props.baseLink}/bulk_update_fields`,
      data: {
        ids: this.state.bulkItems,
        item_params: params,
        all_selected: this.state.allSelected
      },
      success: (res) => {
        const { offices } = this.state

        this.setState({allSelected: false})
        $.each(res.items, function (ind, res_item) {
          $.each(offices, function (i, item) {
            if (item.id == res_item.id) {
              offices[i] = res_item
              offices.associate = false
              return false
            }
          })
        })

        this.setState({
          offices: offices,
          showBulkEditModal: false,
          bulkItems: []
        })

        toastr.success('Items updated successfully')
        $.removeSpinner()
      }
    })
  }

  handleAllSelect (checked) {
    const bulkItems = this.state.bulkItems || []
    const officeIds = this.state.allOffices.map(item => (item.id))

    const filteredItems = checked ? bulkItems.concat(officeIds).uniq() : bulkItems.filter(item => officeIds.indexOf(item) < 0)

    this.setState({
      bulkItems: filteredItems,
      allSelected: checked,
      offices: this.state.offices.map( (item) => ({
        ...item,
        ...{
          associate: checked
        }
      }))
    })

  }

  render () {
    return ( this.state.offices.length > 0 ? (
      <div>
        <Pagination
          totalItems={this.state.totalCount}
          changePageNumber={this.changePageNumber.bind(this)}
          pageNumber={this.state.currentPage}
          itemsPerPage={30}
        />
        <ListItems
          items={this.state.offices}
          baseLink={this.props.baseLink}
          statusDropDown={this.props.statusDropDown}
          changeStatus={this.changeStatus.bind(this)}
          handleBulkEditModal={this.receivedSelectedItems.bind(this)}
          selectedItems={this.state.bulkItems}
          allSelected={this.state.allSelected}
          handleAssociation={this.handleAssociation.bind(this)}
          handleAllSelect={this.handleAllSelect.bind(this)}
          officeEditable={this.props.officeEditable}
        />
        { this.showModal() }
        { this.showBulkEditModal() }
      </div>
    ) : null )
  }
}

export default OfficeLocations
