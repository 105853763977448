import React, { useState, useEffect, useRef } from 'react';
import TinymceEditor from '../../shared/TinymceEditor/';

const TextEditor = (props) => {
  const editorRef = useRef(null);
  const [showPreview, setShowPreview] = useState(true)
  const [content, setContent] = useState(props.substitutionContent || '')
  const [submitting, setSubmitting] = useState(false)
  const [fetchUrl, setFetchUrl] = useState(props.fetch_url)
  const [saveUrl, setSaveUrl] = useState(props.save_url)
  const headers = {'Content-Type': 'application/json'}

  useEffect(() => {
    if (props.editor_for === 'leave_benefit_scenario') {
      PubSub.subscribe('benefitScenarioChanged', (topic, url) => {
        setFetchUrl(url.ruleFetchUrl)
        setSaveUrl(url.ruleSaveUrl)
        setShowPreview(true)
      })

      return () => PubSub.unsubscribe('benefitScenarioChanged')
    }
  }, [])

  useEffect(() => {
    if (fetchUrl) fetchContent()
  }, [fetchUrl])

  const fetchContent = () => {
    $.getJSON(fetchUrl, (res) => {
      if (res.success) {
        const data = res.data || ''
        setContent(data)
      }
    })
  }

  const save = () => {
    setSubmitting(true)
    $.ajax({
      url: saveUrl,
      headers,
      type: 'PUT',
      data: JSON.stringify({[props.field_name]: editorRef.current.getContent()}),
      success: (res) => {
        if (res.success) {
          toastr.success(res.message)
          setContent(editorRef.current.getContent())
          setShowPreview(true)
        } else {
          toastr.error(res.message)
        }
      },
      error: (err) => {
        toastr.error(JSON.parse(err.responseText).message)
      },
      complete: () => {
        setSubmitting(false)
      }
    })
  }

  const renderEditButton = () => {
    if (props.isNotEditable) return

    if (showPreview) {
      return (
        <button className="btn btn-primary btn-sm" onClick={() => setShowPreview(false)}>
          Edit Content
        </button>
      )
    } else {
      return (
        <div>
          <button
            className="btn btn-primary btn-sm btn-save-description btn-mg"
            onClick={save}
            disabled={submitting}
          >
            Save Content
          </button>
          <button
            className="btn btn-danger btn-sm btn-cancel-description"
            style={{marginLeft: '10px'}}
            onClick={() => setShowPreview(true)}
            disabled={submitting}
          >
            Cancel Content Edit
          </button>
        </div>
      )
    }
  }

  return (
    <div>
      {!props.substitutionPreview && <div style={{padding: "10px 0", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <div><h4>{props.header}</h4></div>
          { renderEditButton() }
        </div>
      }
      <TinymceEditor
        content={content}
        editorRef={editorRef}
        showPreview={showPreview}
        substitutionPreview={props.substitutionPreview || false}
        substitutionWidth={props.substitutionWidth}
      />
    </div>
  )
}

export default TextEditor;
