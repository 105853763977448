import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'
import ListImplementationPartnerAdmins from './listImplementationPartnerAdmins'

const initialKeyState = {
    gainsightSecretKey: true,
    gainsightProductKey: true,
    intercomAppSecret: true,
    intercomAccessToken: true,
    intercomAppId: true,
    gainsightCsAccessKey: true,
    gainsightCsEndpoint: true
  }

function BuildImplementationPartner(props) {
  let initialFormData = props.isEdit && props.initialFormData

  const { register, handleSubmit, watch, errors, control, setValue, getValues } = useForm({ defaultValues: props.initialFormData });
  const [ipAdmins, setIpAdmins] = useState({
    show: false,
    admins: []
  })
  const [switchStatus, setSwitchStatus] = useState({
    gainsight: props.isEdit && props.initialFormData.enable_gainsight,
    intercom: props.isEdit && props.initialFormData.enable_intercom
  })
  const [keyDisableState, setKeyState] = useState(initialKeyState)

  const [showEditPencil, setShowEditPencil] = useState({
    gainsightSecretKey: false,
    gainsightProductKey: false,
    intercomAppSecret: false,
    intercomAccessToken: false,
    intercomAppId: false,
    gainsightCsAccessKey: false,
    gainsightCsEndpoint: false
  })
  const formRef = useRef(null);

  const gainsightAvailable = () => {
    return ( props.isEdit && props.initialFormData.enable_gainsight )
  }

  const gainsightCsAvailable = () => {
    return ( props.isEdit && props.initialFormData.enable_cs_gainsight )
  }

  const intercomAvailable = () => {
    return ( props.isEdit && props.initialFormData.enable_intercom )
  }

  const watchCsGainsight = watch("enable_cs_gainsight");

  useEffect(() => {
    props.isEdit && fetchImplementationPartners()
  },[]);

  const setStatus = (key, status) => {
    setSwitchStatus({
      ...switchStatus,
      [key]: status
    })
  }

  const onChange = (key, e) => {
    setKeyState({
      ...keyDisableState,
      [key]: false
    })
  }

  const showEdit = (key, show) => {
    setShowEditPencil({
      ...showEditPencil,
      [key]: show
    })
  }

  const fetchImplementationPartners = () => {
    const method = 'GET'
    const implementation_partner_id = props.implementation_partner.id
    const url = `/admin/implementation_partners/${implementation_partner_id}/implementation_partner_admins`

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setIpAdmins({
          show: true,
          admins: response.data
        })
      },
      error: (response) => {
        toastr.error(response.responseJSON)
      }
    })
  }

  const contentHeader = () => {
    return (
      <div className='admin-header'>
        <div className='row'>
          <div className='col-xs-7'>
            <h3> Create Implementation Partner: </h3>
          </div>
          <div className='col-xs-5'>
            <br className="hidden-xs"></br>
            <a onClick={ () => { formRef.current.dispatchEvent(new Event('submit')) } } className='btn btn-primary pull-right'>Save</a>
          </div>
        </div>
      </div>
    )
  }

  const unRegisterKeys = (data) => {
    data['enable_gainsight'] = switchStatus.gainsight
    data['enable_intercom'] = switchStatus.intercom
    if (initialFormData){
      let keyBackup = {...data}
      if (data.gainsight_api_secret_key === initialFormData.gainsight_api_secret_key || keyDisableState.gainsightSecretKey) {
        delete data.gainsight_api_secret_key
      }
      if (data.gainsight_api_product_key === initialFormData.gainsight_api_product_key || keyDisableState.gainsightProductKey) {
        delete data.gainsight_api_product_key
      }

      if (data.intercom_app_id === initialFormData.intercom_app_id || keyDisableState.intercomAppId) {
        delete data.intercom_app_id
      }
      if (data.intercom_access_token === initialFormData.intercom_access_token || keyDisableState.intercomAccessToken) {
        delete data.intercom_access_token
      }
      if (data.intercom_app_secret === initialFormData.intercom_app_secret || keyDisableState.intercomAppSecret) {
        delete data.intercom_app_secret
      }
      if (data.gainsight_cs_access_key === initialFormData.gainsight_cs_access_key || keyDisableState.gainsightCsAccessKey) {
        delete data.gainsight_cs_access_key
      }
      if (data.cs_gainsight_endpoint === initialFormData.cs_gainsight_endpoint || keyDisableState.gainsightCsEndpoint) {
        delete data.cs_gainsight_endpoint
      }

      if (keyBackup){
        initialFormData['gainsight_api_secret_key'] = keyBackup.gainsight_api_secret_key
        initialFormData['gainsight_api_product_key'] = keyBackup.gainsight_api_product_key
        initialFormData['intercom_app_id'] = keyBackup.intercom_app_id
        initialFormData['intercom_access_token'] = keyBackup.intercom_access_token
        initialFormData['intercom_app_secret'] = keyBackup.intercom_app_secret
        initialFormData['gainsight_cs_access_key'] = keyBackup.gainsight_cs_access_key
        initialFormData['cs_gainsight_endpoint'] = keyBackup.cs_gainsight_endpoint
      }
    }
  }

  const onSubmit = (data) => {
    unRegisterKeys(data)

    let method = 'POST'
    let url = '/admin/implementation_partners'

    if (props.isEdit) {
      method='PUT'
      url=`/admin/implementation_partners/${props.implementation_partner.id}`
    }

    $.ajax({
      method: method,
      url: url,
      data: data,
      success: (response) => {
        toastr.success(response.message)
        if (props.isEdit) {
          fillKeys(response)
        } else {
          window.location = url
        }
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  };

  const fillKeys = (response) => {
    setValue('gainsight_api_secret_key', switchStatus.gainsight && response.data.gainsight_api_secret_key || '')
    setValue('gainsight_api_product_key', switchStatus.gainsight && response.data.gainsight_api_product_key || '')

    setValue('intercom_app_id', switchStatus.intercom && response.data.intercom_app_id || '')
    setValue('intercom_access_token', switchStatus.intercom && response.data.intercom_access_token || '')
    setValue('intercom_app_secret', switchStatus.intercom && response.data.intercom_app_secret || '')

    //setValue('gainsight_cs_access_key', watchCsGainsight && response.data.gainsight_cs_access_key || '')
    //setValue('cs_gainsight_endpoint', watchCsGainsight && response.data.cs_gainsight_endpoint || '')

    setKeyState(initialKeyState)

  }

  const validateKey = (data) => {
    let url=`/admin/implementation_partners/${props.implementation_partner.id}/validate_api_key`
    let method = 'POST'
    $.ajax({
      method: method,
      url: url,
      data: data,
      success: (response) => {
        if (response.validate) {
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const validateGainsightKey = () => {
    const gainsightValues = getValues(["gainsight_api_secret_key", "gainsight_api_product_key"]);

    if (gainsightValues['gainsight_api_product_key'] == '' || gainsightValues['gainsight_api_secret_key'] == '') {
      toastr.error('Keys cant be blank')
    } else {
      let data = {}
      data['key_type'] = 'gainsight'
      if ((gainsightValues['gainsight_api_product_key'] !== initialFormData.gainsight_api_product_key) || !initialFormData) {
        data['gainsight_api_product_key'] = gainsightValues['gainsight_api_product_key']
      }
      if ((gainsightValues['gainsight_api_secret_key'] !== initialFormData.gainsight_api_secret_key) || !initialFormData) {
        data['gainsight_api_secret_key'] = gainsightValues['gainsight_api_secret_key']
      }

      validateKey(data)
    }
  }

  const validateCsGainsightKey = () => {
    const gainsightValues = getValues(['gainsight_cs_access_key', 'cs_gainsight_endpoint']);

    if (gainsightValues['gainsight_cs_access_key'] == '' || gainsightValues['cs_gainsight_endpoint'] == '') {
      toastr.error('Keys cant be blank')
    } else {
      let data = {}
      data['key_type'] = 'gainsight_cs'
      if ((gainsightValues['gainsight_cs_access_key'] !== initialFormData.gainsight_cs_access_key) || !initialFormData) {
        data['gainsight_cs_access_key'] = gainsightValues['gainsight_cs_access_key']
      }
      if ((gainsightValues['cs_gainsight_endpoint'] !== initialFormData.cs_gainsight_endpoint) || !initialFormData) {
        data['cs_gainsight_endpoint'] = gainsightValues['cs_gainsight_endpoint']
      }

      validateKey(data)
    }
  }

  const validateIntercomKey = () => {
    const intercomValues = getValues(['intercom_app_id', 'intercom_access_token', 'intercom_app_secret']);

    if (intercomValues['intercom_app_id'] == '' || intercomValues['intercom_access_token'] == '' || intercomValues['intercom_app_secret'] == '') {
      toastr.error('Keys cant be blank')
    } else {
      let data = {}
      data['key_type'] = 'intercom'
      if ((intercomValues['intercom_app_id'] !== initialFormData.intercom_app_id) || !initialFormData) {
        data['intercom_app_id'] = intercomValues['intercom_app_id']
      }
      if ((intercomValues['intercom_access_token'] !== initialFormData.intercom_access_token) || !initialFormData) {
        data['intercom_access_token'] = intercomValues['intercom_access_token']
      }
      if ((intercomValues['intercom_app_secret'] !== initialFormData.intercom_app_secret) || !initialFormData) {
        data['intercom_app_secret'] = intercomValues['intercom_app_secret']
      }

      validateKey(data)
    }
  }


  const gainsightCsFields = () => {
    return (
      <div className="form-group">
        <div className="row">
            <div className="col-md-6">
              <label> Enable CS Gainsight: </label>
            </div>
            <div className="col-md-6">
              <input
                type="checkbox"
                name='enable_cs_gainsight'
                ref={register}
                defaultChecked={gainsightCsAvailable()}
              />
            </div>
        </div>
          {
            <div className="form-group" hidden={!watchCsGainsight} onMouseOver={(e) => showEdit('gainsightCsAccessKey', true)} onMouseLeave={(e) => showEdit('gainsightCsAccessKey', false)}>
              <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                  <label> Access Key: </label>
                </div>
                <div className="col-md-7">
                  <input
                    className="form-control terms-highlighted"
                    type="text"
                    name="gainsight_cs_access_key"
                    disabled={keyDisableState.gainsightCsAccessKey}
                    required={true}
                    ref={register({ required: watchCsGainsight })}
                  />
                  {errors.gainsight_cs_access_key && <span>This field is required</span>}
                </div>
                <div className="col-md-1">
                  { showEditPencil.gainsightCsAccessKey && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('gainsightCsAccessKey', e) }></i> }
              </div>
              </div>
            </div>
          }
          {
            <div className="form-group" hidden={!watchCsGainsight} onMouseOver={(e) => showEdit('gainsightCsEndpoint', true)} onMouseLeave={(e) => showEdit('gainsightCsEndpoint', false)}>
              <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                  <label> CS EndPoint: </label>
                </div>
                <div className="col-md-7">
                  <input
                    className="form-control terms-highlighted"
                    type="text"
                    name="cs_gainsight_endpoint"
                    disabled={keyDisableState.gainsightCsEndpoint}
                    required={true}
                    ref={register({ required: watchCsGainsight })}
                  />
                  {errors.cs_gainsight_endpoint && <span>This field is required</span>}
                </div>
                <div className="col-md-1">
                  { showEditPencil.gainsightCsEndpoint && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('gainsightCsEndpoint', e) }></i> }
              </div>
              </div>
            </div>
          }
          { props.isEdit &&
            <div className="form-group" hidden={!watchCsGainsight}>
              <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-2">
                  <a onClick={ () => { validateCsGainsightKey('gainsight') } } className='btn btn-primary pull-right'>Test</a>
                </div>
              </div>
            </div>
          }
      </div>
    )
  }

  const gainsightFields = () => {
    return (
      <div className="form-group">
        <div className="row">
            <div className="col-md-6">
              <label> Enable Gainsight: </label>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-1">
              <SwitchInput
                name='enable_gainsight'
                className='required-for-employee'
                onChange={(checked) => setStatus('gainsight',checked)}
                checked={switchStatus.gainsight}
              />
            </div>
        </div>
        <br/>
          {
            <div className="form-group" hidden={!switchStatus.gainsight} onMouseOver={(e) => showEdit('gainsightSecretKey', true)} onMouseLeave={(e) => showEdit('gainsightSecretKey', false)}>
              <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                  <label> Secret Key: </label>
                </div>
                <div className="col-md-7">
                  <input
                    className="form-control terms-highlighted"
                    type="text"
                    name="gainsight_api_secret_key"
                    disabled={keyDisableState.gainsightSecretKey}
                    required={true}
                    ref={register({ required: switchStatus.gainsight })}
                  />
                  {errors.gainsight_api_secret_key && <span>This field is required</span>}
                </div>
                <div className="col-md-1">
                  { showEditPencil.gainsightSecretKey && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('gainsightSecretKey', e) }></i> }
              </div>
              </div>
            </div>
          }

          {
            <div className="form-group" hidden={!switchStatus.gainsight} onMouseOver={(e) => showEdit('gainsightProductKey', true)} onMouseLeave={(e) => showEdit('gainsightProductKey', false)}>
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className="col-md-3">
                <label> Product Key: </label>
              </div>
              <div className="col-md-7">
                <input
                  className="form-control terms-highlighted"
                  type="text"
                  name="gainsight_api_product_key"
                  disabled={keyDisableState.gainsightProductKey}
                  required={true}
                  ref={register({ required: switchStatus.gainsight })}
                />
                {errors.gainsight_api_product_key && <span>This field is required</span>}
              </div>
              <div className="col-md-1">
                { showEditPencil.gainsightProductKey && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('gainsightProductKey', e) }></i> }
              </div>
            </div></div>
          }
          { props.isEdit &&
            <div className="form-group" hidden={!switchStatus.gainsight}>
              <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-7">
                  <a onClick={ () => { validateGainsightKey('gainsight') } } className='btn btn-primary pull-right'>Test</a>
                </div>
              </div>
            </div>
          }
      </div>
    )
  }

  const intercomFields = () => {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col-md-6">
              <label> Enable Intercom: </label>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-1">
              <SwitchInput
                name='enable_intercom'
                className='required-for-employee'
                onChange={(checked) => setStatus('intercom',checked)}
                checked={switchStatus.intercom}
              />
            </div>
        </div>
        <br/>
        {
          <div className="form-group" hidden={!switchStatus.intercom} onMouseOver={(e) => showEdit('intercomAppId', true)} onMouseLeave={(e) => showEdit('intercomAppId', false)}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label> App Id: </label>
              </div>
              <div className="col-md-7">
                <input
                  className="form-control terms-highlighted"
                  type="text"
                  name="intercom_app_id"
                  disabled={keyDisableState.intercomAppId}
                  required={true}
                  ref={register({ required: switchStatus.intercom })}
                />
                {errors.intercom_app_id && <span>This field is required</span>}
              </div>
              <div className="col-md-1">
                { showEditPencil.intercomAppId && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('intercomAppId', e) }></i> }
              </div>
            </div>
          </div>
        }
        {
          <div className="form-group" hidden={!switchStatus.intercom} onMouseOver={(e) => showEdit('intercomAccessToken', true)} onMouseLeave={(e) => showEdit('intercomAccessToken', false)}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label> Acess Token:</label>
              </div>
              <div className="col-md-7">
                <input
                  className="form-control terms-highlighted"
                  type="text"
                  name="intercom_access_token"
                  disabled={keyDisableState.intercomAccessToken}
                  required={true}
                  ref={register({ required: switchStatus.intercom })}
                />
                {errors.intercom_access_token && <span>This field is required</span>}
              </div>
              <div className="col-md-1">
                { showEditPencil.intercomAccessToken && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('intercomAccessToken', e) }></i> }
              </div>
            </div>
          </div>
        }
        {
          <div className="form-group" hidden={!switchStatus.intercom} onMouseOver={(e) => showEdit('intercomAppSecret', true)} onMouseLeave={(e) => showEdit('intercomAppSecret', false)}>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <label> App Secret: </label>
              </div>
              <div className="col-md-7">
                <input
                  className="form-control terms-highlighted"
                  type="text"
                  name="intercom_app_secret"
                  disabled={keyDisableState.intercomAppSecret}
                  required={true}
                  ref={register({ required: switchStatus.intercom })}
                />
                {errors.intercom_app_secret && <span>This field is required</span>}
              </div>
              <div className="col-md-1">
                { showEditPencil.intercomAppSecret && <i className="fa fa-pencil" aria-hidden="true" style={{fontSize: '30px'}} onClick={ (e) => onChange('intercomAppSecret', e) }></i> }
              </div>
            </div>
          </div>
        }
        { props.isEdit &&
          <div className="form-group" hidden={!switchStatus.intercom}>
            <div className="row">
              <div className="col-md-1">
              </div>
              <div className="col-md-3">
              </div>
              <div className="col-md-7">
                <a onClick={ () => { validateIntercomKey('intercom') } } className='btn btn-primary pull-right'>Test</a>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  const IpForm = () => {
    return (
      <form className='generalised-form' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className='row'>
          <div className='col-sm-5'>
            <div className="form-group">
              <label>Name</label>
              <input
                className="form-control terms-highlighted"
                type="text"
                name="name"
                required={true}
                ref={register({ required: true })}
              />
              {errors.name && <span>This field is required</span>}
            </div>
            <div className="form-group">
              <label>Status</label>
              <div>
              <Controller
                as ={
                  <Selectpicker
                    className="select-picker form-control"
                    name="status"
                    data-live-search='true'
                    optionsForSelect = {[["Active", "active"], ["Disabled", "disabled"]]}
                    required={true}
                    ref={register}
                    defaultValue={props.isEdit && props.initialFormData.status}
                  />
                }
                rules={{ required: true }}
                control={control}
                name="status"
                defaultValue={props.isEdit && props.initialFormData.status}
              />
              {errors.status && <span>This field is required</span>}
              </div>
            </div>
            { gainsightFields() }
            {/* { gainsightCsFields() } */}
            { intercomFields() }
          </div>
        </div>
      </form>
    )
  }

  const listAdmins = () => {
    return (
      <ListImplementationPartnerAdmins
        headers={['name','email','phone','actions']}
        admins={ipAdmins.admins}
        partnerId={props.implementation_partner.id}
        allowAddIpAdmin={props.allowAddIpAdmin}
      />
    )
  }

  return (
    <div>
      { contentHeader() }
      { IpForm() }
      { props.isEdit && ipAdmins.show && listAdmins() }
    </div>
  )
}

export default BuildImplementationPartner;
