import React from 'react'
import Button from '../button/index'

class FormModal extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const { header, children } =  this.props;
    if (!this.props.show)
      return null;
    return <div className="react-modal">
      <div className="back-shadow" />
      <div className="modal-content">
        <div className="modal-header">
          <Button
            className="close error-modal-close"
            data-dismiss="modal"
            handleClick={(e) => this.props.handleClose(e)}
            iconClass="fa fa-window-close"
          />
          <h4 className="modal-title">
            {header}
          </h4>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <Button
            className="btn btn-primary"
            name="Add Time off Type"
            iconClass="fa fa-save"
            type="button"
            handleClick={(e) => this.props.handleSubmit(e)}
          />
        </div>
      </div>
    </div>
  }
}

export default FormModal