import React from 'react'

class EditMilestone extends React.Component {
  state = {
    date: this.props.milestone.date,
    askConfirmation: '',
  }

  componentDidMount () {
    this.initializeDatepicker()
  }

  componentDidUpdate () {
    this.initializeDatepicker()
  }

  displaySpinner () {
    const opt = {
      length: 2,
      lines: 8,
      radius: 6
    }
    const spinner = new Spinner(opt).spin()
    $('.spinner-edit-milestone-div').append(spinner.el)
    $('.modal-body .to-be-disabled').attr('disabled', true)
  }

  handleDateChange (e) {
    $(e.target).datepicker('hide')
    let date = e.target.value
    if (date) {
      if (moment(date, gon.default_date_format_string.toUpperCase(), true).isValid()) {
        date = moment(date, gon.default_date_format_string.toUpperCase()).format('YYYY-MM-DD')
        this.setState({ date })
        return
      }
    }
    this.setState({ date })
  }

  pairMilestoneCheck (startMilestone, endMilestone) {
    return (
      startMilestone && endMilestone && (parseInt(endMilestone.id) == parseInt(this.props.milestone.id) || parseInt(startMilestone.id) == parseInt(this.props.milestone.id))
    )
  }

  pairMilestoneDateValidates (startMilestone, endMilestone, confirmationCode) {
    const startDate = new Date(startMilestone.date)
    const endDate = new Date(endMilestone.date)
    const currentDate = new Date(this.state.date)
    const startMilestoneChanged = parseInt(startMilestone.id) === parseInt(this.props.milestone.id)
    const endMilestoneChanged = parseInt(endMilestone.id) == parseInt(this.props.milestone.id)
    if (startMilestoneChanged && currentDate.greater_than(endDate)) {
      this.setState({askConfirmation: `${confirmationCode} After`})
      return false
    } else if (endMilestoneChanged && startDate.greater_than(currentDate)) {
      this.setState({askConfirmation: `${confirmationCode} Before`})
      return false
    } else if (confirmationCode === 'REQ') {
      return startMilestoneChanged ? this.checkTimeOffTypeRange(currentDate, endDate) : this.checkTimeOffTypeRange(startDate, currentDate)
    } else {
      return true
    }
  }

  checkTimeOffTypeRange (reqLeaveStartDate, reqLeaveEndDate) {
    const { timeOffTypes } = this.props
    let overlappingTimeOff = timeOffTypes && timeOffTypes.filter( timeOffType => {
      const startDate = new Date(timeOffType.start_date)
      const endDate = new Date(timeOffType.end_date)
      if (startDate && endDate) {
        return !(startDate.between(reqLeaveStartDate, reqLeaveEndDate) || endDate.between(reqLeaveStartDate, reqLeaveEndDate))
      }
    }).length

    if (overlappingTimeOff && overlappingTimeOff > 0) {
      this.setState({askConfirmation: 'Time Off Type'})
      return false
    } else {
      return true
    }
  }

  disabilityPreceedsRequestedDate (disabilityStartMilestone, reqLeaveStartMilestone) {
    const reqLeaveStartDate = reqLeaveStartMilestone && new Date(reqLeaveStartMilestone.date)
    const currentDate = new Date(this.state.date)
    return disabilityStartMilestone && reqLeaveStartMilestone && (parseInt(disabilityStartMilestone.id) === parseInt(this.props.milestone.id) && reqLeaveStartDate.greater_than(currentDate))
  }

  handleOnSave () {
    if (moment(this.state.date, 'YYYY-MM-DD', true).isValid()) {
      const reqLeaveStartMilestone = this.getMilestone('Requested Leave Start Date')
      const reqLeaveStartDate = reqLeaveStartMilestone && new Date(reqLeaveStartMilestone.date)
      const reqLeaveEndMilestone = this.getMilestone('Requested Leave End Date')
      const disabilityStartMilestone = this.getMilestone('Disability Start Date')
      const disabilityEndMilestone = this.getMilestone('Disability End Date')
      const currentDate = new Date(this.state.date)

      if (this.pairMilestoneCheck(reqLeaveStartMilestone, reqLeaveEndMilestone)) {
        if (this.pairMilestoneDateValidates(reqLeaveStartMilestone, reqLeaveEndMilestone, 'REQ')) {
          this.saveMilestone()
        }
      } else if (this.pairMilestoneCheck(disabilityStartMilestone, disabilityEndMilestone)) {
        if (this.pairMilestoneDateValidates(disabilityStartMilestone, disabilityEndMilestone, 'DIS')) {
          if (this.disabilityPreceedsRequestedDate(disabilityStartMilestone, reqLeaveStartMilestone)) {
            this.setState({askConfirmation: 'DIS Before REQ'})
          } else {
            this.saveMilestone()
          }
        }
      } else if (this.disabilityPreceedsRequestedDate(disabilityStartMilestone, reqLeaveStartMilestone)) {
        this.setState({askConfirmation: 'DIS Before REQ'})
      } else if (reqLeaveStartMilestone && parseInt(reqLeaveStartMilestone.id) != parseInt(this.props.milestone.id) && reqLeaveStartDate.greater_than(currentDate)) {
        this.setState({askConfirmation: 'REQ'})
      } else {
        this.saveMilestone()
      }
    } else {
      $('input.datepicker').addClass('invalid-date').focus()
    }
  }

  saveMilestone (changeLeaveStartDate = false) {
    $.ajax({
      method: 'PUT',
      url: `/schedules/${this.props.milestone.id}/update_milestone_event`,
      dataType: 'JSON',
      data: {
        date: this.state.date,
        week_input_date: this.props.week_first_date,
        month_input_date: this.props.month_first_date,
        year_input_date: this.props.year_first_date,
        change_leave_start_date: changeLeaveStartDate
      },
      beforeSend: () => {
        this.displaySpinner()
      },
      success: (data) => {
        if (data.status === 'success') {
          toastr.success('Milestone saved successfully.')
          if (this.props.isAdmin) {
            PubSub.publish('fetchLeavePlanHistories')
            PubSub.publish('fetchLeaveBenefits')
            PubSub.publish('fetchTaskItems')
          }
          this.props.changeData(data, {update_view: true})
        } else if (data.unauthorized || data.status == 'error') {
          toastr.error(data.error_message, 'Error')
        }
        $('.spinner').remove()
        this.props.handleEditBoxClose()
      }
    })
  }

  revertMilestoneDate () {
    this.setState({askConfirmation: false, date: this.props.milestone.date})
  }

  initializeDatepicker () {
    $('.datepicker').datepicker('destroy')
                    .unbind('changeDate')
                    .unbind('show')
                    .unbind('hide.bs.modal')
    $('.datepicker').datepicker({
      format: gon.default_date_format_string,
      clearBtn : true
    }
    ).on('changeDate', (e) => {
      const formattedDate = e.date
      let date = ''
      if (formattedDate) {
        date = moment(formattedDate).format('YYYY-MM-DD')
      }
      if (this.state.date !== date) {
        this.setState ({ date })
      }
      $(e.target).datepicker('hide')
    }).on('show', (date) => {
      $('.datepicker.datepicker-dropdown').addClass('notranslate')
    }).on('hide.bs.modal', (e) => {
      $('body').removeClass('modal-open')
      e.target.value = this.displayDateValue()
    })
  }

  openDatepicker (e) {
    const datepicker = $(e.target).closest('.input-group').find('.datepicker')
    if (!datepicker.is(':disabled')) {
      datepicker.datepicker('show')
    }
  }

  backdrop () {
    return (
      <div className='modal-backdrop in' />
    )
  }

  confirmationModal () {
    return (
      <div
        className='modal in'
        tabIndex={-1}
        role='dialog'
        ref='modal'
        style={{display: 'block'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='confirmation-text'>
                { this.renderConfirmationText() }
              </div>
              <div className='spinner-edit-milestone-div' />
              <div className='confirmation-buttons'>
                <div className='buttons-div'>
                  { this.renderConfirmationButtons() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderConfirmationButtons () {
    const { askConfirmation } = this.state
    if (['REQ After', 'REQ Before', 'DIS After', 'DIS Before'].indexOf(askConfirmation) >= 0) {
      return (
        <div className='buttons'>
          <button
            className='btn btn-primary btn-darkgray to-be-disabled'
            onClick={this.revertMilestoneDate.bind(this)}
          >
            OK
          </button>
        </div>
      )
    } else if (askConfirmation === 'DIS Before REQ') {
      return (
        <div className='buttons'>
          <button
            className='btn btn-primary btn-darkgray to-be-disabled'
            onClick={(() => this.saveMilestone(true)).bind(this)}
          >
            OK
          </button>
          <button
            className='cancel-btn to-be-disabled'
            onClick={this.revertMilestoneDate.bind(this)}
          >
            Undo
          </button>
        </div>
      )
    } else if (askConfirmation === 'Time Off Type') {
      return (
        <div className='buttons'>
          <button
            className='btn btn-primary btn-darkgray to-be-disabled'
            onClick={(() => this.saveMilestone())}
          >
            Yes
          </button>
          <button
            className='cancel-btn to-be-disabled'
            onClick={(() => this.revertMilestoneDate())}
          >
            No
          </button>
        </div>
      )
    } else {
      return (
        <div className='buttons'>
          <button
            className='btn btn-primary btn-darkgray to-be-disabled'
            onClick={(() => this.saveMilestone(true)).bind(this)}
          >
            Yes
          </button>
          <button
            className='cancel-btn to-be-disabled'
            onClick={(() => this.saveMilestone(false)).bind(this)}
          >
            No
          </button>
        </div>
      )
    }
  }

  renderConfirmationText () {
    if (this.state.askConfirmation === 'REQ After') {
      return this.confirmationTextPaired('Requested Leave Start Date', 'after', 'Requested Leave End Date')
    } else if (this.state.askConfirmation === 'REQ Before') {
      return this.confirmationTextPaired('Requested Leave End Date', 'before', 'Requested Leave Start End')
    } else if (this.state.askConfirmation === 'DIS After') {
      return this.confirmationTextPaired('Disability Start Date', 'after', 'Disability End Date')
    } else if (this.state.askConfirmation === 'DIS Before') {
      return this.confirmationTextPaired('Disability End Date', 'before', 'Disability Start Date')
    } else if (this.state.askConfirmation === 'DIS Before REQ') {
      return this.confirmationTextDisability()
    } else if (this.state.askConfirmation === 'REQ') {
      return this.confirmationTextReq()
    } else if (this.state.askConfirmation === 'Time Off Type') {
      return this.confirmationTextTimeOffMessage()
    }
  }

  monthDateFormat (date) {
    return moment(date).format('MM/DD/YYYY')
  }

  getMilestone (name) {
    return this.props.milestone_dates_hash.filter(milestone => {
      return milestone.name === name
    })[0]
  }

  confirmationTextTimeOffMessage () {
    return 'You currently have time-off time applied that will fall outside of the LeavePlan date range.  Do you want to remove these time-off dates from the LeavePlan?'
  }

  confirmationTextPaired (name, before_after, paired_name) {
    return `The ${name} cannot be ${before_after} the ${paired_name}.`
  }

  confirmationTextDisability () {
    return `The LeavePlan Start Date must be at least no later than the Disability Start Date. We have moved the Requested Leave Start Date to (${this.monthDateFormat(this.state.date)}) as well.`
  }

  confirmationTextReq () {
    return `You updated the ${this.props.milestone.name} and it is now before the start of your LeavePlan. Would you like us to move your start date to this new date (${this.monthDateFormat(this.state.date)}) as well?`
  }

  displayDateValue() {
    if (this.state.date) {
      if (moment(this.state.date, 'YYYY-MM-DD', true).isValid()) {
        return moment(this.state.date).format(gon.default_date_format_string.toUpperCase())
      } else {
        return this.state.date
      }
    } else {
      return ''
    }
  }

  modal () {
    const { milestone } = this.props
    return (
      <div
        className='modal in'
        tabIndex={-1}
        role='dialog'
        ref='modal'
        style={{display: 'block'}}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body'>
              <button
                className='close-button to-be-disabled'
                onClick={this.props.handleEditBoxClose}
              >
                {'Close '}
                <i className='fa fa-times' />
              </button>
              <div className='item-header'>
                <span className='name'>
                  {`Edit ${milestone.name}`}
                </span>
                <span className='date'>
                  { moment(milestone.date).format(gon.default_date_format_string.toUpperCase()) }
                </span>
              </div>
              <div className='milestone-form'>
                <div className='form-group'>
                  <label className='label-control'>
                    Date
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control datepicker to-be-disabled'
                      type='text'
                      placeholder= {gon.default_date_format_string}
                      onChange={this.handleDateChange.bind(this)}
                      value={this.displayDateValue()}
                    />
                    <span
                      className='input-group-addon btn-darkgray'
                      onClick={this.openDatepicker.bind(this)}
                    >
                      <span className='fa fa-calendar-o fa-lg' />
                    </span>
                  </div>
                  <div className='milestone-save-btn'>
                    <button
                      className='btn btn-primary btn-darkgray to-be-disabled'
                      onClick={this.handleOnSave.bind(this)}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className='spinner-edit-milestone-div' />
              </div>
              <div className='item-detail-text'>
                <span dangerouslySetInnerHTML={{__html: _getDescription(milestone.decision_support_text)}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderModal () {
    if (['REQ After', 'REQ Before', 'DIS After', 'DIS Before', 'DIS Before REQ', 'REQ', 'Time Off Type'].indexOf(this.state.askConfirmation) >= 0) {
      return this.confirmationModal()
    } else {
      return this.modal()
    }
  }

  render () {
    return (
      <div>
        { this.backdrop() }
        { this.renderModal() }
      </div>
    )
  }
}

export default EditMilestone;