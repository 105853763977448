import React from 'react'
import TableHead from '../../shared_leave_plans/components/tableHead'
class SharedTaskTable extends React.Component {

  constructor() {
    super()

    this.state = {
      heads: [
        "Completed",
        "Visible",
        "Task Name",
        "Due Date",
        "Priority",
        ""
      ]
    }
  }

  renderHead (head, cssClass, i) {
    return (
      <TableHead
        head={head}
        cssClass={cssClass}
        key={i}
        sort={this.props.sort}
        sortBy={this.props.sortBy} />
    )
  }

  renderRow (task, cssClasses, i) {
    return (
      <tr className='tr-shared-benefit fixed-table-body' key={i}>
        { cssClasses.map( (cssClass, i) => this.renderData(task, cssClass, i) ) }
      </tr>
    )
  }

  handleCheck (task) {
    let timeline_id = task.id
    let check = !task.completed
    $.post("/timeline_items/save_checkbox", {
      timeline_id: timeline_id,
      checked: check
    }, (data) =>{
      this.props.onTimelineCheck(data["data"])
    })
  }

  handleChange() {}

  renderData(task, cssClass, i) {
    return cssClass.element === 'name' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <span className='name' onClick={(e) => this.props.fetchTaskDetail(task)}>
            { task[cssClass.element] }
          </span>
        </td>
      ) :
      cssClass.element === 'button' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <span className={`btn btn-primary ${task[cssClass.element]}`} onClick={(e) => this.props.editTask(task)}>
            Edit
          </span>
        </td>
      ) :
      cssClass.element === 'completed' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <div className="check-status">
            <input type="checkbox" checked={task.completed} onChange={(e) => this.handleChange()} />
            <span className="cr" onClick={(e) => this.handleCheck(task) }><i className="cr-icon fa fa-check"></i></span>
          </div>
        </td>
      ) :
      cssClass.element === 'due_date' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <div className="date">
              { moment(task[cssClass.element]).format(gon.default_date_format_string.toUpperCase()) }
          </div>
        </td>
      ) :
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          { task[cssClass.element] }
        </td>
      )
  }

  findElementClass(element, cssClasses) {
    const cssClass = $.grep(cssClasses, (c) =>{
      return c.element === element
    })[0]

    return cssClass.class
  }

  render () {
    return this.props.sharedTasks.length ?
    (
      <table className="shared-benefit-table">
        <thead className="thead fixed-table-header">
          <tr className="tr">
            {this.state.heads.map ( (head, i) => this.renderHead(head, this.props.classes[i], i))}
          </tr>
        </thead>
        <tbody className="tbody">
          {this.props.sharedTasks.map( (task, i) => this.renderRow(task, this.props.classes, i))}
        </tbody>
      </table>
    ) :
    <span className="no-data">
      No data to show
    </span>

  }

}

export default SharedTaskTable;