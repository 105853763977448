import React from 'react'
import ReactTooltip from "react-tooltip";

const AppToolTip = (props) => {
  return (
    <ReactTooltip
      id={props.id}
      place={props.place}
      effect={props.effect}
    >
      {props.toolTipMsg}
    </ReactTooltip>
  );
}

export default AppToolTip;
