import React from 'react'
import Pagination from '../pagination/pagination'
import BundleVendorForm from './BundleVendorForm'
import Datepicker from '../../shared/inputs/datepicker'
import BulkCopy from '../../shared/Table/components/bulkCopy'

class BundleCategoryVendors extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddModal: false,
      showDeleteModal: false,
      showInfoModal: false,
      infoDescription: '',
      bundleCategoryVendors: props.bundleCategoryVendors,
      bundleCategories: props.bundleCategories,
      vendors: props.vendors,
      selectedItems: [],
      companies: [],
      displayCompanies: false,
      selectedCompanies: [],
      bundleVendorPageNumber: '1',
      companyPageNumber: '1',
      sortBy: {
        column: 'vendors',
        reverse: false
      },
      totalCompanies: null,
      ipId: props.ipId,
      allowCopyToIp: props.allowCopyToIp,
      showBulkCopyModal: false,
      showDateModal: false,
      searchText: ''
    }
  }

  componentDidMount() {
    const { bundleCategoryVendors } = this.state
    this.setState({
      bundleCategoryVendors: bundleCategoryVendors.map(bundleCategoryVendor => {
        return {
          id: bundleCategoryVendor.id,
          logo_url: bundleCategoryVendor.logo_url,
          checked: false,
          bundle_category: bundleCategoryVendor.bundle_category,
          vendor: bundleCategoryVendor.vendor,
          description: bundleCategoryVendor.description,
          name: bundleCategoryVendor.name,
          short_code: bundleCategoryVendor.short_code
        }
      })
    })
  }

  changeCompanyPageNumber(pageNumber) {
    this.setState({
      companyPageNumber: pageNumber
    }, () => { this.fetchAssociatableCompanies() })
  }

  changeBundleVendorPageNumber(pageNumber) {
    this.setState({
      bundleVendorPageNumber: pageNumber
    }, () => { this.fetchBundleVendorData() })
  }

  fetchBundleVendorData() {
    const { selectedItems, bundleVendorPageNumber, sortBy: {column, reverse} } = this.state
    const sort_order = reverse ? "desc" : "asc"
    const data = {
      page: bundleVendorPageNumber,
      sort_by: column,
      sort_order
    }
    let url = '/admin/bundle_category_vendors' + `?q=${this.state.searchText}`
    $.getJSON(url, data, (response) => {
      if(response.items) {
        this.setState({
          bundleCategoryVendors: response.items.map(item => {
            item.checked = selectedItems.indexOf(item.id) > -1
            return item
          })
        })
      }
    })
  }

  closeModalHandler (key, value) {
    this.setState({[key]: value})
  }

  showAddModal() {
    this.setState({ showAddModal: true })
  }

  addedRecordHandler(bundleVendor) {
    this.setState(prevState => {
      return {
        bundleCategoryVendors: [bundleVendor, ...prevState.bundleCategoryVendors],
        showAddModal: false,
      }
    })
  }

  createBundleVendorModal() {
    const { bundleCategories, vendors } = this.state
    return (
      <BundleVendorForm
        onModalClose={ () => this.closeModalHandler('showAddModal', false) }
        onSubmit={ this.addedRecordHandler.bind(this) }
        bundleCategories={ bundleCategories }
        vendors={ vendors }
        isIpa= { this.props.is_ipa }
      />
    )
  }
  showBulkCopy () {
    if (this.state.showBulkCopyModal) {
      return <BulkCopy
      handleBulkCopy={this.handleBulkCopy.bind(this)}
      hideBulkCopyModal={this.hideBulkCopyModal.bind(this)}
      itemsCount={this.state.selectedItems.length}
      url={'/admin/bundle_category_vendors'}
      allowCopyToIp={this.props.allowCopyToIp}
      ipId={this.props.ipId}
      />
      }
  }

  hideBulkCopyModal () {
    this.setState({
      showBulkCopyModal: false
    })
  }

  handleBulkCopy (ipParams) {
    $('.bulk-copy-btn').prop('disabled', true)
    $.ajax({
      type: 'POST',
      url: '/admin/bundle_category_vendors/copy_items',
      data: {
        ids: this.state.selectedItems,
        ...ipParams
      },
      success: (res) => {
        if (ipParams.normalChecked){
          this.setState(prevState => ({
            bundleCategoryVendors: [...prevState.bundleCategoryVendors, ...res.items],
          }))
        }
        this.clearSelectedItems()
        this.setState({showBulkCopyModal: false})
        toastr.success('Items copied successfully')
      },
      error: () => {
        this.setState({showBulkCopyModal: false})
        toastr.error('Items not copied')
      },
      complete: () => {
        $('.bulk-copy-btn').prop('disabled', false)
      }
    })
  }

  showEditPage(bundleVendor, event) {
    if (!(event.target.classList == 'select-item' || event.target.classList == "fa fa-trash-o")) {
      return window.location = `bundle_category_vendors/${bundleVendor.id}/edit`
    }
  }

  handleDelete(bundleVendor, event) {
    event.stopPropagation()
    this.setState({ showDeleteModal: true, deletableBundleVendor: bundleVendor })
  }

  handleSubmit() {
    event.stopPropagation()
    this.setState({ showDateModal: true })
  }

  handleDeleteBundleVendor(bundleVendor) {
    const { bundleCategoryVendors } = this.state
    $.ajax({
      method: 'DELETE',
      url: `/admin/bundle_category_vendors/${bundleVendor.id}`,
      success: (response) => {
        if (response.success) {
          this.setState({ bundleCategoryVendors: bundleCategoryVendors.filter((item) => { return (item !== bundleVendor) }) })
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      }
    })
    this.setState({ showDeleteModal: false })
  }

  showDateModal() {
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-body">
            <div className='form-group'>
              <div className="col-md-12">
                <div className="alert alert-info">
                  <span>{this.state.selectedItems.length}</span> items will be applied.
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className='label-control'> Effective Start Date: </label>
                </div>
                <div className="col-md-6">
                  <Datepicker
                  name='effective_start_date'
                  onChange={(value) => this.stateChangedHandler('effectiveStartDate', value)}
                  placeholder='MM/DD/YYYY'
                  typeable={false}
                  defaultValue={this.state.effectiveStartDate}
                  />
                </div>
                <div className="col-md-2">
                  (optional)
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <fieldset className="confirmation-buttons">
              <button className="btn btn-danger pull-left" onClick={ () => this.hideDateModal() }>
                Cancel
              </button>
              <button className="btn btn-primary" onClick={ () => this.saveAssociation() }>
                Apply
              </button>
            </fieldset>
          </div>
        </div>
      </div>
    )
  };

  hideDateModal(){
    this.setState({showDateModal: false });
  };

  stateChangedHandler (key, value) {
    this.setState({[key]: value})
  }

  showDeleteModal() {
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ () => this.handleDeleteModalClose() }
            >
              ×
            </button>
            <h4 className="modal-title">
              Delete confirmation
            </h4>
          </div>
          <div className="modal-body">
            <div className="confirmation-message text-center">
              <span>Are you sure you want to delete?</span>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary" onClick={ () => this.handleDeleteBundleVendor(this.state.deletableBundleVendor) }>
                Yes
              </button>
              <button className="btn btn-danger" onClick={ () => this.handleDeleteModalClose() }>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleDeleteModalClose() {
    this.setState({ showDeleteModal: false })
  }

  handleSelectAll(event) {
    const bundleCategoryVendors = this.state.bundleCategoryVendors.map(bundleCategoryVendor => (bundleCategoryVendor.id))
    const selectedItems  = this.state.selectedItems || []
    const filteredItems = event.target.checked ? selectedItems.concat(bundleCategoryVendors) : selectedItems.filter(item => bundleCategoryVendors.indexOf(item) < 0)
    this.setState({
    selectedItems: filteredItems,
    bundleCategoryVendors: this.state.bundleCategoryVendors.map((bundleCategoryVendor) => ({
        ...bundleCategoryVendor,
        ...{
          checked: event.target.checked
        }
      }))
    })
  }

  allChecked() {
    const { bundleCategoryVendors, selectedItems } = this.state
    return selectedItems.length && bundleCategoryVendors.every(bundleCategoryVendor => selectedItems.indexOf(bundleCategoryVendor.id) >= 0)
  }

  handleAssociation(id, index) {
    const { bundleCategoryVendors, selectedItems } = this.state
    bundleCategoryVendors[index].checked = !bundleCategoryVendors[index].checked

    if(bundleCategoryVendors[index].checked) {
      selectedItems.push(id)
    } else {
      selectedItems.splice($.inArray(id, selectedItems), 1)
    }
    this.setState({
      bundleCategoryVendors,
      selectedItems
    })
  }

  showSelectedItems() {
    return (
      <span className="selected-items pull-left">
         Selected Items: { this.state.selectedItems.length }
       </span>
     )
  }

  clearSelectedItems() {
    this.setState({
      selectedItems: [],
      bundleCategoryVendors: this.state.bundleCategoryVendors.map( (item) => ({
        ...item,
        ...{
          checked: false
        }
      }))
    })
  }

  showClearSelectedItems() {
    return (
      <span
        className="clear-items"
        style={{ marginLeft: '100px' }}
        onClick={ () => this.clearSelectedItems() }
      >
        Clear selections
      </span>
    )
  }

  handleAssociateToCompany() {
    let url = '/admin/bundle_category_vendors/fetch_associatable_companies'
    $.ajax({
      method: 'GET',
      url: url,
      success: (response) => {
        if (response.success) {
          this.setState({
            companies: response.companies,
            totalCompanies: response.total_companies,
            displayCompanies: true
          })
        }
      }
    })
  }

  infoMessage(e) {
    e.stopPropagation()
    this.setState({showInfoModal: true})
  }

  handleInfoModalClose() {
    this.setState({showInfoModal: false})
  }

  showInfoModal(){
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ () => this.handleInfoModalClose() }
            >
              ×
            </button>
            <h4 className="modal-title">
              Warning Message
            </h4>
          </div>
          <div className="modal-body">
            <div className="confirmation-message">
              <span dangerouslySetInnerHTML={{__html: this.state.infoDescription}} />
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary btn-ok" onClick={ () => this.handleInfoModalClose()} >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  associateToCompany() {
    return (
      <button onClick={ (e) => this.checkDraftTemplate(e) } className="btn btn-primary pull-right">
        Add to Companies
      </button>
    )
  }

  checkDraftTemplate() {
    const { selectedItems } = this.state
    let params = {
      bundle_category_vendor_ids: selectedItems
    }
    let url = '/admin/bundle_category_vendors/check_draft_templates'
    $.ajax({
      method: 'GET',
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          this.handleAssociateToCompany()
        } else {
          const listItems = response.data.map(hash => {
            const { vendor_name, draft_template_names } = hash;
            const templateLists = {
              benefit_templates: "Benefit Templates",
              guide_templates: "Guide Templates",
              task_templates: "Task Templates"
            };

            const draftItems = Object.keys(templateLists)
              .filter(templateType => draft_template_names[templateType].length > 0)
              .map(templateType => {
                const template_list = draft_template_names[templateType]
                  .map(template_name => `<li>${template_name}</li>`)
                  .join('');
                return `<ol type='a'>List of ${templateLists[templateType]} ${template_list}</ol>`;
              })
              .join('');

            return `<ol type='1'><li>${vendor_name} has the following draft items.</li> ${draftItems}</ol>`;
          }).join('');

          const description = response.data.length > 0 ? `<p class='text-center'>Bundle Category Vendor cannot be Associated with draft templates.<p><p class='text-center'>Following Bundle Category Vendor have draft Templates. </p><p>${listItems}</p>` : `<p class='text-center'>Bundle Category Vendor does not have any templates to associate.</p>`
          this.setState({
            showInfoModal: true,
            infoDescription: description,
          })
        }
      }
    })
  }

  handleAssociateCompany(id, index) {
    const { companies, selectedCompanies } = this.state
    companies[index].checked = !companies[index].checked

    if(companies[index].checked) {
      selectedCompanies.push(id)
    } else {
      selectedCompanies.splice($.inArray(id, selectedCompanies), 1)
    }
    this.setState({
      companies,
      selectedCompanies
    })
  }

  saveAssociation() {
    const { selectedItems, selectedCompanies, effectiveStartDate, effectiveEndDate } = this.state
    let params = {
      bundle_category_vendor_ids: selectedItems,
      company_ids: selectedCompanies,
      effective_start_date: effectiveStartDate
    }
    let url = '/admin/bundle_category_vendors/associate_vendor_bundle_to_companies'
    $.ajax({
      method: 'POST',
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          this.setState({
            selectedCompanies: [],
            selectedItems: [],
            showDateModal: false,
          })
          toastr.success(response.message)
          window.location.reload()
        } else {
          toastr.error(response.message)
        }
      }
    })
  }

  searchCompanies(e) {
    e.preventDefault()
    let query = $(e.currentTarget).find('input').val()
    const data = {
      q: query
    }
    let url = '/admin/bundle_category_vendors/fetch_associatable_companies'
    $.getJSON(url, data, (response) => {
      if(response.success) {
        this.setState({
          selectedCompanies: [],
          companies: response.companies,
          totalCompanies: response.total_companies
        })
      }
    })
  }

  fetchAssociatableCompanies() {
    const { selectedCompanies } = this.state
    let data = {
      page: this.state.companyPageNumber,
    }
    let url = '/admin/bundle_category_vendors/fetch_associatable_companies'
    $.getJSON(url, data, (response) => {
      if(response.success) {
        this.setState({
          companies: response.companies.map(company => {
            company.checked = selectedCompanies.indexOf(company.id) > -1
            return company
          })
        })
      }
    })
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
        this.fetchBundleVendorData()
    })
  }

  renderCaret(sortBy, key) {
    if (sortBy.column === key) {
      return sortBy.reverse ?
        (
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        ) :
        (
          <i className="fa fa-sort-asc" aria-hidden="true"></i>
        )
      }
      return null;
  }

  bulkCopy() {
    this.setState({
      showBulkCopyModal: !this.state.showBulkCopyModal,
    })
  }

  searchText(e) {
    this.setState({
      searchText: e.target.value,
    })
  }

  renderVendorBundleTable() {
    const {
      selectedItems,
      bundleCategoryVendors,
      showAddModal,
      showDeleteModal,
      showInfoModal,
      sortBy,
      bundleVendorPageNumber
     } = this.state
    return(
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3">
              <h3>Bundle Category Vendor</h3>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              <form action='#' onSubmit={(e) => this.fetchBundleVendorData()}>
                <div className='input-group' style={{ marginTop: '20px'}}>
                  <input className='form-control' placeholder='Search Text' value={this.state.searchText} onChange={(e) => this.searchText(e)}/>
                  <span className='input-group-btn'>
                    <button className='btn btn-default' type="submit">
                      <i className='fa fa-search'/>
                    </button>
                  </span>
                </div>
              </form>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-4">
              <br/>
              { selectedItems.length > 0 &&
                <span>
                  { this.showSelectedItems() }
                  { this.showClearSelectedItems() }
                  { this.associateToCompany() }
                </span>
              }
            </div>
            <div className="col-sm-2 col-md-3 col-lg-2">
              <br/>
              { selectedItems.length > 0 && <button className="btn btn-primary bulk-copy-btn" onClick={ () => this.bulkCopy() }>Bulk Copy</button> }
              <button className="btn btn-primary pull-right" onClick={ () => this.showAddModal() }>Add new</button>
            </div>
          </div>
        </div>
        <div className="item-table">
          <div className="bundle-template">
              <Pagination
                totalItems={ this.props.total_items }
                changePageNumber={ this.changeBundleVendorPageNumber.bind(this) }
                pageNumber={ bundleVendorPageNumber }
                itemsPerPage={ 30 }
              />
            </div>
          <table className="shared-benefit-table generic-table deletable-rows">
            <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
              <tr className="tr">
                <th>
                  <input
                    type="checkbox"
                    className="select-item"
                    style={{
                      height: 25,
                      width: 15
                    }}
                    checked={ this.allChecked() }
                    onChange={ (event) => this.handleSelectAll(event) }
                  />
                </th>
                <th className="table-title sortable" onClick={ () => this.sort('name') }>
                  <span className="title-text">Name</span>
                  <span className="sort-caret">
                    { sortBy ? this.renderCaret(sortBy, 'name') : ''}
                  </span>
                </th>
                <th className="table-title sortable" onClick={ () => this.sort('short-code') }>
                  <span className="title-text">Short Code</span>
                  <span className="sort-caret">
                    { sortBy ? this.renderCaret(sortBy, 'short-code') : ''}
                  </span>
                </th>
                <th className="table-title">Vendor Logo</th>
                <th className="table-title sortable" onClick={ () => this.sort('vendors') }>
                  <span className="title-text">Vendor</span>
                  <span className="sort-caret">
                    { sortBy ? this.renderCaret(sortBy, 'vendors') : ''}
                  </span>
                </th>
                <th className="table-title sortable" onClick={ () => this.sort('bundle_categories') }>
                  <span className="title-text">Bundle Category</span>
                  <span className="sort-caret">
                    { sortBy ? this.renderCaret(sortBy, 'bundle_categories') : ''}
                  </span>
                </th>
                <th className="table-title sortable" onClick={ () => this.sort('description') }>
                  <span className="title-text">Description</span>
                  <span className="sort-caret">
                    { sortBy ? this.renderCaret(sortBy, 'description') : ''}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                bundleCategoryVendors.map((item, index) => {
                  return(
                    <tr key={ index }
                        onClick={ (e) => this.showEditPage(item, e) }
                        className="tr item-table benefit-items-row fixed-table-header"
                        >
                      <td>
                        <input
                          type="checkbox"
                          className="select-item"
                          style={{
                            height: 25,
                            width: 15
                          }}
                          checked={ item.checked || false }
                          onChange={ () => this.handleAssociation(item.id, index) }
                        />
                      </td>
                      <td className="table-data">{ item.name }</td>
                      <td className="table-data">{ item.short_code }</td>
                      <td className="table-data">
                        <div
                          style={{
                            width: "75px",
                            height: "50px",
                            background: `url(${ item.logo_url }) no-repeat center`,
                            backgroundSize: "contain"
                          }}>
                        </div>
                      </td>
                      <td className="table-data sortable">{ item.vendor.name }</td>
                      <td className="table-data sortable">{ item.bundle_category.name }</td>
                      <td className="table-data">{ item.description }</td>
                      <td className="table-data delete">
                        <span>
                          <i className="fa fa-trash-o" onClick={ (e) => this.handleDelete(item, e) }></i>
                        </span>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        { showAddModal ? this.createBundleVendorModal() : null }
        { showDeleteModal ? this.showDeleteModal() : null }
        { showInfoModal ? this.showInfoModal() : null }
        { this.showBulkCopy() }
      </div>
    )
  }
  backToTemplates() {
    this.setState({
      displayCompanies: false,
      showInfoModal: false
    })
  }

  renderTitle() {
    const { selectedItems } = this.state
    return(
      <div>
        <h3 className='items-chosen'>
          List of companies { selectedItems.length > 0 ? <span style={{ fontSize: '16px' }}>{`(${selectedItems.length } Vendor Bundle Selected)`}</span>: ''}
          <a className="btn no-text-decoration" onClick={() => this.backToTemplates()}> {`<< Back`} </a>
        </h3>
      </div>
    )
  }

  renderSearchBox(){
    const { displayCompanies } = this.state
    if (displayCompanies) {
      return (
        <form action='#' onSubmit={(e) => this.searchCompanies(e)}>
          <div className='input-group' style={{ marginTop: '20px'}}>
            <input className='form-control' placeholder='Search Text'/>
            <span className='input-group-btn'>
              <button className='btn btn-default' type="submit">
                <i className='fa fa-search'/>
              </button>
            </span>
          </div>
        </form>
      )
    }
  }

  renderCompanies() {
    const {
      companies,
      selectedCompanies,
      totalCompanies,
      showDateModal,
      companyPageNumber
    } = this.state
    return(
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              { this.renderTitle() }
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              { this.renderSearchBox() }
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
            <br/>
            {
              selectedCompanies.length > 0 ? (
                <span>
                  <span className="selected-items">
                    Selected Companies: { selectedCompanies.length }
                  </span>
                  <button className="btn btn-primary pull-right" onClick={() => this.handleSubmit()}>Associate Items</button>
                </span>
              ) : null
            }
          </div>
          </div>
        </div>
        <div className="item-table">
          <div className="bundle-template">
            <Pagination
              totalItems={ totalCompanies }
              changePageNumber={ this.changeCompanyPageNumber.bind(this) }
              pageNumber={ companyPageNumber }
              itemsPerPage={ 30 }
            />
          </div>
          <table className="shared-benefit-table generic-table deletable-rows">
            <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
              <tr className="tr">
                <th className="table-title sortable">Name</th>
              </tr>
            </thead>
            <tbody>
              {
                companies.map((company, index) => {
                  return(
                    <tr key={index} className="tr benefit-items-row item-table fixed-table-header">
                      <td>
                        <input type="checkbox" className="selected-class"
                          style={{
                            height: 25,
                            width: 15
                          }}
                          checked={company.checked || false}
                          onChange={(e) => this.handleAssociateCompany(company.id, index) }
                        />
                      </td>
                      <td className="table-data sortable">
                        {company.name}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        { showDateModal ? this.showDateModal() : null }
      </div>
    )
  }

  render() {
    const { displayCompanies } = this.state
    return (
      <div>
        { displayCompanies ? this.renderCompanies() : this.renderVendorBundleTable() }
      </div>
    )
  }
}

export default BundleCategoryVendors
