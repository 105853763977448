import React from 'react'

class LeavePlanInviteButton extends React.Component {

  constructor () {
    super()
  }

  showConfirmation (e, leavePlanId, confirmationType) {
    e.stopPropagation()

    this.props.showConfirmation(leavePlanId, confirmationType)
  }

  render () {
    return (
      <span className='date'>
        <span className='send-invite btn btn-primary' onClick={(e) => this.showConfirmation(e, this.props.leave_plan_id, 'invite_employee')}>
          Send Invite
        </span>
        <i className='delete-leave-plan fa fa-trash-o' onClick={(e) => this.showConfirmation(e, this.props.leave_plan_id, 'delete_leave_plan')}/>
      </span>
    )
  }

}

export default LeavePlanInviteButton;
