import React from 'react'
import ScheduleModalBox from '../../schedules/common_components/ScheduleModalBox'

class AssociatedTask extends React.Component {

  handleCheck(){
    const { timeline_id } = this.props.data;
    $.post("/timeline_items/save_checkbox" , {timeline_id, checked: !this.props.data.check_status}, data => {
      this.props.handleCheck(data);
    });
  }

  handleItemClick() {
    if (this.props.dashboardBenefit) {
      this.props.handleTaskClick(this.props.data.id)
    } else {
      $.getJSON(`/timeline_items/${this.props.data.timeline_id}/fetch_details`, data => {
        gon.company_variables = {...gon.company_variables, ...data.statutory_substitutions_options}
        this.setState({description: data.description, image_url: data.image_url})
        this.props.handleTaskClick('timeline_event', this.props.data.id)
      });
    }
  }

  handleItemClose() {
    this.props.handleTaskClick('', '');
  }

  onTimelineCheck(data){
    this.props.handleCheck(data);
  }

  showCheckBox() {
    return this.props.data.added_to_leave_plan && !this.props.isLeavePlanPartner && this.props.isAddedToLeavePlan && !this.props.isAdmin
  }

  render() {
    return (
      <div className='benefit-timeline-item associated-task'>
        <div className='checkbox-title-wrapper'>
          { this.showCheckBox() &&
            <div className='rounded-checkbox'>
              <input className='timeline-check' id={`timeline-${this.props.data.id}`}
                type='checkbox'
                checked={this.props.data.check_status}
                onChange={this.handleCheck.bind(this)}
              />
              <label htmlFor={`timeline-${this.props.data.id}`}/>
            </div>
          }
          <span onClick={this.handleItemClick.bind(this)} className={`timeline-name ${this.props.class_name}`}>
            {this.props.data.name}
          </span>
        </div>
        <div className='date-type-wrapper'>
          { this.props.data.leave_item === "planning_guide" &&
            <span>
              {this.props.data.type}
            </span>
          }
          <h4 className='task-date'>
            <span className='task-date-header'>
              {moment(this.props.data.date).format("MMMM D YYYY")}
            </span>
            {moment(this.props.data.date).format(" - dddd")}
          </h4>
        </div>
        { this.props.display_modal.item_id === this.props.data.id &&
          <ScheduleModalBox
            item={this.props.data}
            handleItemClose={this.handleItemClose.bind(this)} 
            type='timeline'
            onTimelineCheck={this.onTimelineCheck.bind(this)} 
            description={this.state.description} 
            image_url={this.state.image_url}
            onHelpfulCheck={this.props.onHelpfulCheck}
            isLeavePlanPartner={this.props.isLeavePlanPartner} 
            isAddedToLeavePlan={this.props.isAddedToLeavePlan}
          />
        }
      </div>
    )
  }
}

export default AssociatedTask
