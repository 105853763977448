import React from 'react'
import TableHead from '../shared_leave_plans/components/tableHead'
import TagForm from './TagForm'
import Pagination from '../pagination/pagination'
import Modal from '../../shared/Modal'

class Tags extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddModal: false,
      showEditModal: false,
      sortBy: {
        column: 'name',
        reverse: false
      },
      modal: {
        promptText: "Are you sure?",
        title: "Confirmation",
        show: false,
        id: null
      },
      query: null,
      selectedTag: {},
      tags: props.tags,
      totalItems: props.total_items,
      pageNumber: "1",
      companyId: props.company_id,
      implementationPartnerId: props.implementation_partner_id,
      globalCompanyTag: props.global_company_tag,
      heads: [
        "Name",
        "Taggings Count"

      ]
    }
  }

  tagsTableClasses(){
    return (
      [
        {
          element: "event_date",
          class: "event-date"
        }, {
          element: "category_name",
          class: "note-category"
        }
      ]
    )
  }


  closeModalHandler(key, value) {
    this.setState({ [key]: value })
  }

  showAddModal() {
    this.setState({ showAddModal: true })
  }

  addedRecordHandler (tag) {
    this.setState(prevState => {
      return {
        tags: [tag, ...prevState.tags],
        showAddModal: false,
      }
    })
  }

  showEditModal(tag) {
    this.setState({ showEditModal: true, selectedTag: tag })
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
        this.fetchTags()
    })
  }

  fetchTags() {
    const { sortBy: { column, reverse }, pageNumber} = this.state
    const url = '/admin/tags'
    const { query } = this.state
    const order = reverse ? "desc" : "asc"
    const data = {
      page: pageNumber,
      sortBy: column,
      q: query,
      company_id: this.state.companyId,
      order
    }

    $.getJSON(url, data, (response) => {
      this.setState({
        tags: response.items,
        totalItems: response.total_items
      })
    })
  }

  updatedRecordHandler(item) {
    this.setState(prevState => {
      return {
        tags: prevState.tags.map((tag) => {
          return tag.id === item.id ? item : tag
        }),
        showEditModal: false
      }
    })
  }

  editTagModal() {
    const { selectedTag } = this.state
    return (
      <TagForm
        isEdit={ true }
        tag={ selectedTag }
        onModalClose={ () => this.closeModalHandler('showEditModal', false) }
        onSubmit={ this.updatedRecordHandler.bind(this) }
      />
    )
  }

  createTagModal() {
    return (
      <TagForm
        isNew={ true }
        companyId={ this.state.companyId}
        globalCompanyTag = {this.state.globalCompanyTag}
        implementationPartnerId = { this.state.implementationPartnerId}
        onModalClose={ () => this.closeModalHandler('showAddModal', false) }
        onSubmit={ this.addedRecordHandler.bind(this) }
      />
    )
  }

  handleDelete(id, e) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        id: id
      }
    }
    this.setState({ modal })
    e.stopPropagation()
  }

  searchTags(e){
    let query = $(e.currentTarget).find('input').val()
    e.preventDefault()
    this.setState({
      query: query
    }, () => { this.fetchTags() })
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{ show: false }
    }
    this.setState({ modal })
  }

  deleteItem(id) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/tags/${id}`,
      success: (response) => {
        this.setState({ tags: this.state.tags.filter((tag) => { return (tag.id !== id) }) })
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchTags() })
  }


  showDeleteModal () {
    return(
      <Modal
        {...this.state.modal}
        hideModalInParent={this.hideModal.bind(this)}
        id={this.state.modal.id}
        approve={this.deleteItem.bind(this)}
        className="destroy">
        <span>{ this.state.modal.promptText }</span>
      </Modal>
    )
  }


  tagsTableClasses(){
    return (
      [
        {
          element: "name",
          class: "name sortable"
        }, {
          element: "taggings_count",
          class: "taggings_count sortable"
        }
      ]
    )
  }

  renderHead(head, cssClass, i) {
    return (
      <TableHead
        head={head}
        cssClass={cssClass}
        key={i}
        sort={this.sort.bind(this)}
        sortBy={this.state.sortBy}
      />
    )
  }

  renderRow(tag, cssClasses, i) {
    return (
      <tr className='tr item-table benefit-items-row fixed-table-header' key={i}>
        <td className="table-data name sortable">{ tag.name }</td>
        <td className="table-data sortable">{ tag.taggings_count }</td>
        <td className={"table-data button"} key={i}>
          <span className='edit-delete-tag'>
            <i className='edit-tag-btn fa fa-edit' onClick={(e) => this.showEditModal(tag)} />
            <i className='edit-tag-btn fa fa-trash-o' onClick={(e) => this.handleDelete(tag.id, e)} />
          </span>
        </td>
      </tr>
    )
  }

  renderTable(){
    const {tags, sortBy: { reverse } } = this.state
    return(
      <table className="shared-benefit-table generic-table deletable-rows">
        <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
          <tr className="tr">
            {this.state.heads.map((head, i) => this.renderHead(head, this.tagsTableClasses()[i], i))}
            <th className='table-title button'>
            </th>
          </tr>
        </thead>
        <tbody className="tbody">
          {this.state.tags.map((note, i) => this.renderRow(note, this.tagsTableClasses(), i))}
        </tbody>
      </table>
    )
  }

  renderSearchBox() {
      return (
        <form action='#' onSubmit={(e) => this.searchTags(e)}>
          <div className='input-group' style={{ marginTop: '20px'}}>
            <input className='form-control' placeholder='Search Text'/>
            <span className='input-group-btn'>
              <button className='btn btn-default' type="submit">
                <i className='fa fa-search'/>
              </button>
            </span>
          </div>
        </form>
      )
  }

  render() {
    const { showAddModal, showEditModal, modal, pageNumber } = this.state
    const { totalItems } = this.state
    return (
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <h3>Tags</h3>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              { this.renderSearchBox() }
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <br/>
              <button className="btn btn-primary pull-right" onClick={ () => this.showAddModal() }>Create New Tag</button>
            </div>
          </div>
        </div>
        <div className="bundle-template" style={{ marginTop: '38px'}}>
          <Pagination
            totalItems={ totalItems }
            changePageNumber={ this.changePageNumber.bind(this) }
            pageNumber={ pageNumber }
            itemsPerPage={ 30 }
          />
        </div>
        <div className="item-table">
          { this.renderTable() }
        </div>
        { showAddModal ? this.createTagModal() : null }
        { showEditModal ? this.editTagModal() : null }
        { modal.show ? this.showDeleteModal() : null }
      </div>
    )
  }
}
export default Tags
