import React from 'react'

class ShowFileLists extends React.Component {
  constructor () {
    super()
  }

  downloadItemFile (itemFileId) {
    this.props.downloadItemFile(itemFileId)
  }

  renderFileThumbs (itemFile, index) {
    const filename = itemFile.filename
    const fileIcon = (['jpg', 'jpeg', 'png'].indexOf(filename.split('.').last()) > -1) ? 'fa fa-image' : 'fa fa-file-text'
    let taskFileDivStyle, taskFileNameDivStyle = {}
    if (this.props.modalBox) {
      taskFileDivStyle = { 'flexDirection': 'column' }
      taskFileNameDivStyle = {
        'width': '150px',
        'overflowWrap': 'break-word',
        'textAlign': 'center',
        'padding': '15px'
      }
    } else {
      taskFileDivStyle = { 'flexDirection': 'row' }
    }
    return(
      <div key={index} className='task-file-div' style={taskFileDivStyle}>
        <div className='task-file-item' onClick=  {this.downloadItemFile.bind(this, itemFile.id)}>
          <div className={fileIcon}></div>
        </div>
        <div className='task-file-name-div' style={taskFileNameDivStyle}>
          <a className='task-file-name' onClick=  {this.downloadItemFile.bind(this, itemFile.id)}>
            {filename}
          </a>
        </div>
      </div>
    )
  }

  render () {
    const containerStyle = this.props.modalBox ? { 'flexDirection': 'row' } : { 'flexDirection': 'column' }
    return (
      <div className='task-file-container' style={containerStyle}>
        {this.props.itemFiles.map((file, index) => this.renderFileThumbs(file, index))}
      </div>
    )
  }
}

export default ShowFileLists
