import React from 'react'

class VendorForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.isEdit ? props.vendor.name : '',
      description: props.isEdit ? props.vendor.description : '',
      logo: '',
      src: props.isEdit ? props.vendor.logo_url : "/company_logo_placeholder.png",
      disableButton: false,
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value })
  }

  handleLogoUpload(e) {
    this.setState({
      logo: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })
  }

  inputParams() {
    const { name, description, logo, disableButton } = this.state
    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    if (logo) { formData.append('logo', logo) }
    return formData
  }

  submitVendor() {
    const { vendor, isEdit } = this.props
    this.setState({ disableButton: true })

    let method = 'POST'
    let url = '/admin/vendors'

    if(isEdit) {
      method = 'PUT'
      url = `/admin/vendors/${vendor.id}`
    }

    $.ajax({
      method: method,
      url: url,
      data: this.inputParams(),
      processData: false,
      contentType: false,
      success: (response) => {
        if (response.success) {
          this.props.onSubmit(response.data)
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      }
    })
  }

  render() {
    const { name, description, src, disableButton } = this.state
    const { onModalClose, isEdit, vendor } = this.props
    return (
      <div className="react-modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ onModalClose }
            >×</button>
            <h4 className="modal-title">Vendor</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={ name }
                  onChange={ (e) => this.handleNameChange(e) }
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                className="form-control"
                name="description"
                value={ description }
                onChange={ (e) => this.handleDescriptionChange(e) }
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-9">
                  <label>Logo</label>
                  <input
                    type="file"
                    id="logo"
                    name="logo"
                    onChange={ (e) => this.handleLogoUpload(e) }
                  />
                </div>
                <div className="col-md-3 vendor-bundle-logo">
                  {
                    <img className="img-thumbnail vendor-bundle" style={{backgroundColor: '#FFFFFF'}}
                    alt="logo-vendor" src={ src }></img>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary"
                      onClick={ (e) => this.submitVendor(e) }
                      disabled={ disableButton }>
                { isEdit ? 'Update' : 'Save' }
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VendorForm
