import React from 'react'
import Pagination from '../pagination/pagination'

class CalcEngineLog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      logs: props.data.logs,
      totalPage: props.data.total_logs,
      pageNumber: '1'
    }
  }

  changePageNumber (pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchData() })
  }


  fetchData () {
    $.ajax(({
      cache: false,
      url: `/admin/employees/${this.props.data.employee_id}/calc_engine_logs`,
      dataType: 'json',
      data: { logs_page: this.state.pageNumber },
      success: (response) => {
        this.setState({
          logs: response.logs
        })
      }
    }))
  }
  
  render() {
    return (
      <div>
        <Pagination
          totalItems={ this.state.totalPage } 
          changePageNumber={ this.changePageNumber.bind(this) } 
          pageNumber={ this.state.pageNumber } 
          itemsPerPage={ 50 }
        />
        <table className='table table-hover'>
          <thead>
            <tr>
              <th>Run ID</th>
              <th>Date</th>
              <th>Title</th>
              <th>Logs</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.logs.map((log, index) =>  {
                return(
                  <tr key={ index }>
                    <td> { log.calc_run_identifier } </td>
                    <td> { log.created_at } </td>
                    <td> { log.title } </td>
                    <td> { log.message} </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
  )}
}

export default CalcEngineLog