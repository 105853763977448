import React from "react";
import { Box, useMediaQuery, makeStyles, Typography } from "@material-ui/core";
import ConfidentialLeavePlanning from "../../../../assets/images/icons/confidential-leave-planning.svg";
import NonConfidentialLeavePlanning from '../../../../assets/images/icons/non-confidential.svg'
import BenefitImage from "../../../../assets/images/icons/available-benefits.svg";
import TimeOffImage from "../../../../assets/images/icons/time-off.svg";
import Carousel from "react-material-ui-carousel";

const AuthLayout = ({ children, isConfidentialPlanning }) => {
  const mobile = useMediaQuery("(max-width: 1192px)");
  const classes = useStyles()

  const data = [
    {
      title: isConfidentialPlanning ? "Confidential Leave Planning" : "Informed Leave Planning",
      description:
        isConfidentialPlanning ?
          "With powerful self-service tools, you can navigate your benefits easily and plan the leave you want before disclosure of a life event." : "With powerful self-service tools, you can easily figure out which benefits you may be eligible for and plan the leave you want.",
      image: isConfidentialPlanning ? ConfidentialLeavePlanning : NonConfidentialLeavePlanning,
    },
    {
      title: "What benefits are available to me?",
      description:
        "Benefits such as job protection, paid leave as well as some company benefits are all consolidated in one convenient location.",
      image: BenefitImage,
    },
    {
      title: "How much time can I take off?",
      description:
        "Whether you need to take time off to grow your family, recover from an injury, or care for a family member, we’ll help you feel confident about your plan.",
      image: TimeOffImage,
    },
  ]

  return (
    <div className={classes.registrationDiv}>
      <Box display="flex" flexDirection={mobile ? 'column' : 'row'}>
        <Box flex="1">
          {children}
        </Box>
        <Box flex="1" style={{ backgroundColor: '#F4F0FB' }}>
          <Box padding={mobile ? "20px" : "50px"}>
            <Carousel
              NextIcon={<i className="fa fa-chevron-circle-right icon-carousel"></i>}
              PrevIcon={<i className="fa fa-chevron-circle-left icon-carousel"></i>}
              autoPlay={false}
              indicators={true}
              navButtonsProps={{
                style: {
                  padding: 10,
                  backgroundColor: 'transparent',
                  margin: 0,
                  opacity: 0.4
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: '#5807FA',
                },
              }}
              indicatorContainerProps={{
                style: {
                  marginTop: '50px',
                }
              }}
              style={{ width: '100%' }}
            >
              {data.map((item, i) => {
                return (
                  <div key={i}>
                    <ItemCard
                      item={item}
                    />
                  </div>
                )
              })}
            </Carousel>
          </Box>
        </Box>
      </Box>
    </div >
  );
};

const ItemCard = ({ item }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.card}>
        <div className={classes.imageContainer}>
          <img className={classes.img} src={item.image} height="98" />
        </div>
        <Box textAlign="center" alignSelf="center" style={{ maxWidth: 500, margin: '0 40px' }}>
          {item.title && (
            <Typography className={classes.labelLarge}><span className="underline">{item.title}</span></Typography>
          )}
          <Typography className={classes.body}>{item.description}</Typography>
        </Box>
      </div>
    </>
  );
};


const useStyles = makeStyles({
  registrationDiv: {
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 40px)',
    fontFamily: 'MontserratRegular',
    fontSize: 16,
  },
  labelLarge: {
    fontSize: 18,
    fontFamily: "MontserratSemiBold",
  },
  body: {
    fontSize: 16,
    fontFamily: "MontserratRegular",
    color: '#666666',
  },
  imageContainer: {
    backgroundColor: '#F4F0FB',
    textAlign: 'center',
    borderRadius: 10,
  },
  img: {
    margin: 20
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    backgroundColor: '#F4F0FB',
    textAlign: 'center',
  }
});

export default AuthLayout;
