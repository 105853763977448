import React from 'react'
import BundleCategoryForm from './BundleCategoryForm'
import Pagination from '../pagination/pagination'

class BundleCategories extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedBC: {},
      deletableBC: {},
      bundleCategories: props.bundleCategories,
      pageNumber: "1",
      sortBy: {
        column: 'name',
        reverse: false
      }
    }
  }

  closeModalHandler(key, value) {
    this.setState({[key]: value})
  }

  showAddModal() {
    this.setState({ showAddModal: true })
  }

  addedRecordHandler(bc) {
    this.setState(prevState => {
      return {
        bundleCategories: [bc, ...prevState.bundleCategories],
        showAddModal: false,
      }
    })
  }

  createBCModal() {
    return (
      <BundleCategoryForm
        isNew={ true }
        onModalClose={ (e) => this.closeModalHandler('showAddModal', false) }
        onSubmit={ this.addedRecordHandler.bind(this) }
      />
    )
  }

  showEditModal(bc) {
    this.setState({ showEditModal: true, selectedBC: bc })
  }

  updatedRecordHandler(item) {
    this.setState(prevState => {
      return {
        bundleCategories: prevState.bundleCategories.map((bundleCategory) => {
          return bundleCategory.id === item.id ? item : bundleCategory
        }),
        showEditModal: false,
      }
    })
  }

  editBCModal() {
    const { selectedBC } = this.state
    return (
      <BundleCategoryForm
        isEdit={ true }
        bundleCategory={ selectedBC }
        onModalClose={ () => this.closeModalHandler('showEditModal', false) }
        onSubmit={ this.updatedRecordHandler.bind(this) }
      />
    )
  }

  handleDelete(bc, e) {
    e.stopPropagation()
    this.setState({ showDeleteModal: true, deletableBC: bc })
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false })
  }

  deleteBC(bc) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/bundle_categories/${bc.id}`,
      success: (response) => {
        if (response.success) {
          this.setState({ bundleCategories: this.state.bundleCategories.filter((bundleCategory) => { return (bundleCategory !== bc) }) })
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      }
    })
    this.setState({ showDeleteModal: false })
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchBundleCategory() })
  }

  fetchBundleCategory() {
    const { sortBy: { column, reverse }, pageNumber} = this.state
    const url = '/admin/bundle_categories'
    const sort_order = reverse ? "desc" : "asc"
    const data = {
      page: pageNumber,
      sort_by: column,
      sort_order
    }

    $.getJSON(url, data, (response) => {
      this.setState({
        bundleCategories: response.items
      })
    })
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
        this.fetchBundleCategory()
    })
  }

  showDeleteModal () {
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ () => this.closeDeleteModal() }
            >×</button>
            <h4 className="modal-title">Delete confirmation</h4>
          </div>
          <div className="modal-body">
            <div className="confirmation-message text-center">
              <span>Are you sure you want to delete?</span>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary" onClick={ () => this.deleteBC(this.state.deletableBC) }>
                Yes
              </button>
              <button className="btn btn-danger" onClick={ () => this.closeDeleteModal() }>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      bundleCategories,
      showAddModal,
      showEditModal,
      showDeleteModal,
      sortBy: { reverse },
      pageNumber
    } = this.state
    const {
      total_items
    } = this.props
    return (
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-4 col-md-6 col-lg-4">
              <h3>Bundle Categories</h3>
            </div>
            <div className="col-sm-8 col-md-6 col-lg-8">
              <br/>
              { !this.props.isIpa && <button className="btn btn-primary pull-right" onClick={ () => this.showAddModal() }>Add Bundle Category</button> }
            </div>
          </div>
        </div>
        <div className="item-table">
          <div className="bundle-template">
            <Pagination
              totalItems={ total_items }
              changePageNumber={ this.changePageNumber.bind(this) }
              pageNumber={ pageNumber }
              itemsPerPage={ 30 }
            />
          </div>
          <table className="shared-benefit-table generic-table deletable-rows">
            <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
              <tr className="tr">
                <th className="table-title sortable" onClick={ () => this.sort('name') }>
                  <span className="title-text">Name</span>
                  <span className="sort-caret">
                    {
                      reverse ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      <i className="fa fa-sort-asc" aria-hidden="true"></i>
                    }
                  </span>
                </th>
                <th className="table-title">
                  <span className="title-text">Description</span>
                  <span className="sort-caret"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                bundleCategories.map((bc, index) => {
                  return(
                    <tr key={index}
                        onClick={() => !this.props.isIpa &&this.showEditModal(bc)}
                        className="tr item-table benefit-items-row fixed-table-header"
                        >
                      <td className="table-data sortable">{ bc.name }</td>
                      <td className="table-data">{ bc.description }</td>
                      { !this.props.isIpa && <td className="table-data delete">
                        <span><i className="fa fa-trash-o" onClick={ (e) => this.handleDelete(bc, e) }></i></span>
                      </td> }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        { showAddModal ? this.createBCModal() : null }
        { showEditModal ? this.editBCModal() : null }
        { showDeleteModal ? this.showDeleteModal() : null }
      </div>
    )
  }
}

export default BundleCategories
