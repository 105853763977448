import React, { useState } from "react";
import Modal from "../../shared/Modal";
import FileDeleteButton from "./FileDeleteButton";
import PropTypes from 'prop-types';

function FileUploadTable(props) {
  let [state, setstate] = useState({
    itemId: null,
    showConfirmationModal: false,
    disableButton: false,
  });

  let formatDate = (stringDate) => {
    return moment(stringDate).format(
      gon.default_date_format_string.toUpperCase()
    );
  };

  let showConfirmationModal = (id) => {
    setstate({...state, showConfirmationModal: true, itemId: id });
  };

  let hideConfirmationModal = () => {
    setstate({...state, showConfirmationModal: false });
  };

  function handleDelete() {
    let deleteButton = $(`.delete-employee-file-${state.itemId}`)
    deleteButton.html("Processing...");
    deleteButton.attr("disabled", true);
    $.ajax({
      url: `${props.action}/${state.itemId}`,
      type: "DELETE",
      success: (response) => {
        toastr.success(response.message);
        setButtonEnable();
        props.onDeleteItem(response.deleted_id);
      },
      error: () => {
        toastr.error(response.message);
        setButtonEnable();
      },
    });
  };

  function setButtonEnable() {
    let deleteButton = $(`.delete-employee-file-${state.itemId}`)
    deleteButton.attr("disabled", false);
    deleteButton.html("Delete");
  };

  function tableHead() {
    return (
      <thead>
        <tr>
          {props.tHead.map((head) => {
            return <th key={head}>{head}</th>;
          })}
        </tr>
      </thead>
    );
  };

  function tableBody() {
    const statusMapper = {
      processing: "Processing",
      completed: "Completed",
      canceled_with_errors: "Canceled With Errors",
    };
    return (
      <tbody>
        {props.tBody.map((body) => {
          let disableButton = body.process_status === "processing";
          return (
            <tr key={body.id}>
              <td
                onClick={() => props.handleTableBodyClick(body.id)}
                className="uploaded-file-name-column"
              >
                <a className="uploaded-file-name">{body.file_name}</a>
              </td>
              { body.upload_type ? <td> {body.upload_type } </td> : null}
              <td> {body.uploaded_by} </td>
              <td> {formatDate(body.created_at)} </td>
              <td> {body.total_records} </td>
              <td> {statusMapper[body.process_status]} </td>
              <td> {Object.keys(body.process_errors).length} </td>
              <td>
                <FileDeleteButton
                  handleClick={showConfirmationModal}
                  value={body.id}
                  disable={disableButton || state.disableButton}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  function renderTable() {
    if (props.tBody.length) {
      return (
        <table className="table table-hover">
          {tableHead()}
          {tableBody()}
        </table>
      );
    } else {
      return [
        <table className="table table-hover">{tableHead()}</table>,
        <p className="text-center">There are no files uploaded yet</p>,
      ];
    };
  };

  function renderModal() {
    return (
      <Modal
        show={state.showConfirmationModal}
        hideModalInParent={hideConfirmationModal}
        title="This action will delete the file"
        approve={() => handleDelete()}
      >
        <p>Are you sure want to delete the file ?</p>
      </Modal>
    );
  };

  function renderUploadDetails() {
    return (
      <div>
        <div className="form-group">
          <h2>{props.title}</h2>
          <div className="row">
            <p className="col-md-8">
              Upload your file using the approved LeaveLogic .csv format
            </p>
            <div className="col-md-4 pull-right">
              <input
                type="submit"
                className="btn btn-primary btn-flat"
                value="Upload Data"
                onClick={() => props.showUploadModal()}
              />
            </div>
          </div>
        </div>
        <div className="employee-data-uploads-table">{renderTable()}</div>
        {state.showConfirmationModal && renderModal()}
      </div>
    );
  }

  return renderUploadDetails();

}

FileUploadTable.propTypes = {
  tBody: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  tHead: PropTypes.array,
  showUploadModal: PropTypes.func,
  title: PropTypes.string,
  handleTableBodyClick: PropTypes.func,
  onDeleteItem: PropTypes.func,
  action: PropTypes.string
}

export default FileUploadTable;
