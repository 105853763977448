import React from 'react'

class LeavePlanResendInvitationButton extends React.Component {

  constructor () {
    super()
  }

  showConfirmation (e, leavePlanId, confirmationType) {
    e.stopPropagation()

    this.props.showConfirmation(leavePlanId, confirmationType)
  }

  showTooltip (button) {
    $(button).closest('.helper-text').find('.helper-tooltip').removeClass('hidden')
  }

  hideTooltip () {
    $('.helper-text .helper-tooltip').addClass('hidden')
  }

  render () {
    return (
      <div className='helper-text shared-leave-plans-resend-icon'>
        <div className='helper-tooltip resend-invitation-tooltip hidden'>
          <div className='helper-tooltip-text'>
            Resend invite
          </div>
          <div className='helper-tooltip-pointer' />
        </div>
        <span className='date'>
          <i className='resend-invitation fa fa-envelope' onMouseOver={(e) => this.showTooltip(e.currentTarget)} onMouseLeave={this.hideTooltip} onClick={(e) => this.showConfirmation(e, this.props.leave_plan_id, 'resend_invitation')} />
        </span>
      </div>
    )
  }

}

export default LeavePlanResendInvitationButton;