import React from 'react'
import Modal from '../../shared/Modal'
import LeavePlanInviteButton from './containers/leavePlanInviteButton'

class LeavePlanInviteButtonDiv extends React.Component {
  
  constructor () {
    super()

    this.state = {
      modal: {
        promptText: "Are you sure?",
        title: "Confirmation",
        show: false,
        confirmationType: '',
        id: null
      },
      showInviteButton: true
    }
  }

  showConfirmation (id, confirmationType) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        confirmationType: confirmationType,
        id: id
      }
    }

    this.setState({
      modal
    })
  }

  showModal () {
    if (this.state.modal.show) {
      return (
        <Modal
          {...this.state.modal}
          hideModalInParent={this.hideModal.bind(this)}
          id={this.state.modal.id}
          approve={this.confirmationApproval()} >
          <span>{ this.confirmationMessage() }</span>
        </Modal>
      )
    }
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{
        show: false,
        confirmationType: '',
        id: null
      }
    }
    this.setState({
      modal
    })
  }

  confirmationMessage () {
    if(this.state.modal.confirmationType === 'delete_leave_plan') {
      return 'Are you sure?'
    } else {
      const employeeName = this.props.employee_name
      return `Warning: If ${employeeName} already has a confidential LeavePlan, you will be overriding their details with those in the LeavePlan you are sharing now and we will notify the employee that you have changed their LeavePlan. This cannot be undone.`
    }
  }

  confirmationApproval () {
    return this.state.modal.confirmationType === 'delete_leave_plan' ?
      this.deleteLeavePlan.bind(this) :
      this.inviteEmployee.bind(this)
  }

  inviteEmployee (id) {
    $.ajax({
      method: "PUT",
      url: `/admin/shared_leave_plans/${id}/invite_employee`,
      dataType: 'json',
      success: (data) => {
        if(data.success) {
          this.setState({showInviteButton: false})
          toastr.success('Employee has been invited successfully.', 'Success')
        } else {
          toastr.error(data.error_message, 'Error')
        }
      }
    })
  }

  deleteLeavePlan (id) {
    $.ajax({
      method: "DELETE",
      url: `/admin/shared_leave_plans/${id}`,
      dataType: 'json',
      success: (data) => {
        if(data.success) {
          window.location = '/admin/shared_leave_plans'
          toastr.success('LeavePlan has been deleted successfully.', 'Success')
        } else {
          toastr.error(data.error_message, 'Error')
        }
      }
    })
  }

  render () {
    return this.state.showInviteButton ?
      <div className='leave-plan-invite-button-div'>
        <LeavePlanInviteButton
          leave_plan_id={this.props.leave_plan_id}
          showConfirmation={this.showConfirmation.bind(this)} />
        { this.showModal() }
      </div> : null
  }
}

export default LeavePlanInviteButtonDiv;
