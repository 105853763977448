import React from 'react'

class JustLookingModalBox extends React.Component {

  handleItemClose() {
      return this.props.handleJustLookingClose(false);
  }

  handleClick() {
    $.getJSON("/employees/activate_leave_plan", data => {
      if (data.status === 'success') {
        window.location.reload(true);
      }
    });
  }

  backdrop() {
    return(<div className='modal-backdrop in'></div>)
  }

  renderList (text, index) {
    return <li key={index}>{text}</li>
  }

  modal() {
    return (
      <div className='modal in' tabIndex='-1' role='dialog' aria-hidden='false' ref='modal' style={{ display: 'block'}}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='close-button' onClick={this.handleItemClose.bind(this)}>
                <span>
                  Close
                </span>
                <span className='fa fa-times'/>
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-sm-6 col-sm-offset-3'>
                  <h4> Activate Your LeavePlan </h4>
                </div>
                <div className='col-sm-12'>
                  <div className='form-group leave-type-edit-info'>
                    <b> Create an Active LeavePlan to:</b>
                    <ul>
                      {this.props.infoTexts.map((text, index) => this.renderList(text, index))}
                    </ul>
                  </div>
                  <div className='confirmation-links'>
                    <button className='btn btn-dark btn-bdr-rds' onClick={this.handleItemClose.bind(this)}>
                      {this.props.buttons.cancel}
                    </button>
                    <button className='btn btn-dark btn-bdr-rds' onClick={this.handleClick}>
                      {this.props.buttons.confirm}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className='activate-leave-plan-modal'>
        {this.backdrop()}
        {this.modal()}
      </div>
    );
  }
}

export default JustLookingModalBox
