import React from 'react'
import DashboardBenefitsSummaryToggle from './DashboardBenefitsSummaryToggle'
import DashboardBenefitsViewToggle from './DashboardBenefitsViewToggle'
import DownloadBenefitUsage from './DownloadBenefitUsage'
import DashboardPaidBenefits from './DashboardPaidBenefits'
import DashboardDateLabel from './DashboardDateLabel'
import DashboardMilestones from './DashboardMilestones'
import DashboardJobProtectedBenefits from './DashboardJobProtectedBenefits'
import DashboardAvailableBenefitsToggle from './DashboardAvailableBenefitsToggle'
import DashboardBenefitsTableView from './DashboardBenefitsTableView'
import JustLookingModalBox from '../leave_plan_benefits/shared/JustLookingModalBox';

class DashboardBenefits extends React.Component {
  constructor (props) {
    super (props)
    this.infoTexts = [
      'Add Voluntary Tasks to your LeavePlan',
      'Set reminders for important tasks',
      'Enable sharing of key LeavePlan details with your Manager and HR (you share when you\'re ready)'
    ]
    this.railsEnvironment = gon.rails_environment === 'development' ? 'localhost' : 'leavelogic.com'

    const dashboardViewSettings = Cookies.get('dashboardViewType')
    const settings = JSON.parse(dashboardViewSettings || '{}')
    let graphView = ''
    if (props.leave_plan_id === settings.leave_plan_id) {
      graphView = settings.graphView || ''
    }

    if ((graphView === 'table' && !props.show_available_balances) || graphView === '') {
      graphView = props.isAdmin ? 'detail' : 'summary'
    }
    const weekData = this.buildStateData('week', props.week_data)
    const monthData = this.buildStateData('month', props.month_data)
    const yearData = this.buildStateData('year', props.year_data)
    this.state = {
      ...weekData,
      ...monthData,
      ...yearData,
      period: props.default_period,
      graphView: graphView,
      activate_leave_plan_modal: false,
      benefit_counts: props.benefit_counts,
      milestone_dates_hash: props.milestone_dates_hash,
      showExtendableBenefit: false,
      paid_table_data: props.paid_table_data,
      job_protected_table_data: props.job_protected_table_data
    }
  }

  componentDidMount () {
    this.subscribeEvents()
    if (this.props.adminVisited) {
      $.ajax({
        url: `/admin/shared_leave_plans/${this.props.leave_plan_id}/set_admin_visited`,
        type: 'PUT',
        success: function(result) {
          console.log(result)
        }
      });
    }
  }

  // componentDidUpdate () {
  //   this.updateResizableGrid()
  // }

  componentWillUnmount () {
    PubSub.unsubscribe('fetchDashboardBenefits')
  }

  updateResizableGrid () {
    if (this.state.graphView != 'table') {
      const firstDate = moment(this.firstDate())
      const lastDate = moment(this.lastDate())
      const numberOfDays = lastDate.diff(firstDate, 'days') + 1
      const rowWidth = document.querySelector('.dashboard-paid-benefits-div').getBoundingClientRect().width
      gon.resizableDayWidth = rowWidth / numberOfDays
    }
  }

  subscribeEvents () {
    PubSub.subscribe('fetchDashboardBenefits', () => {
      const params = {
        week_input_date: this.state.week_first_date,
        month_input_date: this.state.month_first_date,
        year_input_date: this.state.year_first_date,
        leave_plan_id: this.props.leave_plan_id
      }
      $.getJSON('/leave_plan_benefits/dashboard_benefit_items', params, (data) => {
        this.changeData(data, {update_view: true})
      })
    })
  }

  benefits (type) {
    let view = ''
    if (this.state.graphView == 'summary') {
      view = '_summary'
    }
    return this.state[`${this.state.period}_${type}${view}_benefits`]
  }

  milestones () {
    const milestones = this.state[`${this.state.period}_milestones`]
    return $.map(milestones.groupBy('date'), (value, key) => {
      return [value]
    })
  }

  firstDate () {
    return this.state[`${this.state.period}_first_date`]
  }

  lastDate () {
    return this.state[`${this.state.period}_last_date`]
  }

  toggleView (toggleType, value) {
    const updateView = { [toggleType]: value }
    this.rememberView(updateView)
    this.setState(updateView)
  }

  rememberView (updateView) {
    this.setCookie(updateView)
  }

  setCookie (cookieDetails) {
    cookieDetails.leave_plan_id = this.props.leave_plan_id
    Cookies.set(
      'dashboardViewType',
      JSON.stringify(cookieDetails),
      {
        secure: true,
        domain: this.railsEnvironment,
        path: ''
      }
    )
  }
  buildStateData (period, data) {
    return {
      [`${period}_first_date`]: data.first_date,
      [`${period}_paid_benefits`]: data.paid_benefits,
      [`${period}_job_protected_benefits`]: data.job_protected_benefits,
      [`${period}_paid_summary_benefits`]: data.paid_benefits_summary,
      [`${period}_job_protected_summary_benefits`]: data.job_protected_benefits_summary,
      [`${period}_milestones`]: data.milestones,
      [`${period}_label_dates`]: data.label_dates,
      [`${period}_last_date`]: data.last_date,
      [`${period}_prev_nav`]: data.show_prev_nav,
      [`${period}_next_nav`]: data.show_next_nav
    }
  }

  periodFirstDate () {
    return this.state[`${this.state.period}_first_date`]
  }

  periodNextNav () {
    return this.state[`${this.state.period}_next_nav`]
  }

  periodPrevNav () {
    return this.state[`${this.state.period}_prev_nav`]
  }

  labelDates () {
    return this.state[`${this.state.period}_label_dates`]
  }

  periodSpans () {
    let periods = this.labelDates().slice()
    if (this.state.period === 'week') {
      return periods.splice(1, periods.length - 1)
    } else {
      return periods
    }
  }

  changeData (data, options = {}) {
    const weekData = this.buildStateData('week', data.week_data)
    let monthData = {}
    let yearData = {}
    let period = {}
    if (data.month_data) {
      monthData = this.buildStateData('month', data.month_data)
    }
    if (data.year_data) {
      yearData = this.buildStateData('year', data.year_data)
    }
    if (options.update_view) {
      period = {period: data.default_period}
    }
    this.setState({
      milestone_dates_hash: data.milestone_dates_hash,
      ...weekData,
      ...monthData,
      ...yearData,
      ...period,
      benefit_counts: data.benefit_counts,
      paid_table_data: data.paid_table_data,
      job_protected_table_data: data.job_protected_table_data
    })
  }

  baselineWidth () {
    return this.state.period === 'week' ? '99.56%' : '99.8%'
  }

  updateBenefits (data, benefits) {
    return benefits.map(rowBenefits => {
      return rowBenefits.map(benefit => {
        if (data.item_id === benefit.id) {
          benefit.helpful = data.helpful
        }
        return benefit
      })
    })
  }

  onHelpfulCheck (data) {
    if (data.paid_leave) {
      this.setState({
        week_paid_benefits: this.updateBenefits(data, this.state.week_paid_benefits),
        month_paid_benefits: this.updateBenefits(data, this.state.month_paid_benefits),
        year_paid_benefits: this.updateBenefits(data, this.state.year_paid_benefits)
      })
    } else {
      this.setState({
        week_job_protected_benefits: this.updateBenefits(data, this.state.week_job_protected_benefits),
        month_job_protected_benefits: this.updateBenefits(data, this.state.month_job_protected_benefits),
        year_job_protected_benefits: this.updateBenefits(data, this.state.year_job_protected_benefits)
      })
    }
  }

  toggleAvailableBalances (tab) {
    let showExtendableBenefit = false
    if (tab == 'availableBalances' && !this.state.showExtendableBenefit) {
      showExtendableBenefit = true
    }
    this.setState({ showExtendableBenefit: showExtendableBenefit })
  }

  handleJustLookingClick (value) {
    this.setState({activate_leave_plan_modal: value})
  }

  renderActivateLeavePlanModal () {
    return (this.state.activate_leave_plan_modal && !this.props.isAdmin) ? (
      <JustLookingModalBox
        handleJustLookingClose={this.handleJustLookingClick.bind(this)}
        infoTexts={this.infoTexts}
        buttons={
          {
            cancel: 'Cancel',
            confirm: 'Activate LeavePlan'
          }
        }
      />
    ) : null
  }

  renderDashboardAvailableBenefitsToggle () {
    if (this.state.graphView != 'table' && this.props.show_available_balances) {
      return (
        <DashboardAvailableBenefitsToggle
          showExtendableBenefit={this.state.showExtendableBenefit}
          toggleAvailableBalances={this.toggleAvailableBalances.bind(this)}
        />
      )
    }
  }

  renderColorCodes() {
    let { color_codes } = this.props;
    if (this.state.graphView != 'table') {
      return (<div className="grid">
        { color_codes && color_codes.map((colorCode) => (
          <div className="grid-item">
            <div style={{ backgroundColor: colorCode.value }} className="color-code-div"></div>
            <div className="color-code-dash">-</div>
            <div className="color-code-name">{colorCode.name}</div>
          </div>
        ))}
      </div>
      )
    }
  }

  renderSummaryDetailsView () {
    if (this.state.graphView == 'table') {
      return (
        <DashboardBenefitsTableView
          paidTableData={this.state.paid_table_data}
          jobProtectedTableData={this.state.job_protected_table_data}
          isAdmin={this.props.isAdmin}
          benefit_counts={this.state.benefit_counts}
          isLeavePlanPartner={this.props.isLeavePlanPartner}
          paid_leave_helper_text={this.props.paid_leave_helper_text}
          job_protected_helper_text={this.props.job_protected_helper_text}
          add_edit_benefits_text={this.props.add_edit_benefits_text}
        />
      )
    } else {
      return (
        <div>
          <DashboardPaidBenefits
            benefit_items={this.benefits('paid')}
            width={this.baselineWidth()}
            onHelpfulCheck={this.onHelpfulCheck.bind(this)}
            handleJustLookingClick={this.handleJustLookingClick.bind(this)}
            isAdmin={this.props.isAdmin}
            benefit_counts={this.state.benefit_counts}
            periodSpans={this.periodSpans()}
            isLeavePlanPartner={this.props.isLeavePlanPartner}
            toggleDetailView={this.toggleView.bind(this)}
            paid_leave_helper_text={this.props.paid_leave_helper_text}
            showExtendableBenefit={this.state.showExtendableBenefit}
            customColors={this.props.custom_colors_hash}
            add_edit_benefits_text={this.props.add_edit_benefits_text}
          />
          <DashboardDateLabel
            period={this.state.period}
            first_date={this.periodFirstDate()}
            label_dates={this.labelDates()}
            line_width={this.baselineWidth()}
            changeData={this.changeData.bind(this)}
            leave_plan_id={this.props.leave_plan_id}
            showNextNav={this.periodNextNav()}
            showPrevNav={this.periodPrevNav()}
          />
          <DashboardMilestones
            milestones={this.milestones()}
            width={this.baselineWidth()}
            first_date={this.firstDate()}
            last_date={this.lastDate()}
            changeData={this.changeData.bind(this)}
            isAdmin={this.props.isAdmin}
            milestone_dates_hash={this.state.milestone_dates_hash}
            week_first_date={this.state.week_first_date}
            month_first_date={this.state.month_first_date}
            year_first_date={this.state.year_first_date}
            isLeavePlanPartner={this.props.isLeavePlanPartner}
            timeOffTypes={this.props.time_off_types}
          />
          <DashboardJobProtectedBenefits
            benefit_items={this.benefits('job_protected')}
            width={this.baselineWidth()}
            onHelpfulCheck={this.onHelpfulCheck.bind(this)}
            handleJustLookingClick={this.handleJustLookingClick.bind(this)}
            isAdmin={this.props.isAdmin}
            benefit_counts={this.state.benefit_counts}
            periodSpans={this.periodSpans()}
            isLeavePlanPartner={this.props.isLeavePlanPartner}
            toggleDetailView={this.toggleView.bind(this)}
            job_protected_helper_text={this.props.job_protected_helper_text}
            showExtendableBenefit={this.state.showExtendableBenefit}
            customColors={this.props.custom_colors_hash}
          />
        </div>
      )
    }
  }

  renderDashboardBenefits () {
    const nonTableHeight = this.state.graphView === 'table' ? '140px' : '418px'
    const pageContentStyle = {
      'minHeight': `calc(100vh - ${nonTableHeight})`
    }
    return (
      <div className='page-content' style={pageContentStyle}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xs-12'>
              <div id='dashboard-benefits' className='dashboard-benefits'>
                <DashboardBenefitsSummaryToggle
                  toggleView={this.toggleView.bind(this)}
                  isAdmin={this.props.isAdmin}
                  leave_plan_id={this.props.leave_plan_id}
                  graphView={this.state.graphView}
                  showAvailableBalances={this.props.show_available_balances}
                />
                { this.state.graphView != 'table' && <DashboardBenefitsViewToggle
                  toggleView={this.toggleView.bind(this)}
                  period={this.state.period}
                />}
                { this.renderDashboardAvailableBenefitsToggle()}
                { this.props.isAdmin && <DownloadBenefitUsage leave_plan_id={this.props.leave_plan_id} /> }
                { this.renderSummaryDetailsView() }
                { this.renderColorCodes() }
                { this.renderActivateLeavePlanModal() }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className='unum-dashboard-benefits'>
        { this.renderDashboardBenefits() }
      </div>
    )
  }
}

export default DashboardBenefits
