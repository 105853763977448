import React, { useEffect, useState } from 'react';
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Paper, styled, makeStyles, TableSortLabel } from '@material-ui/core'
import Pagination from '../pagination/pagination';
import ModalBox from './ModalBox';
import CustomContentHandler from './CustomContentHandler';
import CustomOverridesHandler from './CustomOverridesHandler';
import CustomRulesHandler from './CustomRulesHandler';

export default function CustomContentTable (props) {
  const [pageNumber, setPageNumber] = useState('1')
  const [totalCount, setTotalCount] = useState('')
  const [openCustomContentEditModal, setOpenCustomContentEditModal] = useState(false)
  const [openOverrideModal, setOpenOverrideModal] = useState(false)
  const [overrides, setOverrides] = useState(false)
  const [statutoryContent, setStatutoryContent] = useState('')
  const [rows, setRows] = useState([])
  const [redirectTo, setRedirectTo] = useState('')
  const [openRulesModal, setOpenRulesModal] = useState(false)
  const [rulesContent, setRulesContent] = useState('')

  const [modalType, setModalType] = useState('');

  const [sortColumn, setSortColumn] = useState('company_name');
  const [sortOrder, setSortOrder] = useState('asc');


  const classes = useStyles();

  useEffect(() => {
    fetchData()
  }, [pageNumber, sortColumn, sortOrder]);

  const fetchData = () => {
    $.ajax({
      method: 'GET',
      url: `${props.fetch_url}/?page_no=${pageNumber}&sort_by=${sortColumn}&sort_order=${sortOrder}`,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response) => {
        setRows(response.data)
        setTotalCount(response.total_count)
      },
      error: (err) => {
        console.log({ err })
      }
    })
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
    fetchData();
  }


  const changePageNumber = (pageNumber) => {
    setPageNumber(pageNumber)
  }

  const handleModal = (type) => {
    setModalType(type);
  }

  const getContent = () => {
    switch (modalType) {
      case 'customContent':
        return <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: statutoryContent.value }} />
      case 'rules':
        return <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: rulesContent }}></div>
      case 'overrides':
        return (
          <div style={{ padding: 10 }}>
            {
              overrides.map((override, index) => {
                return <li key={index}>{override}</li>
              })
            }
          </div>
        );
      default:
        return null;
    }
  }

  const modalTitle = () => {
    switch (modalType) {
      case 'customContent':
        return 'Custom Content';
      case 'rules':
        return 'Rules';
      case 'overrides':
        return 'Overrides';
      default:
        return '';
    }
  }

  const modal = () => {
    return (
      <ModalBox
        show={openCustomContentEditModal || openRulesModal || openOverrideModal}
        hideModalInParent={() => {
          setOpenCustomContentEditModal(false);
          setOpenRulesModal(false);
          setOpenOverrideModal(false);
        }}
        title={modalTitle()}
        approveButtonText="Edit"
        approve={(toNavigate) => handleApprove(toNavigate)}
        decline={() => {
          setOpenCustomContentEditModal(false);
          setOpenRulesModal(false);
          setOpenOverrideModal(false);
        }}
      >
        {getContent()}
      </ModalBox>
    );
  }

  const companyHandler = (currentCompany) => {
    window.location.href = getFullURL(currentCompany)
  }

  function getFullURL (urlPath, toNavigate) {
    let loc = window.location;
    let url = "" + loc.protocol + "//" + loc.host + urlPath + (toNavigate || '');
    return url;
  }

  function handleApprove (toNavigate) {
    window.location.href = getFullURL(redirectTo, toNavigate)
  }

  return (
    <div className={classes.main}>
      <Pagination
        totalItems={totalCount}
        changePageNumber={(pageNumber) => changePageNumber(pageNumber)}
        pageNumber={pageNumber}
        itemsPerPage={30}
      />
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'company_name'}
                  direction={sortOrder}
                  onClick={() => handleSort('company_name')}
                >
                  Company
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'custom_contents'}
                  direction={sortOrder}
                  onClick={() => handleSort('custom_contents')}
                >
                  Custom Content
                </TableSortLabel>
              </TableCell>
              { props.type === 'Benefit' &&
                <TableCell className={classes.cell}>
                  <TableSortLabel
                    active={sortColumn === 'rules'}
                    direction={sortOrder}
                    onClick={() => handleSort('rules')}
                  >
                    Rules
                  </TableSortLabel>
                </TableCell>
              }
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'overridden'}
                  direction={sortOrder}
                  onClick={() => handleSort('overridden')}
                >
                  Override
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.cell}>
                <TableSortLabel
                  active={sortColumn === 'updated_at'}
                  direction={sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  Company {props.type} Updated
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const date = moment(row.updated_at).tz('America/Los_Angeles')

              return (
                <TableRow key={index} className={index % 2 === 0 ? classes.rowGrey : ''}>
                  <TableCell className={classes.cell} component="th" scope="row" onClick={() => companyHandler(row.change_current_company)}>
                    <u className={classes.content}>
                      {row.company.name}
                    </u>
                  </TableCell>
                  <CustomContentHandler
                    row={row}
                    openCustomContentEditModal={openCustomContentEditModal}
                    setOpenCustomContentEditModal={setOpenCustomContentEditModal}
                    setStatutoryContent={setStatutoryContent}
                    setRedirectTo={setRedirectTo}
                    handleModal={handleModal}
                  />
                  { props.type === 'Benefit' && <CustomRulesHandler
                    row={row}
                    openRulesModal={openRulesModal}
                    setOpenRulesModal={setOpenRulesModal}
                    setRulesContent={setRulesContent}
                    setRedirectTo={setRedirectTo}
                    handleModal={handleModal}
                  /> }
                  <CustomOverridesHandler
                    row={row}
                    openOverrideModal={openOverrideModal}
                    setOpenOverrideModal={setOpenOverrideModal}
                    setOverrides={setOverrides}
                    setRedirectTo={setRedirectTo}
                    handleModal={handleModal}
                  />
                  <TableCell className={classes.cell}>
                    {date.format(gon.default_date_format_string.toUpperCase())}
                    <br />
                    {date.format("hh:mm A")}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {modal()}
    </div>
  );
}

const useStyles = makeStyles({
  cell: {
    fontSize: 14,
  },
  rowGrey: {
    background: '#f1f1f1'
  },
  content: {
    cursor: 'pointer'
  },
  main: {
    marginTop: 20,
  },
  head: {
    backgroundColor: '#d6d6d6'
  }
})
