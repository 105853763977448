import React from 'react'
import TableHead from '../shared_leave_plans/components/tableHead'
import CompanyNoteDeleteModal from './CompanyNoteDeleteModal'
import CompanyNoteModal from './CompanyNoteModal'


class Notes extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      sortBy: {
        column: "",
        reverse: true
      },
      noteModal: {
        show: false,
        isCreate: true,
        companyNoteId: '',
        viewDetail: false
      },
      deleteModal: {
        show: false,
        companyNoteId: ''
      },
      notes: [],
      noteCategories: [],
      heads: [
        "Date",
        "Category",
        "Note",
        "Created at",
        "Creator",
        "Updated at",
        "Updater"
      ]
    }
  }

  noteTableClasses() {
    return (
      [
        {
          element: "event_date",
          class: "event-date"
        }, {
          element: "category_name",
          class: "note-category"
        }, {
          element: "note",
          class: "note"
        }, {
          element: "created_at",
          class: "date"
        }, {
          element: 'created_by',
          class: 'note-creator'
        }, {
          element: 'updated_at',
          class: 'date'
        }, {
          element: 'updated_by',
          class: 'note-updater'
        }, {
          element: 'button',
          class: 'button'
        }
      ]
    )
  }

  componentDidMount() {
    const url = `/admin/companies/${this.props.companyId}/company_notes`
    $.getJSON(url, (response) => {
      this.setState({
        notes: response.notes,
        noteCategories: response.noteCategories
      })
    })
  }

  addNote() {
    let noteModal = {
      show: true,
      isCreate: true,
      companyNoteId: '',
      viewDetail: false
    }
    this.setState({ noteModal })
  }

  renderHead(head, cssClass, i) {
    return (
      <TableHead
        head={head}
        cssClass={cssClass}
        key={i}
        sort={this.sort.bind(this)}
        sortBy={this.state.sortBy}
      />
    )
  }

  sort(column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: true
      }
    }
    this.setState(
      { sortBy }, () => {
      })
  }

  deleteNote(noteId) {
    this.setState({
      deleteModal: {
        show: true,
        companyNoteId: noteId
      }
    })
  }

  showDeleteModal() {
    let { deleteModal } = this.state
    return deleteModal.show ?
      <CompanyNoteDeleteModal
        cancelDeleteModal={this.cancelDeleteModal.bind(this)}
        companyNoteId={deleteModal.companyNoteId}
        companyId={this.props.companyId}
        removeDeleted={this.removeDeleted.bind(this)}
      /> : null
  }

  cancelDeleteModal() {
    this.setState({
      deleteModal: {
        show: false,
        companyNoteId: ''
      }
    })
  }

  removeDeleted(noteId) {
    let sharedNotes = this.state.notes.filter((note) => note.id != noteId)
    this.setState({
      notes: sharedNotes,
      deleteModal: {
        show: false,
        companyNoteId: ''
      }
    })
  }

  editNote(noteId) {
    let noteModal = {
      show: true,
      isCreate: false,
      companyNoteId: noteId,
      viewDetail: false
    }
    this.setState({ noteModal })
  }

  viewDetailNote(noteId) {
    let noteModal = {
      show: true,
      isCreate: false,
      companyNoteId: noteId,
      viewDetail: true
    }
    this.setState({ noteModal })
  }

  renderData(note, cssClass, i) {
    return cssClass.class === 'date sortable' && note[cssClass.element] ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <div className="date">
            {
              note[cssClass.element]
            }
          </div>
        </td>
      ) :
      cssClass.element === 'button' ?
        (
          <td className={`table-data ${cssClass.class}`} key={i}>
            <span className='edit-delete-note'>
              <i className='delete-leave-plan-note fa fa-trash-o' onClick={(e) => this.deleteNote(note.id)} />
              <i className='edit-leave-plan-note fa fa-edit' onClick={(e) => this.editNote(note.id)} />
            </span>
          </td>
        ) :
        cssClass.element === 'category_name' ?
          (
            <td className={`table-data ${cssClass.class}`} key={i}>
              { note[cssClass.element] || ''}
            </td>
          ) :
          cssClass.element === 'note' ?
            (
              <td className={`table-data ${cssClass.class}`} key={i}>
                <p>{note[cssClass.element]}<a onClick={(e) => this.viewDetailNote(note.id)}>..view more</a></p>
              </td>
            ) :
            (
              <td className={`table-data ${cssClass.class}`} key={i}>
                { note[cssClass.element]}
              </td>
            )
  }

  renderRow(note, cssClasses, i) {
    return (
      <tr className='tr-shared-benefit fixed-table-body' key={i}>
        { cssClasses.map((cssClass, i) => this.renderData(note, cssClass, i))}
      </tr>
    )
  }

  resetCategories(noteCategory) {
    let updatedCategory = {}
    let category = ''
    for (category of noteCategory.categories) {
      updatedCategory[category[1]] = category[0]
    }

    let updatedNotes = this.state.notes.map((note) => {
      note['category_name'] = updatedCategory[note['category_id']]
      return note;
    });

    this.setState({
      noteCategories: updatedCategory,
      noteModal: {
        ...this.state.noteModal,
        show: false
      },
      notes: updatedNotes
    })
  }
  hideNoteModal() {
    const noteModal = {
      show: false
    }
    this.setState({ noteModal })
  }

  cancelNoteModal(noteCategory) {
    if (noteCategory.isCategoriesModified) {
      this.resetCategories(noteCategory)
    }
    else {
      this.hideNoteModal()
    }
  }

  moveToSharedTable(sharedNote, noteCategory) {
    let sharedNotes = this.state.notes
    sharedNote.event_date = moment(sharedNote.event_date).format('MM/DD/YYYY')
    if (!this.state.noteModal.isCreate) {
      sharedNotes = this.state.notes.filter((note) => note.id != sharedNote.id)
    }
    this.setState({
      notes: [...sharedNotes, sharedNote]
    })
    if (noteCategory.isCategoriesModified) {
      this.resetCategories(noteCategory)
    }
    else {
      this.hideNoteModal()
    }
  }
  categoryOptions() {
    let { noteCategories } = this.state
    let options = Object.keys(noteCategories).map(function (categoryId) {
      return [noteCategories[categoryId], categoryId]
    });
    return options
  }
  showNoteModal() {
    let categoryOptions = this.categoryOptions()
    return this.state.noteModal.show ?
      <CompanyNoteModal
        cancelNoteModal={this.cancelNoteModal.bind(this)}
        isCreate={this.state.noteModal.isCreate}
        companyId={this.props.companyId}
        companyNoteId={this.state.noteModal.companyNoteId}
        moveToSharedTable={this.moveToSharedTable.bind(this)}
        categoryOptions={categoryOptions}
        viewDetail={this.state.noteModal.viewDetail}
      /> : null
  }


  render() {
    return (
      <div className="shared-benefits-table">
        <table className="shared-benefit-table generic-table">
          <thead className="thead fixed-table-header">
            <tr className="tr">
              {this.state.heads.map((head, i) => this.renderHead(head, this.noteTableClasses()[i], i))}
              <th className='table-title button'>
                <button type="button" className="btn btn-success" onClick={() => this.addNote()}>
                  Add Note
              </button>
              </th>
            </tr>
          </thead>
          <tbody className="tbody">
            {this.state.notes.map((note, i) => this.renderRow(note, this.noteTableClasses(), i))}
          </tbody>
        </table>
        { this.showNoteModal()}
        { this.showDeleteModal()}
      </div>
    )
  }
}

export default Notes;
