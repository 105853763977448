import React from 'react'
import TableHead from '../../shared_leave_plans/components/tableHead'
import Modal from '../../../shared/Modal'

class SharedBenefitTable extends React.Component {

  constructor() {
    super()

    this.state = {
      heads: [
        "Benefit Name",
        "Eligible",
        "Eligible Hours",
        "Start Date",
        "End Date",
        "Overridden By",
        "Overridden Date",
        "Reason"
      ],
      modal: {
        promptText: "Are you sure?",
        title: "Confirmation",
        show: false,
        confirmationType: "",
        id: null
      }
    }
  }

  showConfirmation (id, confirmationType) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        confirmationType: confirmationType,
        id: id
      }
    }

    this.setState({
      modal
    })
  }

  showModal () {
    if (this.state.modal.show) {
      return (
        <Modal
          {...this.state.modal}
          hideModalInParent={this.hideModal.bind(this)}
          id={this.state.modal.id}
          approve={this.confirmationApproval()} >
          <span>{ this.confirmationMessage() }</span>
        </Modal>
      )
    }
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{
        show: false,
        confirmationType: '',
        id: null
      }
    }
    this.setState({
      modal
    })
  }

  confirmationMessage () {
    if(this.state.modal.confirmationType === 'delete_benefit') {
      return 'Are you sure?'
    }
  }

  confirmationApproval () {
    return this.state.modal.confirmationType === 'delete_benefit' ?
      this.props.deleteBenefit.bind(this) : null
  }

  renderHead (head, cssClass, i) {
    return (
      <TableHead
        key={i}
        head={head}
        cssClass={cssClass}
        sort={this.props.sort}
        sortBy={this.props.sortBy} />
    )
  }

  renderRow (benefit, cssClasses, i) {
    return (
      <tr className='tr-shared-benefit fixed-table-body' key={i}>
        { cssClasses.map( (cssClass, i) => this.renderData(benefit, cssClass, i) ) }
      </tr>
    )
  }

  renderName(benefit, cssClass, i) {
    return (
      <td className={`table-data ${cssClass.class}`} key={i}>
        <span className='name' onClick={(e) => this.props.fetchBenefitDetail(benefit.id)}>
          { benefit[cssClass.element] }
        </span>
      </td>
    )
  }

  renderButton(benefit, cssClass, i) {
    return (
      <td className={`table-data ${cssClass.class}`} key={i}>
        <span className={`btn btn-primary ${benefit[cssClass.element]}`} onClick={(e) => this.props.editBenefit(benefit)}>
          { benefit[cssClass.element] }
        </span>
        {
          benefit[cssClass.element] === 'update' ?
          <span>
              <a>
                <i className='undo-override fa fa-trash-o fa-lg' onClick={(e) => this.showConfirmation(benefit.id, 'delete_benefit')} />
              </a>
          </span> : null
        }
      </td>
    )
  }

  renderDate(benefit, cssClass, i) {
    return (
      <td className={`table-data ${cssClass.class}`} key={i}>
        <div className="date">
          { moment(benefit[cssClass.element]).format(gon.default_date_format_string.toUpperCase()) }
        </div>
      </td>
    )
  }

  renderDates(benefit, cssClass, i) {
    return (
      <td className={`table-data ${cssClass.class} date`} key={i}>
        <div>
          {
            benefit[cssClass.element].map((date, i) => (
              <div className="date" key={i}>
                { moment(date).format(gon.default_date_format_string.toUpperCase()) }
              </div>
            ))
          }
        </div>
      </td>
    )
  }

  renderData(benefit, cssClass, i) {
    if (cssClass.element === 'name') return this.renderName(benefit, cssClass, i);
    if (cssClass.element === 'button') return this.renderButton(benefit, cssClass, i);
    if (cssClass.class === 'date sortable' && benefit[cssClass.element]) return this.renderDate(benefit, cssClass, i);
    if (cssClass.class === 'date dates sortable' && benefit[cssClass.element]) return this.renderDates(benefit, cssClass, i);

    return (
      <td className={`table-data ${cssClass.class}`} key={i}>
        { benefit[cssClass.element] }
      </td>
    )
  }

  findElementClass(element, cssClasses) {
    const cssClass = $.grep(cssClasses, (c) =>{
      return c.element === element
    })[0]

    return cssClass.class
  }

  render () {
    return this.props.sharedBenefits.length ?
    (
      <table className="shared-benefit-table">
        <thead className="thead fixed-table-header">
          <tr className="tr">
            {this.state.heads.map ( (head, i) => this.renderHead(head, this.props.classes[i], i))}
            <td className='table-data button'>
              <button type = "button" className="btn btn-success" data-toggle="modal" data-target="#exampleModalCenter" onClick={this.props.addManualBenefit} >Add Benefit
              </button>
            </td>
          </tr>
        </thead>
        <tbody className="tbody">
          {this.props.sharedBenefits.map( (benefit, i) => this.renderRow(benefit, this.props.classes, i))}
          {this.showModal()}
        </tbody>
      </table>
    ) :
    <span className="no-data">
      No data to show
    </span>

  }

}

export default SharedBenefitTable;
