import React from 'react'

class NoteDeleteModal extends React.Component {
  constructor(props) {
    super(props)
  }

  deleteNote(e) {
    e.preventDefault()
    let { sharedNoteId, sharedLeavePlanId, removeDeleted, cancelDeleteModal } = this.props
    let url = `/admin/shared_leave_plans/${sharedLeavePlanId}/shared_notes/${sharedNoteId}`
    let method = 'DELETE'

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        if (response.success){
          toastr.success(response.message)
          removeDeleted(sharedNoteId)
        } else {
          toastr.error(response.message)
          cancelDeleteModal()
        }
      }
    })
  }

  modalHeader() {
    return (
      <div className="modal-header">
        <div className='item-header-container'>
          <div className='item-header'>
            <div className='name'>
              Delete Note
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="react-modal">
        <div className="back-shadow" onClick={() => this.props.cancelDeleteModal()}>
        </div>
        <div className="modal-content react-modal-content">
          { this.modalHeader() }
          <div className="modal-body leave-plan-benefit-modal">
            <form className='leave-plan-benefit-override-form' action='#' onSubmit={this.deleteNote.bind(this)}>
              <div className='form-group'>
                Are you sure?
                </div>
              <div className="buttons">
                <button className="cancel btn btn-danger" type='reset' onClick={() => this.props.cancelDeleteModal()}>
                  Cancel
                </button>
                <button className="submit btn btn-primary" type='submit' disabled={this.props.viewDetail}>
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
    </div>
    )
  }
}
export default NoteDeleteModal
