import React from 'react'
import SharedNoteTable from './SharedNoteTable'
import NoteModal from './NoteModal'
import NoteDeleteModal from './NoteDeleteModal'
import { sortDate, sortString } from '../../shared/GenericTasks'

class SharedNotes extends React.Component {
  constructor(props) {
    super (props)
    this.state = {
      sortBy: {
        column: "event_date",
        reverse: true
      },
      noteModal:{
        show: false,
        isCreate: true,
        sharedNoteId: '',
        viewDetail: false
      },
      deleteModal:{
        show: false,
        sharedNoteId: ''
      },
      notes: props.notes,
      noteCategories: props.leave_plan_note_categories
    }
  }

  showNoteModal() {
    let categoryOptions = this.categoryOptions()
    return this.state.noteModal.show ?
      <NoteModal
        cancelNoteModal={this.cancelNoteModal.bind(this)}
        isCreate={this.state.noteModal.isCreate}
        leavePlanId={this.props.leave_plan_id}
        sharedNoteId={this.state.noteModal.sharedNoteId}
        moveToSharedTable={this.moveToSharedTable.bind(this)}
        categoryOptions={categoryOptions}
        viewDetail={this.state.noteModal.viewDetail}
      /> : null
  }

  deleteNote(noteId) {
    this.setState({
      deleteModal:{
        show: true,
        sharedNoteId: noteId
      }
    })
  }

  showDeleteModal() {
    let { deleteModal } = this.state
    return deleteModal.show ?
      <NoteDeleteModal
        cancelDeleteModal={this.cancelDeleteModal.bind(this)}
        sharedNoteId={deleteModal.sharedNoteId}
        sharedLeavePlanId={this.props.leave_plan_id}
        removeDeleted={this.removeDeleted.bind(this)}
      /> : null
  }

  cancelDeleteModal() {
    this.setState({
      deleteModal:{
        show: false,
        sharedNoteId: ''
      }
    })
  }

  removeDeleted(noteId){
    let sharedNotes = this.state.notes.filter((note) => note.id != noteId)
    this.setState({
      notes: sharedNotes,
      deleteModal: {
        show: false,
        sharedNoteId: ''
      }
    })
  }

  moveToSharedTable(sharedNote, noteCategory) {
    let sharedNotes = this.state.notes
    sharedNote.event_date = moment(sharedNote.event_date).format('MM/DD/YYYY')
    if (!this.state.noteModal.isCreate){
      sharedNotes = this.state.notes.filter((note) => note.id != sharedNote.id)
    }
    this.setState({
      notes: [...sharedNotes, sharedNote]
    })
    if (noteCategory.isCategoriesModified){
      this.resetCategories(noteCategory)
    }
    else{
      this.hideNoteModal()
    }
  }

  addNote() {
    let noteModal = {
      show: true,
      isCreate: true,
      sharedNoteId: '',
      viewDetail:false
    }
    this.setState({noteModal})
  }

  categoryOptions() {
    let { noteCategories } = this.state
    let options = Object.keys(noteCategories).map(function(categoryId) {
      return [noteCategories[categoryId], categoryId]
    });
    return options
  }

  getSortedNotes() {
    let leavePlanNotes = [...this.state.notes]
    let dates = ['created_at', 'updated_at', 'event_date']
    let sortByColumn = this.state.sortBy.column
    const sorted = leavePlanNotes.sort((note1, note2) => {
      if (dates.indexOf(sortByColumn) >= 0){
        return sortDate(note1[sortByColumn], note2[sortByColumn])
      } else {
        return sortString(note1[sortByColumn], note2[sortByColumn])
      }
    })
    return this.state.sortBy.reverse ? sorted.reverse() : sorted
  }

  editNote(noteId) {
    let noteModal = {
      show: true,
      isCreate: false,
      sharedNoteId: noteId,
      viewDetail: false
    }
    this.setState({noteModal})
  }

  viewDetailNote(noteId){
    let noteModal = {
      show: true,
      isCreate: false,
      sharedNoteId: noteId,
      viewDetail: true
    }
    this.setState({noteModal})
  }

  hideNoteModal() {
    const noteModal = {
      show: false
    }
    this.setState({noteModal})
  }

  resetCategories(noteCategory){
    let updatedCategory = {}
    let category = ''
    for (category of noteCategory.categories){
      updatedCategory[category[1]] = category[0]
    }

    let updatedNotes = this.state.notes.map((note) => {
      note['category_name'] = updatedCategory[note['category_id']]
      return note;
    });

    this.setState({
      noteCategories: updatedCategory,
      noteModal:{
        ...this.state.noteModal,
        show: false
      },
      notes: updatedNotes
    })
  }

  cancelNoteModal(noteCategory) {
    if (noteCategory.isCategoriesModified){
      this.resetCategories(noteCategory)
    }
    else{
     this.hideNoteModal()
    }
  }

  noteTableClasses() {
    return (
      [
        {
          element: "event_date",
          class: "event-date sortable"
        }, {
          element: "category_name",
          class: "note-category sortable"
        }, {
          element: "note",
          class: "note sortable"
        }, {
          element: "created_at",
          class: "date sortable"
        }, {
          element: 'created_by',
          class: 'note-creator sortable'
        }, {
          element: 'updated_at',
          class: 'date sortable sortable'
        }, {
          element: 'updated_by',
          class: 'note-updater sortable'
        }, {
          element: 'button',
          class: 'button'
        }
      ]
    )
  }

  renderNotes() {
    return (
      <SharedNoteTable
        leavePlanNotes={this.getSortedNotes()}
        categories={this.state.noteCategories}
        classes={this.noteTableClasses()}
        sortBy={this.state.sortBy}
        sort={this.sort.bind(this)}
        editNote={this.editNote.bind(this)}
        addNote={this.addNote.bind(this)}
        deleteNote={this.deleteNote.bind(this)}
        cancelNoteModal={this.cancelNoteModal.bind(this)}
        viewDetailNote={this.viewDetailNote.bind(this)}
      />
    )
  }

  sort(column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: true
      }
    }
    this.setState (
      {sortBy}, () => {
    })
  }

  render() {
    return(
      <div className="shared-benefits-table">
        { this.renderNotes() }
        { this.showNoteModal() }
        { this.showDeleteModal() }
      </div>
    )
  }
}

export default SharedNotes