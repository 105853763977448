import {
  noDeletionClassName,
  noDeletionParagraph,
  BASE_URL,
} from './constants';

let currentEditor = null
export const buildCustomPreview = (content) => {
  const previewContent = (content.length === 0) ? `<br>` : content
  let headHtml = ''
  headHtml += `<base href="${BASE_URL}">`
  headHtml += `<link type="text/css" rel="stylesheet" href="assets/skins/content/default/content.min.css">`
  headHtml += `<link type="text/css" rel="stylesheet" href="assets/plugins/a11ychecker/css/annotations.css">`
  headHtml += `<link type="text/css" rel="stylesheet" href="assets/plugins/mediaembed/content.min.css">`
  headHtml += `<link type="text/css" rel="stylesheet" href="assets/plugins/linkchecker/content.min.css">`
  headHtml += `<link type="text/css" rel="stylesheet" href="assets/custom_tinymce.css">`
  // if (showNewSkin) {
  //   headHtml += `<link type="text/css" rel="stylesheet" href="/assets/montserrat.css">`
  //   headHtml += `<link type="text/css" rel="stylesheet" href="/assets/custom_tinymce_latest.css">`
  // }
  // bodyClass = ''
  const preventClicksOnLinksScript = `<script>
    document.addEventListener &&
    document.addEventListener("click", function(e) {
      for (var elm = e.target; elm; elm = elm.parentNode) {
        if (elm.nodeName === "A") {
          e.preventDefault();window.open(elm.href);
        }
      }
    }, false);
   </script> `
  const collapseableScript = `<script>
    function toggleCollapsableDiv(hideNode) {
      var div = hideNode.parentElement.parentElement.querySelector(".tinymce-template-collapsing-div");
      if (div.style.display == "none") {
        div.style.display = "";
        hideNode.textContent = "Hide";
      } else {
        div.style.display = "none";
        hideNode.textContent = "Show";
      }
    };
    if (document.addEventListener) {
      var selectors = document.querySelectorAll(".tinymce-template-collapsing-button");
      for (var i=0; i < selectors.length; i++) {
        toggleCollapsableDiv(selectors[i]);
        selectors[i].addEventListener("click", function(e) {
          var element = e.target;
          toggleCollapsableDiv(element)
        });
      }
    }
  </script> `
  // const dirAttr = editor.settings.directionality ? ` dir="${editor.settings.directionality}"` : ''
  return `<!DOCTYPE html>
  <html>
    <head>${headHtml}</head>
    <body id="tinymce" class="mce-content-body description-preview">
      ${_getDescription(previewContent)}
      ${preventClicksOnLinksScript}
      ${collapseableScript}
    </body>
  </html>`
}

export const registerCustomPreviewButton = (editor) => {
  editor.ui.registry.addButton(
    'previewButton',
    {
      text: 'Preview',
      onAction: () => {
        editor.windowManager.open({
          title: 'Preview',
          size: 'large',
          initialData: {iframe: buildCustomPreview(editor.getContent())},
          body: {
            type: 'panel',
            items: [{
              type: 'iframe',
              name: 'iframe',
              sandboxed: false
            }]
          },
          buttons: [{
            type: 'cancel',
            text: 'Close'
          }]
        })
      }
    }
  )
}

const currentNode = () => {
  return currentEditor.selection.getNode()
}

const hasNoDeletionClassName = () => {
  return currentNode().className.indexOf(noDeletionClassName) >= 0
}

const isCurrentNodeEmpty = () => {
  return currentNode().textContent.length === 0
}

const cursorPosition = () => {
  return currentEditor.selection.getRng().startOffset
}

const isLastNoDeletionElement = () => {
  const previousElementSibling = currentNode() && currentNode().previousElementSibling
  if (!previousElementSibling) return

  return previousElementSibling.className.indexOf(noDeletionClassName) < 0
}

export const insertBlankLineIfNecessary = (event, editor) => {
  currentEditor = editor
  if (event.command !== 'mceInsertContent') return
  if (!hasNoDeletionClassName()) return
  if (!isCurrentNodeEmpty()) return

  const newElement = currentEditor.getDoc().createElement('div')
  newElement.innerHTML = noDeletionParagraph
  currentNode().parentNode.insertBefore(newElement.firstChild, currentNode())
}

export const preventBackspaceIfNecessary = (event, editor) => {
  // Don't let to remove the last line of template
  currentEditor = editor
  if (event.key !== 'Backspace') return
  if (!hasNoDeletionClassName()) return
  if (cursorPosition() !== 0) return
  if (!isLastNoDeletionElement()) return

  event.preventDefault()
}
