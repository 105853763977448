import React from 'react'

class Datepicker extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      displayDate: this.displayDateValue(props.defaultValue)
    }
  }

  displayDateValue(defaultValue) {
    if (defaultValue) {
      if (moment(defaultValue, 'YYYY-MM-DD', true).isValid()) {
        return moment(defaultValue).format(gon.default_date_format_string.toUpperCase())
      } else {
        return defaultValue
      }
    } else {
      return ''
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.onChange) return true;
    const { displayDate } = this.state
    const newDisplayDate = this.displayDateValue(nextProps.defaultValue)
    if (displayDate !== newDisplayDate) {
      this.setState({displayDate: newDisplayDate})
      return false
    }
    return true
  }

  componentDidMount () {
    this.initializeDatePicker()
  }

  componentDidUpdate () {
    this.initializeDatePicker()
  }

  initializeDatePicker () {
    let _this = this
    let datepicker = $(`.datepicker[name=${this.props.name}]`)
    datepicker.datepicker('destroy')
              .unbind('changeDate')
              .unbind('keydown')
              .unbind('hide.bs.modal')
    datepicker.datepicker({
      format: gon.default_date_format_string,
      clearBtn : true
    }).on('changeDate', (e) => {
      _this.onChangeHandler(e)
    }).on('hide.bs.modal', (e) => {
      e.target.value = this.state.displayDate
    })
    if (!this.props.typeable) {
      datepicker.keydown(false)
    }
  }

  additionalClass () {
    return this.props.className ?
      this.props.className : ''
  }

  onChangeHandler(e) {
    let displayDate = e.target.value
    let actualDate = displayDate
    if (displayDate) {
      if (moment(displayDate, gon.default_date_format_string.toUpperCase(), true).isValid()) {
        actualDate = moment(displayDate, gon.default_date_format_string.toUpperCase()).format('YYYY-MM-DD')
      }
    }
    if (this.props.onChange) {
      this.props.onChange(actualDate)
    }
    this.setState({ displayDate })
    $(e.target).datepicker('hide')
  }

  birthDateEndDate () {
    let date = new Date()
    date.setFullYear(date.getFullYear() - 1)
    return moment(date).format(gon.default_date_format_string.toUpperCase())
  }

  render () {
    const birthDateEndDate = this.props.birthDate && this.birthDateEndDate()
    const startDate = this.displayDateValue(this.props.startDate)
    const endDate =this.displayDateValue(this.props.endDate)
    return (
      <input
        type="text"
        className={`form-control datepicker ${this.additionalClass()}`}
        data-provide="datepicker"
        name={this.props.name}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        required={this.props.required}
        value={this.state.displayDate}
        onChange={this.onChangeHandler.bind(this)}
        data-date-end-date={endDate || birthDateEndDate}
        data-date-start-date={startDate}
        readOnly={this.props.readOnly ? 'readonly' : ''}
        autoComplete="off"
      />
    )
  }
}

export default Datepicker;
