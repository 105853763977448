import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    cursor: 'pointer',
  }),
  control: (styles) => ({
    ...styles,
    cursor: 'pointer',
  })
}

const ReactSelectPicker = (props) => {
  const ref = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsMenuOpen(false)
    } else {
      setIsMenuOpen( prevState => !prevState )
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () =>  document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className='select-dropdown' ref={ref}>
      <Select
        {...props}
        styles={customStyles}
        menuIsOpen={isMenuOpen}
      />
    </div>
  )
}

export default ReactSelectPicker
