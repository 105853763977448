import React from 'react'
import { TextField, makeStyles } from '@material-ui/core'

const FormInput = props => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {props.formLabel && <label className={classes.formLabel}>{props.formLabel}</label>}
      <TextField
        {...props}
        variant="outlined"
        className={classes.textField}
        helperText={!!props.error && props.helperText}
        error={!!props.error}
      />
    </div>
  );
};

const useStyles = makeStyles({
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 10px',
    '& .MuiOutlinedInput-input': {
      fontFamily: "MontserratRegular",
      padding: 14,
      borderRadius: 5,
      borderColor: '#5807FA'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: 5
    },
    '& .Mui-error': {
      fontFamily: "MontserratRegular",
    },
    '& .Mui-focused': {
      boxShadow: '0px 0px 0px 1px #5807FA'
    }
  },
  formLabel: {
    fontSize: 14,
    fontFamily: "MontserratMedium",
    paddingBottom: 10
  },
  textField: {
    borderRadius: 5
  },
});

export default FormInput
