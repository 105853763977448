import React from 'react'
import PropTypes from 'prop-types'
import BenefitScenarioTile from '../components/benefitScenarioTile'
import LeavePlanInviteButton from './leavePlanInviteButton'
import LeavePlanAction from './LeavePlanAction'
import Select from 'react-select';

class TableData extends React.Component {

  constructor () {
    super ()
  }

  renderResendInvitation(data, leavePlanId) {
    return (
      <LeavePlanAction
        leave_plan_id={leavePlanId}
        showConfirmation={this.props.showConfirmation}
        isSuperAdmin={this.props.isSuperAdmin}
        inviteEmployee={false}
        resendInvitation={this.props.resendInvitation}
        showDelete={this.props.showInviteButton}
        sharedDate={data}
      />
    )
  }

  renderEmployeeInvitation (leavePlanId) {
    return (
      <LeavePlanAction
        leave_plan_id={leavePlanId}
        showConfirmation={this.props.showConfirmation}
        isSuperAdmin={this.props.isSuperAdmin}
        inviteEmployee={true}
        resendInvitation={false}
        showDelete={this.props.showInviteButton}
     />
    )
  }

  renderReactivate (leavePlanId) {
    return (
      <LeavePlanAction
        leave_plan_id={leavePlanId}
        showConfirmation={this.props.showConfirmation}
        isSuperAdmin={this.props.isSuperAdmin}
        inviteEmployee={false}
        resendInvitation={false}
        reactivate={true}
        showDelete={this.props.showInviteButton}
     />
    )
  }

  renderData (data, cssClass, childClass, leave_plan_id, showInviteButton, resendInvitation, showClaims) {
    data = data || ""

    if (cssClass === "actions") {
      if (childClass === "archived") {
        return this.renderReactivate(leave_plan_id)
      } else if (showInviteButton) {
        return this.renderEmployeeInvitation(leave_plan_id)
      } else {
        return this.renderResendInvitation(data, leave_plan_id)
      }
    } else if (cssClass === "status") {
      return <BenefitScenarioTile text={data} childClass={childClass} />
    } else if (showClaims) {
      return (
        <span className='show-claim' onClick={showClaims}>
          Show Claims
        </span>
      )
    } else if (cssClass === 'shared-date') {
      return (
        <span className='date'>
          <span>{data}</span>
        </span>
      )
    }
    return <span>{data || this.props.children}</span>
  }

  render () {
    return (
      <td className={`table-data ${this.props.class}`}>
        {this.renderData (this.props.data, this.props.class, this.props.childClass || "", this.props.leave_plan_id, this.props.showInviteButton, this.props.resendInvitation, this.props.showClaims)}
      </td>
    )
  }
}

TableData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // text to display in the table data
  class: PropTypes.string, // class of the table data
  childClass: PropTypes.string // class for the leave plan table status child
}

export default TableData;
