import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import Selectpicker from '../../shared/inputs/selectpicker'
import ResponsiveTable from './responsiveTable'
import IpAdminForm from './ipAdminForm'
import Modal from '../../shared/Modal'
import useConfirmationModal from '../../shared/custom_hooks/useConfirmationModal'

const formAttributesMapping = {
  first_name: "implementation_partner_admin[user_attributes][profile_attributes][first_name]",
  middle_name: "implementation_partner_admin[user_attributes][profile_attributes][middle_name]",
  last_name: "implementation_partner_admin[user_attributes][profile_attributes][last_name]",
  email: "implementation_partner_admin[user_attributes][email]",
  phone: "implementation_partner_admin[phone]",
  id: "id"
}

function ListImplementationPartnerAdmins(props) {
  const [showIpForm, setShowIpForm] = useState({show: false, mode: 'add', itemIndex: null});
  const [admins, setAdmins] = useState(props.admins)
  const [initialFormData, setInitialFormData] = useState({})
  const [modal, handleDelete, hideModal] = useConfirmationModal()

  const displayIpForm = (e) => {
    e.preventDefault()
    if (props.allowAddIpAdmin) {
      setShowIpForm({show: true, mode: 'add'})
    } else {
      toastr.error('Implementation Partner has no Company.')
    }
  }

  const IpAdmins = () => {
    return (
      <div className='row'>
        <div className='col-sm-11'>
          <div className='row.vcenter'>
            <div className='col-xs-6'>
              <h4>Implementation Partner Admins</h4>
            </div>
            <div className='col-xs-6'>
              <a className='pull-right no-text-decoration' onClick={(e) => displayIpForm(e)} href=''>
                <i className='fa fa-plus-circle'>
                </i>
                <span>Add Implementation Partner Admins</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const actionOnchange = (action, ipaId, index) => {
    if (action == 'edit') {
      extractAdminInfo(ipaId, index)
    } else if (action == 'resend_invitation'){
      resendInvitation(ipaId, index)
    } else if (action == 'delete'){
      handleDelete(ipaId)
    }
  }

  const destroyIPA = () => {
    const method = 'DELETE'
    const implementation_partner_id = props.partnerId
    const url = `/admin/implementation_partners/${implementation_partner_id}/implementation_partner_admins/${modal.id}`

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setAdmins(admins.filter((admin) => { return (admin.id !== modal.id) }))
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const resendInvitation = (ipaId, index) => {
    const method = 'PUT'
    const implementation_partner_id = props.partnerId
    const url = `/admin/implementation_partners/${implementation_partner_id}/implementation_partner_admins/${ipaId}/send_invitation`

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const extractAdminInfo = (ipaId, index) => {
    const method = 'GET'
    const implementation_partner_id = props.partnerId
    const url = `/admin/implementation_partners/${implementation_partner_id}/implementation_partner_admins/${ipaId}/edit`

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setInitialFormData(buildFormData(eval(response.data)))
        setShowIpForm({show:true, mode: 'edit', itemIndex: index })
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const buildFormData = (data) => {
    let actualData = {}
    Object.keys(formAttributesMapping).forEach((key, index) => {
      actualData[formAttributesMapping[key].toString()] = data[key]
    });
    return actualData

  }

  const updateAdmins = (adminData, mode) => {
    setShowIpForm({show: false})
    if (mode == 'add') {
      setAdmins( admins => [...admins, adminData]);
    } else if (mode == 'edit') {
      admins[showIpForm.itemIndex] = adminData
      setAdmins( admins => [...admins]);
    }
  }

  function showDeleteModal() {
    return(
      <Modal
        {...modal}
        hideModalInParent={hideModal}
        id={modal.id}
        approve={destroyIPA}
        className="destroy">
        <span>{ modal.promptText }</span>
      </Modal>
    )
  }

  return (
    <div>
    { IpAdmins() }
    {
      <ResponsiveTable
        headers={props.headers}
        data={admins}
        actionOptions={[{ value: 'edit', label: 'Edit' },{ value: 'resend_invitation', label: 'Resend Invitation'},{ value: 'delete', label: 'Delete' }]}
        id={'id'}
        actionOnChange={actionOnchange}
      />
    }
    {
      showIpForm.show &&
      <IpAdminForm
        partnerId={props.partnerId}
        hideIpFrom={() => setShowIpForm(false)}
        updateAdmins={updateAdmins}
        mode={showIpForm.mode}
        initialFormData={initialFormData}
      />}
      { modal.show ? showDeleteModal() : null }
    </div>
  )
}


export default ListImplementationPartnerAdmins;
