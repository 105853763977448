export const colorMaps = [
  "123f6e", "LL Background Blue",
  "265a7c", "LL Font Blue",
  "545454", "LL Font Gray",
  "46CDC0", "LL Bar Green",
  "23B1E3", "LL Bar Blue",
  "005293", "LL Blue",
  "E5F4FC", "LL Light Blue",
  "FFE892", "LL Yellow",
  "72E0C1", "LL Green",
  "5807FA", "LL Purple",
  "222222", "LL Dark",
  "FDFDFD", "LL Light",
  "0087CE", "LL LeaveBlue",
  "00A3D4", "LL Ocean",
  "BFEDD2", "Light Green",
  "FBEEB8", "Light Yellow",
  "F8CAC6", "Light Red",
  "ECCAFA", "Light Purple",
  "C2E0F4", "Light Blue",
  "2DC26B", "Green",
  "F1C40F", "Yellow",
  "E03E2D", "Red",
  "B96AD9", "Purple",
  "3598DB", "Blue",
  "169179", "Dark Turquoise",
  "E67E23", "Orange",
  "BA372A", "Dark Red",
  "843FA1", "Dark Purple",
  "236FA1", "Dark Blue",
  "ECF0F1", "Light Gray",
  "CED4D9", "Medium Gray",
  "95A5A6", "Gray",
  "7f7f7f", "Default Gray",
  "7E8C8D", "Dark Gray",
  "34495E", "Navy Blue",
  "000000", "Black",
  "ffffff", "White",
]
