import React from 'react'

class NumberDisplay extends React.Component {
  constructor () {
    super ()
  }

  render () {
    return (
      <div className={`shared-benefit-tile ${this.props.benefit.class}`}>
        <div className="title">
          <span>
            {this.props.benefit.title}
          </span>
        </div>
        <div className="count">
          <span>
              {this.props.benefit.count || 0}
          </span>
        </div>
      </div>
    )
  }
}

export default NumberDisplay;