import React from 'react'

class NotificationFooter extends React.Component {
  constructor() {
    super()
  }

  allNotifications (e) {
    window.location = $(e.target).attr('href')
  }

  render () {
    return (
      <div className='notification-footer'>
        {this.props.hideSeeAll ? null :
          <a className='see-all-notification' href='/admin/notifications' onClick={this.allNotifications}>
            See All
          </a>}
      </div>
    )
  }
}

export default NotificationFooter