import React from 'react'

class TextArea extends React.Component {
  render(){
    return (
      <textarea
        name={this.props.name}
        id={this.props.id}
        className="form-control"
        aria-hidden="true"
        value={this.props.value || ''}
        onChange={() => {}}
      />
    )
  }
}

export default TextArea;