import React, { useState } from 'react'

import GeneralTable from './general_table'
import Pagination from '../pagination/pagination'
import Modal from '../../shared/Modal'
import useConfirmationModal from '../../shared/custom_hooks/useConfirmationModal'

function ImplementationPartner(props) {
  const [implementationPartners, setImplementationPartner] = useState(props.implementationPartners)
  const [currentPage, setPageCurrentPage] = useState(1)
  const [partnerCompanyIds, setPartnerCompanyIds] = useState(props.partnerCompanyIds)
  const [modal, handleDelete, hideModal] = useConfirmationModal()

  function contentHeader() {
    return (
      <div className='row'>
        <div className='col-xs-7'>
          <h3> Implementation Partners: </h3>
        </div>
        <div className='col-xs-5'>
          <br className="hidden-xs"></br>
          <a href='/admin/implementation_partners/new' className='btn btn-primary pull-right'>Add Implementation Partner</a>
        </div>
      </div>
    )
  }

  const actionOnchange = (action, ipId) => {
    if (action == 'masquerade') {
      window.location = `/admin/implementation_partners/${ipId}/masquerade`
    } else if (action == 'delete') {
      handleDelete(ipId)
    }
  }

  const destroyIP = () => {
    const method = 'DELETE'
    const url = `/admin/implementation_partners/${modal.id}`

    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setImplementationPartner(implementationPartners.filter((ip) => { return (ip.id !== modal.id) }))
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const changePageNumber = (selectedPageNumber) => {
    let url = window.location.pathname
    url += window.location.search

    let params = {
      page: selectedPageNumber
    }

    $.ajax(({
      cache: false,
      url: url,
      dataType: 'json',
      data: params,
      success: (response) => {
        setImplementationPartner(response.implementation_partners)
        setPageCurrentPage(selectedPageNumber)
        setPartnerCompanyIds(response.partner_company_ids)
      }
    }))
  }

  const goToLink = (e, ipId) => {
    if (e.target.classList == 'table-data'){
      window.location = `/admin/implementation_partners/${ipId}/edit`
    }
  }

  const listImplementationPartner = () => {
    return (
      <GeneralTable
        headers={['name', 'status', 'actions']}
        data={implementationPartners}
        actionOptions={[{ value: 'masquerade', label: 'Masquerade' }]}
        id={'id'}
        actionOnChange={actionOnchange}
        goToLink={goToLink}
        disabledOptions={{'masquerade': partnerCompanyIds}}
      />
    )

  }

  function showDeleteModal() {
    return(
      <Modal
        {...modal}
        hideModalInParent={hideModal}
        id={modal.id}
        approve={destroyIP}
        className="destroy">
        <span>{ modal.promptText }</span>
      </Modal>
    )
  }


  return (
    <div>
      <div className='admin-header fixed-header' style={{ height: 100 }}>
        {contentHeader()}
        <Pagination
          totalItems={props.totalCount}
          pageNumber={currentPage}
          itemsPerPage={30}
          changePageNumber={changePageNumber}
        />
      </div>
      { listImplementationPartner()}
      { modal.show ? showDeleteModal() : null }
    </div>
  )
}

export default ImplementationPartner;
