import React, { useState, useEffect, useContext } from 'react'

import GeneralTable from './general_table'
import Pagination from '../pagination/pagination'
import CompanyAssociationForm from './companyAssociateForm'
import CompanyMoveForm from './companyMoveForm'

import {addIpaContext} from './editImplementationPartner'

function AssociatedCompanies(props) {
  const IpaContext = useContext(addIpaContext)
  const [showAssociationForm, setShowAssociationForm] = useState(false);
  const [associatedCompanies, setAssociatedCompanies] = useState([])
  const [showMoveCompanyForm, setShowMoveCompanyForm] = useState(false)
  const [movableCompanyId, setMovableCompanyId] = useState();
  const [page, setPageCurrentPage] = useState({
    currentPage: 1,
    totalItems: 0
  })

  useEffect(() => {
    fetchAssociatedCompanies()
  },[]);

  const fetchAssociatedCompanies = () => {
    let method = 'GET'
    let url = `/admin/implementation_partners/${props.implementation_partner.id}/implementation_partner_companies`
    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setAssociatedCompanies(response.data.associated_companies)
        setPageCurrentPage({currentPage: 1, totalItems: response.data.associated_company_count})
      },
      error: (response) => {
        toastr.error(response.responseJSON.error_message)
      }
    })
  }

  function contentHeader() {
    return (
      <div className='row'>
        <div className='col-xs-7'>
          <h3> Associated Companies- ({page.totalItems} Associated) </h3>
        </div>
        <div className='col-xs-5'>
          <br className="hidden-xs"></br>
          <a onClick={ () =>  setShowAssociationForm(true) } className='btn btn-primary pull-right'>Associate Company</a>
        </div>
      </div>
    )
  }

  const actionOnChange = (actionType, companyId, Index) => {
    if (actionType == 'disassociate') {
      dissociateCompany(companyId)
    } else if (actionType == 'move') {
      setMovableCompanyId(companyId)
      setShowMoveCompanyForm(true)
    }
  }

  const dissociateCompany = (Id) => {
    let method = 'DELETE'
    let url = `/admin/implementation_partners/${props.implementation_partner.id}/implementation_partner_companies/${Id}`
    $.ajax({
      method: method,
      url: url,
      data: { page: page.currentPage },
      success: (response) => {
        toastr.success(response.message)
        setAssociatedCompanies(response.data.associated_companies)
        setPageCurrentPage({...page, totalItems: response.data.associated_company_count})
        setShowMoveCompanyForm(false)
        IpaContext.setAllowAddIpAdmin(response.data.associated_company_count > 0)
      },
      error: (response) => {
        toastr.error(response.responseJSON.error_message)
        setShowMoveCompanyForm(false)
      }
    })
  }

  const changePageNumber = (selectedPageNumber) => {
    let url = `/admin/implementation_partners/${props.implementation_partner.id}/associated_companies`

    let params = {
      page: selectedPageNumber
    }

    $.ajax(({
      cache: false,
      url: url,
      dataType: 'json',
      data: params,
      success: (response) => {
        setAssociatedCompanies(response.data.associated_companies)
        setPageCurrentPage({currentPage: selectedPageNumber, totalItems: response.data.associated_company_count})
      }
    }))
  }

  const reloadAssociateCompanies = (response) => {
    setAssociatedCompanies(response.data.associated_companies)
    setPageCurrentPage({...page, totalItems: response.data.associated_company_count })
    setShowAssociationForm(false)
  }

  const listAssociatedCompanies = () => {
    let enableCompanyIds = associatedCompanies.filter((comp) => { return !comp.unmovable} ).map((comp) => comp.id)

    return (
      <GeneralTable
        headers={['name', 'status', 'actions']}
        data={associatedCompanies}
        actionOptions={[{ value: 'move', label: 'Move'}]}
        actionOnChange={actionOnChange}
        id={'id'}
        disabledOptions={{'move': enableCompanyIds}}
      />
    )
  }

  const hideAssociationForm = () => {
    setShowAssociationForm(false)
  }

  const hideMovableForm = () => {
    setShowMoveCompanyForm(false)
  }

  const moveToIp = (newIp) =>{
    if (newIp.trim().length == 0) return null;
    let url = `/admin/implementation_partners/${props.implementation_partner.id}/implementation_partner_companies/${movableCompanyId}/move_to_ip`

    let method = 'POST'
    let params = {
      newIp: newIp
    }
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        setAssociatedCompanies(response.associated_companies)
        toastr.success(response.message)
        setShowMoveCompanyForm(false)
      },
      error: (response) => {
        toastr.error(response.responseJSON.error_message)
      }
    })
  }

  return (
    <div>
      <div className='admin-header fixed-header' style={{ height: 100, marginTop: -22 }}>
        {contentHeader()}
        <Pagination
          totalItems={page.totalItems}
          pageNumber={page.currentPage}
          itemsPerPage={30}
          changePageNumber={changePageNumber}
        />
      </div>
      { listAssociatedCompanies()}
      { showAssociationForm && <CompanyAssociationForm
          partnerId={props.implementation_partner.id}
          hideAssociationForm={hideAssociationForm}
          currentPage={page.currentPage}
          reloadAssociateCompanies={reloadAssociateCompanies}
        />}
      { showMoveCompanyForm && <CompanyMoveForm
          partnerId={props.implementation_partner.id}
          companyId={movableCompanyId}
          moveToSuperAdmin={dissociateCompany}
          hideMovableForm={hideMovableForm}
          moveToIp={moveToIp}
          />}
    </div>
  )
}

export default AssociatedCompanies;
