import React, { useState, useEffect } from "react";

const LinkedBenefitTemplates = (props) => {
  const [linkedBenefits, setLinkedBenefits] = useState([]);

  useEffect(() => {
    fetchLinkedBenefits()
  }, [])

  const fetchLinkedBenefits = () => {
    const url = `${props.basePath}/get_linked_benefits`
    $.getJSON(url, (response) => {
      setLinkedBenefits(response.data)
    })
  }

  const renderLinkedBenefit = (linkedBenefit, index) => {
    return (
      <a key={index} href={`${props.basePath}/${linkedBenefit.id}/edit`}>
        <div style={{paddingTop: 20, paddingBottom: 20, cursor: 'pointer'}}>
          { linkedBenefit.name }
        </div>
      </a>
    )
  }

  return (<div>
      { linkedBenefits.map(renderLinkedBenefit) }
    </div>)
}

export default LinkedBenefitTemplates
