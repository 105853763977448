import React, { useState, useEffect, useRef, useContext } from 'react'
import { useForm, Controller } from "react-hook-form";
import Selectpicker from '../../shared/inputs/selectpicker'

import {addIpaContext} from './editImplementationPartner'

function CompanyAssociationForm(props) {
  const IpaContext = useContext(addIpaContext)
  const { register, handleSubmit, watch, errors, control } = useForm({ defaultValues: {} });
  const [unassociatedCompanies, setUnassociatedCompanies] = useState([])
  const formRef = useRef(null);

  useEffect(() => {
    fetchUnAssociatedCompanies()
  },[]);

  const fetchUnAssociatedCompanies = () => {
    let method = 'GET'
    let url = `/admin/implementation_partners/${props.partnerId}/associatable_companies`
    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setUnassociatedCompanies(response.data)
      }
    })

  }

  const onSubmit = (data) => {
    let method = 'POST'
    let url = `/admin/implementation_partners/${props.partnerId}/implementation_partner_companies`

    $.ajax({
      method: method,
      url: url,
      data: { ...data, page: props.currentPage },
      success: (response) => {
        toastr.success(response.message)
        props.reloadAssociateCompanies(response)
        IpaContext.setAllowAddIpAdmin(true)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  };

  return (
     <div className="react-modal">
      <div className="back-shadow"></div>
      <div className="modal-content react-modal-content">
        <div className="modal-header">
          <button
            className="close error-modal-close"
            type="button" onClick={props.hideAssociationForm}>×</button>
          <h4 className="modal-title">Associate Company</h4>
        </div>

        <form className='generalised-form' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className="modal-body">
            <div className="form-group" >
              <div className="row">
                <div className="col-md-3 col-md-offset-1">
                  <label> Company: </label>
                </div>
                <div className="col-md-5">
                  <div>
                  <Controller
                    as ={
                      <Selectpicker
                        className="select-picker form-control"
                        name="company_id"
                        data-live-search='true'
                        optionsForSelect = {unassociatedCompanies}
                        required={true}
                        ref={register}
                      />
                    }
                    rules={{ required: true }}
                    control={control}
                    name="company_id"
                    defaultValue={'2'}
                  />
                  {errors.company_id && <span>This field is required</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CompanyAssociationForm;
