import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Selectpicker from '../../shared/inputs/selectpicker'

function CompanyContentForm (props) {
  let [disableButton, setButtonDisable] = useState(false)
  let [jobCategory, setJobCategory] = useState([])
  let [statuses, setStatuses] = useState([])

  const handleSubmit = (e) => {
    let params = {
      company_content_export: {
        company_id: props.companyId,
        company_name: props.companyName,
        job_category: jobCategory,
        statuses,
        commit: e.target.value,
      }
    }

    e.preventDefault()
    setButtonDisable(true)
    $.ajax({
      url: `${props.action}`,
      type: "POST",
      data: params,
      success: (response) => {
        setButtonDisable(false)
        toastr.success(response.message);
        props.onFormSubmit(response.records);
      },
      error: () => {
        setButtonDisable(false)
        toastr.error('Error Downloading File.');
      },
    });
  }

  return (
    <div id='companyContentExport'>
      <div className='col-sm-4'>
        <form>
          <input key={props.companyId} value={props.companyId} type='hidden' name='company_id' />
          <input key={props.companyName} value={props.companyName} type='hidden' name='company_name' />
          <div className='col-xs-12'>
            <div className='form-group' style={{ marginTop: '25px' }}>
              <div className="row">
                <div className="col-sm-6">
                  <Selectpicker
                    className="select-picker form-control"
                    name="jobCategory"
                    multiSelect={true}
                    onChange={(value) => setJobCategory(value)}
                    placeholder='Job Categories'
                    optionsForSelect={props.jobCategoryOptions}
                  />
                </div>
                <div className="col-sm-6">
                  <Selectpicker
                    className="select-picker form-control "
                    name="status"
                    multiSelect={true}
                    onChange={(value) => setStatuses(value)}
                    placeholder='Status'
                    optionsForSelect={[['Active', 'active'], ['Draft', 'draft'], ['In Review', 'in_review'], ['Retired', 'retired']]}
                  />
                </div>
              </div>
              <input
                type="submit"
                disabled={disableButton}
                name="commit"
                value="Download QA Report"
                className="btn btn-primary pull-left btn-darkgray"
                style={{ marginTop: '15px' }}
                onClick={handleSubmit}
              />
              <input
                type="submit"
                disabled={disableButton}
                name="commit"
                value="Download Content"
                className="btn btn-primary pull-right btn-darkgray"
                style={{ marginTop: '15px' }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

CompanyContentForm.propTypes = {
  action: PropTypes.string,
  onFormSubmit: PropTypes.func,
  companyId: PropTypes.number,
  companyName: PropTypes.string,
  jobCategoryOptions: PropTypes.array,
};

export default CompanyContentForm;
