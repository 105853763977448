import React from 'react'

class EditTaskModal extends React.Component {
  constructor (props) {
    super (props)

    const task = props.detail.task
    this.state = {
      show: props.detail.show,
      id: task.id,
      eligible: task.eligible,
      due_date: task.due_date
    }
  }

  componentDidMount () {
    this.initializeBootstrapSwitch()
    this.initializeDatePicker()
  }

  initializeBootstrapSwitch () {
    $('.switch').bootstrapSwitch()
      .on('switchChange.bootstrapSwitch', (e) => {
        const eligible = this.state.eligible
        this.setState({eligible: !eligible})
      }).bind(this)
  }

  initializeDatePicker () {
    const _this = this
    $(".datepicker").datepicker({
      format: 'mm/dd/yyyy',
      clearBtn : true
    }).on('changeDate', (e) => {
      let temp = {}
      temp[$(e.target).data('name')] = e.target.value
      _this.setState(temp)
      $(e.target).datepicker('hide')
    }).keydown(false)
  }

  handleDateChange () {}
  handleSwitchChange () {}


  addClass (element, newClass) {
    element.className += ` ${newClass}`;
  }

  closePrompt () {
    const modalContainer = document.getElementsByClassName("react-modal");
    const modal = document.getElementsByClassName("react-modal-content modal-content");
    const backShadow = document.getElementsByClassName("back-shadow");
    this.addClass(modalContainer[0], "remove")
    this.addClass(modal[0], "remove slideOutPosition")
    this.addClass(backShadow[0], "remove")
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.cancel()
    }).bind(this), 500 )
  }

  renderDueDate () {
    return this.state.eligible ?
    (
      <div>
        <div className='benefit-start-end'>
          <div className='form-group'>
            <label className='label-control'>
              Due date:
            </label>
            {this.renderDatePicker('due_date')}
          </div>
        </div>
      </div>
    ) :
    null
  }

  renderCheckBox (state) {
    return (
      <input
        type='checkbox'
        className='switch'
        checked={state}
        value='1'
        onChange={this.handleSwitchChange}
      />
    )
  }

  renderDatePicker (dataName) {
    return (
      <div className='input-group'>
        <input
          type='text'
          className='form-control datepicker'
          data-name={dataName}
          value={this.state.due_date}
          onChange={this.handleDateChange}
        />
        <span className='input-group-addon btn-darkgray' onClick={this.openDatePicker}>
          <span className='fa fa-calendar-o fa-lg'/>
        </span>
      </div>
    )
  }

  openDatePicker (e) {
    const datepicker = $(e.target).closest('.input-group').find('.datepicker')
    if(!datepicker.is(':disabled')) {
      datepicker.datepicker('show')
    }
  }

  itemImage () {
    return {
      backgroundImage: 'url('+ this.props.detail.task.image_url + ')'}
  }

  submitForm (e) {
    e.preventDefault()
    this.props.approve(this.state)
    this.closePrompt()
  }

  render () {
    return this.state.show ? (
      <div className="react-modal">
        <div className="back-shadow" onClick={() => this.closePrompt()}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <div className='item-header-container'>
              <div className='item-header'>
                <div className='name'>
                  {this.props.detail.task.name}
                </div>
              </div>
              <div className='image-div' style={this.itemImage()}/>
            </div>
          </div>
          <div className="modal-body">
            <form className='task-item-edit-form' action='#' onSubmit={this.submitForm.bind(this)}>
              <div className='form-group'>
                <label className='label-control'> Visible? </label>
                {this.renderCheckBox(this.state.eligible)}
              </div>
              {this.renderDueDate()}
              <div className="buttons">
                <button className="cancel btn btn-danger" type='reset' onClick={this.closePrompt.bind(this)}>
                  Cancel
                </button>
                <button className="submit btn btn-primary" type='submit'>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null
  }
 }

 export default EditTaskModal;
