import React from 'react'
import SharedBenefit from './containers/SharedBenefit'
import BenefitTable from './containers/BenefitTable'
import Modal from '../../shared/Modal'
import Pagination from '../pagination/pagination'
import ClaimsListModal from './components/claims_list_modal'
const searchLocation = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')))

class SharedLeavePlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props,
      ...{
        modal: {
          promptText: "",
          title: "Confirmation",
          show: false,
          confirmationType: '',
          id: null
        },
        claimModal: {
          show: false,
          id: null
        },
        leavePlans: [],
        benefits: [
          {
            title: "Draft",
            type: 'draft',
            show: false,
            class: "draft-tile clickable",
            count: 0
          },
          {
            title: "Admin Samples",
            type: "admin_sample",
            show: false,
            class: "admin-sample clickable",
            count: 0
          },
          {
            title: "Confidential Planning",
            type: "confidential",
            show: false,
            class: "confidential",
            count: 0
          },
          {
            title: "Just Looking",
            type: "just_looking",
            show: false,
            class: "just-looking",
            count: 0
          },
          {
            title: "Planning",
            type: "planning",
            show: false,
            class: "planning-tile clickable",
            count: 0
          },
          {
            title: "On Leave",
            type: "on_leave",
            show: false,
            class: "on-leave-tile clickable",
            count: 0
          },
          {
            title: "Returned to Work",
            type: "returned_to_work",
            show: false,
            class: "return-to-work-tile clickable",
            count: 0
          },
          {
            title: "Graduated",
            type: "graduated",
            show: false,
            class: "graduated clickable",
            count: 0
          },
          {
            title: "Total Shared",
            type: "total",
            show: true,
            class: "total-benefits clickable",
            count: 0
          },
          {
            title: "Archived",
            type: "archived",
            show: false,
            class: "archived",
            count: 0
          }
        ],
        sortBy: {
          column: searchLocation.get('sort_by') || "leave_start_date",
          reverse: false
        },
        totalPage: props.data.employees.count,
        pageNumber: searchLocation.get('page') || '1',
        searchParam: searchLocation.get('q') || ''
      }
    }
  }

  componentDidMount() {
    const benefits = this.setBenefits(this.props.data)
    const finalBenefits = this.setBenefitStatus(benefits)
    this.setState({
      leavePlans: jQuery.extend(true, [], this.props.data.leave_plans),
      benefits: jQuery.extend(true, [], finalBenefits)
    })
  }

  setBenefits(data) {
    const sharedView = this.props.view_type === 'shared_leave_plans'
    return this.state.benefits.map((benefit, i) => {
      benefit.count = (!data.include_confidential && this.isConfidentialOrJustLooking(benefit)) || benefit.type === 'total' || (sharedView && benefit.type === 'archived')
        ? data[benefit.type].count
        : (data.group_count[benefit.type] || 0)
      return benefit
    })
  }

  setBenefitStatus(benefits) {
    let statuses = searchLocation.get('statuses')
    return benefits.map((benefit, i) => {
      if (statuses) {
        if (statuses.split(',').filter(status => status === benefit.type).length > 0) {
          benefit.show = true
          this.state.benefits[8].show = false
        }
      }
      return benefit
    })
  }

  filterToggle(type) {
    const benefits = this.toggleBenefitFrom(this.state.benefits, type)
    this.setState({
      benefits: benefits,
      pageNumber: 1,
    }, () => { this.buildUrl() })
  }

  toggleBenefitFrom(benefits, type) {
    const index = benefits.map(benefit => benefit.type).indexOf(type)
    if (type === "total") {
      const filterableBenefits = benefits.map((benefit) => {
        if (benefit.type !== 'total') {
          benefit.show = benefits[index].show
        }
        return benefit
      })
      benefits = filterableBenefits
    } else {
      benefits[8].show = false
    }
    benefits[index].show = !benefits[index].show
    return benefits
  }

  sharedBenefits(benefits) {
    const filteredBenefits = benefits.filter((benefit) => {
      return (this.props.data.include_confidential || !this.isConfidentialOrJustLooking(benefit)) && benefit.type !== "total"
    }).filter((benefit) => {
      return benefits[8].show || benefit.show
    })
    return filteredBenefits
  }

  isConfidentialOrJustLooking(benefit) {
    return benefit.type === 'confidential' || benefit.type === 'just_looking'
  }

  clickableClass(benefit) {
    if (this.props.data.include_confidential && this.isConfidentialOrJustLooking(benefit))
      return ' tile clickable'
    else if (this.props.data.enable_archive_tab)
      return ' clickable'
    else {
      return ''
    }
  }

  renderBenefitCard(benefit, id) {
    const hiddenClass = (benefit.type === 'draft' && benefit.count === 0) ? 'hide' : ''
    const clickableClass = this.clickableClass(benefit)
    const addedClass = benefit.show || (!this.props.data.include_confidential && this.isConfidentialOrJustLooking(benefit)) ? "" : " benefit-inactive"
    let benefitObject = jQuery.extend(true, {}, benefit)
    benefitObject.class = benefit.class + addedClass + clickableClass
    benefitObject.hiddenClass = hiddenClass

    return (
      <SharedBenefit
        benefit={benefitObject}
        key={id}
        toggleBenefit={this.filterToggle.bind(this)} />
    )
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.buildUrl() })
  }

  sort(column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState(
      { sortBy }, () => {
        this.buildUrl()
      })
  }

  buildUrl() {
    const { column, reverse } = this.state.sortBy
    const { searchParam, benefits } = this.state
    const order = reverse ? "desc" : "asc"
    let url = window.location.pathname
    url += window.location.search

    const status_array = benefits.filter(benefit => benefit.type !== 'total' && benefit.show).map(benefit => benefit.type)
    const statuses = benefits.filter(benefit => benefit.show).length === 0 ? ['none'] : status_array
    url = $.buildURL("q", searchParam, url)
    url = $.buildURL("statuses", statuses, url)
    url = $.buildURL("sort_by", column, url)
    url = $.buildURL("order", order, url)
    url = $.buildURL("page", this.state.pageNumber, url)

    this.fetchData(url)
  }

  fetchData(url) {
    const search_location = "?" + url.split("?").last()
    history.replaceState('/', null, search_location)

    $.ajax(({
      cache: false,
      url: url,
      dataType: 'json',
      success: (response) => {
        const benefits = this.setBenefits(response.data)
        this.setState({
          benefits: jQuery.extend(true, [], benefits),
          leavePlans: jQuery.extend(true, [], response.data.leave_plans),
          totalPage: response.data.employees.count
        })
      }
    }))
  }

  showConfirmation(id, confirmationType) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        confirmationType: confirmationType,
        id: id
      }
    }

    this.setState({
      modal
    })
  }

  toggleClaimModal(id, toggleTo) {
    const claimModal = {
      ...this.state.claimModal,
      show: toggleTo,
      id: id
    }
    this.setState({ claimModal })
  }

  claimModal() {
    const { id, show } = this.state.claimModal
    if (show) {
      return (
        <ClaimsListModal
          hideClaimModalInParent={() => this.toggleClaimModal(null, false)}
          id={id}
        />
      )
    }
  }

  showModal() {
    if (this.state.modal.show) {
      return (
        <Modal
          {...this.state.modal}
          hideModalInParent={this.hideModal.bind(this)}
          id={this.state.modal.id}
          approve={this.confirmationApproval()} >
          <span>{this.confirmationMessage()}</span>
        </Modal>
      )
    }
  }

  hideModal() {
    const modal = {
      ...this.state.modal,
      ...{
        show: false,
        confirmationType: '',
        id: null
      }
    }
    this.setState({
      modal
    })
  }

  confirmationMessage() {
    const employeeName = this.findEmployeeName(this.state.modal.id)
    if (this.state.modal.confirmationType === 'delete_leave_plan') {
      return 'Are you sure you want to delete it?'
    } else if (this.state.modal.confirmationType === 'archive_leave_plan') {
      return <span> Are you sure you want to archive this LeavePlan? <br /><br /> The LeavePlan will be locked and no longer accessible to you and the employee. </span>
    } else if (this.state.modal.confirmationType === 'reactivate_leave_plan') {
      return 'Are you sure you want to reactivate it?'
    } else if (this.state.modal.confirmationType === 'invite_employee') {
      return `Warning: If ${employeeName} already has a confidential LeavePlan, you will be overriding their details with those in the LeavePlan you are sharing now and we will notify the employee that you have changed their LeavePlan. This cannot be undone.`
    } else {
      return `Resend LeavePlan invite to ${employeeName}?`
    }
  }

  findEmployeeName(leave_plan_id) {
    const leavePlans = this.state.leavePlans
    const leavePlan = $.grep(leavePlans, (lp) => {
      return parseInt(lp.leave_plan_id) === parseInt(leave_plan_id)
    })[0]
    return leavePlan.employee_name
  }

  confirmationApproval() {
    if (this.state.modal.confirmationType === 'delete_leave_plan') {
      return this.deleteLeavePlan.bind(this)
    } else if (this.state.modal.confirmationType === 'archive_leave_plan') {
      return this.archiveLeavePlan.bind(this)
    } else if (this.state.modal.confirmationType === 'reactivate_leave_plan') {
      return this.reactivateLeavePlan.bind(this)
    } else if (this.state.modal.confirmationType === 'invite_employee') {
      return this.inviteEmployee.bind(this)
    } else {
      return this.resendLeavePlanInvitation.bind(this)
    }
  }

  inviteEmployee(id) {
    $.ajax({
      method: "PUT",
      url: `/admin/shared_leave_plans/${id}/invite_employee`,
      dataType: 'json',
      success: (data) => {
        if (data.success) {
          this.handleInvitedLeavePlan(data)
          toastr.success('Employee has been invited successfully.', 'Success')
        } else {
          toastr.error(data.error_message, 'Error')
        }
      }
    })
  }

  archiveLeavePlan(id) {
    $.ajax({
      method: "PUT",
      url: `/admin/shared_leave_plans/${id}/archive`,
      dataType: 'json',
      data: { view_type: this.props.view_type },
      success: (data) => {
        this.balanceArchive(id, 'add')
        toastr.success('LeavePlan has been archived successfully.', 'Success')
      },
      error: (msg) => {
        toastr.error(msg['responseJSON']['error_message'], 'Error')
      }
    })
  }

  reactivateLeavePlan(id) {
    $.ajax({
      method: "PUT",
      url: `/admin/shared_leave_plans/${id}/reactivate`,
      dataType: 'json',
      data: { view_type: this.props.view_type },
      success: (data) => {
        this.balanceArchive(id, 'remove', data)
        toastr.success('LeavePlan has been reactivated successfully.', 'Success')
      },
      error: (msg) => {
        toastr.error(msg['responseJSON']['error_message'], 'Error')
      }
    })
  }

  deleteLeavePlan(id) {
    $.ajax({
      method: "DELETE",
      url: `/admin/shared_leave_plans/${id}`,
      dataType: 'json',
      success: (data) => {
        if (data.success) {
          this.removeDeleteLeavePlanFrom(data)
          toastr.success('LeavePlan has been deleted successfully.', 'Success')
        } else {
          toastr.error(data.error_message, 'Error')
        }
      },
      error: (msg) => {
        toastr.error(msg['responseJSON']['error_message'], 'Error')
      }
    })
  }

  resendLeavePlanInvitation(id) {
    $.ajax({
      method: 'PUT',
      url: `/admin/shared_leave_plans/${id}/resend_leave_plan_invitation`,
      dataType: 'json',
      success: (data) => {
        if (data.success) {
          toastr.success('Employee has been invited again.', 'Success')
        } else {
          toastr.error(data.error_message, 'Error')
        }
      }
    })
  }

  handleInvitedLeavePlan(data) {
    const benefitIndex = this.findLeavePlanGroupIndex(data.leave_status)
    const benefits = this.state.benefits
    if (['leave_status_name', 'shared_on'].indexOf(this.state.sortBy.column) > -1 || (benefits[0].show && !benefits[benefitIndex].show)) {
      this.fetchData(window.location.href)
    } else {
      this.switchLeavePlanStatus(data)
    }
  }

  switchLeavePlanStatus(data) {
    let { leavePlans, benefits } = this.state
    const leavePlanIndex = this.findLeavePlanIndex(data.leave_plan_id, leavePlans)
    leavePlans[leavePlanIndex].leave_status = data.leave_status
    leavePlans[leavePlanIndex].leave_status_name = data.leave_status_name
    leavePlans[leavePlanIndex].shared_on = data.shared_on
    leavePlans[leavePlanIndex].shared = true

    benefits[0].count -= 1
    const benefitIndex = this.findLeavePlanGroupIndex(data.leave_status)
    benefits[benefitIndex].count += 1

    this.setState({
      benefits: benefits,
      leavePlans: leavePlans
    })
  }


  removeDeleteLeavePlanFrom(data) {
    let leavePlanId = data.leave_plan_id
    let { benefits, leavePlans } = this.state
    let index = this.findLeavePlanIndex(leavePlanId, leavePlans)
    let leaveStatus = this.findLeavePlanStatus(leavePlanId, leavePlans)
    let leavePlanGroupIndex = this.findLeavePlanGroupIndex(leaveStatus)
    if (index >= 0) {
      leavePlans.splice(index, 1)
      benefits[leavePlanGroupIndex].count -= 1
      benefits[8].count -= 1
    }
    this.setState({
      benefits: benefits,
      leavePlans: leavePlans
    })
  }

  leaveStatusVisibility(status) {
    const { benefits } = this.state
    const shownLeavePlans = benefits.filter(benefit => benefit.type !== 'total' && benefit.show)
    const selected = shownLeavePlans.length > 0 && shownLeavePlans.filter(benefit => benefit.type == status)
    const visible = selected.length > 0 && selected[0]['show']

    return (visible || shownLeavePlans.length == 0)
  }

  balanceArchive(leavePlanId, action, data = null) {
    let { benefits, leavePlans } = this.state
    let leavePlanIndex = this.findLeavePlanIndex(leavePlanId, leavePlans)
    let leaveStatus = this.findLeavePlanStatus(leavePlanId, leavePlans)
    let archivedGroupIndex = this.findLeavePlanGroupIndex('archived')
    let leavePlanGroupIndex = this.findLeavePlanGroupIndex(leaveStatus)
    let isArchivedVisible = this.leaveStatusVisibility('archived')

    if (this.props.data.enable_archive_tab && action == 'add') {
      benefits[leavePlanGroupIndex].count -= 1
      benefits[archivedGroupIndex].count += 1
      if (isArchivedVisible) {
        leavePlans[leavePlanIndex].leave_status = 'archived'
        leavePlans[leavePlanIndex].leave_status_name = 'Archived'
      } else {
        leavePlans.splice(leavePlanIndex, 1)
      }
    } else if (this.props.data.enable_archive_tab && action == 'remove') {
      leavePlanGroupIndex = this.findLeavePlanGroupIndex(data.leave_status)
      benefits[leavePlanGroupIndex].count += 1
      benefits[archivedGroupIndex].count -= 1
      if (this.leaveStatusVisibility(data.leave_status)) {
        leavePlans[leavePlanIndex].leave_status = data.leave_status
        leavePlans[leavePlanIndex].leave_status_name = data.leave_status_name
      } else {
        leavePlans.splice(leavePlanIndex, 1)
      }
    } else {
      benefits[leavePlanGroupIndex].count -= 1
      benefits[archivedGroupIndex].count += 1
      leavePlans.splice(leavePlanIndex, 1)
    }

    this.setState({
      benefits: benefits,
      leavePlans: leavePlans
    })
  }

  findLeavePlan(leave_plan_id, leavePlans) {
    return leavePlans.filter((lp) => parseInt(lp.leave_plan_id) === parseInt(leave_plan_id))[0]
  }

  findLeavePlanIndex(leave_plan_id, leavePlans) {
    const leavePlan = this.findLeavePlan(leave_plan_id, leavePlans)
    return leavePlans.indexOf(leavePlan)
  }

  findLeavePlanGroupIndex(leave_plan_status) {
    const leavePlanGroup = $.grep(this.state.benefits, (b) => {
      return b.type === leave_plan_status
    })[0]
    return this.state.benefits.indexOf(leavePlanGroup)
  }

  findLeavePlanStatus(leave_plan_id, leavePlans) {
    const leavePlan = this.findLeavePlan(leave_plan_id, leavePlans)
    return leavePlan.leave_status
  }

  handleSearch(event) {
    const value = event.target.value
    if (event.keyCode === 13) {
      event.preventDefault()
      this.setState({
        pageNumber: 1,
        searchParam: value
      }, () => { this.buildUrl(value) })
    } else {
      this.setState({
        searchParam: value
      })
    }
  }

  renderDownloadSharedLeavePlans() {
    return (
      <div className="download-shared-leave-plans">
        <span className="fa fa-download fa-lg download-shared-leave-plans-icon"> </span>
        <a
          href="#"
          onClick={this.handleHref.bind(this)}
        >
          <p>Download this list </p>
        </a>
      </div>
    )
  }

  handleHref(e) {
    let searchQuery = window.location.search
    let href = `/admin/shared_leave_plans/download_shared_leave_plans${searchQuery}`
    window.location = href
  }

  render() {
    const supportsClaimSubmission = this.props.supports_claim_submission

    return (
      <div>
        <div className="fixed-card-container">
          <div className="benefit-cards-container">
            {this.state.benefits.map((benefit, i) => this.renderBenefitCard(benefit, i))}
          </div>
          <div className="filters-container">
            <div className='search-box'>
              <label> Search By Name :</label>
              <input type='text' ref='name_query' defaultValue={this.state.searchParam} onKeyDown={(e) => this.handleSearch(e)} />
            </div>
            <Pagination
              totalItems={this.state.totalPage}
              changePageNumber={this.changePageNumber.bind(this)}
              pageNumber={this.state.pageNumber}
              itemsPerPage={30}
            />
            <div className="download-shared-leave-plans">
              {this.props.view_type === 'shared_leave_plans' ? this.renderDownloadSharedLeavePlans() : null}
            </div>
          </div>
        </div>
        <div className="table-container">
          <BenefitTable
            benefits={this.state.leavePlans}
            sort={this.sort.bind(this)}
            sortBy={this.state.sortBy}
            showConfirmation={this.showConfirmation.bind(this)}
            supportsClaimSubmission={supportsClaimSubmission}
            toggleClaim={supportsClaimSubmission && this.toggleClaimModal.bind(this)}
            currentCompany={this.props.current_company}
            isSuperAdmin={this.state.data.is_super_admin}
          />
        </div>
        {
          this.showModal()
        }
        {
          this.claimModal()
        }
      </div>
    )
  }
}

export default SharedLeavePlans;
