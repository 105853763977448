import React from 'react'

class SwitchInput extends React.Component {
  state = {
    isChecked: this.props.checked
  }

  shouldComponentUpdate(nextProps) {
    const { isChecked } = this.state
    if (nextProps.checked !== isChecked) {
      this.destroyBootstrapSwitch()
      this.setState({isChecked: !isChecked})
      return false
    }
    return true
  }

  componentDidMount() {
    this.initializeBootstrapSwitch()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.destroyBootstrapSwitch()
    }
    this.initializeBootstrapSwitch()
  }

  destroyBootstrapSwitch() {
    const checkbox = `.switch[name=${this.props.name}]`
    $(checkbox).bootstrapSwitch('destroy')
  }

  initializeBootstrapSwitch () {
    $(`.switch[name=${this.props.name}]`)
      .bootstrapSwitch()
      .off('switchChange.bootstrapSwitch')
      .on('switchChange.bootstrapSwitch', (e) => {
        this.setState({isChecked: !this.state.isChecked})
        this.props.onChange && this.props.onChange($(e.target).is(':checked'))
      })
  }

  additionalClass () {
    return this.props.className ?
      this.props.className : ''
  }

  render () {
    return (
      <input
        type="checkbox"
        name={this.props.name}
        id={this.props.id}
        className={`switch ${this.additionalClass()}`}
        disabled={this.props.disabled}
        checked={this.state.isChecked}
        onChange={() => {}}
        data-target={this.props.dataTarget}
      />
    )
  }
}

export default SwitchInput
