import React from 'react'
import ReactSelectPicker from '../../../shared/ReactSelectPicker'

class LeavePlanActions extends React.Component {

  constructor () {
    super()
  }

  buildActions () {
    let options = []
    if (this.props.inviteEmployee) {
      options.push({ value: 'invite_employee', label: 'Send Invite' })
    } else if (this.props.resendInvitation) {
      options.push({ value: 'resend_invitation', label: 'Resend Invite' })
    } else if (this.props.reactivate) {
      options.push({ value: 'reactivate_leave_plan', label: 'Reactivate' })
    }

    if (this.props.showDelete && this.props.isSuperAdmin) { options.push({ value: 'delete_leave_plan', label: 'Delete' }) }
    if (!this.props.reactivate) { options.push({ value: 'archive_leave_plan', label: 'Archive' }) }

    return options
  }

  handleChange = selectedOption => {
    this.props.showConfirmation(this.props.leave_plan_id, selectedOption.value)
  };

  render () {
    let options = this.buildActions()
    let data = this.props.sharedDate || ''
    return options.length > 0 ?
      <span className="actions" onClick={(e) => e.stopPropagation()}>
        <ReactSelectPicker
          value={''}
          options={options}
          onChange={this.handleChange}
          placeholder={'Action'}
          isSearchable={false}
          className={'action-text'}
        />
      </span> : null
  }

}

export default LeavePlanActions;
