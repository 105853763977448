import React from 'react'

class InstantNotificationsCount extends React.Component {
  constructor () {
    super ()

    this.state = {
      count: 0
    }
  }

  componentDidMount () {
    $.getJSON('/admin/notifications/unseen_notifications_count', (response) => {
      this.setState({count: response.count})
    })
    document.getElementById('notification-handle').addEventListener('click', this.resetUnseenCount.bind(this), true)
  }

  resetUnseenCount (e) {
    const handle = document.getElementById('notification-handle')
    const notificationIcon = $(e.target).closest('#notifications-icon')
    if((notificationIcon.length || e.target === handle) && !$(handle).hasClass('notification-opened')) {
      $.post('/admin/notifications/reset_unseen_count', (response) => {
        this.setState ({count: 0})
      })
    }
  }

  notificationsCountDiv () {
    if(this.state.count) {
      return (
        <div className='notification-count-container'>
          <div className='unseen-notification-count'>
            {this.state.count}
          </div>
        </div>
      )
    } else {
      return (<div/>)
    }
  }
  
  render () {
    return (this.notificationsCountDiv())
  }

}

export default InstantNotificationsCount;