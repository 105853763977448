import React from 'react'

class AddManualBenefitModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: this.props.show,
      manualBenefits: []
    }
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }
  
  componentDidMount() {
    $.displaySpinner('.spinner-div')
    $.getJSON(`/admin/shared_leave_plans/${this.props.leavePlanId}/manual_benefits`, (response) => {
      this.setState({manualBenefits: response.data})
      $.removeSpinner()
    })
  }
  
  addClass(element, newClass) {
    element.className += ` ${newClass}`;
  }
  
  handleSwitchChange(e) {
    const checked = ($(e.target).is(':checked'))
    const manualBenefits =
      this.state.manualBenefits.map(benefit => {
        if (benefit.id === parseInt(e.target.value)) {
          benefit.selected = checked
        }
        return benefit
      })
    this.setState({manualBenefits})
  }

  renderBenefitRow(benefit) {
    return (
      <div key={benefit.id} className="list-group-item">
        <div className="list-group-item-heading">
        <div className="vcenter">
        <h4 className="break-word">
          {this.renderCheckBox(benefit)}{benefit.name}
        </h4>
        </div>
        </div>
      </div>
    )
  }
  
  renderCheckBox(benefit) {
    return (
      <input
        type='checkbox'
        className='eligible-benefit-switch'
        value={benefit.id}
        defaultChecked={benefit.selected}
        onChange={this.handleSwitchChange}
      />
    )
  }

  submitForm(e) {
    e.preventDefault()
    this.addManualBenefits()
  }

  selectedManualBenefits() {
    const manualBenefits = this.state.manualBenefits
    return {
      selected_benefit_ids: manualBenefits.filter(benefit => benefit.selected).map(benefit => benefit.id)
    }
  }

  addManualBenefits() {
    const params = this.selectedManualBenefits()
    if (params.selected_benefit_ids.length > 0) {
      $.post(`/admin/shared_leave_plans/${this.props.leavePlanId}/manual_benefits`, params, (response) => {
        if (response.success) {
          toastr.success('Successfully LeavePlan associated with Benefits.')
          this.props.addManualBenefitsToState(response.data)
          PubSub.publish('fetchDashboardBenefits')
          this.closePrompt()
        } else {
          toastr.error(response.error_message, 'Error')
          this.closePrompt()
        }
      })
    } else {
      toastr.error("None selected", 'Error')
      this.closePrompt()
    }
  }

  closePrompt() {
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.cancel()
    }).bind(this), 500 )
  }

  render() {
    const manualBenefits = this.state.manualBenefits
    return (this.state.show) ? (
      <div className="react-modal">
        <div className="back-shadow" onClick={this.closePrompt.bind(this)}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <div className='item-header-container'>
              <div className='item-header'>
                <div className='name'>
                  Select Manual Benefits
                </div>
              </div>
              </div>
          </div>
          <div className="modal-body leave-plan-benefit-modal">
              <div className="spinner-div">
              </div>
              <div className="bootcards-list">
                 <div className="list-group">
                    { manualBenefits.length > 0 ? manualBenefits.map(benefit => this.renderBenefitRow(benefit)) : <p>Manual benefits not found</p> }
                 </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closePrompt.bind(this)}>Close</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={manualBenefits.length < 1 } onClick={this.submitForm.bind(this)}>Save</button>
            </div>
        </div>
      </div>
    ) : null
  }
}

export default AddManualBenefitModal

