import { TableCell } from '@material-ui/core';
import React from 'react';

const CustomContentHandler = ({ row,
  openCustomContentEditModal,
  setOpenCustomContentEditModal,
  setStatutoryContent,
  setRedirectTo,
  handleModal
}) => {

  const fetchCustomContent = (value) => {
    $.ajax({
      method: 'GET',
      url: `/admin/statutory_substitutions/${value}`,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response) => {
        setStatutoryContent(response.data)
      },
      error: (err) => {
        console.log({ err })
      }
    })
  }

  const customContentHandler = (url, value) => {
    setOpenCustomContentEditModal(true);
    setRedirectTo(url);
    fetchCustomContent(value);
    handleModal('customContent');
  }

  return (
    <TableCell style={{ fontSize: 14 }}>
      {row.custom_contents.map(content =>
        <div onClick={() => customContentHandler(row.url, content.value)} style={{cursor: 'pointer', textDecoration: 'underline'}}>
          {content.name}
          <br />
        </div>
      )}
    </TableCell>
  );
};


export default CustomContentHandler;
