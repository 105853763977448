import React from 'react'

class HelperTooltip extends React.Component {

  constructor () {
    super()
  }

  showTooltip (button) {
    $(button).closest('.helper-text').find('.helper-tooltip').removeClass('hidden')
  }

  hideTooltip () {
    $('.helper-text .helper-tooltip').addClass('hidden')
  }  

  render () {
    return (
      <div className='helper-text'>
        <div className='helper-tooltip hidden'>
          <div className='helper-tooltip-text' dangerouslySetInnerHTML={{__html: this.props.helper_text}} />
          <div className='helper-tooltip-pointer'>
          </div>
        </div>
        <div 
          className='helper-icon'
          onMouseOver={(e) => this.showTooltip(e.currentTarget)}
          onMouseLeave={this.hideTooltip}
        >
          <i className='fa fa-question-circle' />
        </div>
      </div>
    )
  }
}

export default HelperTooltip;
