import { TableCell } from '@material-ui/core';
import React from 'react';

const CustomOverridesHandler = ({ row, openOverrideModal, setOpenOverrideModal, setOverrides, setRedirectTo, handleModal }) => {

  const handleOverridden = (overriddenData, url) => {
    setOpenOverrideModal(true);
    setRedirectTo(url);
    setOverrides(overriddenData);
    handleModal('overrides');
  }

  function getFullURL (urlPath) {
    let loc = window.location;
    let url = "" + loc.protocol + "//" + loc.host + urlPath;
    return url;
  }

  return (
    <TableCell style={{ fontSize: 14 }}>
      {row.overridden.length > 3 ?
        <>
          <div style={{ cursor: 'pointer' }}> &#x2022; {row.overridden[0].wordify(" ", true)} </div>
          <div style={{ cursor: 'pointer' }}> &#x2022; {row.overridden[1].wordify(" ", true)} </div>
          <div>
            <u style={{ cursor: 'pointer' }} onClick={() => handleOverridden(row.overridden, row.url)}>
              and {row.overridden.length - 2} more
            </u>
          </div>
        </> : row.overridden.length > 0 ?
          <>
            {row.overridden.map(overrideData =>
              <div style={{ cursor: 'pointer' }} key={overrideData.id}>&#x2022; {overrideData.wordify(" ", true)}</div>
            )}
            <u style={{ cursor: 'pointer' }} onClick={() => window.location.href = getFullURL(row.url)}>Edit</u>
          </> : <></>
      }
    </TableCell>
  );
};

export default CustomOverridesHandler;
