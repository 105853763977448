import React from 'react'

class Autocomplete extends React.Component {
  constructor(props) {
    super(props)

    this.autoCompleteId = parseInt(Math.random() * 100000)

    this.state = {
      source: props.availableItems,
      selectedItems: props.selectedItems
    }
  }

  componentDidMount() {
    this.initializeAutocomplete()
  }

  componentDidUpdate() {
    $(`.autocomplete#${this.autoCompleteId}`).autocomplete('destroy')
    this.initializeAutocomplete()
  }

  initializeAutocomplete() {
    const selectedItemIds = this.state.selectedItems.map(item => item.id)
    $(`.autocomplete#${this.autoCompleteId}`).autocomplete({
      source: this.state.source.filter(item => selectedItemIds.indexOf(item.id) < 0),
      open: (e) => {
        const input = e.target
        const inputPosition = $(input).offset()
        const inputHeight = $(input).outerHeight()
        const inputWidth = $(input).outerWidth()
        const autocompleteInstance = $(input).autocomplete('instance')
        let autocompleteWidget = autocompleteInstance.widget()
        let widgetElement = autocompleteWidget.context
        let style = widgetElement.style
        style.position = 'absolute'
        style.top = inputPosition.top + inputHeight + 'px'
        style.width = inputWidth + 'px'
      },
      select: (event, item) => {
        const selectedItems = this.state.selectedItems.concat([item.item])
        this.setState({selectedItems})
        this.props.onChange && this.props.onChange(selectedItems)
        // get_leave_items()
      }
    })
  }

  unselectItemHandler(itemId) {
    const selectedItems = this.state.selectedItems.filter(item => item.id !== itemId)
    this.setState({selectedItems})
    this.props.onChange && this.props.onChange(selectedItems)
  }

  renderselectedItem(item) {
    return (
      <a className='list-group-item' key={item.id}>
        <h4 className='list-group-item-heading'>
          {item.value}
          <i
            className='fa fa-times pull-right'
            onClick={() => this.unselectItemHandler(item.id)}
          />
        </h4>
      </a>
    )
  }

  renderHiddenInput(item) {
    return (
      <input key={item.id} value={item.id} type='hidden' name={this.props.name} />
    )
  }

  render() {
    return(
      <div className='autcomplete-div'>
        <div>
          <input
            className='form-control autocomplete'
            placeholder={this.props.placeholder}
            id={this.autoCompleteId}
          />
          <div className='bootcards-list'>
            <div className='list-group'>
              { this.state.selectedItems.map(selectedItem => this.renderselectedItem(selectedItem)) }
            </div>
          </div>
        </div>
        <div>
          { this.state.selectedItems.map(selectedItem => this.renderHiddenInput(selectedItem)) }
        </div>
      </div>
    )
  }
}

export default Autocomplete