import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'

class ApiPartnerForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.isEdit ? props.apiPartner.name : '',
      status: props.isEdit ? props.apiPartner.status : '',
      aws_api_gateway_key: props.isEdit ? props.apiPartner.aws_api_gateway_key : '',
      company_ids: props.isEdit ? props.apiPartner.company_ids : [],
      role: props.isEdit ? props.apiPartner.role : '',
      disableButton: false,
      implementation_partner_ids: props.isEdit ? props.apiPartner.implementation_partner_ids : [],
    }
  }

  handleChange (key, value) {
    if (key === "aws_api_gateway_key") {
      value = value.replace(/\s/g, "")
    }
    this.setState({ [key]: value })
  }

  submitApiPartner () {
    const { apiPartner, isEdit } = this.props
    const { name, status, aws_api_gateway_key, company_ids, role, implementation_partner_ids } = this.state
    this.setState({ disableButton: true })

    let method = 'POST'
    let url = '/admin/api_partners/'
    const params = {
      name: name,
      status: status,
      aws_api_gateway_key: aws_api_gateway_key,
      company_ids: company_ids,
      role: role,
      implementation_partner_ids: implementation_partner_ids,
    }
    if (isEdit) {
      method = 'PUT'
      url = `/admin/api_partners/${apiPartner.id}`
    }

    $.ajax({
      method: method,
      url: url,
      data: { api_partner: params },
      success: (response) => {
        this.props.onSubmit(response.data)
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  render () {
    const { disableButton, name, status, aws_api_gateway_key, company_ids, role, implementation_partner_ids } = this.state
    const { onModalClose, isEdit } = this.props

    return (
      <div className="react-modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={onModalClose}
            >×</button>
            <h4 className="modal-title">API Partner</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => this.handleChange('name', e.target.value)}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label>AWS API gateway key</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="aws_api_gateway_key"
                  disabled={isEdit}
                  value={aws_api_gateway_key}
                  onChange={(e) => this.handleChange('aws_api_gateway_key', e.target.value)}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Type</label>
              <div>
                <Selectpicker
                  className="select-picker form-control"
                  name="role"
                  data-live-search='true'
                  defaultValue={role}
                  onChange={(value) => this.handleChange('role', value)}
                  optionsForSelect={[["Partner", "partner"], ["Companies", "super_admin"]]}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Status</label>
              <div>
                <Selectpicker
                  className="select-picker form-control"
                  name="status"
                  data-live-search='true'
                  defaultValue={status}
                  onChange={(value) => this.handleChange('status', value)}
                  optionsForSelect={[["Draft", "draft"], ["Active", "active"], ["Deactivated", "deactivated"], ["Retired", "retired"]]}
                />
              </div>
            </div>
            {
              role === 'partner' ?
                <div className="form-group">
                  <label>Associate Partner</label>
                  <div>
                    <Selectpicker
                      className="select-picker multi-select form-control"
                      name="implementation_partner_ids"
                      multiSelect={true}
                      defaultValue={implementation_partner_ids}
                      onChange={(value) => this.handleChange("implementation_partner_ids", value)}
                      optionsForSelect={this.props.implementation_partners}
                    />
                  </div>
                </div> :
                <div className="form-group">
                  <label>Associate Companies</label>
                  <div>
                    <Selectpicker
                      className="select-picker multi-select form-control"
                      name="company_ids"
                      multiSelect={true}
                      defaultValue={company_ids}
                      onChange={(value) => this.handleChange("company_ids", value)}
                      optionsForSelect={this.props.companies}
                    />
                  </div>
                </div>
            }
            <div className="modal-footer">
              <div className="buttons">
                <button className="btn btn-primary"
                  onClick={(e) => this.submitApiPartner(e)}
                  disabled={disableButton}>
                  {isEdit ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ApiPartnerForm
