import React from 'react'

class StripTable extends React.Component {

  constructor(props) {
    super(props);
  }

  tableHeads() {
    return (<tr>
      {this.props.theads.map((thead, index) => (
        <th key={index} className={thead.className}>
          {thead.title}
        </th>
      ))}
    </tr>);
  }

  sortedTimeOffTypes() {
    const timeOffTypes = this.props.tRows;
    return timeOffTypes.sort((timeOffOne, timeOffTwo) =>{
            return timeOffOne.order - timeOffTwo.order;
    });
  }

  tableRows() {
    return (<tbody>
      {this.sortedTimeOffTypes().map((tRow, index) => {
        const values = this.props.theads.map((head) => {
          if (tRow[head.key]) {
            return {
              content: tRow[head.key],
              className: head.className,
            };
          } else if (typeof head.render === 'function') {
            return {
              content: head.render(tRow),
              className: head.className,
            };
          } else {
            return {
              content: '',
              className: head.className,
            }
          }
        });
        return (<tr key={index}>
          {values.map((v, vindex) => {
            return (
              <td key={`${index}-${vindex}`} className={v.className}>
                {v.content}
              </td>
            )
          })}
        </tr>)
      }).flatten()}
    </tbody>);
  }

  render() {
    const { className: tableClassName } = this.props;
    return (
      <div className="row">
        <div className={tableClassName}>
          <table className="table table-striped-even table-responsive">
            <thead>
              { this.tableHeads() }
            </thead>
            { this.tableRows() }
          </table>
        </div>
      </div>
    )
  }
}

export default StripTable;
