import React from 'react'
import PropTypes from 'prop-types'
import BenefitScenarioTile from '../../../admin/shared_leave_plans/components/benefitScenarioTile'

class GenericTableData extends React.Component {

  renderData (data, cssClass, childClass) {
    data = data || ""
    if (cssClass === "shared-date") {
      return (
        <span className="date">{data}</span>
      )
    } else if (cssClass === "status") {
        return <BenefitScenarioTile text={data} childClass={childClass} />
    }
    return <span>{data || this.props.children}</span>
  }

  renderChevron (cssClass) {
    return cssClass === "shared-date" ? (
      <span className="chevron"></span>
    ) : ""
  }

  renderChildren() {
    const categoryCss = {
      fontSize: 48,
      textAlign: 'center',
      flex: 1
    }
    const linkCss = {
      fontSize: 16,
      textAlign: 'center',
      flex: 1
    }
    if (this.props.class === 'template-category') {
      const category = this.props.children.props.dangerouslySetInnerHTML.__html;
      if (category === 'statutory') {
        return <i className={`material-icons ${category}`} style={categoryCss}>security</i>
      } else if (category === 'locked') {
        return <i className={`material-icons ${category}`} style={categoryCss}>lock</i>
      } else {
        if (category.includes("-")) {
          return (
            <div>
              {this.bulkCategoryIcon(category, categoryCss)}
            </div>
          );
        }
        return <i className={`material-icons ${category}`} style={categoryCss}>lock_open</i>
      }
    }
    if (this.props.class === 'name sortable') {
      if (this.props.linkedBenefit) {
        const name = this.props.children.props.children;
        return <span> {name} <div className='chain-link-icon' /> </span>
      }
    }
    return this.props.children;
  }

  bulkCategoryIcon(categories, categoryCss) {
    return categories.split("-").map((category, index) => {
      let categoryName = "";
      if (category == "statutory") {
        categoryName = "security";
      } else if (category == "locked") {
        categoryName = "lock";
      } else {
        categoryName = "lock_open";
      }
      return (
        <i key={index} className={`material-icons ${category}`} style={categoryCss}>
          {categoryName}
        </i>
      );
    });
  }

  renderPreview(e) {
    if (Object.keys(this.props.otherInfo).length <= 0) return null;
    if (!this.props.otherInfo.showContentPreview) return null
    this.props.showPreview(e, this.props.otherInfo.substitutionId)
  }

  render () {
    return (
      <td className={`table-data ${this.props.class}`} onClick={(e) => this.renderPreview(e)}>
        {this.renderChildren()}
      </td>
    )
  }
}

GenericTableData.propTypes = {
  class: PropTypes.string, // class of the table data
}

export default GenericTableData;
