import React from 'react'

const READABLE_STATUS = {
  retired: 'Retired',
  active: 'Active',
  in_review: 'In Review',
  draft: 'Draft'
}

const STATUS_LABELS = {
  active: 'success',
  retired: 'danger',
  other: 'default'
}

class ListItems extends React.Component {
  constructor(props) {
    super(props)
  }

  allItemsChecked () {
    const { items, selectedItems } = this.props

    return selectedItems.length && items.every(item => selectedItems.indexOf(item.id) >= 0)
  }

  itemCheckBox(item, itemIndex) {
    return (
      <input type="checkbox"
        className="select-item"
        style={{
          height: 25,
          width: 15
        }}
        checked={item.associate || false}
        onChange={(e) => this.props.handleAssociation(e, item.id, itemIndex) }
      />
    )
  }

  itemInfo (item, itemIndex) {
    let statusLabel = STATUS_LABELS[item.status] || 'default'

    return (
      <div className="break-word col-xs-8 col-sm-10 vcenter">
        { this.props.officeEditable && this.itemCheckBox(item, itemIndex) }
        <h4 className="pull-left">{item.name}</h4>
        <span className={`pull-left label status-label label-${statusLabel}`}>{READABLE_STATUS[item.status]}</span>
      </div>
    )
  }

  changeStatus(e, itemId, newStatus) {
    e.preventDefault()
    this.props.changeStatus(itemId, newStatus[0])
  }

  decorateStatus (itemId, newStatus, index) {
    return (
      <li key={index}>
          <span className="dropdown-item" style={{ padding: 10 }} onClick = {(e) => this.changeStatus(e, itemId, newStatus) }>{newStatus[1]}</span>
      </li>
    )
  }

  statusDropDown (item) {
    if (!this.props.officeEditable) return null;

    let statuses = this.props.statusDropDown[item.status].map ( (option, index) => this.decorateStatus(item.id, option, index))
    return (
      <div className="col-xs-4 col-sm-2">
        <div className="dropdown">
          <i className="fa fa-2x fa-angle-double-down dropdown-toggle" data-toggle="dropdown">
          </i>
          <ul className="dropdown-menu">
            {statuses}
          </ul>
        </div>
      </div>
    )
  }

  handleAllSelect (e) {
    this.props.handleAllSelect(e.target.checked)
  }

  selectAllInput () {
    const { selectedItems, items, officeEditable } = this.props
    const selectedInfo = selectedItems.length > 0 ? `(${selectedItems.length} Offices selected)` : null

    return (officeEditable && items.length > 0)  ? (
      <span>
        <input
          type="checkbox"
          className=""
          checked={this.props.allSelected}
          onChange={(e) => this.handleAllSelect(e) }
        /><label style={{ paddingLeft: 15, fontSize: 18, color: '#41767a' }}> Select All {selectedInfo}</label>
      </span>
    ) : null
  }

  handleBulkEditModal() {
    this.props.handleBulkEditModal(this.props.selectedItems)
  }

  bulkEditControl () {
    const { selectedItems, officeEditable } = this.props

    return( officeEditable && selectedItems.length > 0 ?
      <span>
          <button
            className="btn btn-success btn-sm"
            style={{ marginLeft: 10 }}
            onClick={this.handleBulkEditModal.bind(this)}
          >
            Bulk Edit
          </button>
      </span> : null
    )
  }

  renderItem (item, itemIndex) {
    const officeEditable = this.props.officeEditable
    const editUrl = this.props.officeEditable ? `${this.props.baseLink}/${item.id}/edit` : null
    let itemInfo = this.itemInfo(item, itemIndex)
    let statusesList = this.statusDropDown(item)

    return (
      <a className="list-group-item" key={item.id} href={editUrl}>
          <div className="list-group-item-heading">
            <div className="row vcenter">
              { itemInfo }
              { statusesList }
            </div>
          </div>
          <p className="list-group-item-text"><span>{item.detail_location}</span></p>
        </a>
      )
  }

  render () {
    return (
      <div className="bootcards-list">
        { this.selectAllInput() }
        { this.bulkEditControl() }
        <div className="list-group">
          {this.props.items.map ( (item, index) => this.renderItem(item, index))}
        </div>
      </div>
    )
  }
}

export default ListItems
