import React from 'react'
import Datepicker from '../../shared/inputs/datepicker'

class DashboardBenefitsTableView extends React.Component {
  constructor () {
    super()
  }

  renderBenefitrow (benefit, index) {
    return (
      <tr className='employee-benefit-body-row' key={index}>
        <td className='benefit-table-data name'><span> {benefit.name} </span></td>
        <td className='benefit-table-data'>
          <Datepicker
            defaultValue={benefit.start_date}
            startDate={benefit.start_date}
            endDate={benefit.start_date}
            readOnly='true'
          />
        </td>
        <td className='benefit-table-data'>
          <Datepicker
            defaultValue={benefit.end_date}
            startDate={benefit.end_date}
            endDate={benefit.end_date}
            readOnly='true'
          />
        </td>
        <td className='benefit-table-data'><span> {benefit.forecasted_usage} </span></td>
        <td className='benefit-table-data'><span> {benefit.estimated_remaining_days} </span></td>
        <td className='benefit-table-data'>
          <Datepicker
            defaultValue={benefit.extendable_leave_end_date}
            startDate={benefit.extendable_leave_end_date}
            endDate={benefit.extendable_leave_end_date}
            readOnly='true'
          />
        </td>
      </tr>
    )
  }

  renderDatepickerCalendarSpan () {
    return(
      <span className="input-group-addon btn-darkgray datepicker-addon">
        <span className="fa fa-calendar-o fa-lg"></span>
      </span>
    )
  }

  renderAdditionalLeave () {
    const showAdditional = (this.props.benefit_counts.additional_benefit > 0 && !this.props.isAdmin && !this.props.isLeavePlanPartner)
    const addEditBenefitsText = this.props.add_edit_benefits_text || 'Add/Edit Additional Paid/Unpaid Leaves'
    if (showAdditional) {
      return (
        <div className='dashboard-additional-count'>
          <div className='exclamation-icon'>
            <i className='fa fa-edit' />
          </div>
          <a
            className='additional-count-text standard-link'
            href='/employees/time_off_types'
          >{addEditBenefitsText}</a>
        </div>
      )
    }
  }

  renderBenefitTableHeader (benefitCount) {
    if (benefitCount > 0) {
      return (
        <thead className='thead employee-benefit-table-header'>
          <tr className='employee-benefit-header-row'>
            <th className='table-title name'><span></span></th>
            <th className='table-title'><span>Current Plan Start Date</span></th>
            <th className='table-title'><span>Current Plan End Date</span></th>
            <th className='table-title'><span>Forecasted Usage</span></th>
            <th className='table-title'><span>Estimated Remaining Availability</span></th>
            <th className='table-title'><span>Estimated Eligible through</span></th>
          </tr>
        </thead>
      )
    }
  }

  renderBenefitTable (benefit, benefitData) {
    const benefitCount = benefitData.length
    const benefitCountText = benefitCount + ' benefit'.pluralize(benefitCount, 'noun')
    const paidBenefit = benefit == 'Paid'
    return (
      <div className="dashboard-benefit-tabular-container">
        <div className='dashboard-paid-benefit-count-row'>
          <div className='dashboard-non-additional-count'>
            <div className={`${benefit.parameterize()}-benefit-icon`}></div>
            <span className='count-label'>{`${benefit} Leave`}:</span>
            <span className='count-number'>{benefitCountText}</span>
          </div>
          { paidBenefit ? this.renderAdditionalLeave() : null }
        </div>
        <div className='benefit-table-container'>
          <table className='employee-benefit-table'>
            { paidBenefit ? this.renderBenefitTableHeader(benefitCount) : null }
            { paidBenefit ? <tbody><tr><td><hr /></td></tr></tbody> : null }
            <tbody className='employee-benefit-table-body'>
              { benefitData.map((benefit, index) => {
                return this.renderBenefitrow(benefit, index)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderBenefitTable('Paid', this.props.paidTableData)}
        <br />
        {this.renderBenefitTable('Job Protected', this.props.jobProtectedTableData)}
      </div>
    )
  }
}

export default DashboardBenefitsTableView 