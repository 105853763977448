import React from 'react'
import MilestoneDetail from './MilestoneDetail'
import EditMilestone from './EditMilestone'

class DashboardMilestones extends React.Component {
  constructor () {
    super()

    this.state = {
      detail: {date: '', milestones: []},
      editMilestone: {},
      showDetail: false,
      showEditModal: false
    }
  }

  showDetail (e) {
    const target = e.target
    const circle = $(target).closest('g.initial-milestone-div').find('circle')
    const circleLeftPosition = circle[0].getBoundingClientRect().left
    const windowH = $(window).width()
    const targetPosition = target.getBoundingClientRect()
    // for milestone-edit-icon class there is no data attribute
    const stringifyJSONData = $(target).attr('data-detail') || $(target).siblings('.milestone-name').attr('data-detail')
    const detail = JSON.parse(stringifyJSONData)

    let widthForSidebar = 285
    const leftPaddingOfPageContainer = 15
    let leftPaddingOfDetailDiv = 5
    let heightOfTopBar = 95
    let topPaddingOfPageContainer = 20
    let windowViewPort = 992

    if (this.props.isAdmin) {
      widthForSidebar = 200
      leftPaddingOfDetailDiv = 0
      heightOfTopBar = 50
      topPaddingOfPageContainer = 130
      windowViewPort = 752
    }

    // y-coordinate of milestone + window scrolled to top - height for top bar - top padding of page container - default top padding of a div - top padding of detail div
    let top = targetPosition.top + $(window).scrollTop() - heightOfTopBar - topPaddingOfPageContainer - 3 - 5
    let left = 0
    let circleLeft = 0

    if (windowH > windowViewPort) {
      // x-coordinate of milestone + window scrolled to left - width for sidebar - left padding of page container - left padding of detail div
      left = targetPosition.left + $(window).scrollLeft() - widthForSidebar - leftPaddingOfPageContainer - leftPaddingOfDetailDiv
      circleLeft = circleLeftPosition - widthForSidebar - leftPaddingOfPageContainer - left
    } else {
      // x-coordinate of milestone + window scrolled to left - left padding of page container - left padding of detail div
      left = targetPosition.left + $(window).scrollLeft() - 15
      circleLeft = circleLeftPosition - left - 15
      if (this.props.isAdmin) {
        top = top - 7
      }else{
        top = top + 5
      }
    }

    const detailDiv = document.getElementById('milestone-detail')
    let detailCircle = $(detailDiv).find('.circle')
    let style = document.getElementById('milestone-detail').style
    style.left = left + 'px'
    detailCircle[0].style.left = circleLeft + 'px'
    style.top = top + 'px'
    this.setState({detail: detail, showDetail: true})
  }

  hideDetail () {
    this.setState({showDetail: false})
  }

  milestoneClass () {
    return this.state.showDetail ? '' : 'hide'
  }

  handleMilestoneEdit (milestone) {
    this.setState({
      showEditModal: true,
      editMilestone: {
        id: milestone.calendar_event_id,
        name: milestone.name,
        date: milestone.date,
        decision_support_text: milestone.decision_support_text
      }
    })
  }

  handleEditBoxClose () {
    this.setState({showEditModal: false})
  }

  displayMilestones (props) {
    const data = props.milestones.map((groupedMilestones) => {
      let name = ''
      if (groupedMilestones.length > 1) {
        name = `${groupedMilestones.length} Milestones`
      } else {
        name = groupedMilestones[0].name
      }
      const date = groupedMilestones[0].date
      return {
        date: new Date(moment(date).startOf('day').add(12, 'hours')),
        name: name,
        milestones: groupedMilestones
      }
    })
    const formatDate = d3.time.format(gon.default_date_format)
    const options = {
      margin: {left: 0, right: 20, top: 0, bottom: 20},
      initialWidth: $(document).find('#timeline-labella').width(),
      initialHeight: 220
    }
    const innerWidth = options.initialWidth
    // const innerHeight = options.initialHeight - options.margin.top - options.margin.bottom
    // const colorScale = d3.scale.category10()
    let vis =
      d3.select('#timeline-labella')
        .append('svg').attr('width',  options.initialWidth)
                      .attr('height', options.initialHeight)
        .append('g').attr('transform', 'translate('+(options.margin.left)+','+(options.margin.top)+')')
    const labelText = (d) => d.name
    // compute labels dimension
    let dummyText = vis.append('text')
    const timeScale = d3.time.scale()
      .domain([new Date(moment(props.first_date).startOf('day')), new Date(moment(props.last_date).endOf('day'))])
      .range([0, innerWidth])
    const nodes = data.map((milestone) => {
      const bbox = dummyText.text(labelText(milestone))[0][0].getBBox()
      milestone.h = 2 * bbox.height
      milestone.w = bbox.width
      return (new labella.Node(timeScale(milestone.date), milestone.w + 9, milestone))
    })
    dummyText.remove()
    vis.append('line')
      .classed('timeline', true)
      .attr('x2', innerWidth)
    const linkLayer = vis.append('g')
    const labelLayer = vis.append('g')
    const renderer = new labella.Renderer
      layerGap: 60
      nodeHeight: nodes[0].data.h
      direction: 'bottom'
    const draw = (nodes) => {
      // Add x,y,dx,dy to node
      renderer.layout(nodes)
      // Draw label rectangles
      let sEnter = labelLayer.selectAll('rect.flag')
        .data(nodes)
      .enter().append('g')
        .classed('initial-milestone-div', true)
        .attr('transform', (d) => `translate(${(d.x-d.width/2)},${(d.y)})`)
      sEnter.append('circle')
        .attr('cy', 2)
        .attr('cx', (d) => d.width/2)
        .attr('r', 3)
        .style('fill', '#B3B3B3')
        .style('stroke', '#B3B3B3')
        .style('stroke-width', 1)
      let textDiv = sEnter.append('text')
        .attr('x', 10)
        .attr('y', 20)
      textDiv.append('tspan')
        .attr('x', 10)
        .attr('y', 20)
        .classed('milestone-name', true)
        .attr('data-detail', (d) => {
          return (
            JSON.stringify(
              {
                name: d.data.name,
                date: moment(d.data.date).format('YYYY-MM-DD'),
                milestones: d.data.milestones
              }
            )
          )
        })
        .text((d) => labelText(d.data))
      textDiv.append('tspan')
        .attr('x', 10)
        .attr('y', 38)
        .classed('milestone-date', true)
        .text((d) => formatDate(d.data.date))
      textDiv.append('tspan')
        .attr('x', 80)
        .attr('y', 40)
        .text((d) => {
          if (d.data.milestones.length > 1) {
            return ""
          } else if (d.data.milestones.length == 1) {
            return d.data.milestones.map((milestone) => {
              if (milestone.editable) {
                return ""
              } else {
                return ''
              }
            })
          } else {
            return ''
          }
        })
        .attr('class', 'fa milestone-edit-icon')
      // Draw path from point on the timeline to the label rectangle
      linkLayer.selectAll('path.link')
        .data(nodes)
      .enter().append('path')
        .classed('link', true)
        .attr('d', (d) => renderer.generatePath(d))
        .style('stroke', '#888')
        .style('stroke-width', 1)
        .style('opacity', 1)
        .style('fill', 'none')
      const svgHeight = d3.max(nodes, (d) => d.y + d.data.h) + options.margin.top + options.margin.bottom
      $('svg').attr('height', svgHeight)
    }


    // ---------------------------------------------------
    //  Use labella.Force to place the labels
    // ---------------------------------------------------

    const force = new labella.Force({
      minPos: -10,
      maxPos: innerWidth
    })
      .nodes(nodes)
      .compute()

    draw(force.nodes())
  }

  componentDidMount () {
    this.props.isAdmin && this.addTabChanger()
    this.reloadMilestones()
    $(() => this.reloadMilestones())
    $(window).on('resize', () => {
      this.reloadMilestones()
      $.resizeFooter()
    })
  }

  componentWillUnmount() {
    $(window).unbind('resize')
    $(window).on('resize', () => {
      $.resizeFooter()
    })
  }

  addTabChanger () {
    const onLeaveTab = document.getElementById('leave-plan-tab') || document.getElementById('personal-info-tab')
    onLeaveTab.addEventListener('click', () => {
      $('#timeline-labella').html('')
      setTimeout( ( () => this.reloadMilestones()), 5)
    })
  }

  reloadMilestones () {
    $('#timeline-labella').html('')
    if (this.props.milestones.length > 0) {
      this.displayMilestones(this.props)
      this.bindMouseOverEvent()
    }
  }

  bindMouseOverEvent () {
    const names = $('.initial-milestone-div .milestone-name, .milestone-edit-icon')
    names.map((index, name) => name.addEventListener('mouseover', this.showDetail.bind(this)))
  }

  renderEditMilestone () {
    return this.state.showEditModal ? (
      <EditMilestone
        milestone={this.state.editMilestone}
        handleEditBoxClose={this.handleEditBoxClose.bind(this)}
        changeData={this.props.changeData}
        first_date={this.props.first_date}
        isAdmin={this.props.isAdmin}
        milestone_dates_hash={this.props.milestone_dates_hash}
        week_first_date={this.props.week_first_date}
        month_first_date={this.props.month_first_date}
        year_first_date={this.props.year_first_date}
        timeOffTypes={this.props.timeOffTypes}
      />
    ) : null
  }

  componentDidUpdate() {
    this.reloadMilestones()
  }

  render () {
    return (
      <div id='milestones-div'>
        <div className='timeline-labella-div'>
          <div id='timeline-labella' style={{width: this.props.width}} />
        </div>
        <div>
          <MilestoneDetail
            detail={this.state.detail}
            hideDetail={this.hideDetail.bind(this)}
            milestone_display={this.milestoneClass()}
            handleMilestoneEdit={this.handleMilestoneEdit.bind(this)}
            isAdmin={this.props.isAdmin}
            isLeavePlanPartner={this.props.isLeavePlanPartner}
          />
          {
            this.renderEditMilestone()
          }
        </div>
      </div>
    )
  }
}

export default DashboardMilestones;
