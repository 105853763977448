import React from 'react'

class DashboardBenefitsPeriod extends React.Component {
  renderFirstSeparator () {
    if (this.props.index === 0) {
      return (
        <div className='period-separator first' />
      )
    }
  }

  renderSeparator() {
    if (this.props.onlyLast) {
      if (this.props.lastIndex === this.props.index) {
        return <div className='period-separator' />
      }
    } else {
      return <div className='period-separator' />
    }
  }

  render () {
    return (
      <div className='dashboard-period-benefits' style={{width: this.props.periodSpan.width}}>
        { this.renderFirstSeparator() }
        { this.renderSeparator() }
      </div>
    )
  }
}

export default DashboardBenefitsPeriod
