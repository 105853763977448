import React from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'

class BundleVendorForm extends React.Component {
  constructor() {
    super()

    this.state = {
      description: '',
      bundleCategoryId: '',
      vendorId: '',
      name: '',
      disableButton: false,
      shortCode: ''
    }
  }

  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  getValidationState() {
    this.setState({ disableButton: true })

    if(!(/^[a-z0-9-]*$/.test(this.state.shortCode))) {
      toastr.error("Short code includes lowercase, number and special character '-'")
      this.setState({ disableButton: false })
    } else {
      this.submitBundleVendor()
    }
  }

  submitBundleVendor() {
    const { bundleCategoryId, vendorId, description, name, shortCode } = this.state
    let params = {
      bundle_category_id: bundleCategoryId,
      vendor_id: vendorId,
      description: description,
      name: name,
      short_code: shortCode
    }
    if (this.props.isIpa) {
      params['implementation_partner_template_attributes[id]'] = '',
      params['implementation_partner_template_attributes[implementation_partner_id]'] = ''
    }
    let method = 'POST'
    let url = '/admin/bundle_category_vendors'

    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        this.props.onSubmit(response.data)
        toastr.success(response.message)
      },
      error: (response) => {
        this.setState({ disableButton: false })
        toastr.error($.parseJSON(response.responseText).message)
      }
    })
  }

  render() {
    const { description, disableButton, name, bundleCategoryId, vendorId, shortCode } = this.state
    const { onModalClose, bundleCategories, vendors } = this.props
    return (
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ onModalClose }
            >×</button>
            <h4 className="modal-title">Bundle Category Vendor</h4>
          </div>
          <div className="modal-body">
            { this.props.isIpa && <div className="form-group">
              <div>
                <input
                  type="hidden"
                  className="form-control"
                  name="implementation_partner_template_attributes[id]"
                  value=""
                />
                <input
                  type="hidden"
                  className="form-control"
                  name="implementation_partner_template_attributes[implementation_partner_id]"
                  value=""
                />
              </div>
            </div> }
            <div className="form-group">
              <label>Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={ name }
                  onChange={ (e) => this.handleChange('name', e.target.value) }
                />
              </div>
            </div>
            <div className="form-group">
              <label>Short Code</label>
              <p><em>Include lowercase, number and special character '-' only</em></p>
              <input
                type="text"
                className="form-control"
                name="short_code"
                value={ shortCode }
                onChange={ (e) => this.handleChange('shortCode', e.target.value) }
              />
            </div>
            <div className="form-group">
              <label>Bundle Category</label>
              <div>
                <Selectpicker
                  className="select-picker form-control"
                  name="bundleCategoryId"
                  value={ bundleCategoryId }
                  optionsForSelect={ bundleCategories.map((options) => [options.name, options.id]) }
                  onChange={ (value) => { this.handleChange('bundleCategoryId', value) } }
                />
              </div>
            </div>

            <div className="form-group">
              <label>Vendor</label>
              <div>
                <Selectpicker
                  className="select-picker form-control"
                  name="vendorId"
                  value={ vendorId }
                  optionsForSelect={ vendors.map((options) => [options.name, options.id]) }
                  onChange={ (value) => { this.handleChange('vendorId', value) } }
                />
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                className="form-control"
                name="description"
                value={ description }
                onChange={ (e) => this.handleChange('description', e.target.value) }
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button
                className="btn btn-primary"
                onClick={ () => this.getValidationState() }
                disabled={ disableButton }>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BundleVendorForm
