import React, { useEffect, useState } from 'react';
import Editor from '../../shared/Editor/'

const ALLOWED_FILE_EXTENSIONS = ["css", "sass"];
function CustomCss(props) {
  let [fileDetails, setFileDetails] = useState({
    existingFileName: props.filename,
    fileUrl: props.file_url,
  })
  let [disableButton, setDisableButton] = useState(true);
  let [content, setContent] = useState(null)

  useEffect(() => {
    initializeAjaxCompleteHandler();
    getCssFileContent(fileDetails.fileUrl);
  },[]);

  function getCssFileContent(filePath) {
    if (!filePath) return;
    fetch(filePath)
      .then(response => response.text())
      .then(data =>{
        setContent(data)
      })
  }

  function initializeAjaxCompleteHandler() {
    $("#uploadEmployeeRecordModal form").on(
      "ajax:complete",
      (_xhr, res) => {
        const response = JSON.parse(res.responseText);
        if (response.success == "success") {
          toastr.success(response.message);
          setFileDetails({
            existingFileName: response.filename,
            fileUrl: response.file_url
          })
          getCssFileContent(response.file_url)
        } else {
          toastr.error(response.message);
        }
      }
    );
  }

  const validateFile = (e) => {
    let targetValues = e.target.value.split(".");
    if (ALLOWED_FILE_EXTENSIONS.indexOf(targetValues[targetValues.length - 1]) >= 0) {
      setDisableButton(false)
    } else {
      toastr.error("File Format not supported");
    }
  }

  const existingFile = () => {
    let content =
    fileDetails.existingFileName != null ?
        <div className="row">
          <div className="col-xs-2">
            <label>Existing Css:</label>
          </div>
        <div className="col-xs-6">
          <label>{ fileDetails.existingFileName } ( Note: Renamed to Company's Short Name. )
          </label>
        </div>
      </div> : null
    return ( content )
  }

  const showCssContent = () => {
    if (content == null){
      return ''
    }
    return (<Editor
      editorMode='text/css'
      content={content || ''}
      readOnly={true}
      height='450px'
    />)
  }
  return (
    <div className="custom-css" id="uploadEmployeeRecordModal">
      <form
        encType="multipart/form-data"
        action={`/admin/companies/${props.companyId}/company_custom_csses` }
        data-remote="true"
        method="post"
      >
        <div className="form-group">
          { existingFile() }

          <div className="row">
            <div className="col-xs-2">
            <label>Uploaded Custom Css.</label>
            </div>
            <div className="col-xs-6">
                <input
              type="file"
              name="file"
              id="file"
              onChange={validateFile}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-2">
              <input
                type="submit"
                name="commit"
                value="Upload"
                className="btn btn-success green pull-left"
                disabled={disableButton}
              />
            </div>
          </div>
        </div>
      </form>
      <div>
        { showCssContent() }
      </div>
    </div>
  );
}

CustomCss.propTypes = {
};

export default CustomCss;
