import React from 'react'
import DashboardBenefitRow from './DashboardBenefitRow'
import DashboardBenefitsPeriod from './DashboardBenefitsPeriod'
import HelperTooltip from '../helper_tooltip' 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
class DashboardJobProtectedBenefits extends React.Component {
  renderBenefitRow (rowBenefits, index) {
    return (
      <DashboardBenefitRow
        key={index}
        rowBenefits={rowBenefits}
        onHelpfulCheck={this.props.onHelpfulCheck}
        handleJustLookingClick={this.props.handleJustLookingClick}
        isAdmin={this.props.isAdmin}
        isLeavePlanPartner={this.props.isLeavePlanPartner}
        toggleDetailView={this.props.toggleDetailView}
        showExtendableBenefit={this.props.showExtendableBenefit}
        customColors= {this.props.customColors}
      />
    )
  }

  renderPeriodSpan (periodSpan, index) {
    return (
      <DashboardBenefitsPeriod
        key={periodSpan.name}
        periodSpan={periodSpan}
        index={index}
      />
    )
  }

  renderHelperTooltip () {
    if (this.props.job_protected_helper_text) {
      return <HelperTooltip helper_text={this.props.job_protected_helper_text} />
    } else {
      return null
    }
  }

  render () {
    const jobProtectedLeaveHeader = gon.company_variables.job_protected_leave || 'Job Protected Leave'
    return (
      <div className='dashboard-benefit-container'>
        <div className='dashboard-job-protected-benefit-count-row'>
          <div className='dashboard-non-additional-count'>
            <div className='job-protected-benefit-icon' />
            <span className='count-label'>
              {`${jobProtectedLeaveHeader}: `}
            </span>
            <span className='count-number'>
              {`${this.props.benefit_counts.job_protected_benefit} ${'benefit'.pluralize(this.props.benefit_counts.job_protected_benefit, 'noun')}`}
            </span>
            {this.renderHelperTooltip()}
          </div>
        </div>

        <div className='dashboard-benefit-table-container'>
          <table className='dashboard-job-protected-benefits-div'>
            <thead />
            <tbody>
              <tr>
                <td>
                  <div className='dashboard-job-protected-benefits'>
                    {
                      this.props.benefit_items.map((rowBenefits, index) => this.renderBenefitRow(rowBenefits, index))
                    }
                    <div className='period-container'>
                      {
                        this.props.periodSpans.map((periodSpan, index) => this.renderPeriodSpan(periodSpan, index))
                      }
                    </div>
                  </div>
                  <div className='base-line' />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DashboardJobProtectedBenefits