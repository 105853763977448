import React from 'react'
import HelpfulnessCheck from '../../leave_plan_benefits/shared/HelpfulnessCheck'
import ShowFileLists from './ShowFileLists'
import AssociatedTask from '../../leave_plan_benefits/shared/AssociatedTask'
import Selectpicker from '../../../shared/inputs/selectpicker'

class ScheduleModalBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.name || props.item.name,
      description: props.description,
      image_url: props.image_url,
      timelineItems: props.timelineItems || [],
      itemFiles: props.itemFiles || [],
      showBackBtn: false,
      timelineItemId: props.item.timeline_id,
      showHelpfulnessCheck: true,
      selectedOption: {},
      contents: props.data,
      selectedBenefit: {}
    }
  }

  componentDidMount() {
    this.mountComponentIframe()
    if (this.state.contents) {
      this.setState({ selectedOption: Object.keys(this.props.data)[0] })
    }
    if (this.props.selectedBenefit) {
      this.setState({
        selectedBenefit: this.props.selectedBenefit.id,
      })
    }
  }

  componentDidUpdate() {
    this.mountComponentIframe()
  }

  mountComponentIframe() {
    this.buildIframe();
    this.resizeModalbox();
    $(window).on('resize', this.resizableModal);
    setTimeout(resizeIframe, 200);
  }

  handleCheck() {
    const { timeline_id } = this.props.item;
    const check = !this.props.item.check_status;
    return $.post("/timeline_items/save_checkbox", { timeline_id, checked: check }, data => {
      return this.props.onTimelineCheck(data);
    });
  }

  itemImage() {
    const { image_url } = this.state
    return image_url ? { backgroundImage: `url(${image_url})` } : { backgroundImage: '' }
  }

  checkCompletion() {
    if (moment(this.props.item.date).startOf('day') < moment().startOf('day')) {
      if (this.props.item.check_status) {
        return 'completed';
      } else {
        return 'uncompleted';
      }
    } else {
      return 'upcoming';
    }
  }

  backdrop() {
    return <div className='modal-backdrop in'></div>
  }

  handleExhaustionBtnOnClick(e) {
    if (this.props.isAdmin) {
      $('[data-href="#std-work-sheet-tab"]').trigger('click');
      return e.preventDefault();
    }
  }

  pendingBenefitText() {
    if (this.props.pending) {
      return (gon.company_variables.pending_benefit_instructions || '');
    } else {
      return '';
    }
  }

  handleDownloadFile(itemFileId) {
    const { timelineItemId } = this.state
    if (timelineItemId) {
      const href = `<a href='/timeline_items/${timelineItemId}/download_file?item_file_id=${itemFileId}' />`
      const url = $.parseHTML(href)[0]
      url.trigger('click')
    }
  }

  handleTaskClick(timelineItemId) {
    $.getJSON(`/timeline_items/${timelineItemId}/fetch_details`, data => {
      gon.company_variables = {...gon.company_variables, ...data.statutory_substitutions_options}
      this.setState({
        name: data.name,
        description: data.description,
        image_url: data.image_url,
        itemFiles: data.item_files || [],
        timelineItems: data.timeline_items || [],
        showBackBtn: true,
        timelineItemId: timelineItemId,
        showHelpfulnessCheck: false,
      });
    })
  }

  handleBackBtn() {
    this.fetchData(this.props.item.id);
  }

  fetchData(itemId) {
    $.getJSON(`/leave_plan_benefits/${itemId}/fetch_details`, (data) => {
      gon.company_variables = { ...gon.company_variables, ...data.statutory_substitutions_options }
      this.setState({
        name: data.name,
        description: data.description,
        image_url: data.image_url,
        itemFiles: data.item_files || [],
        timelineItems: data.timeline_items || [],
        showBackBtn: false,
        timelineItemId: null,
        showHelpfulnessCheck: true,
      })
    })
  }

  handleTimelineItemCheck(data) {
    let { timelineItems } = this.state
    let timelineItem = timelineItems.filter(item => item.timeline_id === data.data.timeline_id)[0]
    timelineItem.check_status = data.data.check_status
    this.setState(timelineItems)
  }

  iframeHead() {
    const head = document.createElement('head')
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    let headHtml = '';
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/skins/content/default/content.min.css>`;
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/plugins/a11ychecker/css/annotations.css>`;
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/plugins/mediaembed/content.min.css>`;
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/plugins/linkchecker/content.min.css>`;
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/custom_tinymce.css>`;
    headHtml += `<link type="text/css" rel="stylesheet" href=${baseUrl}/assets/graphik.css>`;
    head.innerHTML = headHtml
    return head
  }

  iframeBody() {
    const body = document.createElement('body')
    let content = ''
    const preventClicksOnLinksScript = `<script>document.addEventListener && document.addEventListener('click', function(e) {for (var elm = e.target; elm; elm = elm.parentNode) {if (elm.nodeName === 'A') {e.preventDefault(); window.open(elm.href);}}}, false);</script> `;
    const collapseableScript = `<script>
      function toggleCollapsableDiv(hideNode) {
        var div = hideNode.parentElement.parentElement.querySelector(".tinymce-template-collapsing-div");
        if (div.style.display == "none") {
          div.style.display = "";
          hideNode.textContent = "Hide";
        } else {
          div.style.display = "none";
          hideNode.textContent = "Show";
        }
      };
      if (document.addEventListener) {
        var selectors = document.querySelectorAll(".tinymce-template-collapsing-button");
        for (var i=0; i < selectors.length; i++) {
          toggleCollapsableDiv(selectors[i]);
          selectors[i].addEventListener("click", function(e) {
            var element = e.target;
            toggleCollapsableDiv(element)
          });
        }
      }
    </script> `
    body.setAttribute('id', "tinymce")
    if (this.props.item.type === 'exhaustion') {
      content = this.getDescription()
      body.setAttribute('style', "font-family: Open Sans, Helvetica, Arial, sans-serif;")
    } else {
      content = this.state.description
    }
    body.setAttribute('class', "mce-content-body description-preview")
    body.innerHTML = this.pendingBenefitText() + _getDescription(content) + preventClicksOnLinksScript + collapseableScript
    return body
  }

  iframeDocument() {
    const dom = document.createElement('html');
    dom.appendChild(this.iframeHead())
    dom.appendChild(this.iframeBody())
    return dom.outerHTML
  }

  buildIframe() {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("class", "iframe-modal benefit-detail");
    iframe.setAttribute("frameborder", "0");
    const iframe_description = $(".item-detail-description-modal")[0];
    iframe_description.innerHTML = "";
    iframe_description.appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(this.iframeDocument());
    iframe.contentWindow.document.close();
  }

  renderTimelineCheckbox() {
    if (this.props.type === 'timeline' && !this.props.isLeavePlanPartner && this.props.isAddedToLeavePlan) {
      return (
        <td
          className='rounded-checkbox'>
          <input
            className='timeline-check'
            id={`modal-${this.props.item.id}`}
            type='checkbox'
            defaultChecked={this.props.item.check_status}
          />
          <label
            htmlFor={`modal-${this.props.item.id}`}
            onClick={this.handleCheck.bind(this)}
          />
        </td>
      )
    }
  }

  renderTimeOffLink() {
    if ((this.props.time_off_enabled && !this.props.isAdmin) || (!this.props.time_off_enabled && !this.props.isAdmin && this.props.item.type == 'exhaustion')) {
      return (
        <a className='btn btn-primary' href="/employees/std_time_off_balances">
          Update Hours Usage
        </a>
      )
    }
  }

  renderTaskBenefitAssociation() {
    if (!jQuery.isEmptyObject(this.props.association)) {
      return (
        <div className='association'>
          <span>
            'This Task is associated with '
          </span>
          <a href={this.props.association.link}>
            {this.props.association.name}
          </a>
        </div>
      )
    }
  }

  renderExhaustionButton() {
    if ((this.props.time_off_enabled && this.props.isAdmin) || (!this.props.time_off_enabled && this.props.isAdmin && this.props.item.type == 'exhaustion')) {
      return (
        <a
          onClick={(e) => this.handleExhaustionBtnOnClick(e)}
          className='btn btn-primary'
        >
          Update Hours Usage
        </a>
      )
    }
  }

  renderHelpfulnessCheck() {
    if (this.state.showHelpfulnessCheck && !this.props.isAdmin && !this.props.isLeavePlanPartner) {
      return (
        <HelpfulnessCheck
          item={this.props.item}
          item_type={this.props.item_type}
          onHelpfulCheck={this.props.onHelpfulCheck}
        />
      );
    }
  }

  renderItemFiles() {
    const { itemFiles } = this.state
    if (itemFiles.length > 0) {
      return (
        <ShowFileLists
          itemFiles={itemFiles}
          downloadItemFile={this.handleDownloadFile.bind(this)}
          modalBox={true}
        />
      )
    }
  }

  renderAssociatedTasks() {
    const { timelineItems } = this.state
    return timelineItems.length > 0 && (
      <div className='associated-tasks'>
        <div className='timeline-items'>
          {timelineItems.map((task) => (
            <AssociatedTask
              key={task.id}
              data={task}
              display_modal={{}}
              dashboardBenefit={true}
              handleTaskClick={this.handleTaskClick.bind(this)}
              isAddedToLeavePlan={task.added_to_leave_plan}
              handleCheck={this.handleTimelineItemCheck.bind(this)}
              isAdmin={this.props.isAdmin}
            />
          ))}
        </div>
      </div>
    )
  }

  renderBackBtn() {
    if (this.state.showBackBtn) {
      return <div className='back-button' onClick={() => this.handleBackBtn()}>
        {'<< Back'}
      </div>
    }
  }

  changeSelectedOption(name, value) {
    this.setState({
      [name]: value
    })
    this.props.item.type === 'time_off_type_benefit' && this.fetchData(value);
  }

  getDescription() {
    const { contents, selectedOption } = this.state
    return contents[selectedOption] || ''
  }

  renderExhaustionDropDown() {
    let optionsForSelect = [];
    delete this.props.data["isAdmin"];
    optionsForSelect = Object.keys(this.state.contents).map((option) => [
      option,
      option,
    ]);
    return this.selectPicker('selectedOption', this.state.selectedOption, optionsForSelect);
  }

  renderBenefitDropdown() {
    let optionsForSelect = [];
    optionsForSelect =
      this.props.item.records &&
      this.props.item.records.map((option) => [option.name, option.id]);
    return this.selectPicker('selectedBenefit', this.state.selectedBenefit, optionsForSelect);
  }

  selectPicker(name, defaultValue, optionsForSelect) {
    return (
      <div
        className="customize-contents-sub-var-wrapper"
        style={{ width: "20%" }}
      >
        <Selectpicker
          name={name}
          optionsForSelect={optionsForSelect}
          onChange={(value) => this.changeSelectedOption(name, value)}
          liveSearch={false}
          defaultValue={defaultValue}
        />
      </div>
    );
  }

  renderDropdown() {
    if (this.props.item.type === "exhaustion") {
      return this.renderExhaustionDropDown()
    } else if (this.props.item.type === "time_off_type_benefit") {
      return this.renderBenefitDropdown()
    }
  }

  modal() {
    return (
      <div
        className='modal in'
        id='resizable-modal-box'
        tabIndex='-1'
        role='dialog'
        aria-hidden='false'
        ref='modal'
        style={{ display: 'block' }}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              {this.renderBackBtn()}
              <div
                className='close-button'
                onClick={this.props.handleItemClose}
              >
                <span>Close</span>
                <span className='fa fa-times' />
              </div>
              <div className='item-header-container'>
                <div className='item-header'>
                  <table className='header-text'>
                    <thead />
                    <tbody>
                      <tr>
                        {
                          this.renderTimelineCheckbox()
                        }
                        <td>
                          <div className="name">
                            {this.state.name}
                          </div>
                          {this.renderTimeOffLink()}
                          {this.renderTaskBenefitAssociation()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{ display: 'flex', justifyContent: "space-between", marginTop: '10px' }}>
                    {this.renderDropdown()}
                    {this.renderExhaustionButton()}
                  </div>
                </div>
                <div className='image-div' style={this.itemImage()} />
              </div>
            </div>
            <div className='modal-body'>
              <div className="item-detail-description-modal"></div>
              {this.renderAssociatedTasks()}
              {this.renderItemFiles()}
            </div>
            <div className='modal-footer' id='leave-plan-benefit-modal-footer'>
              {this.renderHelpfulnessCheck()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  resizeModalbox() {
    const modalWidth = $("#resizable-modal-box .modal-content").width();
    const modalHeight = $("#resizable-modal-box .modal-content").height() + 15;

    $("#resizable-modal-box .modal-content").resizable({
      handles: "ne, nw, se, sw",
      alsoResize: '.item-detail-description-modal',
      minWidth: modalWidth,
      minHeight: modalHeight,
      maxWidth: window.innerWidth - 50,
      maxHeight: window.innerHeight - 50
    });

    $("#resizable-modal-box")
      .find("div.ui-resizable-ne")
      .removeClass('ui-resizable-ne')
      .addClass("ui-icon ui-icon-triangle-1-ne");
    $("#resizable-modal-box")
      .find("div.ui-resizable-nw")
      .removeClass("ui-resizable-nw")
      .addClass("ui-icon ui-icon-triangle-1-nw");
    $("#resizable-modal-box")
      .find("div.ui-resizable-se")
      .removeClass("ui-resizable-se ui-icon-gripsmall-diagonal-se")
      .addClass("ui-icon ui-icon-triangle-1-se");
    $("#resizable-modal-box")
      .find("div.ui-resizable-sw")
      .removeClass('ui-resizable-sw')
      .addClass("ui-icon ui-icon-triangle-1-sw");

    $("#resizable-modal-box .modal-dialog").draggable({
      handle: ".modal-header",
    });
  }

  resizableModal() {
    const resizingModal = $(".modal-content.ui-resizable.ui-resizable-resizing");
    const uiHandleHeight = $('#resizable-modal-box .ui-resizable-handle.ui-icon').height();
    const modalFooterPaddingTop = parseInt($('#resizable-modal-box .modal-footer').css('padding-top'));
    const modalFooterPaddingBottom = parseInt($('#resizable-modal-box .modal-footer').css('padding-bottom'));
    const modalFooterHeight = $('#resizable-modal-box .modal-footer').height() + modalFooterPaddingTop + modalFooterPaddingBottom;
    const modalBodyPaddingTop = parseInt($('#resizable-modal-box .modal-body .item-detail-description-modal').css('padding-top'));
    const modalBodyPaddingBottom = parseInt($('#resizable-modal-box .modal-body .item-detail-description-modal').css('padding-bottom'));
    const modalBodyHeight = modalBodyPaddingTop + modalBodyPaddingBottom;
    const modalHeaderPaddingTop = parseInt($('#resizable-modal-box .modal-header').css('padding-top'));
    const modalHeaderPaddingBottom = parseInt($('#resizable-modal-box .modal-header').css('padding-bottom'));
    const modalHeaderHeight = $('#resizable-modal-box .modal-header').height() + modalHeaderPaddingTop + modalHeaderPaddingBottom;
    const fitToModalHeight = uiHandleHeight + modalFooterHeight + modalBodyHeight + modalHeaderHeight;
    $('#resizable-modal-box .modal-body').css('max-height', Math.abs(resizingModal.height() - fitToModalHeight));
  }

  render() {
    return (
      <div>
        {this.backdrop()}
        {this.modal()}
      </div>
    );
  }
}

export default ScheduleModalBox
