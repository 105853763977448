import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import Selectpicker from '../../shared/inputs/selectpicker'

const ALLOWED_FILE_EXTENSIONS = ["csv", "xls", "xlsx"];

function FileUploadModal (props) {
  let [disableButton, setDisableButton] = useState(true);
  const [fileUploadType, setFileUploadType] = useState('');
  const [fileSelectionButton, setFileSelectionButton] = useState(true);
  const [showUploadTypeSelect, setShowUploadTypeSelect] = useState(false);

  useEffect(() => {
    initializeBeforeAjaxHandler();
    initializeAjaxCompleteHandler();
    initializeFileUploadModal();
  },[]);

  let validateFile = (e) => {
    let targetValues = e.target.value.split(".");
    if (ALLOWED_FILE_EXTENSIONS.indexOf(targetValues[targetValues.length - 1]) >= 0) {
      setDisableButton(false)
    } else {
      toastr.error("File Format not supported");
    }
  }

  function handleUploadTypeChange(value) {
    if (value != "") {
      setFileUploadType(value);
      setFileSelectionButton(false)
    } else {
      toastr.error("Select a valid file type to upload");
    }
  }

  function initializeFileUploadModal()  {
    const isCompanyImport = props.action === '/admin/company_imports'
    setFileSelectionButton(isCompanyImport)
    setShowUploadTypeSelect(isCompanyImport)
  }

  function initializeBeforeAjaxHandler() {
    $("#uploadEmployeeRecordModal.modal form").on("ajax:beforeSend", (_e) => {
      setDisableButton(true);
    });
  }

  function initializeAjaxCompleteHandler() {
    $("#uploadEmployeeRecordModal.modal form").on(
      "ajax:complete",
      (_xhr, res) => {
        const response = JSON.parse(res.responseText);
        if (response.status == "success") {
          toastr.success(response.message);
          props.onFormSubmit(response.records);
        } else {
          toastr.error(response.error_message || "Unable to save the file.")
          props.hideModal();
        }
      }
    );
  }

  return (
    <div
      className="modal fade in"
      id="uploadEmployeeRecordModal"
      role="dialog"
      aria-hidden="false"
      style={{ display: "block" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => props.hideModal()}
            >
              ×
            </button>
            <h4 className="modal-title">Upload the Records</h4>
          </div>
          <form
            encType="multipart/form-data"
            action={props.action}
            data-remote="true"
            method="post"
          >
          <div className="modal-body">
            <div className="form-group">
              <label>File Upload Type</label>
              <div>
              { showUploadTypeSelect && <Selectpicker
                  className="select-picker form-control"
                  name="fileUploadType"
                  optionsForSelect = {props.uploadTypeOptions}
                  required={true}
                  onChange={(value) => handleUploadTypeChange(value)}
                /> }
              </div>
            </div>
            { props.headerIncluded ? (
              <div className="csv-header-checkbox">
                <input
                  type="checkbox"
                  name="headers"
                  id="headers"
                  value="true"
                />
                <label htmlFor="headers">Header Included in File</label>
              </div>) : <label>Uploaded File must contain Header.</label>
            }
            <input
              type="file"
              name="file"
              id="file"
              onChange={validateFile}
              disabled={fileSelectionButton}
            />
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-6">
                  <input
                    type="submit"
                    name="commit"
                    value="Continue"
                    className="btn btn-success green pull-left"
                    disabled={disableButton}
                  />
                </div>
                <div className="col-xs-6">
                  <div
                    className="btn btn-danger pink"
                    data-dismiss="modal"
                    onClick={() => props.hideModal()}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

FileUploadModal.propTypes = {
  hideModal: PropTypes.func,
  onFormSubmit: PropTypes.func,
  action: PropTypes.string,
  uploadType: PropTypes.string,
  headerIncluded: PropTypes.bool
};

export default FileUploadModal;
