import React, { useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import ReactSelectPicker from '../../shared/ReactSelectPicker'

function ResponsiveTable(props) {

  const headers = () => {
    const tableHeaders = props.headers
    const comp = tableHeaders.map( (header, index) =>  <th key={index}>{header}</th> )
    return (
      <thead>
        <tr className='active'>
          {comp}
        </tr>
      </thead>
    )
  }

  const handleChange= (e, id, index) => {
    props.actionOnChange(e.value, id, index)
  }

  const tableData= (data, header, rowIndex, index) => {
    if (header == 'actions') {
      return <td key={index}>
                <ReactSelectPicker
                  options={props.actionOptions}
                  placeholder={'Action'}
                  isSearchable={false}
                  value={''}
                  onChange={e => handleChange(e, data[props.id], rowIndex)}
                />
              </td>
    } else {
      return <td key={index}>{data[header]}</td>
    }
  }

  const renderRow= (data, rowIndex) => {
    return (
      <tr data-index={rowIndex} key={data[props.id]}>
        {props.headers.map( ( header, index) => tableData(data, header, rowIndex, index) )}
      </tr>
    )
  }

  const tableBody = () => {
    const items = props.data.map( (data, index) => renderRow(data, index) )
    return (
      <tbody id="eligible-scenarios-container">
        {items}
      </tbody>
    )
  }

  return (
    <table className="table table-striped-even table-responsive" id="eligible-scenarios-table">
      { headers() }
      { tableBody() }
    </table>
  )
}

export default ResponsiveTable;
