import React from 'react'
import DashboardBenefit from './DashboardBenefit'
import ScheduleModalBox from '../schedules/common_components/ScheduleModalBox'

class DashboardBenefitRow extends React.Component {
  constructor () {
    super ()
    this.state = {
      display_modal: null,
      description: null,
      name: null,
      image_url: null,
      isAdmin: null,
      displayBenefit: {},
      timelineItems: [],
      data: [],
    }
  }

  benefitDetailHandler (benefitDetail) {
    this.setState(benefitDetail)
  }

  handleItemClose () {
    this.setState({ display_modal: false })
  }

  benefitCustomColor(benefit_id) {
    let { customColors } = this.props;
    if(benefit_id && customColors) {
      let filteredColor = customColors.filter(value => (value[benefit_id]))[0]
      return filteredColor && Object.keys(filteredColor).map(e => filteredColor[e])
    }
  }

  render () {
    return (
      <div className='dashboard-benefits-row'>
        {
          this.props.rowBenefits.map((benefit, i) => {
            if (benefit.type === 'extending-benefit-range' && !this.props.showExtendableBenefit) {
              return null
            } else {
              return (
                <DashboardBenefit
                  benefit={benefit}
                  key={i}
                  handleJustLookingClick={this.props.handleJustLookingClick}
                  handleBenefitDetail={this.benefitDetailHandler.bind(this)}
                  toggleDetailView={this.props.toggleDetailView}
                  isAdmin={this.props.isAdmin}
                  customColor={this.benefitCustomColor(benefit.id)}
                />
              )
            }
          })
        }
        {
          (this.state.display_modal) &&
            <ScheduleModalBox
              data={this.state.data}
              item={this.state.displayBenefit}
              selectedBenefit={this.state.selectedBenefit}
              name={this.state.name}
              handleItemClose={this.handleItemClose.bind(this)}
              item_type='benefit_event'
              time_off_enabled={this.state.time_off_enabled}
              description={this.state.description}
              image_url={this.state.image_url}
              onHelpfulCheck={this.props.onHelpfulCheck}
              isAdmin={this.state.isAdmin}
              isLeavePlanPartner={this.props.isLeavePlanPartner}
              pending={this.state.pending}
              timelineItems={this.state.timelineItems}
            />
        }
      </div>
    )
  }
}


export default DashboardBenefitRow;
