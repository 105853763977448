import { RepeatOneSharp } from '@material-ui/icons'
import React from 'react'
import Datepicker from './inputs/datepicker'
import Selectpicker from './inputs/selectpicker'
import TextEditor from './TextEditor'

class SubstitutionPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      parentContent: null,
      childContent: null,
      childLines: 0,
      parentLines: 0,
    }
  }

  componentDidMount() {
    $.ajax({
      method: 'get',
      url: `/admin/substitutions/${this.props.substitutionId}/preview`,
      data: {overridden: window.location.href.includes("companies/substitutions")},
      success: (response) => {
        this.setState({
          loaded: true,
          parentContent: response.parent_preview,
          childContent: response.child_preview,
          childLines: response.child_lines,
          parentLines: response.parent_lines
        })
      }
    })
  }

  closePrompt() {
    this.props.closePreview()
  }

  parentSubstitutionPreview() {
    if (!this.state.loaded) return null;
    if (!this.state.parentContent) return null;
    let contentWidth = parseFloat(this.state.parentLines)* (235/6)
    contentWidth = contentWidth > 400 ? 400 : contentWidth
    return (<>
      <div className="col-md-4">
        <label className='label-control'> <b>Substitution Value</b></label>
      </div>
      <div className='form-group'>
        <TextEditor
        substitutionContent={this.state.parentContent}
        substitutionPreview={true}
        substitutionWidth={contentWidth}
        />
      </div>
      </>
    )
  }

  childSubstitutionPreview() {
    if (!this.state.loaded) return null;
    if (!this.state.childContent) return null;
    let contentWidth = parseFloat(this.state.childLines)* (230/6)
    contentWidth = contentWidth > 400 ? 400 : contentWidth
    return (<>
      <div className="col-md-4">
        <label className='label-control'> <b>Substitution Override</b></label>
      </div>
      <div className='form-group'>
        <TextEditor
        substitutionContent={this.state.childContent}
        substitutionPreview={true}
        substitutionWidth={contentWidth}
        />
      </div>
      </>
    )

  }

  modalHeader() {
    return (
      <div className="modal-header">
        <div className='item-header-container'>
          <div className='item-header'>
            <div className='name'>
              Substitution Preview
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNoteModal() {
    return (
      <div className="react-modal">
        <div className="back-shadow" onClick={(e) => this.closePrompt(e)}>
        </div>
        <div className="modal-content preview-react-modal-content">
          {this.modalHeader()}
          <div className="modal-body leave-plan-benefit-modal">
            <form className='leave-plan-benefit-override-form' action='#'>
              {this.parentSubstitutionPreview()}
              {this.childSubstitutionPreview()}
            </form>
          </div>
          <div className="modal-footer buttons">
            <button className="cancel btn btn-danger" type='reset' onClick={() => this.closePrompt()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return(
      <div>
        {this.renderNoteModal()}
      </div>)
  }
}
export default SubstitutionPreview;
