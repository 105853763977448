import React, { useState, useEffect, useRef } from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'

function CompanyMoveForm(props) {
  const [ips, setIps] = useState([])
  const [selectedIp, setIp] = useState('')

  useEffect(() => {
    fetchIps()
  },[]);

  const fetchIps = () => {
    let method = 'GET'
    let url = `/admin/implementation_partners/${props.partnerId}/ip_list`
    $.ajax({
      method: method,
      url: url,
      success: (response) => {
        setIps(response.ips)
      }
    })

  }

  const moveToSuperAdmin = (e) => {
    e.preventDefault()
    props.moveToSuperAdmin(props.companyId)
  }

  const moveToIp = (e) => {
    e.preventDefault()
    if(selectedIp.trim().length == 0) {
      toastr.error('Please select the IP.')
      return null;
    }
    props.moveToIp(selectedIp)
  }

  return (
     <div className="react-modal">
      <div className="back-shadow"></div>
      <div className="modal-content react-modal-content">
        <div className="modal-header">
          <button
            className="close error-modal-close"
            type="button" onClick={props.hideMovableForm}>×</button>
          <h4 className="modal-title">Move Company</h4>
        </div>

        <form className='generalised-form'>
          <div className="modal-body">
            <div className="form-group" >
              <div className="row">
                <div className="col-md-4 col-md-offset-1">
                  <label>Implementation Partner:</label>
                </div>
                <div className="col-md-5">
                  <div>
                    <Selectpicker
                      className="select-picker form-control"
                      name="company_id"
                      data-live-search='true'
                      optionsForSelect = {ips}
                      onChange={(value) => setIp(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons pull-left">
              <button className="btn btn-primary" onClick={(e) => moveToSuperAdmin(e)}>
                To Super Admin
              </button>
            </div>
            <div className="buttons">
              <button className="btn btn-primary" onClick={(e) => moveToIp(e)}>
                Move
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CompanyMoveForm;
