import React from 'react'
import TableData from '../containers/tableData'
import TableHead from '../components/tableHead'

class Table extends React.Component {

  constructor () {
    super ()
  }

  renderHead (head, cssClass = "", i) {
    return <TableHead
      head={head}
      cssClass={cssClass}
      key={i}
      sort={this.props.sort}
      sortBy={this.props.sortBy} />
  }

  setTokenAndRedirect(leave_plan_id) {
    $.ajax({
      method: "GET",
      url: '/admin/users/admin_view_EE',
      data: {leave_plan_id: leave_plan_id},
      success: (data) => {
        if (data.success) {
          Cookies.set('user-type', 'admin-view', { secure: true })
          Cookies.set('leave-plan-id', leave_plan_id, { secure: true })
          Cookies.set('masqueraded-uid', data.data.email, { secure: true })
          Cookies.set('masqueraded-baseurl', data.data.redirect_to.split('/webapp')[1], { secure: true })
          Cookies.set('show-modal', true, { secure: true })

          window.location = data.data.redirect_to
        } else {
          toastr.error('Error viewing shared LP')
        }
      }
    })
  }

  handleClick (row) {
    if (!!row.leave_end_date) {
      window.location = row.redirect_url
      return;
    } else {
      this.setTokenAndRedirect(row.leave_plan_id)
    }
  }

  renderRow (row, cssClasses, benefits, index) {
    return (
      <tr
        key={index}
        className={`tr benefit-items-row fixed-table-header`}
        onClick={() => (row.leave_status && row.leave_status === 'archived') ? '' : this.handleClick(benefits[index])}
      >
        {this.renderItem(row, "employee_name", "name")}
        {this.renderItem(row, "leave_status_name", "status")}
        {this.renderItem(row, "leave_type", "leave-type")}
        {this.renderItem(row, "leave_start_date", "date")}
        {this.renderItem(row, "leave_end_date", "date")}
        {this.props.supportsClaimSubmission && this.renderItem(row, "has_claims", "claim-button")}
        {this.renderItem(row, "shared_on", "shared-date")}
        {this.renderItem(row, "actions", "actions")}
      </tr>
    )
  }

  renderItem (benefit, selector, cssClass) {
    return <TableData {...this.tableDataProps(benefit, selector, cssClass)} />
  }

  tableDataProps (benefit, selector, cssClass) {
    let props = {
      leave_plan_id: benefit.leave_plan_id,
      data: benefit[selector],
      class: cssClass,
      childClass: benefit.leave_status,
      showConfirmation: this.props.showConfirmation,
      isSamplePlan: benefit.is_sample_plan,
    };
    if (selector === "actions") {
      (props.leave_plan_id = benefit.leave_plan_id),
        (props.showConfirmation = this.props.showConfirmation),
        (props.showInviteButton =
          benefit.admin_created &&
          !benefit.invited &&
          !benefit.shared &&
          this.props.currentCompany.status === "active"),
        (props.resendInvitation =
          benefit.admin_created &&
          benefit.invited &&
          this.props.currentCompany.status === "active"),
        (props.isSuperAdmin = this.props.isSuperAdmin);
    }

    if (selector === 'has_claims' && benefit.has_claims) {
      props.leave_plan_id = benefit.leave_plan_id
      props.showClaims = (e) => {
        e.stopPropagation()
        this.props.toggleClaim(benefit.leave_plan_id, true)
      }
    }
    return props
  }

  render () {
    return (
      <table className = "shared-benefit-table fixed-table-header-shared-leave-plan">
        <thead className="thead fixed-table-header">
          <tr className="tr">
            {this.props.heads.map( (head, i) => this.renderHead(head, this.props.classes[i] || "", i))}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map( (row, i) => this.renderRow(row, this.props.classes, this.props.benefits, i))}
        </tbody>
      </table>
    )
  }
}

export default Table;
