import React from 'react'
import Table from '../components/table'

class BenefitTable extends React.Component {
  constructor (props) {
    super (props)
    this.heads = [
      "Name",
      "Status",
      "Leave Type",
      "Requested Leave Start Date",
      "Requested Leave End Date",
      "Shared On",
      "Actions"
    ]
    if (props.supportsClaimSubmission) {
      this.heads.splice(5, 0, "Claim Submission Status")
    }
  }

  classesArray () {
    return (
      [{
        element: "employee_name",
        class: "name sortable"
      }, {
        element: "leave_status_name",
        class: "status sortable"
      }, {
        element: "leave_type",
        class: "leave-type sortable"
      }, {
        element: "leave_start_date",
        class: "date sortable"
      }, {
        element: "leave_end_date",
        class: "date sortable"
      }, {
        element: "has_claims",
        class: "claims-button"
      }, {
        element: "shared_on",
        class: "shared-date sortable"
      }, {
        element: "actions",
        class: "actions"
      }]
    )
  }

  renderTable () {
    if (this.props.benefits.length) {
      return (
        <Table
          heads={this.heads}
          rows={this.props.benefits}
          benefits={this.props.benefits}
          showConfirmation={this.props.showConfirmation}
          supportsClaimSubmission={this.props.supportsClaimSubmission}
          toggleClaim={this.props.toggleClaim}
          currentCompany={this.props.currentCompany}
          classes={this.classesArray()}
          sort={this.props.sort}
          sortBy={this.props.sortBy}
          isSuperAdmin={this.props.isSuperAdmin}/>
      )
    } else {
      return (
        <span className="no-data">
          No data to show
        </span>
      )
    }
  }

  render () {
    return (
      (() => this.renderTable())()
    )
  }
}

export default BenefitTable;
