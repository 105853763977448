import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  headings: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 70,
  },
  formDiv: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    fontFamily: "MontserratRegular",
  },
  forgetPassword: {
    float: "right",
    fontFamily: "MontserratRegular",
    fontSize: 18
  },
  registrationDiv: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 10px)'
  },
  heading1: {
    fontSize: 32,
    fontFamily: "MontserratExtraBold",
  },
  subHead: {
    fontSize: 18,
    fontFamily: "MontserratRegular",
    color: '#666666',
  },
  signup: {
    paddingLeft: 12,
    cursor: 'pointer',
    fontFamily: "MontserratRegular",
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  labelMedium: {
    fontSize: 16,
    fontFamily: "MontserratSemiBold",
  }
});
