import React, { useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import ReactSelectPicker from '../../shared/ReactSelectPicker'

function GeneralTable(props) {

  const headers = () => {
    const tableHeaders = props.headers
    const comp = tableHeaders.map( (header, index) =>
      <th className="table-title sortable" key={index}>
        <span className="title-text">{header.titleize()}</span>
        <span className="sort-caret">
        </span>
      </th>
    )
    return (
      <thead className="thead fixed-table-header" style={{ marginTop: "73px" }}>
        <tr className="tr">
          {comp}
        </tr>
      </thead>
    )
  }

  const handleChange= (e, id, index) => {
    props.actionOnChange(e.value, id, index)
  }

  const changedOptions = (id) => {
    let options = [...props.actionOptions]
    if (props.disabledOptions){
      let disabledOptions = Object.keys(props.disabledOptions)
      return options.map ((option) => {
        let cloneOption = {...option}
        if((disabledOptions.indexOf(option.value) >= 0) && !(props.disabledOptions[option.value].indexOf(id) >= 0)) {
          cloneOption['isDisabled'] = true
        }
        return cloneOption
      })
    }
    else {
      return options
    }
  }

  const tableData= (data, header, rowIndex, index) => {
    if (header === 'actions') {
      return <td className="table-data actions" key={index}><span className="general-actions"><ReactSelectPicker
        options={changedOptions(data[props.id])}
        placeholder={'Action'}
        isSearchable={false}
        value={''}
        onChange={e => handleChange(e, data[props.id], rowIndex)}
      /></span></td>
    } else {
      return <td className="table-data" key={index}>{data[header]}</td>
    }
  }

  const renderRow= (data, rowIndex) => {
    return (
      <tr key={ rowIndex } className="tr item-table benefit-items-row fixed-table-header" onClick={(e) => {props.goToLink && props.goToLink(e, data['id'])}}>
          {props.headers.map( ( header, index) => tableData(data, header, rowIndex, index) )}
      </tr>
    )
  }

  const tableBody = () => {
    const items = props.data.map( (data, index) => renderRow(data, index) )
    return (
      <tbody>
        {items}
      </tbody>
    )
  }

  return (
    <div className="item-table">
      <table className="shared-benefit-table generic-table deletable-rows">
          { headers() }
          { tableBody() }
        </table>
    </div>
  )
}


export default GeneralTable;
