import React from 'react';
import Editor from '../../shared/Editor';

class HrisYamlConfiguration extends React.Component {
  configurationChangedHandler(content) {
    $(this.props.target).val(content)
  }

  render() {
    return(
      <div>
        <Editor
          editorMode='yaml'
          content={this.props.content}
          onChange={this.configurationChangedHandler.bind(this)}
        />
      </div>
    )
  }
}

export default HrisYamlConfiguration;
