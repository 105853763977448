import React from 'react'
import Pagination from '../pagination/pagination'

class TaskList extends React.Component {
  constructor() {
    super()

    this.state = {
      tasktemplates: [],
      selectedItemIds: [],
      pageNumber: "1",
      totalItems: null
    }
  }

  componentDidMount(){
    let params = this.getParams()
    $.getJSON(params.url, params.data, (response) => {
      this.setState({
        selectedItemIds: response.associated_item_ids,
        totalItems: response.total_count,
        tasktemplates: response.task_templates.map(item => {
          item.checked = response.associated_item_ids.indexOf(item.id) > -1
          return item
        })
      })
    })  
  }

  getParams() {
    const { bundleVendorId, bundledTemplateItemId } = this.props
    let data = { 
      bundle_vendor_id: bundleVendorId,
      page: this.state.pageNumber,
    }
    return ({
      data,
      url: `/admin/bundled_template_items/${bundledTemplateItemId}/fetch_task_templates`
    })
  }

  fetchAssociatedTaskTemplates() {
    let params = this.getParams()
    $.getJSON(params.url, params.data, (response) => {
      this.setState({
        tasktemplates: response.task_templates.map(item => {
          item.checked = this.state.selectedItemIds.indexOf(item.id) > -1
          return item
        })
      })
    })
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchAssociatedTaskTemplates() })
  }

  handleAssociateTaskTemplate() {
    const { bundleVendorId, bundledTemplateItemId } = this.props
    const { selectedItemIds } = this.state
    let method = 'POST'
    let url = `/admin/bundled_template_items/${bundledTemplateItemId}/task_template_associations`
    const params = {
      bundle_vendor_id: bundleVendorId,
      association_ids: selectedItemIds
    }
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        this.props.onModalClose()
        if (response.success) {
          toastr.success(response.success_message)
        } else{
          toastr.error("Error associating task template")
        }
      }
    })
  }

  handleChecked(id, index) {
    const {selectedItemIds, tasktemplates } = this.state
    tasktemplates[index].checked = !tasktemplates[index].checked
    if(tasktemplates[index].checked) {
      selectedItemIds.push(id)
    } else {
      selectedItemIds.splice($.inArray(id, selectedItemIds), 1)
    }
    this.setState({ selectedItemIds })
  }

  disabledReason(disable_association) {
    return disable_association ?
      "This task template cannot be associated to both a Leave Benefit Template and a Planning Guide Template"
      : ''
  }

  renderTaskTemplateTable(){
    const { tasktemplates } = this.state
    return(
      <table className="shared-benefit-table generic-table">
        <thead className="thead" style={{ marginTop: '0px' }}>
          <tr className="tr">
            <th className="table-title">Name</th>
            <th className="table-title">Status</th>
          </tr>
        </thead>
        <tbody>
          {
            tasktemplates.map((template, index) => {
              return(
                <tr key={ index } className="tr item-table">
                  <td>
                    <input
                      type="checkbox"
                      className="select-item"
                      style={{
                        height: 25,
                        width: 15
                      }}
                      disabled={ template.disable_association }
                      title={ this.disabledReason(template.disable_association) }
                      checked={ template.checked }
                      onChange={ () => this.handleChecked(template.id, index) }
                    />
                  </td>
                  <td className="table-data">{ template.timeline_event_name }</td>
                  <td className="table-data">{ template.status }</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  renderButtons() {
    return (
      <div className="col-sm-6 com-md-6 col-lg-6">
        <div className="buttons">
          <button className="btn btn-danger pull-right" style={{ marginLeft: '5px'}} onClick={ this.props.onModalClose }>
            Cancel
          </button>
          <button className="btn btn-primary pull-right" onClick={ () => this.handleAssociateTaskTemplate() }>
            Associate
          </button>
        </div>
      </div>
    )
  }

  renderPagination() {
    const {  pageNumber, totalItems } = this.state

    return (
      <div className="col-sm-6 col-md-6 col-lg-6">
        <Pagination
          totalItems={ totalItems }
          changePageNumber={ this.changePageNumber.bind(this) }
          pageNumber={ pageNumber }
          itemsPerPage={ 10 }
        />
      </div>
    )
  }

  renderTaskTemplateList() {
    const { tasktemplates } = this.state

    if(tasktemplates.length > 0){
      return(
        <div className="item-table">
          <div className="row">
            { this.renderPagination() }
            { this.renderButtons() }
          </div>
          { this.renderTaskTemplateTable() }
        </div>
      )
    } else {
      return(
        <div><br/>No Associated Task Templates</div>
      )
    }
  }

  render() {
    return(
      <div className="react-modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content" style={{ width: '900px', top: '0' }}>
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ this.props.onModalClose }
            >
              ×
            </button>
            <h4 className="modal-title">
              Task Template
            </h4>
          </div>
          <div className="modal-body">
            <div className="text-center">
              { this.renderTaskTemplateList() }
            </div>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    )
  }
}

export default TaskList;
