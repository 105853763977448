import React from 'react'
import Selectpicker from '../../inputs/selectpicker'

class BulkCopy extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
        ipChecked: this.notFromLeaveScenario() ? false : true,
        normalChecked: this.notFromLeaveScenario() ? true : false,
        implementationPartner: [],
        isSubmitDisabled: false,
        invalidTemplates: [],
    }
  }

  componentDidMount () {
    if(!this.props.allowCopyToIp) return null;

    const _this = this
    jQuery.ajax({
      type: 'GET',
      url: `${this.props.url}/ip_list`,
      success: function(res) {
        _this.setState({ implementationPartner: res.ips.map( ip=> [ip['name'],ip['id']]) })
      }
    })
  }

  handleCheckboxChange (e) {
    const stateName = e.target.name
    const alternateName = stateName == 'normalChecked' ? 'ipChecked' : 'normalChecked'
    const state = this.props.allowCopyToIp ? !this.state[stateName] : true
    this.setState({
      [stateName]: state,
      [alternateName]: !state
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    const form = e.target
    const formData = $(form).attributes()
    if (this.formValid(formData)) {
      formData['ipChecked'] == 'on' ? formData['ipChecked'] = 'true' : formData['ipChecked'] = 'false'
      this.props.handleBulkCopy(formData)
    } else {
      toastr.error("Implementation Partner cannot be blank")
    }
  }

  formValid (formData) {
    if (this.state.ipChecked) {
      formData['move_to_next_env'] = true
      return formData.ip.trim().length > 0
    }
    if (this.props.ipId && this.props.ipId.trim().length > 0) {
      formData['ip'] = this.props.ipId
    }
    return true
  }

  handleIpChange (ipId) {
    const templateControllers = [
      '/admin/leave_benefit_templates',
      '/admin/planning_guide_templates',
      '/admin/task_item_templates',
      '/admin/manager_training_templates'
    ]
    if (!templateControllers.includes(this.props.url)) return;
    if (!ipId) return;
    this.setState({isSubmitDisabled: true})

    jQuery.ajax({
      type: 'POST',
      data: {ip_id: ipId, template_ids: this.props.bulkItems},
      url: `${this.props.url}/validate_copying_ip_templates`,
      success: (res) => {
        if (res.invalid_templates.length) {
          this.setState({invalidTemplates: res.invalid_templates})
          return
        }
        this.setState({isSubmitDisabled: false, invalidTemplates: []})
      }
    })
  }

  renderIp () {
    return (
      <div key={1}>
        <div className="col-md-4">
          <div className="radio">
            <label>
              <input
                type="radio"
                checked={this.state.ipChecked}
                name="ipChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Copy To partner
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              className="select-picker form-control"
              name="ip"
              //defaultValue={this.props.data.status.default_value}
              disabled={!this.state.ipChecked}
              optionsForSelect={this.state.implementationPartner}
              onChange={this.handleIpChange.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }

  renderNormalCopy () {
    return (
      <div key={0}>
        <div className="col-md-12">
          <div className="radio">
            <label>
              <input
                type="radio"
                checked={this.state.normalChecked}
                name="normalChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Duplicate item
            </label>
          </div>
        </div>
      </div>
    )
  }

  notFromLeaveScenario() {
    return this.props.url !== '/admin/leave_scenarios'
  }

  renderTemplateErrors() {
    if (this.state.invalidTemplates.length === 0) return;
    if (!this.state.ipChecked) return;

    return (<div className="col-md-12" style={{color: 'red'}}>
      {
        this.state.invalidTemplates.map((invalidTemplate, index) => {
          return (<li key={index}>{invalidTemplate}</li>)
        })
      }
    </div>)
  }

  render () {
    const isSubmitDisabled = (this.state.ipChecked && this.state.isSubmitDisabled) ? {disabled: true} : {}
    return (
      <form className='bulk-edit-form' onSubmit={this.handleSubmit.bind(this)}>
        <div className="react-modal">
          <div className="back-shadow">
          </div>
          <div className="modal-content react-modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                Bulk Copy Items
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-info">
                    <span>{this.props.itemsCount}</span> items will be copied.
                  </div>
                </div>
                { this.notFromLeaveScenario() && this.renderNormalCopy() }
                { this.props.allowCopyToIp && this.renderIp() }
                { this.renderTemplateErrors() }
              </div>
            </div>
            <div className="modal-footer">
              <fieldset className="confirmation-buttons">
                <button className="btn btn-danger pull-left" onClick={this.props.hideBulkCopyModal.bind(this)}>
                  Cancel
                </button>
                <button className="btn btn-primary" type='submit' {...isSubmitDisabled}>
                  Apply
                </button>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default BulkCopy;
