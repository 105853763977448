import React from 'react'

class DashboardBenefitsViewToggle extends React.Component {
  isActive(period) {
    return this.props.period === period ? 'active' : ''
  }

  buildPeriod() {
    this.view = {
      weekView: this.isActive('week'),
      monthView: this.isActive('month'),
      yearView: this.isActive('year'),
    }
  }

  toggleToWeekView () {
    if (this.view.weekView !== 'active') {
      this.props.toggleView('period', 'week')
    }
  }

  toggleToMonthView () {
    if (this.view.monthView !== 'active') {
      this.props.toggleView('period', 'month')
    }
  }

  toggleToYearView () {
    if (this.view.yearView !== 'active') {
      this.props.toggleView('period', 'year')
    }
  }

  render () {
    this.buildPeriod()

    return (
      <div className='view-toggle period-toggle'>
        <div
          className={`dashboard-benefit-view left-button ${this.view.yearView}`}
          onClick={this.toggleToYearView.bind(this)}
        >
          Year
        </div>
        <div
          className={`dashboard-benefit-view center-button ${this.view.monthView}`}
          onClick={this.toggleToMonthView.bind(this)}
        >
          Months
        </div>
        <div
          className={`dashboard-benefit-view right-button ${this.view.weekView}`}
          onClick={this.toggleToWeekView.bind(this)}
        >
          Weeks
        </div>
      </div>
    )
  }
}

export default DashboardBenefitsViewToggle
