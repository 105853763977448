import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Selectpicker from '../../shared/inputs/selectpicker';
import AuthLayout from './AuthLayout';
import FormInput from './FormInput';

let headers = {
  "Content-Type": "application/json",
}
const DisableSignUpLoginScreen = (props) => {
  const classes = useStyles()

  const [inputs, setInputs] = useState({
    login: "",
    password: "",
  });

  const [request, setRequest] = useState({
    message: '',
    title: '',
    url: '',
    company_id: '',
    company_options: [],
    allow_login: false
  })

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordPresent, setIsPasswordPresent] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    let method = 'POST'
    let url = '/users/sign_in'

    $.ajax({
      method: method,
      url: url,
      headers: headers,
      data: JSON.stringify({ user: inputs }),
      success: (response, _, jqXHR) => {
        if (response.success) {
          if (response.redirect_location) {
            let redirectURL = Cookies.get("redirect-url");
            Cookies.set("is-super-admin", response.is_super_admin, {
              secure: true,
            });
            Cookies.set("admin-role", response.admin_role, { secure: true });
            Cookies.set("user-role", response.admin_role, { secure: true });
            Cookies.set("is-authenticated", "true", { secure: true });
            window.localStorage.setItem('company-id', response.default_company_id);
            window.location = redirectURL
              ? `/webapp${redirectURL}`
              : response.redirect_location;
            toastr.success("Signed in successfully.");
          } else {
            let allowLogin = response.allow_login;
            setRequest({
              allow_login: allowLogin,
              message: response.message,
              url: response.request_url,
              title: response.title,
              company_options: !allowLogin && response.company_options,
              company_id: !allowLogin && response.company_options[0].value
            })
          }
        }
      },
      error: (err) => {
        toastr.error(JSON.parse(err.responseText).message)
      }
    })
  }

  const requestAccount = (e) => {
    e.preventDefault();
    let params = {
      company_id: request.company_id,
      commit: 'Request+Account',
    }

    $.ajax({
      method: 'POST',
      url: request.url,
      headers: headers,
      data: JSON.stringify(params),
      success: (response) => {
        toastr.success(response.message)
        setRequest({ ...request, allow_login: false, company_id: '' })
        setInputs({
          login: "",
          password: "",
        })
      },
      error: (err) => {
        toastr.error(JSON.parse(err.responseText).message)
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSelectPickerChange = (key, value) => {
    setRequest({ ...request, [key]: value })
  }

  const { login, password } = inputs;

  const renderLogin = () => {
    return (
      <>
        <div className={classes.headings}>
          <span className={classes.heading1}><span className="underline">
            Welcome to {props.application_name}. Please enter your work email.
          </span></span>
        </div>
        <form name="form" onSubmit={handleSubmit}>
          <div className={classes.formDiv}>
            <FormInput
              type="text"
              name="login"
              formLabel="Login"
              value={login}
              onChange={handleChange}
              error={!isEmailValid}
              helperText={"You must enter a valid email address"}
            />
            {request.allow_login ?
              <FormInput
                type="password"
                name="password"
                formLabel="Password"
                value={password}
                onChange={handleChange}
                error={!isPasswordPresent}
                helperText={"Password is required"}
              />
              : ''}
          </div>
          <div className={classes.formGroup}>
            <Button style={{ fontFamily: "MontserratRegular" }} className="primary-login-button" type="submit">
              Continue
            </Button>
          </div>
          {
            props.overview_link ?
              <>
                <p>'Click here for a brief overview of {props.application_name}: </p>
                <a href={`${props.overview_link}`} target="_blank">{props.application_name} Overview</a>
              </> : ''
          }
        </form>
      </>
    )
  }

  const renderRequestAccount = () => {
    return (
      <Box>
        <div className={classes.headings}>
          <span className={classes.heading1}><>
            {request.title.length > 0 ?
              <span className="underline">{request.title}</span> :
              <span dangerouslySetInnerHTML={{ __html: request.message }}>
              </span>
            }
          </></span>
        </div>
        {
          request.company_options && request.company_options.length > 1 ?
            <Selectpicker
              className="select-picker form-control"
              name="company_id"
              optionsForSelect={request.company_options.map(company => [company.name, company.value])}
              onChange={(value) => handleSelectPickerChange('company_id', value)}
            />
            : ''
        }
        <div className={classes.formGroup}>
          <Button className="primary-login-button" type="submit" onClick={requestAccount}>
            Request Account
          </Button>
        </div>
      </Box>
    )
  }

  const renderForm = () => {
    if (request.company_id) {
      return renderRequestAccount();
    }
    return renderLogin();
  }

  return (
    <AuthLayout isConfidentialPlanning={props.is_confidential_planning}>
      <Container maxWidth="sm">
        <div className={classes.registrationDiv}>
          {renderForm()}
        </div>
      </Container>
    </AuthLayout>
  )
}

const useStyles = makeStyles({
  headings: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 70,
    marginBottom: 30,
  },
  formDiv: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
  },
  forgetPassword: {
    float: "right",
  },
  registrationDiv: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 10px)'
  },
  heading1: {
    fontSize: 32,
    fontFamily: "MontserratExtraBold",
  },
  subHead: {
    fontSize: 18,
    fontFamily: "MontserratRegular",
    color: '#666666',
  },
  signup: {
    paddingLeft: 12,
    cursor: 'pointer'
  },
  formGroup: {
    marginTop: 10
  }
});



export default DisableSignUpLoginScreen;
