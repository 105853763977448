import React from 'react'
import Editor from '../../shared/Editor'

class CompanySubstitutionEditor extends React.Component {
  configurationChangedHandler(content) {
    $(this.props.target).val(content)
  }

  render() {
    return (
      <div className='company-claim-substitution'>
        <Editor
          editorMode='application/json'
          content={this.props.content}
          onChange={this.configurationChangedHandler.bind(this)}
        />
      </div>
    )
  }
}

export default CompanySubstitutionEditor;