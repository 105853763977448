import React from 'react'

class SquareCheckbox extends React.Component {
  constructor (props) {
    super (props)
  }

  additionalClass () {
    return this.props.className ? this.props.className : ''
  }

  render() {
    const { id, disabled, checked } = this.props

    return (
      <span className='square-checkbox'>
        <input
          className={`filter-check ${this.additionalClass()}`}
          id={id}
          type='checkbox'
          defaultChecked={checked}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          disabled={disabled}
          onClick={() => !disabled && this.props.onChange(!checked)}
        />
      </span>
    )
  }
}

export default SquareCheckbox;
