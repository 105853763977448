
function sortDate (d1, d2) {
  d1 = new Date(d1)
  d2 = new Date(d2)
  return d1-d2
}

function sortString (str1, str2) {
  str1 = str1 && str1.toLowerCase() || ''
  str2 = str2 && str2.toLowerCase() || ''
  if (str1 < str2) {
    return -1
  } else if (str2 < str1) {
    return 1
  }
  return 0
}

export {sortDate , sortString};
