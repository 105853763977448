import React from 'react'
import TableData from '../../shared_leave_plans/containers/tableData'

class LeavePlanHistoryTableRow extends React.Component {
  constructor () {
    super ()
    this.state = {
      timeOffChanges: 'time off type changes'
    }
  }

  renderData (data, className, i) {
    if (typeof data === "object") {
      return <TableData key={i} class={className} >{data}</TableData>
    } else {
      return <TableData key={i} class={className} data={data} />
    }
  }

  generateTexts (history) {
    const {leave_plan_changes, created_at, updated_by_name} = history
    const description = (history.leave_plan_changes.change_type === this.state.timeOffChanges) ? this.generateTimeOffChangeHistory(leave_plan_changes.leave_plan_changes) : this.generateDescription(leave_plan_changes.leave_plan_changes)
    return  [
      leave_plan_changes.change_type,
      description,
      updated_by_name,
      moment(created_at).format(gon.default_date_format_string.toUpperCase())
      ]
  }

  generateTimeOffChangeHistory(lp_changes){
    return (
      <ul className="history-list">
        {
          lp_changes
            .map(this.generateTimeOffChangeDescription)
            .map((text, i) => (<li key={i}>{text}</li>))
        }
      </ul>
    )
  }

  generateDescription (lp_changes) {
    return (
      <ul className="history-list">
        {
          lp_changes
            .map(this.handleDescriptionCases)
            .map((text, i) => (<li key={i}>{text}</li>))
        }
      </ul>
    )
  }

  generateTimeOffChangeDescription(change)
  {
    if (change.new_value !== change.prev_value) {
      return (
        <p>
          <span className="highlight-value">
            {` ${change.name} `}
          </span> changed from
          <span className="highlight-value">
            {` ${change.prev_value} `} hours
          </span> to
          <span className="highlight-value">
            {` ${change.new_value} `} hours
          </span>
        </p>
        )
    }
  }

  handleDescriptionCases (change) {
    if (change.type === "leave_plan_scenario") {
      return (
        <p>
          LeavePlan Scenario changed from
          <span className="highlight-value">
            {` ${change.prev_value.name} `}
          </span> to
          <span className="highlight-value">
            {` ${change.new_value.name} `}
          </span>
        </p>
        )
    }

    if (change.type === 'leave_benefit_update' || change.type === 'task_item_update') {
      const keys = Object.keys(change.prev_value)
      const previous_dates = change.prev_value.leave_intermittent_dates || change.prev_value.intermittent_dates
      const new_dates = change.new_value.leave_intermittent_dates || change.new_value.intermittent_dates
      return (
        <div>
          {`${change.name}'s`}
          <ul className='benefit-changes'>
            {
              keys.map((key, i) => {
                return (
                  <li key={i}>
                    <span>
                      {`${key.titleize()} changed from`}
                      <span className='highlight-value'>
                        { key === 'intermittent_dates' || key === 'leave_intermittent_dates' ? (previous_dates.map((date, i) => {
                          return (
                            <p key={i}>
                              { date.start_date + "  -   " + date.end_date }
                            </p>
                          )}
                          ))
                        : ` ${change.prev_value[key]} `}
                      </span> to
                      <span className='highlight-value'>
                      { key === 'intermittent_dates' || key === 'leave_intermittent_dates' ? (new_dates.map((date, i) => {
                        return (
                          <p key={i}>
                            { date.start_date + "  -   " + date.end_date }
                          </p>
                        )}
                        )) : ` ${change.new_value[key]}`}
                      </span>
                    </span>
                  </li>
                )
              })
            }
          </ul>
        </div>
      )
    }

    if (!change.prev_value) {
      return (
        <p>
          {change.name} added as
          <span className="highlight-value">
            {` ${moment(change.new_value).format(gon.default_date_format_string.toUpperCase())} `}
          </span>
        </p>
      )
    } else if (!change.new_value) {
      return `${change.name} removed`
    }

    return (
    <p>
      {change.name} changed from
      <span className="highlight-value">
        {` ${moment(change.prev_value).format(gon.default_date_format_string.toUpperCase())} `}
      </span> to
      <span className="highlight-value">
        {` ${moment(change.new_value).format(gon.default_date_format_string.toUpperCase())} `}
      </span>
    </p>)
  }

  render () {
    return (
      <tr className="tr-leave-history fixed-table-body">
        {
          this.generateTexts (this.props.history)
            .map( (historyText, i) => this.renderData(historyText, this.props.cssClasses[i].class, i))
        }
      </tr>
    )
  }
}

export default LeavePlanHistoryTableRow;