import React from 'react'
import Editor from '../../shared/Editor/'

class ClaimConfiguration extends React.Component {
  configurationChangedHandler(content) {
    $(this.props.target).val(content)
  }

  render() {
    return (
      <div>
        <Editor
          editorMode='yaml'
          content={this.props.content}
          readOnly={this.props.readonly}
          onChange={this.configurationChangedHandler.bind(this)}
        />
      </div>
    )
  }
}

export default ClaimConfiguration;
