import React from 'react'

class TagForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.isEdit ? props.tag.name : '',
    }
  }

  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  submitTag() {
    const { tag, isEdit } = this.props
    const { name } = this.state
    this.setState({ disableButton: true })

    let method = 'POST'
    let url = '/admin/tags/'
    const params = {
      name: name,
      company_id: this.props.companyId,
      is_global_company_tag: this.props.globalCompanyTag,
      implementation_partner_id: this.props.implementationPartnerId
    }
    if(isEdit) {
      method = 'PUT'
      url = `/admin/tags/${tag.id}`
    }

    $.ajax({
      method: method,
      url: url,
      data: { tag: params },
      success: (response) => {
        if (response.success) {
          this.props.onSubmit(response.data)
          toastr.success(response.message)
        }
        else{
          toastr.error(response.message)
          this.props.onModalClose()
        }
      },
      error: (response) => {
        toastr.error(response.message)
      }
    })
  }

  render() {
    const { disableButton, name } = this.state
    const { onModalClose, isEdit } = this.props

    return (
      <div className="react-modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ onModalClose }
            >×</button>
            <h4 className="modal-title">Tag</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={ (e) => this.handleChange('name', e.target.value) }
                  required={true}
                />
              </div>
            </div>
            </div>
          <div className="modal-footer">
              <div className="buttons">
                <button className="btn btn-primary"
                        onClick={ (e) => this.submitTag(e) }
                        disabled={ disableButton }>
                  { isEdit ? 'Update' : 'Save' }
                </button>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

export default TagForm
