import React from 'react'
import LeavePlanHistoryTable from './containers/leavePlanHistoryTable'
class LeavePlanHistory extends React.Component {

  constructor (props) {
    super (props)

    this.state = {
      leavePlanHistories: props.data,
      pageNumber: '1',
    }
  }

  componentWillUnmount () {
    PubSub.unsubscribe('fetchLeavePlanHistories')
  }

  componentDidMount () {
    this.subscribeEvents()
  }

  subscribeEvents () {
    PubSub.subscribe('fetchLeavePlanHistories', () => {
      this.fetchLeavePlanHistories()
    })
  }

  fetchLeavePlanHistories () {
    $.getJSON(`/admin/shared_leave_plans/${this.props.leave_plan_id}/leave_plan_histories`, (response) => {
      let leavePlanHistories = response.leave_plan_histories
      this.setState({
        leavePlanHistories
      })
    })
  }

  changePageNumber (pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchData() })
  }

  fetchData () {
    $.ajax(({
      cache: false,
      url: `/admin/employees/${this.props.employee_id}/leave_plan_histories`,
      dataType: 'json',
      data: { leave_plan_history_page: this.state.pageNumber },
      success: (response) => {
        this.setState({
          leavePlanHistories: response.data
        })
      }
    }))
  }

  classesArray () {
    return [{
      element: "name",
      class: "update-name"
    },{
      element: "description",
      class: "description"
    }, {
      element: "updated_by_name",
      class: "updated-date"
    }, {
      element: "updated_at",
      class: "updated-date"
    }]
  }

  render () {
    return (
      <div className="leave-plan-history-table">
        <LeavePlanHistoryTable
          leavePlanHistories={this.state.leavePlanHistories}
          totalHistoriesCount={this.props.total_leave_plan_histories}
          changePageNumber={this.changePageNumber.bind(this)}
          employee_id={this.props.employee_id}
          classes={this.classesArray()} />
      </div>
    )
  }
}

export default LeavePlanHistory;
