import React from "react";
import PropTypes from "prop-types";

const FileDownloadButton = (props) => {
  return (
    <button
      className={`btn btn-primary content-download-button download-company-content-${props.value}`}
      onClick={() => props.handleClick(props.value, props.type)}
      value={props.value}
      disabled={props.disable}
    >
      Download
    </button>
  );
};

FileDownloadButton.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.number,
  disable: PropTypes.bool,
  type: PropTypes.string,
};

export default FileDownloadButton;
