import React from 'react'
import NotificationHeader from '../components/NotificationHeader'
import NotificationFooter from '../components/NotificationFooter'
import InstantNotification from '../components/InstantNotification'

class InstantNotifications extends React.Component {
  constructor () {
    super ()

    this.state = {
      notifications: [],
      allowFetchData: true
    }
  }

  componentDidMount () {
    this.subscribeEvents()
    this.setState ({notifications: this.props.notifications})
    let notificationDiv = $(this.props.notificationDiv)
    notificationDiv[0].addEventListener('scroll', this.handleScroll.bind(this))
  }

  componentWillUnmount () {
    PubSub.unsubscribe('notificationRead')
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.fetchNewNotificationsForTopBar && this.state.notifications.length < 6) {
      this.fetchNotifications()
      return false
    }
    return true
  }

  handleScroll (e) {
    const div = $(e.currentTarget)
    if(Math.abs(div.outerHeight() + div.scrollTop() - div[0].scrollHeight) < 10) {
      this.fetchNotifications()
    }
  }

  subscribeEvents () {
    PubSub.subscribe('notificationRead', (topic, notification_id) => {
      if(notification_id == 'all') {
        this.markReadForAll()
      } else {
        this.markReadForOne(notification_id)
      }
    })
  }

  pushNotificationToState (notification) {
    let state_notifications = this.state.notifications
    const index = this.findNotificationIndex(notification.id)
    if(index >=0) {
      state_notifications.splice(index, 1)
    }
    const new_notifications = [notification].concat(state_notifications)
    this.setState ({notifications: new_notifications})
  }

  showSpinner () {
    const div = $(this.props.spinnerDiv)
    div.show()
    $.displaySpinner(div)
  }

  hideSpinner () {
    const div = $('.notifications-container .spinner-div')
    div.hide()
    $.removeSpinner()
  }

  fetchNotifications () {
    if (this.state.allowFetchData && (this.props.data.access_to_classic_ui && !this.props.data.access_to_new_ui)) {
      this.showSpinner()
      const notifications = this.state.notifications
      const length = notifications.length
      const input_data = length > 0 ? {id: notifications[length - 1].id, time: notifications[length - 1].updated_at} : {}
      this.setState ({allowFetchData: false})
      $.getJSON('/admin/notifications', input_data, (response) => {
        this.buildNotifications(response.notifications)
        this.setState ({allowFetchData: !response.last_data_included})
        this.hideSpinner()
      })
    }
  }

  findNotificationIndex (id) {
    const notification = $.grep(this.state.notifications, (n) => {
      return parseInt(n.id) === parseInt(id)
    })[0]
    return this.state.notifications.indexOf(notification)
  }

  buildNotifications (notifications) {
    let state_notifications = this.state.notifications
    let notInState = notifications.filter(n => !state_notifications.some(s => s.id === n.id))
    this.setState ({notifications: state_notifications.concat(notInState)})
  }

  markAllRead () {
    $.post('/admin/notifications/mark_all_read', (response) => {
      PubSub.publish('notificationRead', 'all')
    })
  }

  markReadForAll () {
    const notifications = this.state.notifications.map((notification, i) => {
      notification.read = true
      return notification
    })
    this.setState ({notifications: notifications})
  }

  markAsRead (notification_id) {
    $.post('/admin/notifications/'+notification_id+'/mark_as_read', (response) => {
      PubSub.publish('notificationRead', notification_id)
    })
  }

  redirectToNewUi () {
    $.post('/admin/users/switch_admin_ui', (response) => {
      window.location.href = response.redirect_to + '/notifications';
    })
  }

  markReadForOne (notification_id) {
    let state_notifications = this.state.notifications
    if(state_notifications.length) {
      let index = this.findNotificationIndex(notification_id)
      state_notifications[index]['read'] = true
      this.setState ({notifications: state_notifications})
    }
  }

  notificationHeader () {
    return <NotificationHeader markAllRead={this.markAllRead.bind(this)} hideMarkAllRead={this.props.data.access_to_new_ui} />
  }

  notificationFooter () {
    if (!this.props.forIndex) {
      return <NotificationFooter hideSeeAll={this.props.data.access_to_new_ui} />
    }
  }

  render () {
    return (
      <div>
        {
          this.notificationHeader()
        }
        <div className='notifications'>
          {this.props.data.access_to_classic_ui && !this.props.data.access_to_new_ui ? <div>
          {
              this.state.notifications.map((notification) => {
              return <InstantNotification
                key={notification.id}
                notification={notification}
                markAsRead={this.markAsRead.bind(this)} />
            })
          }
            <div className='spinner-div' />
          </div> : this.props.data.access_to_new_ui ?
            <div className='message'>
              <div className='header'>This page has been refreshed in the new Admin experience!</div>
              To view your notifications in the new Admin experience,{" "}
              <span className='link' onClick={this.redirectToNewUi.bind(this)}>click here</span> or click the Switch to New Experience link in the header.
            </div> : null
          }
        </div>
        {
          this.notificationFooter()
        }
      </div>
    )
  }
}

export default InstantNotifications;