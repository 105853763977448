import React from 'react'
import Pagination from '../pagination/pagination'
import TaskList from './TaskList'

const FORMAT = 'YYYY-MM-DD'

class TaskTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showTaskTemplateModal: false,
      searchParam: '',
      bundleVendor: props.bundleCategoryVendor,
      selectedItemIds: [...props.selectedItemIds],
      associatedItemIds: [...props.selectedItemIds],
      displayAssociatableTemplate: false,
      selected: false,
      sortByAssociated: {
        column: props.defaultSortBy,
        reverse: false
      },
      sortByAssociatable: {
        column: props.defaultSortBy,
        reverse: false
      },
      associated: {
        pageNumber: '1',
        templates: this.props.templates,
        totalCount: props.associatedTemplatesCount 
      },
      associatable:{
        pageNumber: '1',
        templates: props.associatableTemplates,
        totalCount: props.associatableTemplatesCount
      },
      bundledTemplateItemId: ""
    }
  }

  handleAssociate(id, index) {
    const { associatable: { templates }, selectedItemIds } = this.state
    templates[index].checked = !templates[index].checked
    if(templates[index].checked) {
      selectedItemIds.push(id)
    } else {
      selectedItemIds.splice($.inArray(id, selectedItemIds), 1)
    }
    this.setState({
      associatable: {
        ...this.state.associatable,
        ...{ templates: templates }
      },
      selectedItemIds,
      selected: true
    })
  }

  changeAssociatedPageNumber(pageNumber) {
    const associated = {
      ...this.state.associated,
      pageNumber: pageNumber
    }
    this.setState({
      associated
    }, () => { this.fetchAssociatedData() })
  }

  fetchAssociatedData() {
    const { bundleVendor } = this.state
    const { column, reverse } = this.state.sortByAssociated
    const order = reverse ? "desc" : "asc"
    let url = `/admin/bundle_category_vendors/${bundleVendor.id}/associated_template_items`
    let data = {
      column,
      order,
      template: this.props.template,
      page: this.state.associated.pageNumber,
    }
    $.getJSON(url, data, (response) => {
      this.setState({
        associated: {
          ...this.state.associated,
          templates: response.associated_templates
        }
      })
    })
  }

  changeAssociatablePageNumber(pageNumber) {
    const associatable = {
      ...this.state.associatable,
      pageNumber: pageNumber
    }
    this.setState({
      associatable
    }, () => { this.fetchAssociatableData() })
  }

  fetchAssociatableData() {
    const {
      bundleVendor,
      selectedItemIds,
      sortByAssociatable: {
        column,
        reverse
      }
    } = this.state
    const order = reverse ? "desc" : "asc"
    let url = `/admin/bundle_category_vendors/${bundleVendor.id}/associatable_template_items`
    let data = {
      template: this.props.template,
      page: this.state.associatable.pageNumber,
      column,
      order
    }
    $.getJSON(url, data, (response) => {
      this.setState({
        associatable: {
          ...this.state.associatable,
          templates: response.items.map(item => {
            item.checked = selectedItemIds.indexOf(item.id) > -1
            return item
          })
        }
      })
    })
  }

  allItemsChecked() {
    const { associatable: { templates }, selectedItemIds } = this.state
    return selectedItemIds.length && templates.every(item => selectedItemIds.indexOf(item.id) >= 0)
  }

  handleSelectAll(e) {
    const { selectedItemIds } = this.state
    const items = this.state.associatable.templates.map(item => (item.id))
    const filteredItems = e.target.checked ? selectedItemIds.concat(items).uniq() : selectedItemIds.filter(item => items.indexOf(item) < 0)
    this.setState({
      selectedItemIds: filteredItems,
      associatable: {
        ...this.state.associatable,
        templates: this.state.associatable.templates.map((item) => ({
          ...item,
          ...{
            checked: e.target.checked
          }
        })),
      },
      selected: true
    })
  }

  handleSubmit() {
    const { selectedItemIds, bundleVendor, selected } = this.state
    this.setState({ displayAssociatableTemplate: true })

    if(selected === true) {
      const params = {
        bundle_category_vendor_id: bundleVendor.id,
        template: this.props.template,
        item_ids: selectedItemIds,
      }
      let method = "POST"
      let url = "/admin/bundle_category_vendors/associate_bundle_template"

      $.ajax({
        method: method,
        url: url,
        data: params,
        success: (response) => {
          if (response.success) {
            const associated = {
              ...this.state.associated,
              totalCount: response.total_associated_items,
              pageNumber: '1',
              templates: response.data
            }
            this.setState({
              associated,
              associatedItemIds: this.state.selectedItemIds
            })
            toastr.success(response.message)
          } else {
            toastr.error(response.message)
          }
          this.setState({ displayAssociatableTemplate: false, selected: false })
        }
      })
    }
  }

  closeAssociation() {
    const { associatedItemIds, associatable: { templates } } = this.state
    this.setState({
      displayAssociatableTemplate: false,
      selected: false,
      selectedItemIds: [...associatedItemIds],
      associatable: {
        ...this.state.associatable,
        templates: templates.map((item) => ({
          ...item,
          ...{ checked: associatedItemIds.indexOf(item.id) !== -1 }
        })),
      }
    })
  }

  sortAssociatedItems (column, className) {
    let sortByAssociated = this.state.sortByAssociated
    if (className.indexOf('sortable') >= 0) {
      if (sortByAssociated.column === column) {
        sortByAssociated.reverse = !sortByAssociated.reverse
      } else {
        sortByAssociated = {
          column,
          reverse: false
        }
      }
      this.setState (
        {sortByAssociated}, () => {
          this.fetchAssociatedData()
      })
    }
  }

  sortAssociatableItems(column, className) {
    let sortByAssociatable = this.state.sortByAssociatable
    if (className.indexOf('sortable') > -1) {
      if (sortByAssociatable.column === column) {
        sortByAssociatable.reverse = !sortByAssociatable.reverse
      } else {
        sortByAssociatable = {
          column,
          reverse: false
        }
      }
      this.setState (
        {sortByAssociatable}, () => {
          this.fetchAssociatableData()
      })
    }
  }

  renderCaret(sortBy, key, className) {
    if (sortBy.column === key && className.indexOf('sortable') > -1) {
    return sortBy.reverse ?
      (
        <i className="fa fa-sort-desc" aria-hidden="true"></i>
      ) :
      (
        <i className="fa fa-sort-asc" aria-hidden="true"></i>
      )
    }
    return null;
  }

  renderRow(item, headers, index){
    return (
      <tr key={ index } className="tr benefit-items-row fixed-table-header">
        { headers.map( (header, i) => this.renderData(item, header, i) ) }
      </tr>
    )
  }

  renderData(item, header, i){
    if(header.class === 'associate') {
      return(
        <td className={`table-data ${header.class}`} key={i}>
          <a className={`${header.key}`} onClick={() => this.showTaskListModal(item)}>
          { header.key }
          </a>
      </td>
      )
    } else {
      return (
        <td key={i} className={`table-data ${header.class}`}>{ item[header.key] }</td>
      )
    }
  }

  handleTaskTemplateModalClose = () => {
    this.setState({ showTaskTemplateModal: false })
  }

  showTaskTemplateModal() {
    const { bundleVendor, bundledTemplateItemId } = this.state
    return (
      <TaskList
        onModalClose={ this.handleTaskTemplateModalClose }
        bundleVendorId={ bundleVendor.id }
        bundledTemplateItemId={ bundledTemplateItemId }
      />
    )
  }

  showTaskListModal(item) {
    this.setState({ showTaskTemplateModal: true, bundledTemplateItemId: item.bundled_template_item_id })
  }

  renderAssociatedItems() {
    const { associated: { templates }, sortByAssociated } = this.state
    return(
      <div className="item-table">
        <table className="shared-benefit-table generic-table deletable-rows vendor-templates">
          <thead className="thead fixed-table-header bundle-vendor-thead">
            <tr className="tr">
              {
                this.props.headers.map(header => (
                  <th key={header.name} className={`table-title ${header.class}`} onClick={() => this.sortAssociatedItems(header.key, header.class)}>
                    <span className="title-text">{header.name}</span>
                    <span className="sort-caret">{ sortByAssociated ? this.renderCaret(sortByAssociated, header.key, header.class) : ''}</span>
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody className="bundle-vendor-tbody">
            {
              templates.map((item, index) => this.renderRow(item, this.props.headers, index))
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderAssociatableTasks() {
    const { associatable: { templates }, sortByAssociatable } = this.state
    const { headers } = this.props
    const filteredHeaders = headers.filter(header => (header.name != 'Associate'))

    return(
      <div className="item-table">
        <table className="shared-benefit-table generic-table deletable-rows vendor-templates">
          <thead className="thead fixed-table-header bundle-vendor-thead">
            <tr className="tr">
              <th>
                <input
                  type="checkbox"
                  style={{ height: '25px', width: '15px' }}
                  checked={ this.allItemsChecked() }
                  onChange={ (e) => this.handleSelectAll(e) }
                />
              </th>
              {
                filteredHeaders.map(header => (
                  <th key={header.name} className={`table-title ${header.class}`} onClick={() => this.sortAssociatableItems(header.key, header.class)}>
                    <span className="title-text">
                      {header.name}
                    </span>
                    <span className="sort-caret">
                      { sortByAssociatable ? this.renderCaret(sortByAssociatable, header.key, header.class) : ''}
                    </span>
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody className="bundle-vendor-tbody">
            {
              templates.map((item, index) => {
                return(
                  <tr key={ index }
                      className="tr benefit-items-row fixed-table-header"
                      >
                    <td className="associate-input-td">
                      <input
                        type="checkbox"
                        style={{ height: "25px", widht: "15px", margin: "0px" }}
                        checked={ item.checked }
                        value={ item.id }
                        onChange={ () => this.handleAssociate(item.id, index) }
                      />
                    </td>
                    {
                      filteredHeaders.map(header => (
                        <td key={header.name} className={`table-data ${header.class}`}>
                          { item[header.key] }
                        </td>
                      ))
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderAssociatedTasks() {
    const { selectedItemIds } = this.state
    return(
      <div>
        {
          selectedItemIds.length > 0 ?
          this.renderAssociatedItems() :
          <div><br/><br/>No Associated Templates</div>
        }
      </div>
    )
  }

  searchAssociatableTemplates(e) {
    e.preventDefault()
    const { bundleVendor } = this.state
    let query = $(e.currentTarget).find('input').val()
    this.setState({
      searchParam: query
    })
    let data = {
      q: query,
      template: this.props.template
    }
    let url = `/admin/bundle_category_vendors/${bundleVendor.id}/associatable_template_items`
    $.getJSON(url, data, (response) => {
      if(response.success) {
        this.setState({
          associatable:  {
            ...this.state.associatable,
            ...{ templates: response.items, totalCount: response.total_count }
          }
        })
      }
    })
  }

  renderSearchBox(){
    const { searchParam, displayAssociatableTemplate } = this.state
    if (displayAssociatableTemplate) {
      return (
        <form action='#' onSubmit={(e) => this.searchAssociatableTemplates(e)}>
          <div className='input-group' style={{ marginTop: '20px'}}>
            <input className='form-control' defaultValue={ searchParam } placeholder='Search Text'/>
            <span className='input-group-btn'>
              <button className='btn btn-default' type="submit">
                <i className='fa fa-search'/>
              </button>
            </span>
          </div>
        </form>
      )
    }
  }

  renderPagination(){
    const { displayAssociatableTemplate } = this.state
    if(displayAssociatableTemplate) {
      return (
        <div className="bundle-template" style={{ marginTop: '0px' }}>
          <Pagination
            totalItems={ this.state.associatable.totalCount }
            changePageNumber={ this.changeAssociatablePageNumber.bind(this) }
            pageNumber={ this.state.associatable.pageNumber }
            itemsPerPage={ 30 }
          />
        </div>
      )
    }
    else {
      return (
        <div className="bundle-template" style={{ marginTop: '0px' }}>
          <Pagination
            totalItems={ this.state.associated.totalCount }
            changePageNumber={ this.changeAssociatedPageNumber.bind(this) }
            pageNumber={ this.state.associated.pageNumber }
            itemsPerPage={ 30 }
          />
        </div>
      )
    }
  }

  renderCloseButton() {
    const { displayAssociatableTemplate } = this.state
    if(displayAssociatableTemplate) {
      return(
        <button className="btn btn-danger pull-right" style={{ marginRight: '10px' }} onClick={ () => this.closeAssociation() }>Cancel</button>
      )
    }
  }

  renderTaskTemplate(){
    const { displayAssociatableTemplate } = this.state
    if(displayAssociatableTemplate) {
      return this.renderAssociatableTasks()
    } else {
      return this.renderAssociatedTasks()
    }
  }

  renderTitle() {
    const { selectedItemIds } = this.state
    return(
      <h4>
        {this.props.title} { selectedItemIds.length > 0 ? <span>{`(${selectedItemIds.length} Items Selected)`}</span>: ''}
      </h4>
    )
  }

  render() {
    return (
      <div>
        <div className="admin-header fixed-header bundle-vendor-header">
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3">
              { this.renderTitle() }
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              { this.renderSearchBox() }
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              { this.renderPagination() }
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              <br/>
              <button className="btn btn-primary pull-right" onClick={ () => this.handleSubmit() }>Associate to Bundle</button>
              { this.renderCloseButton() }
            </div>
          </div>
        </div>
        { this.renderTaskTemplate() }
        { this.state.showTaskTemplateModal && this.showTaskTemplateModal() }
      </div>
    )
  }
}

export default TaskTemplates
