import React from 'react'
import SwitchInput from "../../shared/inputs/SwitchInput";


class ClaimSubmissionSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: props.submissionSettingDescription,
      allowClaimSubmission: props.allowClaimSubmission,
      disableButton: false,
    };
  }

  renderSubmissionSettingToggle() {
    return(
      <div className="row form-group">
        <div className="col-md-4 col-xs-6 col-sm-6 switch-label">
          <label htmlFor="allow_claim_submission">Claim Submissions :</label>
        </div>
        <div className="col-md-2 claim-submission-switch">
          <SwitchInput
            name="allow_claim_submission"
            checked={this.state.allowClaimSubmission}
            onChange={(checked) => this.handleSwitchInputChange(checked)}
            id="allow_claim_submission"
          />
        </div>
      </div>
    )
  }

  renderSubmissionSettingDescriptionField() {
    if(!this.state.allowClaimSubmission) {
      return (
        <div className="row form-group">
          <div className = "col-md-8 col-sm-12">
            <label>Enter optional message to display with disabled submission button</label>
            <textarea
              type="text"
              className="form-control"
              name="description"
              value={ this.state.description }
              onChange={ (e) => this.handleChange('description', e.target.value) }
            />
          </div>
        </div>
      )
    }
  }

  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  updateClaimSubmissionSetting(){
    let url = `/admin/companies/${this.props.companyId}/update_claims_submission_setting`;
    let params = { allow_claim_submission: this.state.allowClaimSubmission,
                   claim_submission_description: this.state.description};
    this.setState({ disableButton: true })
    $.ajax({
      method: "PUT",
      url: url,
      data: params,
      success: (response) => {
        this.setState({ disableButton: false })
        toastr.success(response.message)
      },
      error: (response) => {
        this.setState({ disableButton: false })
        toastr.error(response.message || response.error_message)
      },
    });
  }

  handleSwitchInputChange(checked) {
      this.setState({ allowClaimSubmission: checked})
  }

  render() {
    return (
      <div className='form-group claim-submission-settings-fields'>
        <div  className = 'col-md-8'>
          { this.renderSubmissionSettingToggle() }
          { this.renderSubmissionSettingDescriptionField() }
        </div>
        <div className=' col-md-4'>
          <button
            className="btn btn-primary btn-sm pull-right"
            onClick={() => this.updateClaimSubmissionSetting()}
            disabled={ this.state.disableButton }
          >Save</button>
        </div>
      </div>
    )
  }
}

export default ClaimSubmissionSettings;
