import React from "react";
import PropTypes from "prop-types";

function FileUploadTableModal(props) {
  function tableHead() {
    return (
      <thead>
        <tr>
          {props.tHead.map((head) => {
            return <th key={head}>{head}</th>;
          })}
        </tr>
      </thead>
    );
  }

  function tableBody() {
    return (
      <tbody>
        {Object.keys(props.tBody).map((key) => {
          return (
            <tr key={key}>
              <td> {key} </td>
              <td>
                {props.tBody[key].row_numbers &&
                  props.tBody[key].row_numbers.join(", ")}
              </td>
              <td> {props.tBody[key].msg} </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  function tableDetail() {
    return (
      <table className="table table-hover file-upload-table-modal">
        {(() => {
          if (Object.keys(props.tBody).length) {
            return [tableHead(), tableBody()];
          } else {
            return <p className="text-center">There are no Errors In File</p>;
          }
        })()}
      </table>
    );
  }

  return (
    <div className="react-modal employee-upload-file-errors-table">
      <div className="back-shadow"></div>
      <div className="modal-content react-modal-content">
        <div className="modal-header">
          <button
            className="close error-modal-close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.hideModal()}
          >
            ×
          </button>
          <h4 className="modal-title">
            Details For File {props.modalTitle}
          </h4>
        </div>
        <div className="modal-body">{tableDetail()}</div>
        <div className="modal-footer">
          <div className="buttons">
            <button
              className="btn btn-primary"
              onClick={() => props.hideModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FileUploadTableModal.propTypes = {
  tBody: PropTypes.object,
  tHead: PropTypes.array,
  hideModal: PropTypes.func,
  modalTitle: PropTypes.string,
};

export default FileUploadTableModal;
