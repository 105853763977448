import React from 'react'
import DashboardWeekLabels from './DashboardWeekLabels'
import DashboardMonthLabels from './DashboardMonthLabels'
class DashboardDateLabel extends React.Component {
  dateLabelComponent () {
    const components = {
      week: DashboardWeekLabels,
      month: DashboardMonthLabels,
      year: DashboardMonthLabels
    }
    return components[this.props.period]
  }

  renderDateLabelComponent () {
    const DateLabelComponent = this.dateLabelComponent()
    return (
      <DateLabelComponent
        first_date={this.props.first_date}
        label_dates={this.props.label_dates}
        changeData={this.props.changeData}
        leave_plan_id={this.props.leave_plan_id}
        periodDifference={this.periodDifference()}
        period={this.props.period}
        showNextNav={this.props.showNextNav}
        showPrevNav={this.props.showPrevNav}
      />
    )
  }

  periodDifference () {
    return this.props.period === 'year' ? 3 : 1
  }

  renderPeriodDiv (labelDate, index) {
    return (!this.props.week_view || index !== 0) ? (
      <div
        key={index}
        className='period-div'
        style={{width: labelDate.width}}
      >
        <div className='axis' />
      </div>
    ) : null
    
  }

  render () {
    return (
      <div className='milestone-date-label'>
        {this.renderDateLabelComponent()}
        <div className='label-line-div'>
          <div className='label-line-place'>
            <div style={{width: this.props.line_width}}>
              <div className='first axis' />
              {
                this.props.label_dates.map((labelDate, index) => this.renderPeriodDiv(labelDate, index))
              }
              <div className='label-line' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardDateLabel
