import React from 'react'
import PropTypes from 'prop-types'
import GenericTableData from '../components/genericTableData'
import TableHead from '../../../admin/shared_leave_plans/components/tableHead'
import ItemTableCheckbox from './ItemTableCheckbox'
import PlainModal from '../components/PlainModal'
import SubstitutionPreview from '../../substitutionPreview'

class TableGeneric extends React.Component {
  constructor () {
    super()
    this.state = {
      modal: {
        show: false
      },
      showPreview: false,
      substitutionId: null
    }
  }

  renderHead (head, cssClass = "", i) {
    return <TableHead
      head={head}
      cssClass={cssClass}
      key={i}
      sort={this.props.sort}
      sortBy={this.props.sortBy} />
  }

  isDisabled (disableAssociation) {
    return this.props.taskAssociation && disableAssociation
  }

  canAssociateInput (id, index, canAssociate, associate, status, disableAssociation, disabledReason) {
    return canAssociate ? (
      <td className="associate-input-td">
        <ItemTableCheckbox
          style={{
            height: 25,
            width: 15,
            margin: 0
          }}
          disabled={this.isDisabled(disableAssociation)}
          title={disabledReason}
          value={id}
          checked={associate}
          onChange={() => this.props.handleAssociate(id, index)}
        />
      </td>
    ) : null
  }

  showPlainModal (e, row) {
    let item = row.columns.filter(record => record).map (column => column.props.data).filter(record => record !== undefined && record.length > 0).filter(datum => datum[0].length == 3).flat()
    this.setState({
      modal: {
        show: true,
        item: item
      }
    })
    e.stopPropagation()
  }

  showSubstitutionPreview (e, substitutionId) {
    this.setState({
      showPreview: true,
      substitutionId: substitutionId
    })
    e.preventDefault()
    e.stopPropagation()
  }

  hideSubstitutionPreview (e) {
    this.setState({
      showPreview: false
    })
  }

  renderPrompt () {
    if (this.state.modal.show) {
      return (
        <PlainModal
          show={true}
          hideModalInParent={this.hidePrompt.bind(this)} >
          { this.getCompanyList(this.state.modal.item) }
        </PlainModal>
      )
    }
  }

  renderSubstitutionPreview () {
    if (this.state.showPreview) {
      return (<SubstitutionPreview closePreview={this.hideSubstitutionPreview.bind(this)} substitutionId={this.state.substitutionId}/>)
    }
  }

  hidePrompt () {
    this.setState({
      modal: {
        show: false,
        item: null
      }
    })
  }

  getCompanyList(item) {
    let url = window.location.pathname.includes('linked_leave_benefits') ? this.props.url : window.location.pathname
    return (
      <ul className="react-list">
        {
          item.map( (datum, index) => (
            <li key={index}> <a href={`${url}/${datum[2]}/edit?mode=view&company_id=${datum[0]}`}>{datum[1]}</a> </li>
          ))
        }
      </ul>
    )
  }

  renderRow (row, cssClasses, items, index, editUrl) {
    return (
      <tr key={index} className={`tr benefit-items-row fixed-table-header ${this.bulkItemClass()} ${this.props.paginationClass || ''}`}
        onClick={(e) => row.locked_item ? this.showPlainModal(e, row) : !this.props.taskAssociation && this.props.gotoLink(row.id, e, editUrl)}
      >
        {
          !row.locked_item && this.canAssociateInput (row.id, index, this.props.canAssociate, row.associate, row.columns[this.props.statusIndex].props.children, row.disableAssociation, row.disabledReason)
        }
        {
          row.columns.map ((column, i) => this.renderItem(column, i, cssClasses, row.id, row.showContentPreview, row.linkedBenefit))
        }
      </tr>
    )
  }

  renderItem (item, index, cssClasses, id, showContentPreview = false, linkedBenefit = false) {
    return <GenericTableData key={index} class={cssClasses[index].class} otherInfo={this.otherInfo(cssClasses, index, id, showContentPreview)} showPreview={this.showSubstitutionPreview.bind(this)} linkedBenefit={linkedBenefit}>{item}</GenericTableData>
  }

  otherInfo(cssClasses, index, id, showContentPreview) {
    if (!(['substitution_value', 'substitution_override'].indexOf(cssClasses[index].element) >= 0)) return {}
    if (!showContentPreview) return {}
    return {
      url: this.props.base_url,
      substitutionId: id,
      showContentPreview: showContentPreview
    }
  }

  bulkEditControl () {
    const allowBulkEdit = this.props.canBulkEdit && this.props.bulkItems.length
    return( allowBulkEdit ?
      <span>
        <div className="col-md-4 col-sm-4 col-xs-4">
          <button
            className="btn btn-success btn-sm"
            onClick={this.props.handleBulkEditModal}
          >
            Bulk Edit
          </button>
        </div>
      </span> : null
    )
  }

  bulkCopyControl () {
    const allowBulkCopy = this.props.canBulkCopy && this.props.bulkItems.length
    return ( allowBulkCopy ?
      <span>
        <div className="col-md-4 col-sm-4 col-xs-4">
          <button
            className="bulk-copy-btn btn btn-success btn-sm"
            onClick={this.props.copyBulkItems}
          >
            Bulk Copy
          </button>
        </div>
      </span> : null
    )
  }

  showClearBulkItems() {
    return (
      <span
        className="pull-right clear-items"
        onClick={() => this.props.clearBulkItems()}
      >
        Clear selections
      </span>
    )
  }

  showSelectedItems() {
    return (
     <span className="selected-items">
        Selected Items: { this.props.bulkItems.length }
      </span>
    )
  }

  bulkDeleteControl () {
    if(this.props.bulkItems) {
      const selectedItems = this.props.items.filter(item => {
        return this.props.bulkItems.indexOf(item.id) > -1
      })
      const isDraft = selectedItems.length ? selectedItems.every(obj => obj.status === 'draft') : false
      return this.props.canBulkDelete && this.props.bulkItems.length > 0 && isDraft ? (
        <div className="col-md-4 col-sm-4 col-xs-4">
          <button
            className="btn btn-danger btn-sm"
            onClick={this.props.handleBulkDeleteModal}
          >
            Bulk Delete
          </button>
        </div>
      ) : null
    }
  }

  allItemsChecked () {
    const { items, bulkItems } = this.props
    return bulkItems.length && items.filter(item => !item.locked_item).every(item => bulkItems.indexOf(item.id) >= 0)
  }

  bulkItemClass () {
    return !this.props.taskAssociation && this.props.bulkItems.length ?
      " bulk-item-on"
      : ""
  }

  selectAllInput () {
    return !(this.props.taskAssociation || this.props.tableBodyClass == 'substitutions-checklist' || this.props.searchAll || this.props.ipScenario)  ? (
      <th>
        <input
          type="checkbox"
          className=""
          style={{
            height: 25,
            width: 15
          }}
          checked={this.allItemsChecked()}
          onChange={this.handleAllSelect.bind(this)}
        />
      </th>
    ) : null
  }

  handleAllSelect (e) {
    this.props.handleAllSelect(e.target.checked)
  }

  showBulkEditRow() {
    if(!this.props.taskAssociation) {
      return (
        <div className={`row bulk-edit-control ${this.props.paginationClass || ''}`}>
          <div className="col-md-6 col-sm-6 col-xs-6">
            <span>
              {this.bulkEditControl()}
              {this.bulkCopyControl()}
            </span>
            <span>
              { this.bulkDeleteControl() }
            </span>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6">
            {
              this.props.bulkItems.length ? (
                <span>
                  { this.showSelectedItems() }
                  { this.showClearBulkItems() }
                </span>
              ) : null
            }
          </div>
        </div>
      )
    }
  }

  renderTable () {
    return this.props.items.length ? (
      <div>
        { this.renderPrompt() }
        { this.showBulkEditRow() }
        { this.renderSubstitutionPreview() }
        <table className={`shared-benefit-table generic-table deletable-rows fixed-table-header-${this.props.tableBodyClass}`}>
          <thead className={`thead fixed-table-header ${this.bulkItemClass()} ${this.props.paginationClass || ''}`}>
            <tr className="tr">
              {
                this.selectAllInput()
              }
              {
                this.props.heads.map( (head, i) => this.renderHead(head, this.props.classes[i] || "", i))
              }
            </tr>
          </thead>
          <tbody className={`${this.props.tableBodyClass}`}>
            {
              this.props.items.map( (row, i) => this.renderRow(
                row,
                this.props.classes,
                this.props.items,
                i,
                row.editUrl
              ))
            }
          </tbody>
        </table>
      </div>
      ) : this.props.taskAssociation ? (
        <span className="no-data">
          No Associated {this.props.associationWith}s
        </span>
      ) :
      (
        <span className="no-data">
          No data to show
        </span>
      )
  }

  render () {
    return this.renderTable()
  }
}

TableGeneric.propTypes = {
  heads: PropTypes.array,
  items: PropTypes.array,
  classes: PropTypes.array,
  gotoLink: PropTypes.func,
  sort: PropTypes.func,
  sortBy: PropTypes.object,
  statusIndex: PropTypes.number,
  tableBodyClass: PropTypes.string,
  canAssociate: PropTypes.bool,
  handleAssociate: PropTypes.func,
  taskAssociation: PropTypes.bool,
  searchAll: PropTypes.bool,
}

export default TableGeneric;
