import React from 'react'

class Selectpicker extends React.Component {
  constructor (props) {
    super (props)
  }

  componentDidMount () {
    this.initializeSelectPicker()
  }

  componentDidUpdate () {
    this.initializeSelectPicker()
  }

  initializeSelectPicker () {
    $(`.select-picker[name="${this.props.name}"]`).selectpicker('refresh')
                                                .off('change.SelectpickerValue')
                                                .on('change.SelectpickerValue', (e) => {
                                                  this.props.onChange && this.props.onChange($(e.target).val())
                                                })
  }

  additionalClass () {
    return this.props.className ?
      this.props.className : ''
  }

  buildTitle() {
    const { multiSelect, defaultValue } = this.props
    let showTitle = true
    if (defaultValue) {
      if (multiSelect) {
        showTitle = defaultValue.length === 0
      } else {
        showTitle = String(defaultValue).trim().length === 0
      }
    }
    if (showTitle) {
      return { title: this.props.placeholder || 'Select' }
    }
    return {}
  }

  renderOption(option) {
    return (
      <option
        key={option[1]}
        value={option[1]}
      >
        {option[0]}
      </option>
    )
  }

  render () {
    return (
      <div>
      { this.props.multiSelect && <input disabled={this.props.disabled} name={this.props.name.concat("[]")} type="hidden"/> }
      <select
        className={`select-picker form-control ${this.additionalClass()}`}
        data-live-search={this.props.liveSearch || 'true'}
        data-actions-box={this.props.multiSelect && this.props.actionsBox !== 'false'}
        name={this.props.name}
        defaultValue={this.props.defaultValue}
        disabled={this.props.disabled}
        multiple={this.props.multiSelect}        
        { ...this.buildTitle() }
      >
        { this.props.optionsForSelect.map(option => this.renderOption(option)) }
      </select>
    </div>
    )
  }
}

export default Selectpicker;
