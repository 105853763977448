import React from 'react'
import PropTypes from 'prop-types'
import PlainModal from './PlainModal'

class ExtraList extends React.Component {
  constructor () {
    super ()

    this.state = {
      modal: {
        show: false
      }
    }
  }

  showPrompt (e) {
    this.setState({
      modal: {
        show: true
      }
    })
    e.stopPropagation()
  }

  hidePrompt () {
    this.setState({
      modal: {
        show: false
      }
    })
  }

  getList (data) {
    return (
      <ul className="react-list">
        {
          data.map( (datum, index) => (
            (datum.length == 3) ? <li key={index}> <a href={`${window.location.pathname}/${datum[2]}/edit?mode=view&company_id=${datum[0]}`}>{datum[1]}</a> </li> : <li key={index}> {datum} </li>
          ))
        }
      </ul>
    )
  }

  renderPrompt (data) {
    if (this.state.modal.show) {
      return (
        <PlainModal
          show={true}
          hideModalInParent={this.hidePrompt.bind(this)} >
          { this.getList(data) }
        </PlainModal>
      )
    }
  }

  renderList (data) {
    return (
      <li>
        <span className="link" onClick={(e) => this.showPrompt(e)}>
          {`and ${data.slice(2).length} more`}
        </span>
        {this.renderPrompt(this.props.data)}
      </li>
    )
  }

  render () {
    return (
      this.renderList(this.props.data)
    )
  }
}

ExtraList.propTypes = {
  data: PropTypes.array
}

export default ExtraList
