import React, { useEffect, useState, useRef } from "react";
import Selectpicker from '../../shared/inputs/selectpicker'
import Datepicker from '../../shared/inputs/datepicker'
import Input from '../input'
import Pagination from '../pagination/pagination'
const BenefitHeaders = ['Shares Max Duration', 'Leave Benefit', 'ID', 'Reorder', 'Actions']
const BenefitTemplateHeaders = ['Shares Max Duration', 'Leave Benefit Template', 'Template ID', 'Reorder', 'Actions']
const BenefitModalHeaders = ['Leave Benefit Name', 'Benefit ID', 'Theme', 'Updated At']
const BenefitTemplateModalHeaders = ['Leave Benefit Template Name', 'Template ID', 'Theme', 'Updated At']
const durationUnits = [['Calendar Days', 'days'], ['Work Days', 'work_days'], ['Work Weeks', 'weeks'], ['Months', 'months']]

const LinkedBenefitTemplateForm = (props) => {
  const [name, setName] = useState('')
  const modalRef = useRef(null);
  const warningModalRef = useRef(null);
  const searchTextRef = useRef(null);
  const [status, setStatus] = useState('')
  const [effectiveStartDate, setEffectiveStartDate] = useState('')
  const [effectiveEndDate, setEffectiveEndDate] = useState('')
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [templates, setTemplates] = useState([])
  const [isFetchingLeaveScenarios, setIsFetchingLeaveScenarios] = useState(false)
  const [leaveScenarios, setLeaveScenarios] = useState([])
  const [sharedMaxDurations, setSharedMaxDurations] = useState([])
  const [leaveBenefitScenarios, setLeaveBenefitScenarios] = useState([])
  const [showBenefits, setShowBenefits] = useState(false)
  const [showSharedMaxDuration, setShowSharedMaxDuration] = useState(false)
  const [currentSharedMaxDuration, setCurrentSharedMaxDuration] = useState({})
  const [templateSearchName, setSearchName] = useState('')
  const [disableEdit, setDisableEdit] = useState(false || props.disableSave)
  const [templatePagination, setTemplatePagination] = useState({
    page: 1,
    perPage: 10,
    totalItems: 0
  })
  const [linkedBenefits, setLinkedBenefits] = useState([])
  const title = props.basePath == '/admin/linked_benefit_templates' ? 'Leave Benefit Templates' : 'Leave Benefits'
  const [originalLinkedBenefits, setOriginalLinkedBenefits] = useState([])
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [warningMessage, setWarningMessage] = useState({})

  useEffect(() => {
    fetchTemplates()
    if (props.id) {
      fetchLinkedBenefitDetails()
    }
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keyup", handleKeyUp)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [])

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target) && e.target.id !== 'add_benefit_template') {
      setShowBenefits(false)
    }
    if (warningModalRef.current && !warningModalRef.current.contains(e.target) && e.target.id !== 'saveLinkedBenefit') {
      setShowWarningModal(false)
    }
  };

  const handleKeyUp = (e) => {
    if ($('#wrapper_benefits_modal').length < 1) return ;
    let searchText = ($('#wrapper_benefits_modal').length > 0) && searchTextRef.current?.value || templateSearchName
    if ((e.key == 'Enter' && searchText !== '') || (e.key == 'Backspace' && searchText == '')) {
      fetchTemplates(1, true)
    }
  };

  const fetchTemplates = (pageNumber = 1, newSearch = false) => {
    let searchText = ($('#wrapper_benefits_modal').length > 0) && searchTextRef.current?.value || templateSearchName
    let companyId = !!props.companyId ? `&company_id=${props.companyId}` : ''
    let url = searchText != '' ? `/fetch_benefits?q=${searchText}&page=${pageNumber}${companyId}` : `/fetch_benefits?page=${pageNumber}${companyId}`
    url = `${props.basePath}${url}`
    $.getJSON(url, (response) => {
      setTemplates(response.data)
      if (newSearch){
        setTemplatePagination((prev) => ({...prev, totalItems: response.total_items, page: 1 }))
      }else {
        setTemplatePagination((prev) => ({...prev, totalItems: response.total_items }))
      }
    })
  }

  const fetchLeaveScenarios = () => {
    const benefitIdsSharingMSD = linkedBenefits.reduce((acc, lb) => {
      if (lb.shares_max_duration) {
        acc.push(lb.leave_benefit_id)
      }
      return acc
    }, [])

    if (benefitIdsSharingMSD.length == 0) return;

    setIsFetchingLeaveScenarios(true)
    const data = {
      benefit_ids: benefitIdsSharingMSD
    }

    let companyId = !!props.companyId ? `?company_id=${props.companyId}` : ''
    const url = `${props.basePath}/fetch_leave_scenarios${companyId}`
    $.getJSON(url, data, (response) => {
      setLeaveScenarios(response.data)
      setIsFetchingLeaveScenarios(false)
    })
  }

  const fetchLinkedBenefitDetails = () => {
    setIsFetchingData(true)
    let companyId = !!props.companyId ? `?company_id=${props.companyId}` : ''
    const url = `${props.basePath}/${props.id}${companyId}`
    $.getJSON(url, (response) => {
      const data = response.data
      setName(data.name)
      setStatus(data.status)
      setEffectiveStartDate(data.effective_start_date)
      setEffectiveEndDate(data.effective_end_date)
      setLinkedBenefits(data.linked_benefits)
      setOriginalLinkedBenefits(data.linked_benefits.map((lb,index) => lb.leave_benefit_id))
      setSharedMaxDurations(data.max_shared_durations)
      setIsFetchingData(false)
      setDisableEdit(data.disable_edit)
    })
  }

  const fetchLeaveBenefitScenarios = (leaveScenarioIds) => {
    const data = {
      leave_scenario_ids: leaveScenarioIds,
      leave_benefit_ids:
        linkedBenefits
          .filter(lb => !lb._destroy && lb.shares_max_duration)
          .map(({leave_benefit_id}) => leave_benefit_id)
    }
    const url = `${props.basePath}/fetch_leave_benefit_scenarios`
    $.getJSON(url, data, (response) => {
      setLeaveBenefitScenarios(response.data)
    })
  }

  const renderSharedMaxDurationForm = () => {
    if (!showSharedMaxDuration) return;

    const onClose = () => {
      setShowSharedMaxDuration(false)
      setLeaveBenefitScenarios([])
    }

    const changeLeaveScenario = (leaveScenarioId) => {
      const isArray = leaveScenarioId instanceof Array
      const scenarioIds = isArray ? leaveScenarioId : Array(leaveScenarioId)
      const scenarios = leaveScenarios.reduce((acc, scenario) => {
        if (scenarioIds.includes(String(scenario.id))) {
          acc.push({
            id: scenario.id,
            name: scenario.name
          })
        }
        return acc
      }, [])

      setCurrentSharedMaxDuration({
        ...currentSharedMaxDuration,
        scenarios
      })

      fetchLeaveBenefitScenarios(scenarioIds)
    }

    const renderScenarioDetail = (label, value) => {
      return (
        <div>
          <span>{`${label}:`}</span>
          <span style={{marginLeft: 5, fontWeight: 600}}>{value}</span>
        </div>)
    }

    const renderLeaveBenefitScenario = (benefitScenario, index) => {
      const benefitTitle = props.basePath == '/admin/linked_benefit_templates' ? 'Leave Benefit Template' : 'Leave Benefit'
      return (
        <div key={index} style={{marginLeft: 5, marginBottom: 10, marginTop: 10}}>
          <div>
            <span>{`${benefitTitle} Name`}</span>
            <span style={{fontWeight: 600, marginLeft: 5, marginRight: 5}}>{benefitScenario.leave_benefit_name}</span>
            <span>{`[${benefitScenario.leave_benefit_id}]`}</span>
          </div>
          <div style={{marginLeft: 20}}>
            { renderScenarioDetail(`${benefitTitle} Status`, benefitScenario.leave_benefit_status) }
            { renderScenarioDetail('Leave Benefit Scenario Name', benefitScenario.name) }
            { renderScenarioDetail('Leave Benefit Scenario Status', benefitScenario.status) }
            { renderScenarioDetail('Leave Benefit Scenario Effective Start Date', benefitScenario.effective_start_date) }
            { renderScenarioDetail('Leave Benefit Scenario Effective End Date', benefitScenario.effective_end_date) }
          </div>
        </div>
      );
    }

    const handleInputChange = (key, value) => {
      setCurrentSharedMaxDuration({
        ...currentSharedMaxDuration,
        [key]: value
      })
    }

    const handleSubmit = () => {
      let durations = []
      const {scenarios, ...currentDuration} = currentSharedMaxDuration
      const currentDurations = scenarios.map(scenario => ({
        ...currentDuration,
        leave_scenario_id: scenario.id,
        leave_scenario_name: scenario.name
      }))
      if (currentSharedMaxDuration.isEdit) {
        durations = sharedMaxDurations.map((duration, index) => {
          if (index == currentSharedMaxDuration.index) {
            return JSON.parse(JSON.stringify(currentDurations[0]))
          }
          return duration
        })
      } else {
        durations = [
          ...sharedMaxDurations,
          ...JSON.parse(JSON.stringify(currentDurations))
        ]
      }
      setSharedMaxDurations(durations)
      onClose()
    }

    const benefitConfigurations = currentSharedMaxDuration.benefit_configurations || []
    const addBenefitConfiguration = () => {
      setCurrentSharedMaxDuration({
        ...currentSharedMaxDuration,
        benefit_configurations: [
          ...benefitConfigurations,
          {
            leave_benefit_id: '',
            max_duration: '',
            max_duration_unit: '',
            min_duration: '',
            min_duration_unit: ''
          }
        ]
      })
    }

    const setLeaveBenefitConfiguration = (changingConfigurationIndex, key, value) => {
      setCurrentSharedMaxDuration({
        ...currentSharedMaxDuration,
        benefit_configurations: benefitConfigurations.map((config, index) => {
          if (index == changingConfigurationIndex) {
            config[key] = value
          }
          return config
        })
      })
    }

    const removeConfiguration = (removingConfigurationIndex) => {
      setCurrentSharedMaxDuration({
        ...currentSharedMaxDuration,
        benefit_configurations: benefitConfigurations.filter((_, index) => index !== removingConfigurationIndex)
      })
    }

    const renderBenefitConfiguration = (configuration, index) => {
      const usedBenefitIds = benefitConfigurations.map(({leave_benefit_id}) => leave_benefit_id)
      const leaveBenefitOptions = linkedBenefits.filter(benefit => (
        benefit.leave_benefit_id == configuration.leave_benefit_id ||
        usedBenefitIds.indexOf(benefit.leave_benefit_id) < 0
      ))
      return (
        <div key={index} className="row" style={{display: 'grid', margin: 10, borderWidth: 1, borderRadius: 10, borderStyle: 'solid', padding: 10}}>
          <div style={{justifySelf: 'flex-end', cursor: 'pointer'}} onClick={() => removeConfiguration(index)}>X</div>
          <div className="col-xs-12">
            <div><b>Select a Benefit</b></div>
            <div className="row">
              <div className="col-xs-12">
                <Selectpicker
                  className="select-picker form-control"
                  name="leave_benefit_id"
                  data-live-search='true'
                  defaultValue={configuration.leave_benefit_id}
                  onChange={(leaveBenefitId) => setLeaveBenefitConfiguration(index, 'leave_benefit_id', Number(leaveBenefitId))}
                  optionsForSelect={leaveBenefitOptions.map((benefit) => [`${benefit.name} [${benefit.leave_benefit_id}]`, benefit.leave_benefit_id])}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12" style={{marginTop: 10}}>
            <div className="row">
              <div className="col-xs-6">
                <Input
                  label="Minimum Duration:"
                  type="number"
                  onChange={(e) => setLeaveBenefitConfiguration(index, 'min_duration', e.target.value)}
                  className="form-control form-control-sm"
                  value={configuration.min_duration}
                  min="0"
                />
              </div>
              <div className="col-xs-6">
                <div className="row">
                  <div className="col-xs-6"><b>Minimum Duration Unit:</b></div>
                  <div className="col-xs-6">
                    <Selectpicker
                      className="select-picker form-control"
                      name="min_duration_unit"
                      data-live-search='true'
                      defaultValue={configuration.min_duration_unit}
                      onChange={(amountUnit) => setLeaveBenefitConfiguration(index, 'min_duration_unit', amountUnit)}
                      optionsForSelect={durationUnits}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="row">
              <div className="col-xs-6">
                <Input
                  label="Maximum Duration:"
                  type="number"
                  onChange={(e) => setLeaveBenefitConfiguration(index, 'max_duration', e.target.value)}
                  className="form-control form-control-sm"
                  value={configuration.max_duration}
                  min="0"
                />
              </div>
              <div className="col-xs-6">
                <div className="row">
                  <div className="col-xs-6"><b>Maximum Duration Unit:</b></div>
                  <div className="col-xs-6">
                    <Selectpicker
                      className="select-picker form-control"
                      name="max_duration_unit"
                      data-live-search='true'
                      defaultValue={configuration.max_duration_unit}
                      onChange={(amountUnit) => setLeaveBenefitConfiguration(index, 'max_duration_unit', amountUnit)}
                      optionsForSelect={durationUnits}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const renderLeaveBenefitScenarios = () => {
      if (leaveBenefitScenarios.length == 0) return;

      return (<div style={{marginTop: 20}}>
        <div><b>Leave Benefit Scenario Details</b></div>
        { leaveBenefitScenarios.map(renderLeaveBenefitScenario) }
      </div>)
    }

    const renderLeaveScenariosSelectpicker = () => {
      if (isFetchingLeaveScenarios) return;

      return (
        <Selectpicker
          className="select-picker form-control"
          name="leave_scenario_id"
          data-live-search='true'
          multiSelect={!currentSharedMaxDuration.isEdit}
          defaultValue={currentSharedMaxDuration.leave_scenario_id}
          onChange={(leaveScenarioId) => changeLeaveScenario(leaveScenarioId)}
          optionsForSelect={leaveScenarios.map((scenario) => [scenario.name, scenario.id])}
        />)
    }

    return (
      <div className="react-modal list-modal">
        <div className="back-shadow" onClick={onClose} />
        <div className="modal-content react-modal-content" style={{width: 800}}>
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={onClose}
            >×</button>
            <h4 className="modal-title">Shared Maximum Duration</h4>
          </div>
          <div className="modal-body">
            <div>
              <div><b>Leave Benefit Scenario</b></div>
              { renderLeaveScenariosSelectpicker() }
              {!isFetchingLeaveScenarios && leaveScenarios.length < 1  ? <div style={{ color: 'red', paddingTop: '6px'}}>The selected benefits do not have a Leave Benefit Scenario in common; Shared Maximum Duration cannot be configured.</div> : null}
            </div>
            { renderLeaveBenefitScenarios() }
            <div className="form-group row" style={{marginTop: 35, display: 'flex', alignItems: 'center'}}>
              <div className="col-sm-6 col-form-label col-form-label-sm">
                <b>Shared Max Duration Amount:</b>
              </div>
              <div className="col-sm-4">
                <input
                  style={{width: '100%', height: 34}}
                  type="number"
                  value={currentSharedMaxDuration.amount || ''}
                  onChange={(e) => handleInputChange('amount', e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row" style={{display: 'flex', alignItems: 'center'}}>
              <div className="col-sm-6 col-form-label col-form-label-sm"><b>Max Amount Unit:</b></div>
              <div className="col-sm-4">
                <Selectpicker
                  className="select-picker form-control"
                  name="amount_unit"
                  data-live-search='true'
                  defaultValue={currentSharedMaxDuration.amount_unit}
                  onChange={(amountUnit) => handleInputChange('amount_unit', amountUnit)}
                  optionsForSelect={durationUnits}
                />
              </div>
            </div>

            <div>
              <div style={{cursor: 'pointer', marginTop: 20, marginLeft: 10}} onClick={addBenefitConfiguration}>
                <i className="fa fa-plus-circle" style={{marginRight: 10}} />
                Add Shared Max Duration Configuration
              </div>
              { benefitConfigurations.map(renderBenefitConfiguration) }
            </div>
            <div style={{marginTop: 10, display: 'flex', justifyContent: 'flex-end'}}>
              <div className="btn btn-danger" onClick={onClose}>
                Cancel
              </div>
              <div style={{marginLeft: 30}} className="btn btn-primary" onClick={handleSubmit} id='saveLinkedBenefit'>
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderAddMaxSharedButton = () => {
    if (linkedBenefits.filter(benefit => benefit.shares_max_duration).length < 2) return;

    return (
      <div
        onClick={() => {
          if (disableEdit) return;
          setCurrentSharedMaxDuration({})
          fetchLeaveScenarios()
          setShowSharedMaxDuration(true)
        }}
        style={{cursor:'pointer'}}
      >
        <i className="fa fa-plus-circle" />
        Add Shared Max Duration
      </div>
    )
  }

  const deleteSharedMaxDuration = (deletingDurationIndex) => {
    const sharedDurations = sharedMaxDurations.reduce((acc, duration, index) => {
      if (index == deletingDurationIndex) {
        if (duration.id) {
          acc.push({id: duration.id, _destroy: '1'})
        }
      } else {
        acc.push(duration)
      }
      return acc
    }, [])
    setSharedMaxDurations(sharedDurations)
  }

  const editSharedMaxDuration = (sharedDuration, index) => {
    setCurrentSharedMaxDuration({
      ...sharedDuration,
      scenarios: [{id: sharedDuration.leave_scenario_id, name: sharedDuration.leave_scenario_name}],
      index,
      isEdit: true
    })
    fetchLeaveScenarios()
    setShowSharedMaxDuration(true)
    fetchLeaveBenefitScenarios([sharedDuration.leave_scenario_id])
  }

  const renderSharedMaxDuration = (sharedDuration, index) => {
    return (
      <tr key={index}>
        <td>{sharedDuration.leave_scenario_name}</td>
        <td>{`${sharedDuration.amount} ${sharedDuration.amount_unit}`}</td>
        <td>{sharedDuration.updated_at}</td>
        <td></td>
        <td>
          <i className="fa fa-edit" style={{cursor: 'pointer'}} onClick={() => editSharedMaxDuration(sharedDuration, index)} />
          <i className="fa fa-trash" style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => { if (disableEdit) return; deleteSharedMaxDuration(index)}} />
        </td>
      </tr>
    )
  }

  const saveChainedBenefits = () => {
    let url = props.basePath
    let method = 'POST'
    const params = {
      name,
      status,
      effective_start_date: effectiveStartDate,
      effective_end_date: effectiveEndDate,
      linked_benefits_attributes: linkedBenefits.map((benefit, position) => {
        return {
          id: benefit.id,
          leave_benefit_id: benefit.leave_benefit_id,
          shares_max_duration: benefit.shares_max_duration,
          _destroy: benefit._destroy,
          position
        }
      }),
      max_shared_durations_attributes: sharedMaxDurations
    }

    if (props.id) {
      url = `${props.basePath}/${props.id}`
      method = 'PUT'
    }
    $.ajax({
      contentType: 'application/json',
      method: method,
      url: url,
      data: JSON.stringify(params),
      success: (response) => {
        if (response.success) {
          location.href = props.basePath
        } else {
          toastr.error(response.message);
        }
      }
    })

  }

  const saveLinkedBenefits = () => {
    if (linkedBenefits.length < 2) {
      let templateError = "Linked Leave Benefit Template chain must contain two (2) or more Benefit Templates"
      let compError = "Linked Leave Benefit chain must contain two (2) or more Leave Benefits."
      let error = props.basePath.includes("linked_benefit_templates") ? templateError : compError
      toastr.error(error);
      return
    }

    let newLinkedBenefits = props.basePath.includes('template') && linkedBenefits.map((lb,index) => lb.leave_benefit_id).filter((lb) => !originalLinkedBenefits.includes(lb)) || []
    let url = props.basePath

    if (props.id && newLinkedBenefits.length){
      url = `${props.basePath}/${props.id}/linked_benefits_warning`
      $.ajax({
        contentType: 'application/json',
        method: 'POST',
        url: url,
        data: JSON.stringify({'benefit_ids': newLinkedBenefits}),
        success: (response) => {
          if (response.success) {
            if (detectWarningMessage(response.data)){
              setWarningMessage(response.data)
              setShowWarningModal(true)
            } else {
              saveChainedBenefits()
            }
          } else {}
        }
      })
    } else {
      saveChainedBenefits()
    }
  }

  const detectWarningMessage = (message) => {
    let hasMessage = Object.keys(message).filter((benefit) => message[benefit]['linkable'].length || message[benefit]['non_linkable'].length)
    return hasMessage.length > 0
  }

  const renderNameInput = () => {
    return (
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row" style={{display: 'flex', alignItems: 'center'}}>
            <div className="col-xs-2">
              <label htmlFor="name:-status" className="col-form-label col-form-label-sm">
                Link Name:
              </label>
            </div>
            <div className="col-xs-10">
              <input
                type="text"
                value={name}
                className="form-control form-control-sm"
                onChange={(e) => setName(e.target.value)}
                style={{width: '400px'}}
                required={true}
              />
            </div>
          </div>
        </div>
      </div>)
  }

  const renderLinkId = () => {
    if (!props.id) return;

    return (
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row" style={{display: 'flex', alignItems: 'center'}}>
            <div className="col-xs-2">
              <span><b>Link ID:</b></span>
            </div>
            <div className="col-xs-10">
              {props.id}
            </div>
          </div>
        </div>
      </div>)
  }

  const renderStatusInput = () => {
    const renderStatusSelectpicker = () => {
      if (isFetchingData) return;

      return (
        <Selectpicker
          className="select-picker form-control"
          name="status"
          data-live-search='true'
          defaultValue={status}
          onChange={(value) => setStatus(value)}
          optionsForSelect={[['Draft', 'draft'], ['In Review', 'in_review'], ['Active', 'active'], ['Retired', 'retired']]}
          required={true}
        />)
    }

    return (
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row" style={{display: 'flex', alignItems: 'center'}}>
            <div className="col-xs-2">
              <label htmlFor="status:-input" className="col-form-label col-form-label-sm">Status:</label>
            </div>
            <div className="col-xs-10">
              <div style={{width: 400}}>
                { renderStatusSelectpicker() }
              </div>
            </div>
          </div>
        </div>
      </div>)
  }

  const renderEffectiveDate = (effectiveDateType) => {
    let label = 'Effective Start Date:'
    let setState = setEffectiveStartDate
    let date = effectiveStartDate
    let required = true

    if (effectiveDateType == 'effectiveEndDate') {
      label = 'Effective End Date:'
      setState = setEffectiveEndDate
      date = effectiveEndDate
      required = false
    }

    return (
      <div className='form-group col-sm-4'>
        <div className="row" style={{display: 'flex', alignItems: 'center'}}>
          <div className="col-xs-4">
            <label className='label-control'>{label}</label>
          </div>
          <div className="col-xs-6">
            <Datepicker
              name={effectiveDateType}
              defaultValue={date}
              onChange={(value) => setState(value)}
              placeholder={gon.user_preferred_date_format_string}
              typeable={false}
              required={required}
            />
          </div>
        </div>
      </div>)
  }

  const renderWrapperInputs = () => {
    return (
      <>
        { renderNameInput() }
        { renderLinkId() }
        { renderStatusInput() }
        <div className="row">
          { renderEffectiveDate('effectiveStartDate') }
          { renderEffectiveDate('effectiveEndDate') }
        </div>
      </>)
  }

  const headers = () => {
    return (
      <div>
        <div className="header-buttons">
          <h3 className="wrapper-header">{props.headerName || ''}</h3>
          <div className="wrapper-buttons">
            <button className="cancel btn btn-danger" type='reset' onClick={() => {window.location = props.basePath}}>
              Cancel
            </button>
            <button className="submit btn btn-primary" type='submit' onClick={saveLinkedBenefits} disabled={disableEdit}>
              Save
            </button>
          </div>
        </div>
        <hr style={{marginTop: "-1px", border: "1px dashed #000;"}}/>
      </div>
    )
  }

  const renderLinkedBenefitsTable = () => {
    let headers = props.basePath == '/admin/linked_benefit_templates' ? BenefitTemplateHeaders : BenefitHeaders
    let lastBenefitIndex = linkedBenefits.reduce((acc, lb, index) => {
      if (!lb._destroy) {
        acc = index
      }
      return acc
    }, 0);
    return (
      <table className="table table-striped-even table-responsive" id="wrapper_benefits">
        <thead>
          <tr className="active">
            {
              headers.map((header, index) => {
                return <th key={index}>{header}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            linkedBenefits.reduce((acc, lb, index) => {
              if (lb._destroy) return acc;

              const downIcon = (<i
                onClick={() => moveLinkedBenefitToBottom(index)}
                className="fa fa-arrow-down"
                style={{cursor: 'pointer'}}
              />)
              acc.push(<tr key={index}>
                <td>
                  <input
                    type='checkbox'
                    checked={!!lb.shares_max_duration}
                    onChange={() => { if (disableEdit) return; toggleSharesMaxDuration(lb) }}
                  />
                </td>
                <td>{lb.name}</td>
                <td>{lb.leave_benefit_id}</td>
                <td>{index < lastBenefitIndex ? downIcon : null}</td>
                <td style={{cursor:'pointer'}}><i className="fa fa-trash" onClick={() => {if (disableEdit) return; deleteLinkedBenefit(lb.leave_benefit_id)}}></i></td>
              </tr>)

              return acc;
            }, [])
          }
        </tbody>
      </table>)
  }

  const renderMaxDurationsTable = () => {
    const durations = sharedMaxDurations.filter(duration => !duration._destroy)
    return (
      <table className="table table-striped-even table-responsive">
        <thead>
          <tr className="active">
            <th>Scenario</th>
            <th>Shared Max Duration</th>
            <th>Updated At</th>
            <th>Updated By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { durations.map(renderSharedMaxDuration) }
        </tbody>
      </table>)
  }

  const listBenefitsModal = () => {
    if (!showBenefits) return null;

    return (
      <div className="react-modal" id="wrapper_benefits_modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content" style={{width: 800}} ref={modalRef}>
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ () => setShowBenefits(false) }
            >×</button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body">
            <div className="template-bar">
              <div className="search-bar">
                <input
                  type={"text"}
                  id={"template-search"}
                  style={{height: '34px'}}
                  value={templateSearchName || ''}
                  ref={searchTextRef}
                  onChange={(e) => {
                  setSearchName(e.target.value)
                }}
                />
                <span className="input-group-btn" onClick = {() => {fetchTemplates(1, true)}}>
                  <button className="btn btn-default">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </div>
              <div>
              <Pagination
                totalItems={templatePagination.totalItems}
                changePageNumber={(pageNumber) =>{
                  setTemplatePagination((prev) => ({...prev, page: pageNumber}))
                  fetchTemplates(pageNumber)
                }
                }
                pageNumber={templatePagination.page}
                itemsPerPage={10}
                leftBound={-1}
                rightBound={1}
              />
              </div>
            </div>
            {templateLists()}
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </div>
    )
  }

  const renderLinkedBenefits = () => {
    let header = props.basePath == '/admin/linked_benefit_templates' ? 'Add Leave Benefit Template' : 'Add Leave Benefit'
    return (
      <>
        <div style={{display: "flex", flexDirection:"row", justifyContent: 'space-between'}}>
          <div>{title}</div>
          <div id='add_benefit_template' onClick={() => {if (disableEdit) return; setShowBenefits(true) }} style={{cursor:'pointer'}}><i className="fa fa-plus-circle"></i>{header}</div>
        </div>
        {renderLinkedBenefitsTable()}
      </>
    )
  }

  const renderMaxDurations = () => {
    return (
      <div style={{marginTop: 80}}>
        <div style={{display: "flex", flexDirection:"row", justifyContent: 'space-between'}}>
          <div>Shared Max Durations</div>
          { renderAddMaxSharedButton() }
        </div>
        {renderMaxDurationsTable()}
      </div>
    )
  }

  const templateLists = () => {
    let headers = props.basePath == '/admin/linked_benefit_templates' ? BenefitTemplateModalHeaders : BenefitModalHeaders
    return (
      <table className="table table-responsive" id="wrapper_benefits">
        <thead>
          <tr className="active">
            {
              headers.map((header, index) => {
                return <th>{header}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            templates.map((template,index) => {
              return (
                <tr onClick={() => { linkedToWrapper(template); setShowBenefits(false) } } style={{cursor:'pointer'}}>
                  <td style={{width: '400px'}}>{template.name}</td>
                  <td>{template.id}</td>
                  <td>{template.theme}</td>
                  <td>{template.updated_at}</td>
                </tr>
              )
            })}
        </tbody>
      </table>)
  }

  const linkedToWrapper = (benefit) => {
    let hasLinkedAlready = linkedBenefits.find((lb) => lb.leave_benefit_id == benefit.id)
    if (hasLinkedAlready) return;
    setLinkedBenefits(prevState => [...prevState, {leave_benefit_id: benefit.id, name: benefit.name}])
  }

  const moveLinkedBenefitToBottom = (leaveBenefitIndex) => {
    const benefit = linkedBenefits.find((_, index) => leaveBenefitIndex == index)
    const nextBenefitIndex = linkedBenefits.findIndex((lb, index) => !lb._destroy && index > leaveBenefitIndex)
    const nextBenefit = linkedBenefits.find((_, index) => nextBenefitIndex == index)
    const benefits = linkedBenefits.reduce((acc, lb, index) => {
      if (index == leaveBenefitIndex) {
        acc.push(nextBenefit)
      } else if (index == nextBenefitIndex) {
        acc.push(benefit)
      } else {
        acc.push(lb)
      }
      return acc
    }, [])
    setLinkedBenefits(benefits)
  }

  const deleteLinkedBenefit = (leaveBenefitId) => {
    const wrapperBenefits = linkedBenefits.reduce((acc, lb) => {
      if (lb.leave_benefit_id == leaveBenefitId) {
        if (lb.id) {
          acc.push({id: lb.id, _destroy: '1'})
        }
      } else {
        acc.push(lb)
      }
      return acc
    }, [])
    setLinkedBenefits(wrapperBenefits)
  }

  const toggleSharesMaxDuration = (benefit) => {
    const benefits = linkedBenefits.map(lb => {
      if (benefit.leave_benefit_id == lb.leave_benefit_id) {
        lb.shares_max_duration = !lb.shares_max_duration
      }
      return lb
    })
    setLinkedBenefits(benefits)
  }

  const linkableWarningMessage = (benefitName) => {
    if (warningMessage[benefitName]['linkable'].length < 1) return ;
    return (
      <>
        <span><b>{benefitName}</b> will be added to <b>{name}</b> at the following companies:</span>
        <ul>
          {
            warningMessage[benefitName]['linkable'].map((companyName) => {
              return <li>{companyName}</li>
            })
          }
        </ul>
      </>
    )
  }

  const nonLinkableWarningMessage = (benefitName) => {
    if (warningMessage[benefitName]['non_linkable'].length < 1) return ;
    return (
    <>
      <span><b>{benefitName}</b> does not exist on the following companies and will NOT be added {name}.</span><br/>
      <span>Associate <b>{benefitName}</b> to the company(ies) to update their Linked Leave Benefit.</span>
      <ul>
        {
          warningMessage[benefitName]['non_linkable'].map((companyName) => {
            return <li>{companyName}</li>
          })
        }
      </ul>
    </>
    )
  }

  const displayWarningMessage = () => {
    return (
      <div>
        {
          Object.keys(warningMessage).map((benefitName) => {
            return (
            <div>
              {linkableWarningMessage(benefitName)}
              {nonLinkableWarningMessage(benefitName)}
              <hr/>
            </div>
            )
          })
        }
      </div>
    )
  }

  const warningModal = () => {
    if (!showWarningModal) return null;
    return (
      <div className="react-modal">
        <div className="back-shadow"></div>
        <div className="modal-content react-modal-content" style={{width: 800}} ref={warningModalRef}>
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ () => setShowWarningModal(false) }
            >×</button>
            <h4 className="modal-title">Warning</h4>
          </div>
          <div className="modal-body">
            {displayWarningMessage()}
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={ () => setShowWarningModal(false) }>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={ () => saveChainedBenefits() }>
              Continue
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="admin-policy-items">
      {headers()}
      {renderWrapperInputs()}
      {renderLinkedBenefits()}
      {renderMaxDurations()}
      {renderSharedMaxDurationForm()}
      {listBenefitsModal()}
      {warningModal()}
    </div>
  );
};

export default LinkedBenefitTemplateForm;
