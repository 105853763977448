import React from 'react'

class HelpfulnessCheck extends React.Component {
  constructor() {
    super()
  }

  _checkGrayed() {
    if (this.props.item.helpful !== null) {
      if (this.props.item.helpful) { 
        return 'up';
      } else {
        return 'o-up';
      }
    } else {
      return 'o-up';
    }
  }

  _checkNonGrayed() {
    if (this.props.item.helpful !== null) {
      if (!this.props.item.helpful) { 
        return 'down';
      } else {
        return 'o-down';
      }
    } else {
      return 'o-down';
    }
  }

  _helpfulButton(btn_type, icon, checked, check_grayed){
    return (
      <div className="inline-option">
        <a href='' onClick={this.handleHelpfulCheck.bind(this)} className={`thumb-${btn_type}`} data={JSON.stringify({ helpful: checked })}>
          <i className={`fa fa-thumbs-${check_grayed}`} data={JSON.stringify({ helpful: checked })} />
        </a>
      </div>
    );
  }
  handleHelpfulCheck(e){
    let id, link;
    e.preventDefault();
    if (this.props.item_type === 'benefit_event') {
      id = this.props.item.id;
      link = 'leave_plan_benefits';
    } else if (this.props.item_type === 'planning_guide') {
      id = this.props.item.id;
      link = 'leave_plan_guides';
    } else {
      id = this.props.item.timeline_id;
      link = 'timeline_items';
    }
    const target_value = $(e.target).attr('data');
    const { helpful } = JSON.parse(target_value);
    $.post(`/${link}/${id}/mark_helpfulness` , { helpful}, data => {
      this.props.onHelpfulCheck(data);
    });
  }

  render() {
    return(
      <div className='helpfulness-check'>
        <b className='inline-option'>
          Was this helpful?
        </b>
        {this._helpfulButton("success", "up", true, this._checkGrayed())}
        {this._helpfulButton("danger", "down", false, this._checkNonGrayed())}
      </div>
    )
  }
}

export default HelpfulnessCheck

