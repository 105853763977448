import React from 'react'

class DashboardMonthLabels extends React.Component {
  fetchPrevData () {
    const prevDate = moment(this.props.first_date).add(-this.props.periodDifference, 'months').format('YYYY-MM-DD')
    this.fetchNewData(prevDate)
  }

  fetchNextData () {
    const nextDate = moment(this.props.first_date).add(this.props.periodDifference, 'months').format('YYYY-MM-DD')
    this.fetchNewData(nextDate)
  }

  fetchNewData (date) {
    $.getJSON('/leave_plan_benefits/dashboard_benefit_items', {input_date: date, leave_plan_id: this.props.leave_plan_id}, (data) => {
      this.props.changeData(data)
    })
  }

  renderPrevNav () {
    if (this.props.showPrevNav) {
      return (
        <i onClick={this.fetchPrevData.bind(this)} className='prev-month fa fa-angle-left next-prev' />
      )
    }
  }

  render () {
    return (
      <div className='date-label' style={{width: this.props.width}}>
        { this.labelName() }
      </div>
    )
  }

  renderDateLabel (label) {
    let labelName = ''
    if (this.props.period === 'month') {
      labelName = moment(label.date).format('MMM YYYY')
    } else {
      labelName = `Q${(moment(label.date).month() / 3 + 1)} ${moment(label.date).year()}`
    }
    return (
      <div key={label.date} className='date-label' style={{width: label.width}}>
        { labelName }
      </div>
    )
  }

  renderNextNav () {
    if (this.props.showNextNav) {
      return (
        <i onClick={this.fetchNextData.bind(this)} className='next-month fa fa-angle-right next-prev' />
      )
    }
  }

  render () {
    return (
      <div className='month-dates-label-div'>
        <div className='first-date-labels-div'>
          { this.renderPrevNav() }
        </div>

        <div className='date-labels-div'>
          {
            this.props.label_dates.map(label => this.renderDateLabel(label))
          }
        </div>
        <div className='next-month-container'>
          { this.renderNextNav() }
        </div>
      </div>
    )
  }
}

export default DashboardMonthLabels