import React from "react";
import PropTypes from "prop-types";

const FileDeleteButton = (props) => {
  return (
    <button
      className={`btn btn-danger delete-employee-file-${props.value}`}
      onClick={() => props.handleClick(props.value)}
      value={props.value}
      disabled={props.disable}
    >
      Delete
    </button>
  );
};

FileDeleteButton.propTypes = {
  handleClick: PropTypes.func,
  value: PropTypes.number,
  disable: PropTypes.bool,
};

export default FileDeleteButton;
