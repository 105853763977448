import React from 'react'
import PropTypes from 'prop-types'
import TableGeneric from '../../shared/Table/containers/TableGeneric'
import List from '../../shared/Table/components/List'
import Modal from '../../shared/Modal'

class DataTable extends React.Component {
  constructor (props) {
    super(props)
    const search_location = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')))
    this.state = {
      ...props,
      ...{
        modal: {
          promptText: "Are you sure?",
          title: "Confirmation",
          show: false,
          id: null
        },
      }
    }
  }

  getItems(items, classes) {
    const retItem = items.map (item=> {
      return {
        id: item.id,
        linkedBenefit: item.linked_benefit,
        company_id: item.company_id,
        locked_item: item.locked_item,
        editUrl: item.edit_url,
        status: item.status,
        associate: item.associate,
        disableAssociation: item.disable_association,
        disabledReason: item.disable_reason,
        showContentPreview: !!item.type && item.type == 'content',
        columns: classes
        .map( cl => ({
          ...cl,
          ...{
            value: item[cl.element]
          }
        }))
        .map( (cl, i) => ({
          ...cl,
          ...{
            handleCheck: () => {
            }
          }
        }))
        .map( (column, i) => {
          return (
            this.props.wordifyIndexes != undefined && this.props.wordifyIndexes.indexOf(i) !== -1 ? {
              ...column,
              ...{
                value : column.value.wordify("_", true)
              }
            } : column
          )
        })
        .map( (column, i, columns) => this.classifyDom(column, i, item, columns) )
    }})
    return retItem
  }

  classifyDom (column, i, item, columns) {
    const typeOfValue = typeof column.value
    if (typeOfValue  === "string" || typeOfValue === "undefined") {
      if (column.element === "status") {
        return (
          <span className={ `label status-label label-${column.value}` }>
            { this.props.item_status[column.value] }
          </span>
        )
      }
      else if (column.type === "date") {
        const date = moment(column.value).tz('America/Los_Angeles')
        return (
          <span>
            <span>
              { date.format(gon.default_date_format_string.toUpperCase()) }
            </span>
            <br/>
            <span>
              { date.format("hh:mm A") }
            </span>
          </span>
        )
      }
      else if (i === columns.length - 1 ) {
        const statutoryBenefit = this.props.model === 'StatutoryLeaveBenefit' && !item.draft
        const undeletable = (this.props.isIpa && item.category === 'statutory') || (this.props.searchAll) || statutoryBenefit || (this.props.taskAssociation && (this.props.canAssociate || item.disable_association)) || this.props.undeletable || item.locked_item
        return undeletable ?
          null :
          (
            <span data-confirm-message={"Are you sure?"} data-method={"DELETE"} className={""} onClick={(e) => this.setDeleteItem(e, item.id)}>
              <i className="fa fa-trash-o">
              </i>
            </span>
          )
      }
      else if (column.element === "name") {
        if (item.associated_companies != undefined) {
          return (
            <div className="companies-count-item">
              <div>
                { column.value }
              </div>
              <div>
                <label>Companies: </label> { item.associated_companies }
              </div>
            </div>
          )
        }
        else {
          return (
            <span>{ column.value }</span>
          )
        }
      }
      else {
        return (
          <span dangerouslySetInnerHTML={{__html: column.value}} />
        )
      }
    } else if (typeOfValue === "object" && column.type === "array") {
      return (
        <List data={column.value} avoidWordify={column.avoidWordify} />
      )
    } else if (typeOfValue === 'number') {
      return (
        <span>
          { column.value }
        </span>
      )
    }
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{
        show: false
      }
    }
    this.setState({
      modal
    })
  }

  setDeleteItem (e, id) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        id: id
      }
    }

    this.setState({
      modal
    })
    e.stopPropagation()
  }

  showModal () {
    if (this.state.modal.show) {
      return (
        <Modal
          {...this.state.modal}
          hideModalInParent={this.hideModal.bind(this)}
          id={this.state.modal.id}
          approve={this.props.handleDelete}
          className='destroy'>
          <span>{ this.state.modal.promptText }</span>
        </Modal>
      )
    }
  }

  render () {
    return (
      <div>
        <TableGeneric
          {...this.props}
          items={this.getItems(this.props.items, this.props.classes)}
          tableBodyClass={this.props.tableBodyClass || ""}
        />
        { this.showModal() }
      </div>
    )
  }
}

export default DataTable;
