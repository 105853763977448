import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

class GenericPagination extends React.Component {

  constructor (props) {
    super (props)
    const itemsPerPage = this.props.itemsPerPage
    this.state = {
      currentPage: this.props.currentPage,
      totalPages: Math.ceil(this.props.totalItems/this.props.itemsPerPage)
    }
  }

  componentWillReceiveProps (props) {
    this.setState({
      currentPage: props.currentPage,
      totalPages: Math.ceil(props.totalItems/props.itemsPerPage)
    })
  }

  gotoPage(e, currentPage) {
    e.preventDefault()
    this.setState({
      currentPage: currentPage
    })
    this.props.changePageNumber(currentPage)
  }

  renderPageLink(page) {
    const activePage = page == this.state.currentPage

    return (
      <Pagination.Item key={page} active={activePage} onClick={(e) => this.gotoPage(e, page)}>
        {page}
      </Pagination.Item>
    )
  }

  renderPages () {
    let { currentPage, totalPages } = this.state
    let pages = []

    const leftBound = currentPage - 2
    const rightBound = currentPage + 2

    const startPage = leftBound > 1 ? leftBound : 1
    const endPage = rightBound < totalPages ? rightBound : totalPages

    for (var page = startPage; page<=endPage; page++) {
      pages.push(this.renderPageLink(page))
    }

    return pages
  }

  gotoPage (e, currentPage) {
    e.preventDefault()
    this.setState({
      currentPage: currentPage
    })
    this.props.changePageNumber(currentPage)
  }

  renderFirstPageLink() {
    return this.state.currentPage > 1 ? ( <Pagination.First onClick={(e) => this.gotoPage(e, 1)}/> ) : null

  }

  renderPreviousPageLink() {
    return this.state.currentPage > 1 ? ( <Pagination.Prev onClick={(e) => this.gotoPage(e, this.state.currentPage - 1)} /> ) : null
  }

  renderNextPageLink () {
    return this.state.currentPage < this.state.totalPages ? ( <Pagination.Next onClick={(e) => this.gotoPage(e, this.state.currentPage + 1)} /> ) : null

  }

  renderLastPageLink () {
    return this.state.currentPage < this.state.totalPages ? ( <Pagination.Last onClick={(e) => this.gotoPage(e, this.state.totalPages)} /> ) : null
  }

  render() {
    return this.state.totalPages > 1 ? (
      <Pagination>
        {this.renderFirstPageLink()}
        {this.renderPreviousPageLink()}
        {this.renderPages()}
        {this.renderNextPageLink()}
        {this.renderLastPageLink()}
      </Pagination> ) : null
  }
}

export default GenericPagination
