import React from 'react'
import VendorForm from './VendorForm'
import Pagination from '../pagination/pagination'

class Vendors extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedVendor: {},
      deletableVendor: {},
      vendors: props.vendors,
      pageNumber: "1",
      sortBy: {
        column: 'name',
        reverse: false
      },
    }
  }

  closeModalHandler(key, value) {
    this.setState({ [key]: value })
  }

  showAddModal() {
    this.setState({ showAddModal: true })
  }

  addedRecordHandler (vendor) {
    this.setState(prevState => {
      return {
        vendors: [vendor, ...prevState.vendors],
        showAddModal: false,
      }
    })
  }

  createVendorModal() {
    return (
      <VendorForm
        isNew={ true }
        onModalClose={ () => this.closeModalHandler('showAddModal', false) }
        onSubmit={ this.addedRecordHandler.bind(this) }
      />
    )
  }

  showEditModal(vendor) {
    this.setState({ showEditModal: true, selectedVendor: vendor })
  }

  updatedRecordHandler (vendor) {
    this.setState(prevState => {
      return {
        vendors: prevState.vendors.map((filter) => {
          return filter.id === vendor.id ? vendor : filter
        }),
        showEditModal: false
      }
    })
  }

  editVendorModal() {
    const { selectedVendor } = this.state
    return (
      <VendorForm
        isEdit={ true }
        vendor={ selectedVendor }
        onModalClose={ (e) => this.closeModalHandler('showEditModal', false) }
        onSubmit={ this.updatedRecordHandler.bind(this) }
      />
    )
  }

  handleDelete(vendor, e) {
    e.stopPropagation()
    this.setState({ showDeleteModal: true, deletableVendor: vendor })
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false })
  }

  deleteVendor(vendor) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/vendors/${vendor.id}`,
      success: (response) => {
        if (response.success) {
          this.setState({ vendors: this.state.vendors.filter((filter) => { return (filter !== vendor) }) })
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      }
    })
    this.setState({ showDeleteModal: false })
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchVendor() })
  }

  fetchVendor() {
    const { sortBy: {column, reverse}, pageNumber } = this.state
    const sort_order = reverse ? "desc" : "asc"
    const data = {
      page: pageNumber,
      sort_by: column,
      sort_order
    }
    const url = '/admin/vendors'
    $.getJSON(url, data, (response) => {
      this.setState({
        vendors: response.items
      })
    })
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
        this.fetchVendor()
    })
  }

  showDeleteModal () {
    return(
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              data-dismiss="modal"
              type="button"
              onClick={ () => this.closeDeleteModal() }
            >×</button>
            <h4 className="modal-title">Delete confirmation</h4>
          </div>
          <div className="modal-body">
            <div className="confirmation-message text-center">
              <span>Are you sure you want to delete?</span>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary" onClick={ () => this.deleteVendor(this.state.deletableVendor) }>
                Yes
              </button>
              <button className="btn btn-danger" onClick={ () => this.closeDeleteModal() }>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      vendors,
      showAddModal,
      showEditModal,
      showDeleteModal,
      sortBy,
      pageNumber
    } = this.state
    const {
      total_items
    } = this.props
    return (
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <h3>All Vendors</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <br/>
              { !this.props.isIpa && <button className="btn btn-primary pull-right" onClick={ () => this.showAddModal() }>Add Vendor</button> }
            </div>
          </div>
        </div>
        <div className="item-table">
          <div className="bundle-template">
            <Pagination
              totalItems={ total_items }
              changePageNumber={ this.changePageNumber.bind(this) }
              pageNumber={ pageNumber }
              itemsPerPage={ 30 }
            />
          </div>
          <table className="shared-benefit-table generic-table deletable-rows">
            <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
              <tr className="tr">
                <th className="table-title sortable" onClick={ () => this.sort('name') }>
                  <span className="title-text">Name</span>
                  <span className="sort-caret">
                    {
                      sortBy.reverse ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      <i className="fa fa-sort-asc" aria-hidden="true"></i>
                    }
                  </span>
                </th>
                <th className="table-title">Description</th>
                <th className="table-title">
                  <span className="title-text">Logo</span>
                  <span className="sort-caret"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                vendors.sort().map((vendor, index) => {
                  return(
                    <tr key={ index }
                        onClick={() => !this.props.isIpa && this.showEditModal(vendor)}
                        className="tr item-table benefit-items-row fixed-table-header"
                        >
                      <td className="table-data sortable">{ vendor.name }</td>
                      <td className="table-data">{ vendor.description }</td>
                      <td className="table-data">
                        <div
                          style={{
                            width: "75px",
                            height: "50px",
                            background: `url(${ vendor.logo_url }) no-repeat center`,
                            backgroundSize: "contain"
                          }}>
                        </div>
                      </td>
                      { !this.props.isIpa && <td className="table-data delete">
                        <span><i className="fa fa-trash-o" onClick={ (e) => this.handleDelete(vendor, e) }></i></span>
                      </td> }
                    </tr>
                  )
                })
              }
            </tbody>
            </table>
          </div>
        { showAddModal ? this.createVendorModal() : null }
        { showEditModal ? this.editVendorModal() : null }
        { showDeleteModal ? this.showDeleteModal() : null }
      </div>
    )
  }
}

export default Vendors
