import React from 'react'
import PropTypes from 'prop-types'

class PlainModal extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      show: this.props.show
    }
  }

  addClass (element, newClass) {
    element.className += ` ${newClass}`;
  }

  closePrompt (e) {
    const modalContainer = document.getElementsByClassName("react-modal");
    const modal = document.getElementsByClassName("react-modal-content modal-content");
    const backShadow = document.getElementsByClassName("back-shadow");
    this.addClass(modalContainer[0], "remove")
    this.addClass(modal[0], "remove slideOutPosition")
    this.addClass(backShadow[0], "remove")
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.hideModalInParent()
    }).bind(this), 500 )
    e.stopPropagation()
  }

  render () {
    return this.state.show ? (
      <div className="react-modal list-modal">
        <div className="back-shadow" onClick={(e) => this.closePrompt(e)}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="close-modal" onClick={(e) => this.closePrompt(e)}>
            <span>
              Close
            </span>
          </div>
          <div className="modal-body">
            <div className="confirmation-message text-center">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    ) : null
  }
}


PlainModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  hideModalInParent: PropTypes.func
}

export default PlainModal;
