import React from 'react'

class Input extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    const handlerAttach = (typeof this.props.onChange === 'function')
    const { label, type, className: inputClassName, value, parentClassName, parentStyle, ...restProps } = this.props;
    const inputId = `${label}-input`
    return (
      <div className={`form-group row ${parentClassName}`} style={parentStyle}>
        <label htmlFor={inputId} className="col-sm-4 col-form-label col-form-label-sm">
          { label }
        </label>
        <div className="col-sm-4">
          <input
            {...restProps}
            type={type}
            id={inputId}
            value={value || ''}
            className={inputClassName}
            onChange={(e) => handlerAttach ? this.props.onChange(e) : false}
          />
        </div>
      </div>
    )
  }
}

export default Input;
