import React from 'react'
import EdiText from 'react-editext'

class LeavePlanTitle extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      title: this.props.title
    }
  }

  onSave = val => {
    if (val != this.props.title) {
      $.ajax({
        method: 'PATCH',
        url: `/leave_plans/${this.props.leave_plan_id}/rename_leave_plan`,
        data:{
          title: val,
          id: this.props.leave_plan_id
        },
        success: (data) => {
          if (data.status === 'success') {
            toastr.success(data.message)
            this.setState({title: val})
          } else {
            toastr.error(data.error_message, 'Error')
          }
        }
      })
    }
  }

  render(){
    // return (
    //   <div className='leaveplan-title-container'>
    //     <EdiText
    //       inputProps={{
    //         style: {
    //           color: '#233C51',
    //           width: 250,
    //           height: 30
    //         }
    //       }}
    //       saveButtonClassName="save-btn"
    //       cancelButtonClassName="cancel-btn"
    //       type="text"
    //       editButtonClassName="edit-btn"
    //       validationMessage="Leave Plan Title can not be blank."
    //       validation={val => val.trim().length >= 1 }
    //       value={this.state.title}
    //       editOnViewClick={true}
    //       submitOnEnter
    //       submitOnUnfocus
    //       cancelOnEscape
    //       onSave={this.onSave.bind(this)}
    //     />
    //   </div>
    // )
    return null
  }

}

export default LeavePlanTitle
