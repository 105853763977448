import React from 'react'
import ApiPartnerForm from './ApiPartnerForm'
import Pagination from '../pagination/pagination'
import Modal from '../../shared/Modal'

class ApiPartners extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddModal: false,
      showEditModal: false,
      modal: {
        promptText: "Are you sure?",
        title: "Confirmation",
        show: false,
        id: null
      },
      selectedApiPartner: {},
      apiPartners: props.api_partners,
      companies: [],
      pageNumber: "1",
      implementation_partners: [],
    }
  }

  componentDidMount () {
    this.fetchCompanies()
    this.fetchImplementationPartners()
  }

  fetchCompanies () {
    const url = '/admin/companies/companies_for_selection'
    $.getJSON(url, (response) => {
      this.setState({
        companies: response.data
      })
    })
  }

  fetchImplementationPartners () {
    const url = '/admin/implementation_partners/implementation_partners_for_selection'
    $.getJSON(url, (response) => {
      this.setState({
        implementation_partners: response.data
      })
    })
  }

  closeModalHandler (key, value) {
    this.setState({ [key]: value })
  }

  showAddModal() {
    this.setState({ showAddModal: true })
  }

  addedRecordHandler (apiPartner) {
    this.setState(prevState => {
      return {
        apiPartners: [apiPartner, ...prevState.apiPartners],
        showAddModal: false,
      }
    })
  }

  createApiPartnerModal() {
    return (
      <ApiPartnerForm
        isNew={true}
        companies={this.state.companies}
        implementation_partners={this.state.implementation_partners}
        onModalClose={() => this.closeModalHandler('showAddModal', false)}
        onSubmit={this.addedRecordHandler.bind(this)}
      />
    )
  }

  showEditModal(apiPartner) {
    this.setState({ showEditModal: true, selectedApiPartner: apiPartner })
  }

  updatedRecordHandler(item) {
    this.setState(prevState => {
      return {
        apiPartners: prevState.apiPartners.map((apiPartner) => {
          return apiPartner.id === item.id ? item : apiPartner
        }),
        showEditModal: false
      }
    })
  }

  editApiPartnerModal() {
    const { selectedApiPartner } = this.state
    return (
      <ApiPartnerForm
        isEdit={true}
        apiPartner={selectedApiPartner}
        companies={this.state.companies}
        implementation_partners={this.state.implementation_partners}
        onModalClose={() => this.closeModalHandler('showEditModal', false)}
        onSubmit={this.updatedRecordHandler.bind(this)}
      />
    )
  }

  handleDelete(id, e) {
    const modal = {
      ...this.state.modal,
      ...{
        show: true,
        id: id
      }
    }
    this.setState({ modal })
    e.stopPropagation()
  }

  hideModal () {
    const modal = {
      ...this.state.modal,
      ...{ show: false }
    }
    this.setState({ modal })
  }

  deleteItem(id) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/api_partners/${id}`,
      success: (response) => {
        this.setState({ apiPartners: this.state.apiPartners.filter((apiPartner) => { return (apiPartner.id !== id) }) })
        toastr.success(response.message)
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  changePageNumber(pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.fetchApiPartners() })
  }

  fetchApiPartners() {
    const data = {
      page: this.state.pageNumber,
    }
    const url = '/admin/api_partners'
    $.getJSON(url, data, (response) => {
      this.setState({
        apiPartners: response.items
      })
    })
  }

  showDeleteModal () {
    return(
      <Modal
        {...this.state.modal}
        hideModalInParent={this.hideModal.bind(this)}
        id={this.state.modal.id}
        approve={this.deleteItem.bind(this)}
        className="destroy">
        <span>{ this.state.modal.promptText }</span>
      </Modal>
    )
  }
  renderTable(){
    const {apiPartners } = this.state
    return(
      <table className="shared-benefit-table generic-table deletable-rows">
        <thead className="thead fixed-table-header" style={{ marginTop: "90px" }}>
          <tr className="tr">
            <th className="table-title">Name</th>
            <th className="table-title">Status</th>
            <th className="table-title">Type</th>
          </tr>
        </thead>
        <tbody>
          {
            apiPartners.map((apiPartner, index) => {
              return(
                <tr key={ index }
                    onClick={() => this.showEditModal(apiPartner)}
                    className="tr item-table benefit-items-row fixed-table-header"
                    >
                  <td className="table-data sortable">{ apiPartner.name }</td>
                  <td className="table-data">{ apiPartner.status }</td>
                  <td className="table-data">{apiPartner.role === 'super_admin' ? 'Companies' : 'Partner'}</td>
                  <td className="table-data delete">
                    <span><i className="fa fa-trash-o" onClick={ (e) => this.handleDelete(apiPartner.id, e) }></i></span>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }
  render() {
    const { showAddModal, showEditModal, modal, pageNumber } = this.state
    const { total_items } = this.props
    return (
      <div>
        <div className="admin-header fixed-header" style={{ height: "100px" }}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <h3>API Partners</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              <br/>
              <button className="btn btn-primary pull-right" onClick={ () => this.showAddModal() }>Add API Partner</button>
            </div>
          </div>
        </div>
        <div className="item-table">
          <div className="bundle-template" style={{ marginTop: '38px'}}>
            <Pagination
              totalItems={ total_items }
              changePageNumber={ this.changePageNumber.bind(this) }
              pageNumber={ pageNumber }
              itemsPerPage={ 30 }
            />
          </div>
          { this.renderTable() }
        </div>
        { showAddModal ? this.createApiPartnerModal() : null }
        { showEditModal ? this.editApiPartnerModal() : null }
        { modal.show ? this.showDeleteModal() : null }
      </div>
    )
  }
}
export default ApiPartners
