import React from "react";
import SwitchInput from "../../shared/inputs/SwitchInput";

class ColorCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorCodes: [],
      supportColorCode: props.supportColorCode,
    };
  }

  componentDidMount() {
    let { colorCodes } = this.props;
    if (colorCodes && colorCodes.length > 0) {
      this.setState({
        colorCodes: colorCodes.map((colorCode) => ({
          name: colorCode.name,
          value: colorCode.value,
          id: colorCode.id,
          company_id: colorCode.company_id
        })),
      });
    } else {
      this.handleAddClick();
    }
  }

  generateColor() {
    return "#" + Math.random().toString(16).substr(-6);
  }

  handleRemoveClick(item) {
    let url = `/admin/color_codes/${item.id}`;
    $.ajax({
      method: "DELETE",
      url: url,
      success: () => {
        this.setState((prevState) => ({
          colorCodes: prevState.colorCodes.filter((colorCode) => {
            return colorCode.id !== item.id;
          }),
        }));
      },
      error: (response) => {
        toastr.error(response.responseJSON.message);
      },
    });
  }

  handleAddClick() {
    let url = `/admin/color_codes`;
    let params = {
      color_code: { 
        name: "",
        value: this.generateColor()
      },
      company_id: this.props.companyId
    };
    $.ajax({
      method: "POST",
      url: url,
      data: params,
      success: (response) => {
        this.setState((prevState) => ({
          colorCodes: [...prevState.colorCodes, response.item],
        }));
      },
      error: (response) => {
        toastr.error(response.responseJSON.message);
      },
    });
  }

  handleChange(index, key, value) {
    let colorCodes = [...this.state.colorCodes];
    colorCodes[index][key] = value;
    this.setState({ colorCodes });
  }

  handleSwitchInputChange(checked) {
    if (this.state.supportColorCode != checked) {
      let url = `/admin/companies/${this.props.companyId}/support_color_code`;
      let params = { support_color_code: checked };

      $.ajax({
        method: "PUT",
        url: url,
        data: params,
        success: (response) => {
          this.setState({ supportColorCode: response.support_color_code })
        },
        error: (response) => {
          toastr.error(response.responseJSON.message || response.responseJSON.error_message)
        },
      });
    }
  }

  updateColorCode(item) {
    let url = `/admin/color_codes/${item.id}`;
    let params = { color_code: item };
    $.ajax({
      method: "PUT",
      url: url,
      data: params,
      success: (response) => {
        this.setState((prevState) => ({
          colorCodes: prevState.colorCodes.map((colorCode) => ( 
            colorCode.id === response.item.id ? response.item : colorCode
            )
          ),
        }));
      },
      error: (response) => {
        toastr.error(response.message);
      },
    });
  }

  listColorCodes() {
    return (
      this.state.colorCodes.map((colorCode, index) => {
        return (
          <div className="color-code-container" key={index}>
            <div>
              <input
                type="color"
                name="value"
                value={colorCode.value}
                onBlur={() => this.updateColorCode(colorCode)}
                onChange={(e) => this.handleChange(index, e.target.name, e.target.value) }
              />
            </div>
            <div>
              <input
                type="text"
                name="name"
                className="form-control"
                value={colorCode.name}
                onBlur={() => this.updateColorCode(colorCode)}
                onChange={(e) => this.handleChange(index, e.target.name, e.target.value) }
              />
            </div>
            <div>
              <i
                className="fa fa-lg fa-trash-o"
                onClick={() => this.handleRemoveClick(colorCode)}
              ></i>
            </div>
          </div>
        );
      })
    )
  }

  renderColorCodeFields() {
    if(this.state.supportColorCode) {
      return (
        <div>
          <div>
            <label htmlFor="color_code">Company Color code</label>
          </div>
          { this.listColorCodes() }
          <div
            className="btn btn-primary add-input-button"
            onClick={() => this.handleAddClick()}
          >
            ADD
          </div>
        </div>
      )
    }
  }

  renderColorCodeToggle() {
    return(
      <div className="support-color-code-container">
        <div className="switch-label">
          <label htmlFor="support_color_code">Color Key on Dashboard</label>
        </div>

        <div className="toggle-support-color-code">
          <SwitchInput
            name="support_color_code"
            checked={this.state.supportColorCode}
            onChange={(checked) => this.handleSwitchInputChange(checked)}
            id="support_color_code"
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="color-code-wrapper">
        { this.renderColorCodeToggle() }
        { this.renderColorCodeFields() }
      </div>
    );
  }
}

export default ColorCode;
