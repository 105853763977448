import React from 'react'

class DashboardAvailableBenefitsToggle extends React.Component {
  constructor (props) {
    super(props)
    if (props.showExtendableBenefit) {
      this.state = {
        currentPlan: 'inactive',
        availableBalances: 'active'
      }
    } else {
      this.state = {
        currentPlan: 'active',
        availableBalances: 'inactive'
      }
    }
  }

  changeTab (tab) {
    const tabMapper = {
      currentPlan: {
        currentPlan: 'active',
        availableBalances: 'inactive'
      },
      availableBalances: {
        currentPlan: 'inactive',
        availableBalances: 'active'
      }
    }
    this.setState(tabMapper[tab])
    return this.props.toggleAvailableBalances(tab)
  }

  render () {
    return (
      <div className='view-toggle available-balances-toggle'>
        <div
          className={`dashboard-benefit-view left-button ${this.state.currentPlan}`}
          onClick={() => this.changeTab('currentPlan')}
        >
          Current Plan
        </div>
        <div
          className={`dashboard-benefit-view right-button ${this.state.availableBalances}`}
          onClick={() => this.changeTab('availableBalances')}
        >
          Potential Remaining
        </div>
      </div>
    )
  }
}

export default DashboardAvailableBenefitsToggle