import React from 'react'
import Datepicker from '../../shared/inputs/datepicker'

class CategoryModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      noteCategory: props.noteCategory
    }
  }

  preSubmission() {
    let { noteCategory } = this.state
    if (noteCategory.operatedCategory.value.length){
      this.submitCategory()
    }
    else{
      toastr.error('Category cannot be empty.')
    }
  }

  submitCategory(e) {
    e.preventDefault()
    let { noteCategory } = this.state
    let { leavePlanId, sharedNoteId, modifyCategoryList, hideCategoryModal } = this.props

    let method = 'POST'
    let url = `/admin/shared_leave_plans/${leavePlanId}/add_note_category`

    if (!noteCategory.isAdd){
      method = 'PUT'
      url = `/admin/shared_leave_plans/${leavePlanId}/update_note_category`
    }

    let params = noteCategory.operatedCategory
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        if (response.success){
          toastr.success(response.message)
          modifyCategoryList(response.data)
        } else{
          toastr.error(response.message)
          hideCategoryModal()
        }
      }
    })
  }

  closePrompt() {
    this.props.hideCategoryModal()
  }

  categoryChangeHandler(e) {
    let { noteCategory } = this.state
    noteCategory.operatedCategory.value = e.target.value
    this.setState({
      noteCategory: noteCategory
    })
  }

  renderCategoryModal() {
    let { isAdd, operatedCategory } = this.state.noteCategory
    return (
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <div className='item-header-container'>
              <div className='item-header'>
                <div className='name'>
                  Category
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body leave-plan-benefit-modal category-modal">
            <form className='leave-plan-benefit-override-form' action='#' onSubmit={(e) => this.submitCategory(e)}>
              <div className='form-group'>
                <label className='label-control'> Category: </label>
                <input onChange={(e) => this.categoryChangeHandler(e)} value={operatedCategory.value}></input>
              </div>
              <div className="buttons">
                <button onClick={() => this.closePrompt()}>
                  Cancel
                </button>
                <button className="submit btn btn-primary" type='submit'>
                  { isAdd ? 'Add' : 'Rename'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderCategoryModal()}
      </div>
    )
  }
}
export default CategoryModal
