import React from 'react'

class Pagination extends React.Component {
  setVariables() {
    this.currentPage = parseInt(this.props.pageNumber)
    this.totalPages = Math.ceil(this.props.totalItems / this.props.itemsPerPage)
  }

  gotoPage(e, currentPage) {
    e.preventDefault()
    this.props.changePageNumber(currentPage)
  }

  renderFirstPageLink() {
    return this.currentPage > 1 ? (
      <li className='first'>
        <a href='' onClick={(e) => this.gotoPage(e, 1)}>
          « First
        </a>
      </li>
    ) : null
  }

  renderPreviousPageLink() {
    return this.currentPage > 1 ? (
      <li className='prev'>
        <a href='' onClick={(e) => this.gotoPage(e, this.currentPage - 1)}>
          ‹ Prev
        </a>
      </li>
    ) : null
  }

  renderPageLink(currentPage) {
    const activePage = currentPage == this.currentPage ? ' active' : ''
    return (
      <li className={`page ${activePage}`} key={currentPage}>
        <a href='' onClick={(e) => this.gotoPage(e, currentPage)}>
          {currentPage}
        </a>
      </li>
    )
  }

  renderPagesLink() {
    const leftBound = this.currentPage - 4
    const rightBound = this.currentPage + 4

    const startPage = leftBound > 1 ? leftBound : 1
    const endPage = rightBound < this.totalPages ? rightBound : this.totalPages

    let pages = []
    if (startPage !== 1) {
      pages.push(this.renderDottedLink('startDot'))
    }
    for (var page = startPage; page<=endPage; page++) {
      pages.push(this.renderPageLink(page))
    }
    if (endPage !== this.totalPages) {
      pages.push(this.renderDottedLink('endDot'))
    }
    return pages
  }

  renderNextPageLink() {
    return this.currentPage < this.totalPages ? (
      <li className='next_page'>
        <a href='' onClick={(e) => this.gotoPage(e, this.currentPage + 1)}>
          Next ›
        </a>
      </li>
    ) : null
  }

  renderLastPageLink() {
    return this.currentPage < this.totalPages ? (
      <li className='next_page'>
        <a href='' onClick={(e) => this.gotoPage(e, this.totalPages)}>
          Last »
        </a>
      </li>
    ) : null
  }

  renderDottedLink (key) {
    return (
      <li key={key} className='dotted disabled'>
        <a>...</a>
      </li>
    )
  }

  render() {
    this.setVariables()

    return this.totalPages > 1 ? (
      <ul className='pagination'>
        {this.renderFirstPageLink()}
        {this.renderPreviousPageLink()}
        {this.renderPagesLink()}
        {this.renderNextPageLink()}
        {this.renderLastPageLink()}
      </ul>
    ) : null
  }
}

export default Pagination