import React, { useEffect, useState } from "react";
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Paper, styled, makeStyles, TableSortLabel } from '@material-ui/core'

function AssociatedLinkedWrappers ({ fetchUrl, headers, processData, emptyMessage }) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    $.ajax({
      method: 'GET',
      url: fetchUrl,
      headers: {
        "Content-Type": "application/json",
      },
      success: (response) => {
        setRows(response.data);
        setTotalCount(response.count);
      },
      error: (err) => {
        console.log({ err });
      }
    });
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header.key} className={classes.cell}>{header.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {totalCount === 0 ? (
            <TableRow>
              <TableCell colSpan={headers.length} className={classes.emptyCell}>
                {emptyMessage}
              </TableCell>
            </TableRow>
          ) : (
            processData(rows, classes)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles({
  cell: {
    fontSize: 14,
    verticalAlign: 'initial',
  },
  rowGrey: {
    background: '#f1f1f1'
  },
  content: {
    cursor: 'pointer'
  },
  main: {
    marginTop: 40,
  },
  head: {
    backgroundColor: '#d6d6d6'
  },
  emptyCell: {
    fontSize: 14,
    textAlign: 'center',
  },
  list: {
    padding: 0,
  }
})

export default AssociatedLinkedWrappers;
