import React from 'react'
import InstantNotifications from './containers/InstantNotifications'

class TopBarNotifications extends React.Component {
  constructor() {
    super()

    this.state = {
      fetchNewNotifications: false
    }
  }

  componentDidMount () {
    let _this = this
    $('#notification-handle').on('click', function (e) {
      const handle = document.getElementById('notification-handle')
      const notificationIcon = $(e.target).closest('#notifications-icon')
      if ($(this).hasClass('notification-opened') && (e.target === handle || notificationIcon.length)) {
        $(this).removeClass('notification-opened')
        window.removeEventListener('click', _this.hideNotificationContainer.bind(this), true)
      } else {
        $(this).addClass('notification-opened')
        window.addEventListener('click', _this.hideNotificationContainer.bind(this), true)
      }
      if ($(this).hasClass('notification-opened')) {
        _this.setState({ fetchNewNotificationsForTopBar: true })
      }
    })
  }

  hideNotificationContainer (e) {
    const handle = document.getElementById('notification-handle')
    if ($(e.target).closest('#notification-handle').length == 0) {
      $(handle).removeClass('notification-opened')
      window.removeEventListener('click', this.hideNotificationContainer, true)
    }
  }

  render () {
    return (
      <div className='topbar notifications-container hidden-fields'>
        <InstantNotifications
          notifications={[]}
          data={this.props}
          fetchNewNotificationsForTopBar={this.state.fetchNewNotificationsForTopBar}
          spinnerDiv='.topbar.notifications-container .spinner-div'
          notificationDiv='.topbar.notifications-container .notifications'
        />
      </div>
    )
  }
}

export default TopBarNotifications;