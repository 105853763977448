import React from 'react'

class Switch extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    const { className, checked } = this.props;
    $(`.${className}`).on('switchChange.bootstrapSwitch', (event, state) => {
      this.props.onChange(state)
    });
    $(`.${className}`).bootstrapSwitch('state', checked);
  }

  render() {
    const { label, className, checked } = this.props;
    const switchId = `${label}-switch`;
    return (
      <div className="form-group row">
        <label htmlFor={switchId} className="col-sm-4 col-form-label col-form-label-sm sus">
          { label }
        </label>
        <div className="col-sm-4">
          <input
            type="checkbox"
            className={`switch form-control form-control-sm ${className}`}
            id={switchId}
            checked={checked}
            onChange={() => {}}
          />
        </div>
      </div>
    )
  }
}

export default Switch;
