import React from 'react'
import Modal from '../../../shared/Modal'

class ClaimsListModal extends React.Component {
  
  constructor() {
    super()

    this.statuses = {
      draft: 'Draft',
      processing: 'Processing',
      submitted: 'Submitted',
      completed: 'Completed',
      failed: 'Submission Error'
    }

    this.state = {
      claims: []
    }
  }

  componentDidMount() {
    $.getJSON(`/leave_plans/${this.props.id}/claims`, {}, (response) => {
      this.setState({
        claims: response.claims.map(claim => {
          claim.showPayload = false
          return claim
        })
      })
    })
  }

  renderViewRequestButton(claim) {
    if ($.isEmptyObject(claim.response)) {
      return (<span />)
    } else {
      return (
        <span className='show-payload-link' onClick={() => this.showPayloadFor(claim.id)}>
          View Response
        </span>
      )
    }
  }

  renderResubmitClaimButton(claim) {
    if(claim.status === 'failed') {
      return (
        <span className='show-payload-link' onClick={() => this.submitClaim(claim.id)}>
          Resend Claim
        </span>
      )
    } else {
      return (<span />)
    }
  }

  showPayloadFor(id) {
    const claims = this.state.claims.map(claim => {
      claim.showPayload = claim.id === id
      return claim
    })
    this.setState({claims})
  }

  hidePayload() {
    const claims = this.state.claims.map(claim => {
      claim.showPayload = false
      return claim
    })
    this.setState({claims})
  }

  renderStatusButton(claim) {
    if (claim.status === 'pending_send') {
      return (
        <button className='submit-button btn btn-primary' onClick={() => this.submitClaim(claim.id)}>Submit</button>
      )
    } else {
      return (<span className='claim-status'>{this.statuses[claim.status]}</span>)
    }
  }

  submitClaim(claimId) {
    $.post(`/claims/${claimId}/submit`, {}, (response) => {
      if (response.success) {
        this.changeClaimStatus(claimId)
        toastr.success('Claim submitted successfully.')
      } else {
        toastr.error(response.message)
      }
    })
  }

  changeClaimStatus(claimId) {
    let claims =
      this.state.claims
        .map(claim => {
          if (claim.id == claimId) {
            claim.status = 'submitted'
          }
          return claim
        })
    this.setState({claims})
  }

  renderRequest(claim) {
    if (claim.showPayload) {
      return (
        <div>
          <span className='close-request-payload' onClick={this.hidePayload.bind(this)}>X</span>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item active">
              <a className="nav-link active show" id="response-tab" data-toggle="tab" href="#response" role="tab" aria-controls="response" aria-selected="false">Response</a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade active in" id="response" role="tabpanel" aria-labelledby="response-tab">
              <pre className='request-payload'>
                { JSON.stringify(claim.response, undefined, 2) }
              </pre>
            </div>
          </div>
        </div>
      )
    }
  }

  renderClaims() {
    return (
      <div className='claims-div'>
        <div className='claim-div header'>
          <span>Name</span>
          <span>Status</span>
        </div>
        {
          this.state.claims.map(claim => {
            return (
              <div key={claim.id}>
                <div className='claim-div'>
                  <span>{claim.name}</span>
                  <span className='claim-status-div'>
                    <span>{this.renderViewRequestButton(claim)}</span>
                    <span>{this.renderResubmitClaimButton(claim)}</span>
                    <span>{this.renderStatusButton(claim)}</span>
                  </span>
                </div>
                {this.renderRequest(claim)}
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    return (
      <Modal
        hideModalInParent={this.props.hideClaimModalInParent}
        id={this.props.id}
        show={true}
        title='Claims'
        closeButtonText='Cancel' >
        <div>{ this.renderClaims() }</div>
      </Modal>
    )
  }
}

export default ClaimsListModal;