import React, { useEffect, useState } from "react";
import Selectpicker from "../../shared/inputs/selectpicker";
import { Doughnut } from "react-chartjs-2";
import useMediaQuery from "../../shared/useMediaQuery";

const Dashboard = (props) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedRange, setSelectedRange] = useState(props.employeeSignUps[0][2]);
  const [ranges, setRanges] = useState(props.employeeSignUps);
  const [totalLeavePlans, setTotalLeavePlans] = useState(props.leavePlanCount);
  const [data, setData] = useState(props.leavePlanStatusCount);
  const isRowBased = useMediaQuery("(min-width: 1020px)");

  useEffect(() => {
    fetchActiveCompanies();
  }, []);

  const fetchActiveCompanies = () => {
    const url = "/admin/analytical_dashboard/fetch_companies";
    $.getJSON(url, (response) => {
      setCompanies(response.data);
    });
  };

  const BACKGROUND_COLOR = [
    "#0099d5",
    "#ff7100",
    "#e20000",
    "#00c08a",
    "#4478a1",
    "#00c4cf",
    "#febd00",
    "#880024",
    "#306185",
  ];
  const HOVER_BACKGROUND_COLOR = [
    "#35baea",
    "#e87a27",
    "#f72525",
    "#26f2b8",
    "#358cce",
    "#4de2ea",
    "#f2cc63",
    "#961f3d",
    "#507fa0",
  ];

  const doughnutState = {
    labels: Object.keys(data).map((label) => {
      return label.titleize() + " - " + dataPercentage(data[label]);
    }),
    datasets: [
      {
        label: "LeavePlans",
        backgroundColor: BACKGROUND_COLOR,
        hoverBackgroundColor: HOVER_BACKGROUND_COLOR,
        data: Object.values(data),
      },
    ],
  };

  function dataPercentage(currentValue) {
    return totalLeavePlans == 0 ? "0%" : Math.floor((currentValue / totalLeavePlans) * 100) + "%";
  }

  const handleSubmit = (value) => {
    setSelectedCompanies(value);

    let data = [];
    if (value && value.length > 0) {
      data = {
        company_ids: value,
      };
    }

    $.getJSON("/", data, (response) => {
      setData(response.data);
      setRanges(response.total_employee_signups);
      setTotalLeavePlans(response.total_leave_plan);
      setSelectedRange(response.total_employee_signups[0][2]);
      $(".timeframe").find("option:selected").removeAttr("selected");
      $(".timeframe").selectpicker("refresh");
    });
  };

  const DoughnutChart = () => {
    return (
      <Doughnut
        width={300}
        height={300}
        data={doughnutState}
        options={{
          title: {
            display: true,
            text: "LeavePlans",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
            },
          },
          cutoutPercentage: 80,
          maintainAspectRatio: false,
          responsive: true,
          animation: false,
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let currentValue = dataset.data[tooltipItem.index];
                return dataPercentage(currentValue);
              },
            },
          },
        }}
      />
    );
  };

  const handleSelectedRange = (value) => {
    setSelectedRange(ranges.find((r) => r[1] == value)[2]);
  };

  return (
    <div style={styles.column}>
      <div className="form-group" style={{ width: 500 }}>
        <Selectpicker
          className="select-picker multi-select form-control"
          name="selectedCompanies"
          value={selectedCompanies}
          onChange={handleSubmit}
          optionsForSelect={companies}
          multiSelect={true}
          placeholder="All Companies"
        />
      </div>
      <div style={styles.container(isRowBased)}>
        <div style={styles.card}>
          <DoughnutChart />
          <div style={styles.innerContent}>
            <h4>Total</h4>
            <h3 style={{ fontSize: "50px" }}>{totalLeavePlans}</h3>
          </div>
        </div>
        <div style={styles.card}>
          <h3>Employee Sign-ups</h3>
          <div style={styles.row}>
            <p style={styles.signUpCount}>{selectedRange}</p>
            <p style={styles.divider}>/</p>
            <div style={styles.selectPicker}>
              <Selectpicker
                className="select-picker form-control timeframe"
                name="timeframe"
                value={selectedRange}
                defaultValue={ranges[0][0]}
                onChange={handleSelectedRange}
                optionsForSelect={ranges}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: (isRowBased) => ({
    display: "flex",
    flexDirection: isRowBased ? "row" : "column",
    paddingTop: 50,
    flexWrap: "wrap",
    maxWidth: 1150,
    justifyContent: "space-between",
  }),
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    padding: 50,
  },
  card: {
    borderRadius: "3px",
    boxShadow: "0 4px 5px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    margin: "10px",
    padding: "30px",
    position: "relative",
    width: "500px",
    backgroundColor: "#ffffff",
  },
  innerContent: {
    top: "56%",
    left: "32.5%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  signUpCount: {
    fontSize: "66px",
    marginRight: "20px",
    color: "#0099d5",
  },
  divider: {
    fontSize: "36px",
    marginRight: "20px",
    marginTop: "27px",
  },
  selectPicker: {
    marginTop: "31px",
    width: "20%",
  },
};

export default Dashboard;
