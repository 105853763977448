import React from 'react'
import PropTypes from 'prop-types'

class TableHead extends React.Component {
  constructor () {
    super ()
  }

  handleClick (e, sortBy, sortByAlias) {
    const isSortable = e.currentTarget.classList.contains("sortable")
    isSortable && this.props.sort && this.props.sort(sortBy, sortByAlias)
  }

  renderCaret (sortBy, elementObj) {
    if (sortBy.column === elementObj.element) {
      return sortBy.reverse ?
        (
          <i className="fa fa-sort-desc" aria-hidden="true"></i>
        ) :
        (
          <i className="fa fa-sort-asc" aria-hidden="true"></i>
        )
    }
    return null
  }

  render () {
    return (
      <th className ={`table-title ${this.props.cssClass.class}`} onClick={(e) => this.handleClick(e, this.props.cssClass.element, this.props.cssClass.sortByAlias)}>
        <span className="title-text">
          { this.props.head }
        </span>
        <span className="sort-caret">
          { this.props.sortBy && this.renderCaret(this.props.sortBy, this.props.cssClass)}
        </span>
      </th>
    )
  }
}

TableHead.propTypes = {
  head: PropTypes.string,
  cssClass: PropTypes.object,
  sortBy: PropTypes.object,
  sort: PropTypes.func
}

export default TableHead;
