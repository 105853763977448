import React from 'react'
import PropTypes from 'prop-types'
import DataTable from './DataTable'
import Modal from '../../shared/Modal'
import BulkEditModal from '../../shared/Table/components/bulkEditModal'
import BulkCopy from '../../shared/Table/components/bulkCopy'
import Pagination from '../pagination/pagination'

class ItemsTable extends React.Component {
  constructor (props) {
    super(props)
    const search_location = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')))

    this.state = {
      ...props,
      ...{
        sortBy: {
          column: props.defaultSortBy,
          reverse: false
        },
        pageNumber: search_location.get('page') || "1",
        bulkDeleteModal: {
          show: false,
          title: "Confirmation",
          promptText: "Are you sure you want to delete these items?"
        },
        bulkItems: [],
        showBulkCopyModal: false
      }
    }
  }

  componentDidMount () {
    const queries = {}
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
      queries[key] = value
    })
    const { order = "asc" } = queries
    let reverse = order === "desc" || !(order === "asc")
    const bulkItems = (JSON.parse(sessionStorage.getItem(this.props.sessionStore)) || []).map(Number)
    this.setState({
      bulkItems: bulkItems,
      sortBy: {
        column: queries.sortBy || this.state.sortBy.column,
        reverse : reverse || this.state.sortBy.reverse
      },
      items: this.props.items.map( (item) => ({
          ...item,
          ...{
            associate: bulkItems.indexOf(item.id) !== -1
          }
        })
      )
    })
    if (this.props.canBulkEdit) {
      this.setState({
        showBulkEditModal: false
      })
    }

    PubSub.subscribe('ClearSelectedItemData', () => {
      this.clearBulkItems();
    })
    this.markSearchQuery()
  }

  componentDidUpdate() {
    this.markSearchQuery()
  }

  componentWillUnmount() {
    PubSub.unsubscribe('ClearSelectedItemData')
  }

  markSearchQuery() {
    let param_query = $.getUrlVars(window.location.href).q
    if(param_query) {
      $('.table-data.name').mark(decodeURIComponent(param_query))
    }
  }

  handleAssociate(itemId, itemArrayIndex) {
    const { items, bulkItems } = this.state
    items[itemArrayIndex].associate = !items[itemArrayIndex].associate
    if (items[itemArrayIndex].associate) {
      bulkItems.push(itemId)
    } else {
      bulkItems.splice($.inArray(itemId, bulkItems), 1)
    }
    this.setState({
      items,
      bulkItems
    })
    sessionStorage[this.props.sessionStore] = JSON.stringify(bulkItems.map(String))
  }

  changePageNumber (pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.buildUrl() })
  }

  sort (column, sortByAlias, reverse = false) {
    if (column === this.state.sortBy.column) {
      reverse = !this.state.sortBy.reverse
    }
    const sortBy = {
      ...this.state.sortBy,
      ...{
        column,
        reverse
      }
    }
    this.setState({
      sortBy
    }, () => {
      this.buildUrl(sortByAlias)
    })
  }

  buildUrl (sortByAlias = "") {
    const { column, reverse } = this.state.sortBy
    const order = reverse ? "desc" : "asc"
    let url = this.state.base_url
    if (window.location.search) {
      url += window.location.search
    }
    url = $.buildURL("sortBy", sortByAlias || column, url)
    url = $.buildURL("order", order, url)
    url = $.buildURL("page", this.state.pageNumber, url)
    this.fetchData(url)
  }

  fetchData (url) {
    const search_location = "?" + url.split("?").last()
    history.replaceState('/', null, search_location)

    $.ajax({
      cache: false,
      url: url,
      dataType: 'json',
      success: (data) => {
        let { bulkItems } = this.state
        const items = data.items.map(item => {
          item.associate = bulkItems.indexOf(item.id) > -1
          return item
        })
        this.setState({
          items: items,
          total_items: data.total_items || this.props.total_items
        })
      }
    })
  }

  statutoryItem(id) {
    let items = this.state.items
    let item = items.filter(item => {
     return item.id === id
    })[0]

    return ['locked', 'statutory'].indexOf(item.category) > -1
  }

  handleDelete(id) {
    let req_url = ''
    if(this.props.statutory_check && this.statutoryItem(id)){
      req_url = `${this.state.url}/${id}/remove_statutory_item`
    } else {
      req_url = `${this.state.url}/${id}/`
    }
    $.ajax({
      method: 'DELETE',
      url: req_url,
      dataType: 'json',
      success: (response) => {
        this.state.items.length === 1 && this.state.pageNumber != 1 ? this.changePageNumber(this.state.pageNumber - 1) : this.buildUrl()
        toastr.success(response.message)
      },
      error: (error) => {
        toastr.error(error.responseJSON.message)
      }
    })
  }

  gotoLink (id, e, editUrl) {
    const {url, action, searchAll} = this.state
    if (e.target.classList.contains("fa-trash-o")
    || e.target.parentElement.classList.contains("associate-input-td")
    || e.target.classList.contains("associate-input-td")) {
    } else {
      if (action) {
        window.location = `${url}/${id}/${action}`
      } else {
        if (searchAll) {
          window.location = `${url}/${editUrl}?mode=view&q=`
        } else {
          window.location = `${url}/${id}/edit?mode=view&q=${$.getUrlVars(window.location.href).q || ''}`
        }
      }
    }
  }

  clearBulkItems () {
    this.setState({
      bulkItems: [],
      items: this.state.items.map( (item) => ({
        ...item,
        ...{
          associate: false
        }
      }))
    })
    sessionStorage[this.props.sessionStore] = "[]"
  }

  buildEditData (data) {
    const statusField = data.status ? {status: data.status} : {}
    const effectiveDateRange = data.effective_start_date && data.effective_end_date ?
      {
        effectiveDateRange: {
          effectiveStartDate: data.effective_start_date,
          effectiveEndDate: data.effective_end_date
        }
      } : {}
    const leave_scenario_id = data.leave_scenario_id ? {leaveScenario: data.leave_scenario_id} : {}
    const leave_location_ids = data.leave_location_ids ? {leaveLocation: data.leave_location_ids} : {}
    const health_plan = data.custom_filter_health_plan ? {healthPlan: data.custom_filter_health_plan} : {}
    const leaveCategory = data.leave_category_id ? {leaveCategory: data.leave_category_id} : {}
    const jobCategoryOptions = data.job_category_ids || data.job_category_id
    const jobCategory = jobCategoryOptions ? {jobCategory: jobCategoryOptions} : {}
    const timelineEventType = data.timeline_event_type ? { timelineEventType: data.timeline_event_type } : {}
    const remainingBalance = data.show_remaining_balances ? {remainingBalance: data.show_remaining_balances} : {}
    const retireScenario = data.retire_scenarios && this.props.companyItem ? {retireScenario: data.retire_scenarios} : {}
    const hardStopScenario = data.hard_stop_scenarios && this.props.companyItem ? {hardStopScenario: data.hard_stop_scenarios} : {}
    return {
      ...statusField,
      ...effectiveDateRange,
      ...leave_scenario_id,
      ...leave_location_ids,
      ...health_plan,
      ...leaveCategory,
      ...jobCategory,
      ...timelineEventType,
      ...retireScenario,
      ...hardStopScenario,
      ...remainingBalance,
    }
  }

  showBulkEditModal () {
    if (this.state.showBulkEditModal) {
      return <BulkEditModal
        companyItem={this.props.companyItem}
        itemsCount={this.state.bulkItems.length}
        handleBulkEditModal={this.handleBulkEditModal.bind(this)}
        handleBulkEdit={this.handleBulkEdit.bind(this)}
        isBenefitLibrary={this.props.sessionStore == 'leave_benefits_store'}
        data={this.buildEditData(this.state.editData)} />
    }
  }

  showBulkCopy () {
    if (this.state.showBulkCopyModal && this.props.allowCopyToIp) {
      return <BulkCopy
        handleBulkCopy={this.handleBulkCopy.bind(this)}
        hideBulkCopyModal={this.hideBulkCopyModal.bind(this)}
        itemsCount={this.state.bulkItems.length}
        url={this.state.url}
        allowCopyToIp={this.props.allowCopyToIp}
        ipId={this.props.ipId}
        bulkItems={this.state.bulkItems}
      />
    }
  }

  handleBulkEditModal () {
    const showModal = !this.state.showBulkEditModal
    if (showModal) {
      const bulkUrl = this.state.bulkUrl || this.state.url
      const url = `${bulkUrl}/bulk_editable_items`
      $.getJSON(url, {ids: this.state.bulkItems }, (res) => {
        this.setState({
          showBulkEditModal: showModal,
          editData: res.data
        })
      })
    } else {
      this.setState({
        showBulkEditModal: showModal,
        editData: {}
      })
    }
  }

  handleBulkCopy (ipParams) {
    const _this = this
    $('.bulk-copy-btn').prop('disabled', true)
    const url = this.state.bulkUrl || this.state.url
    jQuery.ajax({
      type: 'POST',
      url: `${url}/copy_items`,
      data: {
        ids: this.state.bulkItems,
        ...this.item_company_params(),
        ...ipParams
      },
      success: function(res) {
        if (ipParams.normalChecked){
          const items = _this.state.items.concat(res.items)
          _this.setState( {items} )
        }
        _this.clearBulkItems()
        _this.setState({showBulkCopyModal: false})
        toastr.success('Items copied successfully')
        $('.bulk-copy-btn').prop('disabled', false)
      },
      error: function(error_message) {
        toastr.error('Items not copied')
        $('.bulk-copy-btn').prop('disabled', false)
        _this.setState({showBulkCopyModal: false})
      }
    })
  }

  hideBulkCopyModal () {
    this.setState({
      showBulkCopyModal: false
    })
  }

  copyBulkItems () {
    if (this.props.allowCopyToIp) {
      this.setState({
        showBulkCopyModal: !this.state.showBulkCopyModal,
      })
    }
    else {
      const _this = this
      const ipParams = { 'normalChecked': true, 'ip': this.props.ipId }
      $('.bulk-copy-btn').prop('disabled', true)
      jQuery.ajax({
        type: 'POST',
        url: `${this.state.url}/copy_items`,
        data: {
          ids: this.state.bulkItems,
          ...this.item_company_params(),
          ...ipParams
        },
        success: function(res) {
          const items = _this.state.items.concat(res.items)
          _this.setState( {items} )
          _this.clearBulkItems()
          toastr.success('Items copied successfully')
          $('.bulk-copy-btn').prop('disabled', false)
        },
        error: function(error_message) {
          toastr.error('Items not copied')
          $('.bulk-copy-btn').prop('disabled', false)
        }
      })
    }
    // if (showModal) {
    //   // alert(JSON.stringify(this.state.bulkItems))
    //   // const url = `${this.state.url}/bulk_editable_items`
    //   // $.getJSON(url, {ids: this.state.bulkItems }, (res) => {
    //   //   this.setState({
    //   //     showBulkEditModal: showModal,
    //   //     editData: res.data
    //   //   })
    //   // })
    // } else {
    //   this.setState({
    //     showBulkCopyModal: showModal,
    //   })
    // }
    // const _this = this
    // $('.bulk-copy-btn').prop('disabled', true)
    // jQuery.ajax({
    //   type: 'POST',
    //   url: `${this.state.url}/copy_items`,
    //   data: {
    //     ids: this.state.bulkItems,
    //     ...this.item_company_params()
    //   },
    //   success: function(res) {
    //     const items = _this.state.items.concat(res.items)
    //     _this.setState( {items} )
    //     _this.clearBulkItems()
    //     toastr.success('Items copied successfully')
    //     $('.bulk-copy-btn').prop('disabled', false)
    //   },
    //   error: function(error_message) {
    //     toastr.error('Items not copied')
    //     $('.bulk-copy-btn').prop('disabled', false)
    //   }
    // })
  }

  item_company_params () {
    return this.props.allow_item_company_mapper ? this.item_company_mapper() : {}
  }

  item_company_mapper () {
    let item_company_mapper = {}
    let _this = this
    this.state.bulkItems.map(function(item_id) {
      item_company_mapper[item_id] = _this.props.items.filter(item => item.id == item_id)[0]['company_id']
    });
    return { item_company_mapper: item_company_mapper }
  }

  handleBulkEdit (params) {
    const _this = this
    $.displaySpinner('.modal-body')
    $('.confirmation-buttons').prop('disabled', true)
    const url = this.state.bulkUrl || this.state.url
    jQuery.ajax({
      type: 'PUT',
      url: `${url}/bulk_update_fields`,
      data: {
        ids: this.state.bulkItems,
        item_params: params
      },
      success: function(res) {
        const { items } = _this.state
        $.each(res.items, function (ind, res_item) {
          $.each(items, function (i, item) {
            if (item.id == res_item.id) {
              items[i] = res_item
              return false
            }
          })
        })

        _this.setState({
          items: items,
          showBulkEditModal: false
        })
        _this.clearBulkItems()
        toastr.success('Items updated successfully')
      }
    })
  }

  handleBulkDelete() {
    const url = this.state.bulkUrl || this.state.url
    jQuery.ajax({
      type: 'DELETE',
      url: `${url}/bulk_destroy`,
      data: {
        ids: this.state.bulkItems
      },
      success: (res) => {
        const items = this.state.items.filter(item => this.state.bulkItems.indexOf(item.id) < 0)
        this.setState({items})
        this.clearBulkItems()
        toastr.success(res.message)
      },
      error: (response) => {
        this.clearBulkItems()
        toastr.error(response.responseJSON.message, 'Error')
      }
    })
  }

  handleBulkDeleteModal() {
    const bulkDeleteModal = {
      ...this.state.bulkDeleteModal,
      show: true
    }
    this.setState({
      bulkDeleteModal
    })
  }

  hideBulkDeleteModal() {
    const bulkDeleteModal = {
      ...this.state.bulkDeleteModal,
      ...{
        show: false
      }
    }
    this.setState({
      bulkDeleteModal
    })
  }

  showBulkDeleteModal() {
    if (this.state.bulkDeleteModal.show) {
      return (
        <Modal
          {...this.state.bulkDeleteModal}
          hideModalInParent={this.hideBulkDeleteModal.bind(this)}
          approve={this.handleBulkDelete.bind(this)}
          className='bulk_destroy'
        >
          <span>{this.state.bulkDeleteModal.promptText}</span>
        </Modal>
      )
    }
  }

  handleAllSelect (checked) {
    const items = this.state.items.map(item => (item.id))
    const bulkItems = this.state.bulkItems || []
    const filteredItems = checked ? bulkItems.concat(this.state.items.filter(item => !item.locked_item).map(item => (item.id))).uniq() : bulkItems.filter(item => items.indexOf(item) < 0)

    this.setState({
      bulkItems: filteredItems,
      items: this.state.items.map( (item) => ({
        ...item,
        ...{
          associate: checked
        }
      }))
    })
    sessionStorage[this.props.sessionStore] = JSON.stringify(filteredItems.map(String))
  }

  paginationClass () {
    return this.props.total_items && this.props.total_items > 30 ? "pagination-on" : ''
  }

  render () {
    return (
      <div>
        <div className={`items-table-pagination ${this.paginationClass()}`}>
          <Pagination
            totalItems={this.props.total_items}
            changePageNumber={this.changePageNumber.bind(this)}
            pageNumber={this.state.pageNumber}
            itemsPerPage={30}
          />
        </div>
        <DataTable
          {...this.props}
          heads={this.state.heads}
          items={this.state.items}
          classes={this.state.classes}
          gotoLink={this.gotoLink.bind(this)}
          sort={this.sort.bind(this)}
          sortBy={this.state.sortBy}
          canAssociate={this.state.canAssociate}
          handleDelete={this.handleDelete.bind(this)}
          handleAssociate={this.handleAssociate.bind(this)}
          bulkItems={this.state.bulkItems}
          clearBulkItems={this.clearBulkItems.bind(this)}
          handleBulkEditModal={this.handleBulkEditModal.bind(this)}
          copyBulkItems={this.copyBulkItems.bind(this)}
          handleBulkDeleteModal={this.handleBulkDeleteModal.bind(this)}
          handleAllSelect={this.handleAllSelect.bind(this)}
          paginationClass={this.paginationClass()}
        />
        { this.showBulkEditModal() }
        { this.showBulkDeleteModal() }
        { this.showBulkCopy() }
      </div>
    )
  }
}

ItemsTable.propTypes = {
  items: PropTypes.array
}

export default ItemsTable;
