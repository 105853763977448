import React from 'react'

class MilestoneDetail extends React.Component {
  renderMilestoneName (milestone) {
    const editable = milestone.editable && !this.props.isLeavePlanPartner
    let editIcon = null
    if (editable) {
      editIcon = <span className='edit fa fa-pencil-square-o' onClick={() => this.props.handleMilestoneEdit(milestone)} />
    }
    return (
      <div key={milestone.calendar_event_id}>
        <span className='name'>
          {milestone.name}
        </span>
        { editIcon }
      </div>
    )
  }

  renderScheduleLinks () {
    if (!this.props.isAdmin) {
      return (
        <div>
          <div className='schedule-link'>
            <a href={`/schedules?input_date_for_calendar_view=${this.props.detail.date}&calendar_view=true&summary_date=${this.props.detail.date}`}>
              <span className='link-icon fa fa-th-large' />
              <span className='link-text'>
                view on calendar
              </span>
            </a>
          </div>
        </div>
      )
    }
  }

  render () {
    return (
      <div
        className={`${this.props.milestone_display}`}
        id='milestone-detail'
        onMouseLeave={this.props.hideDetail}
      >
        <div className='circle' />
        { this.props.detail.milestones.map((milestone) => this.renderMilestoneName(milestone))}
        <div className='date'>
          { moment(this.props.detail.date).format(gon.default_date_format_string.toUpperCase()) }
        </div>
        <hr className='horizontal-line' />
        <div className='schedule-link-div'>
          <div className='schedule-links'>
            { this.renderScheduleLinks() }
          </div>
        </div>
      </div>
    )
  }
}

export default MilestoneDetail;