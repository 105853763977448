import React from 'react'
import SharedBenefitTable from './containers/sharedBenefitTable'
import AddManualBenefitModal from './containers/addManualBenefitModal'
import BenefitOverrideModal from './containers/benefitOverrideModal'
import ScheduleModalBox from '../../employee/schedules/common_components/ScheduleModalBox'

class SharedBenefits extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      sharedBenefits: props.data,
      benefitDetail: {
        show: false,
        description: '',
        image_url: '',
        name: ''
      },
      overrideDetail: {
        show: false,
        benefit: {}
      },
      addManualBenefit: {
        show:false
      },
      sortBy: {
        column: "name",
        reverse: false
      },
      undo_override: false
    }
  }

  componentDidMount () {
    this.subscribeEvents()
  }

  componentWillUnmount () {
    PubSub.unsubscribe('fetchLeaveBenefits')
  }

  subscribeEvents () {
    PubSub.subscribe('fetchLeaveBenefits', () => {
      this.fetchLeaveBenefits()
    })
  }

  fetchLeaveBenefits () {
    $.getJSON(`/admin/shared_leave_plans/${this.props.leave_plan_id}/leave_plan_benefits`, (response) => {
      this.setState({
        sharedBenefits: response.shared_benefits
      })
    })
  }

  handleItemClose () {
    const benefitDetail = {
      show: false,
      description: '',
      image_url: '',
      name: ''
    }
    this.setState({benefitDetail})
  }

  showBenefitDetail () {
    if(this.state.benefitDetail.show){
      return( 
              <ScheduleModalBox
                item={{name: this.state.benefitDetail.name}}
                handleItemClose={this.handleItemClose.bind(this)}
                item_type='benefit_event'
                description={this.state.benefitDetail.description}
                image_url={this.state.benefitDetail.image_url}
                isAdmin={true}
                pending={this.state.benefitDetail.pending}
              />)
    }
  }

  showOverrideModal () {
    if(this.state.overrideDetail.show){
      return( 
              <BenefitOverrideModal
                detail={this.state.overrideDetail}
                approve={this.overrideBenefit.bind(this)}
                cancel={this.cancelOverride.bind(this)}
              />)
    }
  }
  
  showAddManualBenefit () {
    if (this.state.addManualBenefit.show) {
      return (
        <AddManualBenefitModal
          show={true}
          cancel={this.cancelManual.bind(this)}
          leavePlanId={this.props.leave_plan_id}
          addManualBenefitsToState={this.addManualBenefits.bind(this)}
        />
      )
    }
  }

  addManualBenefits(benefits) {
    const sharedBenefits = this.state.sharedBenefits.concat(benefits)
    this.setState({sharedBenefits})
  }

  updateOverriddenBenefit (benefit) {
    let sharedBenefits = this.state.sharedBenefits
    const index = this.findBenefitIndex(benefit.id)
    sharedBenefits[index] = benefit
    this.setState({sharedBenefits})
  }

  findBenefitIndex (benefit_id) {
    const benefit = $.grep(this.state.sharedBenefits, (benefit) => {
      return parseInt(benefit.id) === parseInt(benefit_id)
    })[0]
    return this.state.sharedBenefits.indexOf(benefit)
  }

  overrideBenefit (params) {
    this.hideOverrideModal()
    $.post(`/leave_plan_benefits/${params.id}/override`, params, (response) => {
      if(response.success) {
        toastr.success('Successfully Overridden.')
        PubSub.publish('fetchLeaveBenefits')
        PubSub.publish('fetchTaskItems')
        PubSub.publish('fetchDashboardBenefits')
        PubSub.publish('fetchLeavePlanHistories')
      } else {
        toastr.error(response.error_message, 'Error')
      }
    })
  }

  fetchBenefitDetail (id) {
    $.getJSON(`/leave_plan_benefits/${id}/fetch_details`, (data) => {
      const benefitDetail = {
        description: data.description,
        image_url: data.image_url,
        name: data.name,
        show: true,
        pending: data.pending
      }
      gon.company_variables = {...gon.company_variables, ...data.statutory_substitutions_options}
      this.setState({benefitDetail})
    })
  }

  addManualBenefit() {
    let addManualBenefit = {
      show: true
    }
    this.setState({addManualBenefit})
  }

  deleteBenefit (id) {
    $.delete(`/leave_plan_benefits/${id}/undo_override`, (response) => {
      if (response.success) {
        PubSub.publish('fetchLeaveBenefits')
        PubSub.publish('fetchTaskItems')
        PubSub.publish('fetchDashboardBenefits')
        PubSub.publish('fetchLeavePlanHistories')
        toastr.success('Successfully reverted override')
      } else {
        toastr.error(response.error_message, 'Error')
      }
    })
  }

  editBenefit (benefit) {
    const overrideDetail = {
      show: true,
      benefit: benefit.overridden_fields
    }

    this.setState({overrideDetail})
  }

  cancelOverride () {
    this.hideOverrideModal()
  }

  cancelManual(){
    this.hideManualModal()
  }

  hideManualModal(){
    const addManualBenefit ={
      show: false
    }
    this.setState({addManualBenefit})
  }

  hideOverrideModal () {
    const overrideDetail = {
      show: false,
      benefit: {}
    }

    this.setState({overrideDetail})
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: false
      }
    }
    this.setState (
      {sortBy}, () => {
    })
  }

  getSortedBenefits (benefit, sortBy) {
    const dates = ["overridden_date"]
    const arrayOfDates = ["start_dates", "end_dates"]
    const numbers = ['eligible_hours']
    const sortByColumn = sortBy.column
    const sorted = benefit.sort (((benefit1, benefit2) => {
      if (dates.indexOf(sortByColumn) >= 0) {
        return this.sortDate(benefit1[sortByColumn], benefit2[sortByColumn])
      } else if ((arrayOfDates.indexOf(sortByColumn) >= 0)) {
        return this.sortDate(benefit1[sortByColumn][0], benefit2[sortByColumn][0])
      } else if (numbers.indexOf(sortByColumn) >= 0) {
        return this.sortNumber(benefit1[sortByColumn], benefit2[sortByColumn])
      } else {
        return this.sortString(benefit1[sortByColumn], benefit2[sortByColumn])
      }
    }).bind(this))
    return sortBy.reverse ? sorted.reverse() : sorted
  }

  sortDate (d1, d2) {
    d1 = new Date(d1)
    d2 = new Date(d2)
    if (d1.getTime() == NaN) return -1;
    if (d2.getTime() == NaN) return 1;
    return d1-d2
  }

  sortNumber (n1, n2) {
    n1 = parseFloat(n1) || 0
    n2 = parseFloat(n2) || 0
    return n1-n2
  }

  sortString (str1, str2) {
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()
    if (str1<str2) {
      return -1
    } else if (str2<str1) {
      return 1
    }
    return 0
  }

  classesArray () {
    return [{
      element: "name",
      class: "benefit-name sortable"
    },{
      element: "eligibility",
      class: "eligibility sortable"
    }, {
      element: "eligible_hours",
      class: "eligibility sortable"
    }, {
      element: "start_dates",
      class: "date dates sortable"
    }, {
      element: 'end_dates',
      class: 'date dates sortable'
    }, {
      element: 'overridden_by',
      class: 'overridden-name sortable'
    }, {
      element: 'overridden_date',
      class: 'date sortable'
    }, {
      element: 'reason',
      class: 'reason'
    }, {
      element: 'button',
      class: 'button'
    }
    ]
  }

  render () {
    return (
      <div className="shared-benefits-table">
        <SharedBenefitTable
          sharedBenefits={this.getSortedBenefits(this.state.sharedBenefits, this.state.sortBy)}
          classes={this.classesArray()}
          fetchBenefitDetail={this.fetchBenefitDetail.bind(this)}
          addManualBenefit={this.addManualBenefit.bind(this)}
          editBenefit={this.editBenefit.bind(this)}
          deleteBenefit={this.deleteBenefit.bind(this)}
          sort={this.sort.bind(this)}
          sortBy={this.state.sortBy} />
        { this.showBenefitDetail() }
        { this.showOverrideModal() }
        { this.showAddManualBenefit() }
      </div>
    )
  }
}

export default SharedBenefits;