import React from 'react'

class Button extends React.Component {
  constructor(props) {
    super(props)
  }

  buttonIcon() {
    return <i className={this.props.iconClass}></i>;
  }

  render() {
    const clickHandlerAttach = (typeof this.props.handleClick === 'function')
    const { className: buttonClass, name } = this.props
    return (
      <button
        className={buttonClass}
        onClick={(e) => clickHandlerAttach ? this.props.handleClick(e) : false }
        type={this.props.type}
      >
        {this.buttonIcon()}
        <span>
          {name}
        </span>
      </button>
    )
  }
}

export default Button
