import React from 'react'

class NotificationHeader extends React.Component {
  constructor() {
    super()
  }

  render () {
    return (
      <div className='notification-header'>
        <span className='notification-text'>
          Notifications
        </span>
        {this.props.hideMarkAllRead ? null :
          <span className='mark-all-read' onClick={this.props.markAllRead}>
            Mark All as read
          </span>}
      </div>
    )
  }
}

export default NotificationHeader