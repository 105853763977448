import React from 'react'

class InstantNotification extends React.Component {
  constructor () {
    super ()
  }

  notificationClass () {
    if(this.props.notification.read){
      return 'notification-link read'
    } else {
      return 'notification-link unread'
    }
  }

  notificationLink () {
    return '/admin/shared_leave_plans/' + this.props.notification.notifiable_id + '/details' + '?notification_id=' + this.props.notification.id
  }

  showDetail (e) {
    const link = $(e.target).closest('a')
    const markAsRead = $(e.target).closest('.mark-as-read-div')
    if(link.length && !markAsRead.length){
      window.location = link.attr('href')
    } else if (markAsRead.length) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  notificationIcon () {
    if(this.props.notification.action === 'shared') {
      return 'fa fa-calendar-plus-o'
    } else {
      return 'fa fa-calendar-check-o'
    }
  }

  notificationDescription () {
    gon.company_variables = {username: this.props.notification.username}
    return this.props.notification.description
  }

  notificationDate () {
    return moment(this.props.notification.updated_at).tz('America/Los_Angeles').format("MMMM D, YYYY HH:mm:ss A")
  }

  toggleReadHelp (e) {
    if(e.type === 'mouseenter') {
     $('.mark-as-read-div .mark-as-read-help').show()
    } else {
      $('.mark-as-read-div .mark-as-read-help').hide()
    }
  }

  markAsReadIcon () {
    if(this.props.notification.read) {
      return 'fa fa-circle-o'
    } else {
      return 'fa fa-circle'
    }
  }

  markAsReadText () {
    if(this.props.notification.read) {
      return 'Read'
    } else {
      return 'Mark As Read'
    }
  }

  markAsRead (e) {
    if(!this.props.notification.read) {
      this.props.markAsRead(this.props.notification.id)
    }
  }

  readHelpTextClass () {
    if(this.props.notification.read) {
      return 'mark-as-read-div read'
    } else {
      return 'mark-as-read-div unread'
    }
  }

  markAsReadDiv () {
    return (
      <div className={this.readHelpTextClass()}>
        <div className='mark-as-read-help'>
          <div className='mark-as-read-text'>
            {this.markAsReadText()}
          </div>
          <div className='pointer'/>
        </div>
        <i className={this.markAsReadIcon()} onMouseEnter={this.toggleReadHelp} onMouseLeave={this.toggleReadHelp} onClick={this.markAsRead.bind(this)}>
        </i>
      </div>
    )
  }

  render () {
    return (
      <div className='notification-container'>
        <a className={this.notificationClass()} href={this.notificationLink()} onClick={this.showDetail}>
          <div className='icon-container'>
            <i className={this.notificationIcon()}/>
          </div>
          <div className='description-container'>
            <div className='notification-description' dangerouslySetInnerHTML={{__html: _getDescription(this.notificationDescription())}}/>
            <div className='notification-date'>
              {this.notificationDate()}
            </div>
          </div>
          {this.markAsReadDiv()}
        </a>
      </div>
    )
  }

}

export default InstantNotification;