import React from 'react'
import MultiStateToggle from '../../helpers/toggle'

class BenefitOverrideModal extends React.Component {
  constructor (props) {
    super (props)
    const benefit = this.props.detail.benefit
    this.state = {
      show: this.props.detail.show,
      id: benefit.id,
      eligible: benefit.eligibility,
      eligible_leave_hours: benefit.eligible_hours,
      reason: benefit.reason || '',
      using_benefit: benefit.using_benefit,
      pending: benefit.pending,
      eligible_intermittent_dates: benefit.eligible_intermittent_dates
    }
  }

  componentDidMount () {
    this.initializeBootstrapSwitchUsing()
    this.initializeDatePicker()
  }

  componentDidUpdate () {
    this.initializeDatePicker()
    this.initializeBootstrapSwitchUsing()
  }

  initializeDatePicker () {
    const _this = this
    $('.datepicker')
      .unbind('changeDate')
      .datepicker('destroy')
    $.each($('.datepicker'), (index, datepicker) => {
      let options = {format: 'mm/dd/yyyy', clearBtn: true}
      $(datepicker).datepicker(
        options
      ).on('changeDate', (e) => {
        let temp = {}
        let name = $(e.target).data('name')
        let value = e.target.value
        if (name.includes('-')){
          let ind = name.split('-')[1]
          let nameTag = name.split('-')[0]
          let ranges = _this.state.eligible_intermittent_dates
          ranges[ind][nameTag] = value
          _this.setState({eligible_intermittent_dates: ranges})
        }
        $(e.target).datepicker('hide')
      }).keydown(false)
    })
  }

  toggleLabels() {
   return (
    [
      {
        text: 'Eligible',
        value: 'eligible',
        checked: !this.state.pending && this.state.eligible,
        helperText: 'Employee is eligible to use the benefit in this LeavePlan and will see it in their LeavePlan',
      },
      {
        text: 'Pending',
        value: 'pending',
        checked: this.state.pending,
        helperText: 'Employee may be eligible and will see the benefit in their LeavePlan as Pending HR',
      },
      {
        text: 'Not Eligible',
        value: 'ineligible',
        checked: !this.state.eligible && !this.state.pending,
        helperText: 'Employee is not eligible to use the benefit in this LeavePlan and will not see it in their LeavePlan',
      }
    ]
   )
  }

  initializeBootstrapSwitchUsing() {
    $('.using-switch').bootstrapSwitch('destroy')
    $('.using-switch').bootstrapSwitch()
      .on('switchChange.bootstrapSwitch', (e) => {
        const using_benefit = this.state.using_benefit
        const intermittent = this.state.intermittent
        this.setState({using_benefit: !using_benefit})
      }).bind(this)
  }

  handleDateChange () {}
  handleSwitchChange () {}

  addClass (element, newClass) {
    element.className += ` ${newClass}`;
  }

  closePrompt () {
    const modalContainer = document.getElementsByClassName("react-modal");
    const modal = document.getElementsByClassName("react-modal-content modal-content");
    const backShadow = document.getElementsByClassName("back-shadow");
    this.addClass(modalContainer[0], "remove")
    this.addClass(modal[0], "remove slideOutPosition")
    this.addClass(backShadow[0], "remove")
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.cancel()
    }).bind(this), 500 )
  }

  renderMaxEligibleHours () {
    return (
      <div className='form-group'>
        <label className='label-control'> Max. Eligible Hours: </label>
        <input
          type='number'
          className='form-control'
          value={this.props.detail.benefit.max_leave_hours === '0.0' ? '0.0' : this.state.eligible_leave_hours}
          step='0.01'
          min='0.0'
          onChange={(e) => this.handleTextChange('eligible_leave_hours', e.target.value)}
        />
      </div>
    )
  }

  renderEligibleValues () {
    return (
      <div className="eligible-hours">
        {(this.state.eligible && this.renderMaxEligibleHours())}
      </div>
    )
  }


  renderCalendarValues () {
    return this.state.eligible && this.props.detail.benefit.calculate ?
      (
        <div>
          {this.renderEligibleValues()}
          {this.renderIntermittentEligibleRange()}
          {this.renderIntermittentEligilibilityRangeList()}
        </div>
      ) : null
  }

  addEligibleIntermittentRange () {
    let ranges = this.state.eligible_intermittent_dates
    ranges.push({start_date: '', end_date: ''})
    this.setState({eligible_intermittent_dates: ranges})
  }

  renderIntermittentEligibleRange () {
    return (
      <div className='eligible-usage-start-end'>
        <div className='form-group'>
          <label className='label-control'>
            Allow Benefit Eligible Range:
          </label>
        </div>
        <div className='form-group'>
          <label onClick={this.addEligibleIntermittentRange.bind(this)}>
            Add Range
          </label>
        </div>
      </div>
    )
  }
  
  removeEligibleIntermittentRange (index) {
    const ranges = this.state.eligible_intermittent_dates.filter((range, ind) => !(index==ind))
    this.setState({eligible_intermittent_dates: ranges})
  }

  renderIntermittentDatePicker(index, date, dataName, datepickerClass) {
    return (
      <div className='input-group'>
        <input
          type='text'
          className={`form-control datepicker intermittent`}
          data-name={`${dataName}-${index}`}
          value={date}
          required='required'
        />
        <span className='input-group-addon btn-darkgray' onClick={this.openDatePicker}>
          <span className='fa fa-calendar-o fa-lg'/>
        </span>
      </div>
    )
  }

  renderSingleIntermittent(index, range) {
    return (<div className='eligible-usage-start-end'>
        <div className='form-group'>
          <label className='label-control'>
          </label>
        </div>
        <div className='form-group'>
          {this.renderIntermittentDatePicker(index, range['start_date'], 'start_date', 'actual-start-datepicker')}
        </div>
        <div className='form-group'>
          <label className='label-control end-date-label'>
            to
          </label>
            {this.renderIntermittentDatePicker(index, range['end_date'], 'end_date', 'actual-end-datepicker')}
        </div>
        <div className='form-group'>
          <label className='label-control end-date-label' onClick={() => this.removeEligibleIntermittentRange(index)}>
            Remove
          </label>
        </div>
      </div>)
  }

  renderIntermittentEligilibilityRangeList () {
    return (this.state.eligible_intermittent_dates.map((range, index) => this.renderSingleIntermittent(index, range)))
  }

  renderCheckBox (state, switchname ) {
    return (
      <input
        type='checkbox'
        className={switchname}
        checked={state}
        value='1'
        onChange={this.handleSwitchChange}
      />
    )
  }

  renderUsingSwitch() {
    return (
      <div>
        <div className='form-group'>
          <label className='label-control'> Using? </label>
          {this.renderCheckBox(this.state.using_benefit, 'using-switch')}
        </div>
      </div>
    )
  }

  renderHealthPlanWarning () {
    return this.state.eligible ?
      null :
      <div className='association-warning'>
        Any Tasks, Benefits, or Planning Guides associated to this Benefit will also be changed to Ineligible
      </div>
  }

  renderNoOfDays () {
    return (
      <div>
        <div className='form-group'>
          <label className='label-control'> No. of Days </label>
          <label> {this.props.detail.benefit.days} </label>
        </div>
      </div>
    )
  }

  handleTextChange (key, value) {
    let temp = {}
    temp[key] = value
    this.setState(temp)
  }

  openDatePicker (e) {
    let datepicker = $(e.target).closest('.input-group').find('.datepicker')
    if(!datepicker.is(':disabled')) {
      datepicker.datepicker('show')
    }
  }

  itemImage () {
    return {
      backgroundImage: 'url('+ this.props.detail.benefit.image_url + ')'}
  }

  submitForm (e) {
    e.preventDefault()
    this.props.approve(this.state)
    this.closePrompt()
  }

  eligibilityCheckHandler (eligibilityValue) {
    let eligibilityMapper = {
      eligible: {
        eligible: true, pending: false
      },
      pending:  {
        pending: true, eligible: true
      },
      ineligible: {
        eligible: false, pending: false
      }
    }

    this.setState(eligibilityMapper[eligibilityValue])
  }

  renderHelperText () {
    return (
      <span className='tooltip-helper eligible-tooltip'>
        <div className='helper-text'>
          <div className={`helper-tooltip hidden`}>
            <div className='helper-tooltip-text'>
            </div>
            <div className='helper-tooltip-pointer'></div>
          </div>
        </div>
      </span>
    )
  }

  render () {
    return this.state.show ? (
      <div className="react-modal">
        <div className="back-shadow" onClick={() => this.closePrompt()}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <div className='item-header-container'>
              <div className='item-header'>
                <div className='name'>
                  {this.props.detail.benefit.name}
                </div>
              </div>
              <div className='image-div' style={this.itemImage()}/>
            </div>
          </div>
          <div className="modal-body leave-plan-benefit-modal">
            <form className='leave-plan-benefit-override-form' action='#' onSubmit={this.submitForm.bind(this)}>
              <div className='form-group'>
                <label className='label-control'> Eligible? </label>
                <MultiStateToggle
                  labelClass='toggle-btn'
                  wrapperClass='eligible-pending-wrapper'
                  labels={this.toggleLabels()}
                  handleClick={(value) => this.eligibilityCheckHandler(value)}
                />
              </div>

              {this.renderHealthPlanWarning()}
              {this.state.eligible && this.props.detail.benefit.calculate ? this.renderUsingSwitch() : null}
              {this.state.eligible && this.props.detail.benefit.calculate && this.state.using_benefit ? this.renderNoOfDays() : null}
              {this.state.eligible ? this.renderCalendarValues() : null}
              <div className='form-group'>
                <label className='label-control'> Reason: </label>
                <textarea
                  type='text'
                  className='form-control'
                  required='required'
                  value={this.state.reason}
                  onChange={(e) => this.handleTextChange('reason', e.target.value)}
                />
              </div>
              <div className="buttons">
                <button className="cancel btn btn-danger" type='reset' onClick={this.closePrompt.bind(this)}>
                  Cancel
                </button>
                <button className="submit btn btn-primary" type='submit'>
                  Override
                </button>
              </div>
            </form>
          </div>
          {this.renderHelperText()}
        </div>
      </div>
    ) : null
  }
}

export default BenefitOverrideModal;
