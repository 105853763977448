import React from 'react'
import Button from '../../button'
import Switch from '../../switch'
import Input from '../../input'
import TextArea from '../../text_area'
import StripTable from '../../strip_table'
import FormModal from '../../form_modal'
import Selectpicker from '../../../shared/inputs/selectpicker'
import Modal from '../../../shared/Modal'

class ExhaustionRules extends React.Component {

  constructor(props) {
    super()
    const {
      exhaustion_elimination_period_only,
      delay_eligibility_start_date,
      total_preservable_hours
    } = props.leave_benefit;
    this.state = {
      showFormModal: false,
      showDeleteModal: false,
      total_preservable_hours,
      exhaustion_elimination_period_only,
      delay_eligibility_start_date,
      clickedAction: '',
      time_off_leave_types: [],
      all_time_off_leave_types: [],
      timeOffType: {
        name: '',
        preservable_hours: '',
        time_off_leave_type_id: '',
        order: '',
        id: '',
        help_description_text: '',
      },
      deleteableItem: null,
      tRows: [],
      errors: {},
    };
  }

  componentDidMount() {
    $('.exhaustion-elimination-period').on('switchChange.bootstrapSwitch', (event, state) => {
      this.handleLeavePlanAttributeChange({ exhaustion_elimination_period_only: state })
    });
    this.getAllTimeOffTypes()
  }

  handleEditClick(row) {
    const listOfLeaveTypes = this.state.time_off_leave_types.filter(s => s.type != "edit")
    const editTimeOffLeaveType = this.state.all_time_off_leave_types.filter(s => s.id === row.time_off_leave_type_id)[0]
    const editObj = { ...editTimeOffLeaveType, ...{ type: 'edit' } }
    this.setState({
      clickedAction: 'edit',
      timeOffType: row,
      time_off_leave_types: [editObj, ...listOfLeaveTypes],
      showFormModal: true,
    });
  }

  handleDeleteClick(row, e) {
    e.preventDefault()
    this.setState({
      showDeleteModal: true,
      deleteableItem: row.id,
    });
  }

  renderDelaySwitch () {
    if(this.props.leave_benefit.paid_leave){
      return (
        <div>
          <Switch
            label="Delay Eligibility Start"
            className="delay-elimination-start-date"
            checked= {this.props.leave_benefit.delay_eligibility_start_date}
            onChange={(val) => this.handleLeavePlanAttributeChange({
              delay_eligibility_start_date: val
            })}
          />
        </div>
      )
    }
  }

  handleDeleteElement(id) {
    $.ajax({
      method: 'DELETE',
      url: `/admin/leave_benefits/${this.leaveBenefitID()}/time_off_types/${id}`,
      success: () => {
        toastr.success('Time off type Deleted');
        const newList = this.state.tRows.filter(s => s.id !== id)
        this.setState({
          tRows: newList,
        })
      },
      error: () => {

      },
    })
  }

  hideDeleteModal() {
    this.setState({
      showDeleteModal: false,
      deleteableItem: null,
    });
  }

  tableHeads() {
    return ([
      {
        title: 'id',
        key: 'id',
        className: 'hide',
      },
      {
        title: 'Order',
        key: 'order'
      },
      {
        title: 'Time Off Type',
        key: 'name',
      },
      {
        title: 'Preservable Hours',
        key: 'preservable_hours',
      },
      {
        title: 'time off type leave plan',
        key: 'time_off_leave_type_id',
        className: 'hide',
      },
      {
        title: 'actions',
        key: 'actions',
        render: (row) => {
          return (
            <div>
              <a href="#" className="no-text-decoration" onClick={() => this.handleEditClick(row)}>
                <i className="fa fa-edit"></i>
              </a>
              <a
                href="#" data-confirm-message="Are you sure?"
                className="no-text-decoration delete-item"
                onClick={(e) => this.handleDeleteClick(row, e)}
              >
                <i className="fa fa-trash-o"></i>
              </a>
            </div>
          )
        }
      }
    ]);
  }

  handleAddButtonClick(e) {
    const listOfLeaveTypes = this.state.time_off_leave_types.filter(s => s.type != "edit")
    e.preventDefault()
    this.setState({
      clickedAction: 'new',
      showFormModal: true,
      time_off_leave_types: listOfLeaveTypes,
      timeOffType: {
        ...this.state.timeOffType,
        time_off_leave_type_id: listOfLeaveTypes[0] && listOfLeaveTypes[0].id,
      }
    })
  }

  showModal() {
    this.setState({
      showFormModal: true,
    });
  }

  hideModal() {
    this.setState({
      showFormModal: false,
    });
  }

  handleFormSubmit(e) {
    const validate = this.validateData()
    if (Object.keys(validate).length) {
      this.setState( { errors: validate })
      return false;
    } else {
      this.setState( { errors: {} })
    }
    let data = { ...this.state.timeOffType }
    if (this.state.clickedAction === "edit") {
      data = { ...data, leave_benefit_id: this.leaveBenefitID() }
    }
    const preservableHours = $('.preservable-hours-swtich').bootstrapSwitch('state');
    if (!preservableHours) {
      this.handleInputchange({ preservable_hours: 'ALL' })
      data = { ...data, ...{ preservable_hours: 'ALL' } }
    }
    e.preventDefault();
    const { id: timeOffTypeID } = this.state.timeOffType;
    const { url, method, success } = this.actionMapper({
      leaveBenefitId: this.leaveBenefitID(), timeOffTypeId: timeOffTypeID
    });
    this.sendAsychronousRequest({
      method,
      url,
      data: { time_off_type: data },
      success,
    });
    this.hideModal();
  }

  validateData() {
    const preservableHourSwitch = $('.preservable-hours-swtich').bootstrapSwitch('state');
    const { preservable_hours, order } = this.state.timeOffType;
    let errorList = { }
    if (preservableHourSwitch && !preservable_hours) {
      errorList = { preservableHours: 'required' }
    }
    if (!order) {
      if (Object.keys(errorList).length) {
        errorList = { order: 'required' }
      } else {
        errorList = { ...errorList, ...{ order: 'required' } }
      }
    }
    return errorList
  }

  actionMapper({ leaveBenefitId, timeOffTypeId }) {
    const actionURLs = {
      edit: {
        url: `/admin/leave_benefits/${leaveBenefitId}/time_off_types/${timeOffTypeId}`,
        method: 'PATCH',
        success: (data) => {
          toastr.success('Time off type updated');
          this.updateTimeOffType();
        }
      },
      new: {
        url: `/admin/leave_benefits/${leaveBenefitId}/time_off_types/`,
        method: 'POST',
        success: (data) => {
          if (data.errors) {
            if (data.errors.time_off_leave_type_id.length) {
              toastr.error('Time off leave type should present');
            }
          } else {
            this.appendDataInTableRow(data);
            this.setState({
              timeOffType: this.timeOffTypeEmptyState(),
              time_off_leave_types: data.time_off_leave_types,
            });
            toastr.success('Time off type added');
          }
        }
      }
    }
    return actionURLs[this.state.clickedAction];
  }

  updateTimeOffType() {
    const updatedTimeOffType = this.state.tRows.filter(row => row.id !== this.state.timeOffType.id)
    this.setState({
      tRows: [...updatedTimeOffType, this.state.timeOffType],
    })
  }

  sendAsychronousRequest(params) {
    $.ajax({ ...params,
      failure: (error) => {
        toastr.error(error)
      }
    })
  }

  handleLeavePlanUpdate(e) {
    const exhaustion_elimination_period_only = $('.exhaustion-elimination-period').bootstrapSwitch('state');
    // For Job protected benefit Delay Eligibility Start is not present.
    const delay_eligibility_start_date = $('.delay-elimination-start-date').length > 0 ? $('.delay-elimination-start-date').bootstrapSwitch('state') : false;
    const exhaustion_help_text = $('#exhaustion-requirement-help-text').val()
    this.setState({ exhaustion_elimination_period_only, delay_eligibility_start_date})
    const { total_preservable_hours } = this.state
    e.preventDefault()
    this.sendAsychronousRequest({
      method: 'PUT',
      url: `/admin/leave_benefits/${this.leaveBenefitID()}/exhaustion_rule.json`,
      data: {
        leave_benefit: {
          total_preservable_hours,
          exhaustion_elimination_period_only,
          delay_eligibility_start_date,
          exhaustion_help_text,
        },
      },
      success: (data) => {
        toastr.success('Leave Benefit Exhaustion rules updated');
      }
    })
  }

  getAllTimeOffTypes() {
    this.sendAsychronousRequest({
      method: 'GET',
      url: `/admin/leave_benefits/${this.leaveBenefitID()}/time_off_types`,
      success: (data) => {
        const leaveTypeId = data.time_off_leave_types[0] && data.time_off_leave_types[0].id
        this.setState({
          tRows: data.time_off_types,
          time_off_leave_types: data.time_off_leave_types,
          all_time_off_leave_types: data.all_time_off_leave_types,
          timeOffType: {
            time_off_leave_type_id: leaveTypeId,
          }
        })
      }
    })
  }

  leaveBenefitID() {
    return this.props.leave_benefit.id;
  }

  handleCloseButtonClick(e) {
    e.preventDefault()
    this.setState({
      showFormModal: !this.state.showFormModal,
    });
  }

  handleSwitchState(switchState) {
    $('.preservable-hours-input').val('')
    if (switchState) {
      $('.preservable-hours-input').show()
    }
    else {
      $('.preservable-hours-input').hide()
    }
  }

  timeOffTypeEmptyState() {
    const time_off_leave_types = this.state.time_off_leave_types
    const leaveTypeId = time_off_leave_types[0] && time_off_leave_types[0].id
    return {
      name: '',
      preservable_hours: '',
      order: '',
      id: '',
      time_off_leave_type_id: leaveTypeId,
      help_description_text: '',
    };
  }

  handleInputchange(changeInputObj) {
    this.setState({
      timeOffType: {
        ...this.state.timeOffType,
        ...changeInputObj,
      },
    });
  }

  handleHelpTextChange(e){
    this.setState({
      timeOffType: {
        ...this.state.timeOffType,
        ...{ help_description_text: e.target.value },
      },
    });
  }

  appendDataInTableRow(data) {
    this.setState({
      tRows: [...this.state.tRows, data],
    });
  }

  handleLeavePlanAttributeChange(inputObj) {
    this.setState(inputObj)
  }

  checkValidation(e, errorName){
    let {errors} = this.state
    if(e.target.value < 0){
      errors[errorName] =  "value should be equal or greater than 0."
      e.target.value = null
      this.setState({ errors: errors })
      return
    }
    errors[errorName] = ''
    this.setState({ errors: errors })

  }

  checkTotalPreservalValidation(e){
    this.checkValidation(e, 'totalPreservalHours')
    this.handleLeavePlanAttributeChange({ total_preservable_hours: e.target.value })
  }

  checkPreservalValidation(e){
    this.checkValidation(e, 'preservableHours')
    this.handleInputchange({ preservable_hours: e.target.value })
  }

  render() {
    const {
      showFormModal: showModal,
      time_off_leave_types: selectOptions,
      time_off_leave_type_id,
      showDeleteModal,
      deleteableItem,
      exhaustion_elimination_period_only: exhaustionEliminationPeriodOnly,
      delay_eligibility_start_date: delayEligibilityStartDate,
      total_preservable_hours: totalPreservableHours
    } = this.state;
    const {
      name,
      preservable_hours: preservableHours,
      help_description_text: helpText,
      order,
      id,
    } = this.state.timeOffType;
    const allPreservableHoursPresent = ['ALL'].indexOf(preservableHours) > -1;
    const preservableHoursInputStyle = allPreservableHoursPresent ? { display: 'none' } : {};
    return (
      <div>
        <form className="exhaustion-elimination-rules-form">
          <div className="col-md-5">
            <Switch
              label="Exhaust Elimination Period Only"
              className="exhaustion-elimination-period"
              checked={exhaustionEliminationPeriodOnly}
              onChange={(val) => this.handleLeavePlanAttributeChange({
                exhaustion_elimination_period_only: val
              })}
            />
            {this.renderDelaySwitch()}
            <Input
              label="Total Preservable Paid Leave hours"
              type="number"
              onChange={(e) => this.checkTotalPreservalValidation(e)}
              className="form-control form-control-sm"
              value={totalPreservableHours}
              min="0"
            />
            <span className="row error-msg">{this.state.errors.totalPreservalHours}</span>
          </div>
          <div className="col-md-7">
            <input type="hidden" className="exhaustion_help_text exhaustion-requirement-help-text" />
            <TextArea
              id="exhaustion-requirement-help-text"
              className="exhaustion_help_text exhaustion-requirement-help-text"
              value={this.props.leave_benefit.exhaustion_help_text}
              data-target = "exhaustion-requirement-help-text"
              name="leave_benefit[exhaustion_help_text]"
            />
          </div>
          <div className="col-md-12">
            <label className="col-sm-6">Required Time Off Types</label>
            <Button
              className="btn btn-link btn-sm col-md-6 pull-right"
              name="Add Time off Type"
              iconClass="fa fa-plus-circle"
              type="button"
              handleClick={(e) => this.handleAddButtonClick(e)}
            />
          </div>
          <StripTable
            theads={this.tableHeads()}
            className='col-md-12'
            tRows={this.state.tRows}
          />
          <div className="time-off-type-modal">
            <FormModal
              header="Time Off Type"
              show={showModal}
              handleClose={(e) => this.handleCloseButtonClick(e)}
              handleSubmit={(e) => this.handleFormSubmit(e) }
            >
              <div className="form-group row">
                <Input
                  type="number"
                  className="form-control form-control-sm"
                  label="Order"
                  value={order}
                  onChange={(e) => this.handleInputchange({ order: e.target.value })}
                />
                <span className="error-msg">{this.state.errors.order}</span>
              </div>
              <div className="form-group row">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label col-form-label-sm">
                    Time Off Type Name
                  </label>
                  <div className="col-sm-4">
                    <Selectpicker
                      className="select-picker form-control form-control-sm"
                      name="time-off-leave-type"
                      optionsForSelect={selectOptions.map( (options) => [options.name, options.id])}
                      onChange={(value) => { this.handleInputchange({ time_off_leave_type_id: value }) }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <Switch
                  label="Customize preservable hours"
                  onChange={(switchState) => this.handleSwitchState(switchState)}
                  className="preservable-hours-swtich"
                  checked={!allPreservableHoursPresent}
                />
                <Input
                  type="number"
                  min="0"
                  className="form-control form-control-sm"
                  parentClassName="preservable-hours-input"
                  parentStyle={preservableHoursInputStyle}
                  label="Preservable Hours"
                  value={preservableHours}
                  onChange={(e) => this.checkPreservalValidation(e) }
                />
                <span className="error-msg">{this.state.errors.preservableHours}</span>
                <div className='form-group row'>
                  <label className="col-sm-4 col-form-label col-form-label-sm">Help Text</label>
                  <div className="col-sm-4">
                    <textarea
                      type='text'
                      className='form-control'
                      value={this.state.timeOffType.help_description_text}
                      onChange={(e) => this.handleHelpTextChange(e) }
                    />
                  </div>
                </div>
              </div>
            </FormModal>
          </div>
          {
            showDeleteModal && <Modal
              hideModalInParent={(e) => this.hideDeleteModal(e)}
              show={showDeleteModal}
              id={deleteableItem}
              approve={(e) => this.handleDeleteElement(e)}
              decline={(e) => this.hideDeleteModal(e)}
              title="Delete time off type" >
              <span>Are you sure?</span>
            </Modal>
          }
          <Button
            className="btn btn-primary btn-sm col-md-2"
            name="Update leave benefit"
            iconClass="fa fa-plus-edit"
            type="button"
            handleClick={(e) => this.handleLeavePlanUpdate(e)}
          />
        </form>
      </div>
    )
  }
}

export default ExhaustionRules;
