import React from 'react'
import PropTypes from 'prop-types'

class Modal extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      show: props.show,
      disabled: props.disabled
    }
  }

  renderApproveButton() {
    if (this.props.approve) {
      return (
        <button className="btn btn-primary" onClick={(e) => this.handleApprove(e)}>
          Yes
        </button>
      )
    }
  }

  handleApprove (e) {
    e.preventDefault()
    this.closePrompt()
    setTimeout ( (() => {
      this.props.approve(this.props.id)
    }).bind(this), 500 )
  }

  handleDecline (e) {
    e.preventDefault()
    this.closePrompt()
  }

  addClass (element, newClass) {
    element.className += ` ${newClass}`;
  }

  closePrompt () {
    const modalContainer = document.getElementsByClassName("react-modal");
    const modal = document.getElementsByClassName("react-modal-content modal-content");
    const backShadow = document.getElementsByClassName("back-shadow");
    this.addClass(modalContainer[0], "remove")
    this.addClass(modal[0], "remove slideOutPosition")
    this.addClass(backShadow[0], "remove")
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.hideModalInParent()
    }).bind(this), 500 )
  }

  renderFooter (){
    return this.props.submitClaimRequest ? (
      <div className="modal-footer">
        <div className="buttons">
          <button
            className="btn btn-success btn-lg submit-claim-btn"
            disabled={this.props.disabled}
            onClick={(e) => this.handleApprove(e)}
          >
            Submit my Request
          </button>
          <a className="claim-back-link" onClick={(e) => this.handleDecline(e)}>
            Go Back
          </a>
        </div>
      </div>
    ) : (
      <div className="modal-footer">
        <div className="buttons">
          {this.renderApproveButton()}
          <button className="btn btn-danger" onClick={(e) => this.handleDecline(e)}>
            {this.props.closeButtonText || 'No'}
          </button>
        </div>
      </div>
    )
  }

  render () {
    return this.state.show ? (
      <div className={`react-modal ${this.props.className}`}>
        <div className="back-shadow" onClick={() => this.closePrompt()}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {this.props.title}
            </h4>
          </div>
          <div className="modal-body">
            <div className="confirmation-message text-center">
              {this.props.children}
            </div>
          </div>
          {this.renderFooter()}
        </div>
      </div>
    ) : null
  }
}


Modal.propTypes = {
  title: PropTypes.string,
  approve: PropTypes.func,
  decline: PropTypes.func,
  show: PropTypes.bool,
  hideModalInParent: PropTypes.func,
  id: PropTypes.number,
  className: PropTypes.string,
}

export default Modal;
