import React from 'react'
import BrokenLinkTable from './BrokenLinkTable'
import Pagination from '../pagination/pagination'
import FixBrokenLinkModal from './FixBrokenLinkModal'
import ScheduleModalBox from '../../employee/schedules/common_components/ScheduleModalBox'

const searchLocation = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')))

class BrokenLinks extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      ...props,
      ...{
        fixLink:{
          show: false,
          broken_link: {}
        },
        items: props.items,
        totalItems: props.total_items,
        remainingLinks: props.unfixed_link_count,
        reportGeneratedOn: props.report_generated_on,
        artifactDetail: {
          show: false,
          description: '',
          image_url: '',
          name: ''
        },
        sortBy: {
          column: "company_name",
          reverse: false
        },
        itemsPerpage: 30,
        totalPage: props.items.count,
        pageNumber: searchLocation.get('page') || '1',
        searchParam: searchLocation.get('q') || ''
      }
    }
  }

  changePageNumber (pageNumber) {
    this.setState({
      pageNumber: pageNumber
    }, () => { this.buildUrl() })
  }

  sort (column) {
    let sortBy = this.state.sortBy
    if (sortBy.column === column) {
      sortBy.reverse = !sortBy.reverse
    } else {
      sortBy = {
        column,
        reverse: true
      }
    }
    this.setState(
      { sortBy }, () => {
        this.buildUrl()
    })
  }

  fetchData (url) {
    const search_location = "?" + url.split("?").last()
    history.replaceState('/', null, search_location)

    $.ajax(({
      cache: false,
      url: url,
      dataType: 'json',
      success: (response) => {
        this.setState({
          items: response.items
        })
      }
    }))
  }

  showFixBrokenLinkModal(){
    return this.state.fixLink.show ?
      (
        <FixBrokenLinkModal
          data={this.state.fixLink}
          cancel={this.hideBrokenLinkModal.bind(this)}
          submitForm = {this.updateBrokenLink.bind(this)}
          updateAllLinks = {this.updateAllLinks.bind(this)}
        />
        ) : null
  }

  fetchArtifactDetail (e, id) {
    e.preventDefault()
    e.stopPropagation()
    let url = `/admin/broken_links/${id}/fetch_artifact_details`
    let data = {
      template: "test"
    }
    $.getJSON(url, data, (response) => {
      const artifactDetail = {
        item: {
          name: ''
        },
        description: response.description,
        image_url: response.image_url,
        name: response.name,
        show: true
      }
      this.setState({artifactDetail})
    })
  }

  handleItemClose () {
    const artifactDetail = {
      show: false,
      description: '',
      image_url: '',
      name: ''
    }
    this.setState({artifactDetail})
  }

  showArtifactDetailModal(){
    return this.state.artifactDetail.show ?
      React.createElement(ScheduleModalBox, {
        item: this.state.artifactDetail.item,
        handleItemClose: this.handleItemClose.bind(this),
        item_type: 'broken_link',
        description: this.state.artifactDetail.description,
        image_url: this.state.artifactDetail.image_url,
        isAdmin: true,
        type: 'broken_link'
      }) : null
  }

  hideBrokenLinkModal () {
    const fixLink = {
      show: false,
      broken_link: {}
    }

    this.setState({fixLink})
  }

  fixBrokenLink (e, link) {
    e.preventDefault()
    e.stopPropagation()
    const fixLink = {
      show: true,
      broken_link: link
    }

    this.setState({fixLink})
  }

  updateBrokenLink(params){
    let method = 'PUT'
    let url = `/admin/broken_links/${params.id}`
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        this.hideBrokenLinkModal()
        if (response.success) {
          toastr.success(response.message)
          this.setState({
            items: response.broken_links,
            remainingLinks: response.unfixed_link_count
          })
        } else {
          toastr.error(response.message)
        }
        // this.setState({ disableButton: false })
      }
    })
  }

  updateAllLinks(params){
    let method = 'POST'
    let url = `/admin/update_all_links`
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        this.hideBrokenLinkModal()
        if (response.success) {
          toastr.success(response.message)
          this.setState({
            items: response.broken_links,
            remainingLinks: response.unfixed_link_count
          })
        } else {
          toastr.error(response.message)
        }
      }
    })
  }

  buildUrl () {
    const { column, reverse } = this.state.sortBy
    const { searchParam, benefits } = this.state
    const order = reverse ? "desc" : "asc"
    let url = window.location.pathname
    url += window.location.search

    url = $.buildURL("q", searchParam, url)
    url = $.buildURL("sortBy", column, url)
    url = $.buildURL("order", order, url)
    url = $.buildURL("page", this.state.pageNumber, url)
    this.fetchData(url)
  }

  classesArray () {
    return [{
      element: "category",
      class: 'template_category'
    },{
      element: "company_name",
      class: "company_name sortable"
    },{
      element: "artifactable_type",
      class: "artifactable_type sortable"
    }, {
      element: "artifact_id",
      class: "artifact_id sortable"
    }, {
      element: "artifact_name",
      class: "artifact_name sortable"
    }, {
      element: 'is_fixed',
      class: 'is_fixed'
    }, {
      element: 'url',
      class: 'url'
    }
    ]
  }

  gotoLink (editUrl) {
    const {url} = this.state
    window.open(`${url}/${editUrl}?mode=view&q=${$.getUrlVars(window.location.href).q || ''}`, '_blank')
  }

  render () {
    const { remainingLinks, reportGeneratedOn } = this.state
    return (
      <div>
        <div className="fixed-card-container">
          <div className="filters-container">
            <div className="col-md-8 col-sm-7 col-xs-7">
              <Pagination
                totalItems={this.state.totalItems}
                changePageNumber={this.changePageNumber.bind(this)}
                pageNumber={this.state.pageNumber}
                itemsPerPage={30}
              />
            </div>
            <div className="col-md-4 col-sm-5 col-xs-5 pull-right">
            <div className="short-div div-right">
              <div className="row header-info">
                <div className="span6">
                  Broken Links Remaining : &nbsp;
                </div>
                <div className="span6">
                  <div id="broken_links_count">
                    { remainingLinks }
                  </div>
                </div>
              </div>
            </div>
            <div className="short-div div-right">
              <div className="row header-info">
                <div className="span6">
                  Report Generated On : &nbsp;
                </div>
                <div className="span6">
                  <div id="broken_links_count">
                    { reportGeneratedOn }
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      <div className="broken-links-table-container">
        {
          <BrokenLinkTable
            items={ this.state.items }
            gotoLink={this.gotoLink.bind(this)}
            sort={ this.sort.bind(this) }
            sortBy={ this.state.sortBy}
            fixBrokenLink={ this.fixBrokenLink.bind(this) }
            fetchArtifactDetail={ this.fetchArtifactDetail.bind(this) }
            heads= {this.state.heads}
            classes = {this.classesArray()}
          />
        }
        { this.showFixBrokenLinkModal() }
        { this.showArtifactDetailModal() }
      </div>
    </div>
    )
  }
}

export default BrokenLinks;
