import { useState } from "react";

const useConfirmationModal = (promptText = null, title = null) => {
  const [modal, setModal] = useState({
    promptText: promptText || "Are you sure?",
    title: title || "Confirmation",
    show: false,
    id: null
  })

  const handleDelete = (id) => {
    setModal(prevModal => {
      prevModal.id = id
      prevModal.show = true
      return {...prevModal}
    })
    //e.stopPropagation();
  }

  const hideModal = () => {
    setModal(prevModal => {
      prevModal.show = false
      return {...prevModal}
    })
  }

  return [modal, handleDelete, hideModal];
};

export default useConfirmationModal;
