import React, { Component } from 'react';

class ItemTableCheckbox extends Component {
  state = {
    isChecked: false,
    reRender: false,
  }

  componentDidMount() {
    // this set has been set just to reRender this component so that shouldComponentUpdate is called and actual checked state of props would be set as the state of this checkbox. All this is done to prevent controlled input to uncontrolled input warning.
    this.setState({reRender: true})
  }

  shouldComponentUpdate(nextProps) {
    const { isChecked } = this.state
    const isCheckedForProps = nextProps.checked || false
    if (isCheckedForProps !== isChecked) {
      this.setState({isChecked: !isChecked})
      return false
    }
    return true
  }

  onChangeHandler = () => {
    const { isChecked } = this.state
    this.setState({isChecked: !isChecked})
    this.props.onChange()
  }

  render() {
    const {
      value,
      style,
      disabled,
      title,
    } = this.props

    return (
      <input
        style={style}
        disabled={disabled}
        title={title}
        type="checkbox"
        checked={this.state.isChecked}
        onChange={this.onChangeHandler}
      />
    )
  }
}

export default ItemTableCheckbox;
