import React from 'react'
import NumberDisplay from '../components/NumberDisplay'

class SharedBenefit extends React.Component {

  constructor() {
    super()
  }

  handleClick(type) {
    if (this.props.benefit.class.indexOf('clickable') >= 0) {
      this.props.toggleBenefit(type)
    }
  }

  render() {
    return (
      <div data-tip data-for={this.props.benefit.type}
        className={`shared-benefit-tile-container ${this.props.benefit.hiddenClass}`}
        onClick={() => this.handleClick(this.props.benefit.type)}
      >
        <NumberDisplay benefit={this.props.benefit} />
      </div>
    )
  }
}

export default SharedBenefit;