import React from 'react'

class BenefitScenarioTile extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <span className={`shared-benefit-status-tile ${this.props.childClass}-color`}>
        { this.props.text }
      </span>
    )
  }
}

export default BenefitScenarioTile;
