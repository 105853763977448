import React, { useState } from 'react'

import BuildImplementationPartner from './buildImplementationPartner'
import AssociatedCompanies from './AssociatedCompanies'

export const addIpaContext = React.createContext()

function EditImplementationPartner(props) {
  const [allowAddIpAdmin, setAllowAddIpAdmin] = useState(props.allowAddIpAdmin)

  return (
    <addIpaContext.Provider
    value={{allowAddIpAdmin: allowAddIpAdmin, setAllowAddIpAdmin: setAllowAddIpAdmin}}
    >
    <div>
      <ul className='nav nav-tabs fixed-header-edit-tabs bundle-vendor-tab' id='timelineTab'>
        <li className='properties active'>
          <a href='#properties'> Properties</a>
        </li>
        <li className='properties'>
          <a href='#companies'>Associated Companies</a>
        </li>
      </ul>
      <div className='tab-content last-item fixed-header-tab-items'>
        <div className='tab-pane fade active in' id='properties'>
         <BuildImplementationPartner
          isEdit={true}
          implementation_partner={props.implementation_partner}
          initialFormData={props.initialFormData}
          allowAddIpAdmin={allowAddIpAdmin}
         />
        </div>
        <div className='tab-pane fade' id='companies'>
          <AssociatedCompanies
            isEdit={true}
            implementation_partner={props.implementation_partner}
          />
        </div>
      </div>
    </div>
    </addIpaContext.Provider>
  )
}

export default EditImplementationPartner;
