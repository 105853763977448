import React from 'react'
import TableHead from '../../admin/shared_leave_plans/components/tableHead'

class SharedNoteTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heads: [
        "Date",
        "Category",
        "Note",
        "Created at",
        "Creator",
        "Updated at",
        "Updater"
      ]
    }
  }
   renderData(note, cssClass, i) {
    return cssClass.class === 'date sortable' && note[cssClass.element] ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <div className="date">
            {
              note[cssClass.element]
            }
          </div>
        </td>
      ):
      cssClass.element === 'button' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <span className='edit-delete-note'>
            <i className='delete-leave-plan-note fa fa-trash-o' onClick={(e) => this.props.deleteNote(note.id)}/>
            <i className='edit-leave-plan-note fa fa-edit' onClick={(e) => this.props.editNote(note.id)}/>
          </span>
        </td>
      ):
      cssClass.element === 'category_name' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          { note[cssClass.element] || ''}
        </td>
      ):
      cssClass.element === 'note' ?
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          <p>{ note[cssClass.element] }<a onClick={(e) => this.props.viewDetailNote(note.id)}>..view more</a></p>
        </td>
      ):
      (
        <td className={`table-data ${cssClass.class}`} key={i}>
          { note[cssClass.element] }
        </td>
      )
  }

  renderHead(head, cssClass, i) {
    return (
      <TableHead
        head={head}
        cssClass={cssClass}
        key={i}
        sort={this.props.sort}
        sortBy={this.props.sortBy}
      />
    )
  }

  renderRow(note, cssClasses, i) {
    return (
      <tr className='tr-shared-benefit fixed-table-body' key={i}>
        { cssClasses.map( (cssClass, i) => this.renderData(note, cssClass, i) ) }
      </tr>
    )
  }

  render() {
    return (
      <table className="shared-benefit-table generic-table">
        <thead className="thead fixed-table-header">
          <tr className="tr">
            {this.state.heads.map ( (head, i) => this.renderHead(head, this.props.classes[i], i))}
            <th className='table-title button'>
              <button type = "button" className="btn btn-success" onClick={() => this.props.addNote()}>
                Add Note
              </button>
            </th>
          </tr>
        </thead>
        <tbody className="tbody">
          { this.props.leavePlanNotes.map( (note, i) => this.renderRow(note, this.props.classes, i)) }
        </tbody>
      </table>
    )
  }
}

export default SharedNoteTable
