import React from 'react'
import Selectpicker from '../../inputs/selectpicker'
import Datepicker from '../../inputs/datepicker'
import SwitchInput from '../../inputs/SwitchInput'

const formFieldNames = {
  retireScenarioChecked: 'Scenario Status',
  retire_scenario: 'Leave Scenario',
  hardStopScenarioChecked: 'Scenario Hard Stop',
  benefit_scenario: 'Hard Stop Scenario',
}

class BulkEditModal extends React.Component {
  constructor (props) {
    super (props)

    const jobCategory = this.isManagerTrainingItemModel() ? 'job_category_id' : 'job_category_ids'
    this.state = {
      statusChecked: false,
      effectiveRangeChecked: false,
      leaveScenarioChecked: false,
      leaveLocationChecked: false,
      healthPlanChecked: false,
      leaveCategoryChecked: false,
      leaveScenarioControl: "",
      jobCategoryChecked: false,
      jobCategoryControl: "",
      timelineEventTypeChecked: false,
      remainingBalanceChecked: false,
      retireScenarioChecked: false,
      hardStopScenarioChecked: false,
    }
    if (this.props.data.remainingBalance)
    {
      this.setState({
        showRemainingBalances: this.props.data.remainingBalance.defaultValue
      })
    }
    if (this.props.data.hardStopScenario) {
      this.setState({
        showHardStopScenarios: this.props.data.hardStopScenario.defaultValue
      })
    }
    this.dynamicFieldMapper = {
      leave_scenario_id: {
        checked: 'leaveScenarioChecked',
        control: 'leaveScenarioControl',
        options: this.props.data.leaveScenario && this.props.data.leaveScenario.options
      },
      [jobCategory]: {
        checked: 'jobCategoryChecked',
        control: 'jobCategoryControl',
        options: this.props.data.jobCategory && this.props.data.jobCategory.options && this.props.data.jobCategory.options,
        defaultValue: this.props.data.jobCategory && this.props.data.jobCategory.default_value
      }
    }
  }

  handleCheckboxChange (e) {
    const stateName = e.target.name
    const state = !this.state[stateName]
    this.setState({[stateName]: state})
  }

  getRetireScenarioFieldProperty () {
    if (!this.state.retireScenarioChecked) return {};

    return {
      retireScenarioChecked: {
        retire_scenario: {
          type: 'multi_select',
          required: true,
        },
        scenario_status:{
          type: 'single_select',
          required: false
        }
      }
    }
  }

  getHardStopScenarioFieldProperty() {
    if (!this.state.hardStopScenarioChecked) return {};

    return {
      hardStopScenarioChecked: {
        benefit_scenario: {
          type: 'multi_select',
          required: true,
        }
      }
    }
  }

  buildFieldProperty() {
    return {...this.getRetireScenarioFieldProperty(), ...this.getHardStopScenarioFieldProperty()}
  }

  formValid (formData) {
    let errMsgs = [];
    let dataAssociation = this.buildFieldProperty()

    // Scenario Hard Stop option in tandem with Scenario Status option
    if (!dataAssociation['retireScenarioChecked'] && !!dataAssociation['hardStopScenarioChecked']) {
      return ['Leave Scenario Status is not selected']
    }

    let associationKeys = Object.keys(dataAssociation)
    for (var key_index = 0; key_index < associationKeys.length; key_index++) {
      let key = associationKeys[key_index]
      let hasNonRequiredFields = false
      let selectedAnyNonRequiredFields = true
      if (this.state[key]){
        let fields = Object.keys(dataAssociation[key])
        hasNonRequiredFields = fields.filter((field) => !dataAssociation[key][field].required).length > 0
        selectedAnyNonRequiredFields = hasNonRequiredFields ? false : true
        for (var field_index = 0; field_index < fields.length; field_index++) {

          let field = fields[field_index]
          let className = this.getClassName(dataAssociation[key][field].type)
          let value = document.querySelector(`${className}[name='${field}']`).value

          if (dataAssociation[key][field].required && value == '') {
            errMsgs.push(`${formFieldNames[field]} is not selected.`)
          }
          if ((!dataAssociation[key][field].required || field === 'benefit_scenario') && value !== '') {
            selectedAnyNonRequiredFields = true
          }
        }
      }
      if (hasNonRequiredFields && !selectedAnyNonRequiredFields) {
        errMsgs.push(`${formFieldNames[key]} not selected.`)
      }
    }

    // if (this.state.statusChecked){
    //   errMsgs.push('Status cannot be blank.')
    // }
    return errMsgs
  }

  getClassName(type) {
    if (type == 'single_select') return '.select-picker';
    if (type == 'multi_select') return '.select-picker';
    if (type == 'datepicker') return '.datepicker';
  }

  handleSubmit (e) {
    e.preventDefault()
    const form = e.target
    const formData = $(form).attributes()
    const errorMessages = this.formValid(formData)
    if (errorMessages.length == 0) {
      this.props.handleBulkEdit(formData)
    } else {
      const builtMessage = '<ul>'+errorMessages.map((message) => `<li>${message}</li>`)+'</ul>'
      toastr.error(builtMessage)
    }
  }

  handleSwitchChange (checked) {
    this.setState({showRemainingBalances: checked})
  }

  handleHardStopChange (checked) {
    this.setState({showHardStopScenarios: checked})
  }

  formSubmittable () {
    const {
      statusChecked,
      effectiveRangeChecked,
      leaveScenarioChecked,
      leaveLocationChecked,
      healthPlanChecked,
      leaveCategoryChecked,
      jobCategoryChecked,
      timelineEventTypeChecked,
      remainingBalanceChecked,
      retireScenarioChecked,
      hardStopScenarioChecked,
    } = this.state
    return statusChecked || effectiveRangeChecked || leaveScenarioChecked || leaveLocationChecked || healthPlanChecked || leaveCategoryChecked || jobCategoryChecked || timelineEventTypeChecked || remainingBalanceChecked || retireScenarioChecked || hardStopScenarioChecked
  }

  renderStatus (key) {
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.statusChecked}
                name="statusChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Status
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              className="select-picker form-control"
              name="status"
              defaultValue={this.props.data.status.default_value}
              disabled={!this.state.statusChecked}
              optionsForSelect={this.props.data.status.options}
            />
          </div>
        </div>
      </div>
    )
  }

  renderTimelineEventType (key) {
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.timelineEventTypeChecked}
                name="timelineEventTypeChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Timeline Event Type
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              className="select-picker form-control"
              name="timeline_event_type"
              defaultValue={this.props.data.timelineEventType.default_value}
              disabled={!this.state.timelineEventTypeChecked}
              optionsForSelect={this.props.data.timelineEventType.options}
            />
          </div>
        </div>
      </div>
    )
  }

  onDataPickerChange (target_name, date) {
    const stateName = target_name
    this.setState({[stateName]: date})
  }

  renderEffectiveDateRange (key) {
    return (
      <div key={key}>
        <div className="col-md-3">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.effectiveRangeChecked}
                name="effectiveRangeChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Effective Date
            </label>
          </div>
        </div>
        <div className="col-md-9">
          <div className="form-group">
            <div className="col-xs-6">
              <div className="form-group">
                <Datepicker
                    name='effective_start_date'
                    placeholder='Effective Start Date'
                    disabled={!this.state.effectiveRangeChecked}
                    required={this.state.effectiveRangeChecked}
                    defaultValue={this.props.data.effectiveDateRange.effectiveStartDate.default_value}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <div className="form-group">
                <Datepicker
                    name='effective_end_date'
                    placeholder='Effective End Date'
                    disabled={!this.state.effectiveRangeChecked}
                    defaultValue={this.props.data.effectiveDateRange.effectiveEndDate.default_value}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDynamicMultiSelectPicker(name) {
    const dynamicFieldMapper = this.dynamicFieldMapper
    return(
      <div className="form-group">
        <div className="col-xs-6">
          <div className="form-group">
            <Selectpicker
              name={dynamicFieldMapper[name].control}
              disabled={!this.state[dynamicFieldMapper[name].checked]}
              onChange={(value) => this.setState({
                  [dynamicFieldMapper[name].control]: value
                })
              }
              optionsForSelect={
                [
                  ['Add to existing', `added_${name}`],
                  ['Find and remove these', `removed_${name}`],
                  ['Replace all with', name]
                ]
              }
            />
          </div>
        </div>
        <div className="col-xs-6">
          <div className="form-group">
            <Selectpicker
              name={this.state[dynamicFieldMapper[name].control]}
              disabled={!(this.state[dynamicFieldMapper[name].control].length && this.state[dynamicFieldMapper[name].checked])}
              multiSelect={true}
              optionsForSelect={dynamicFieldMapper[name].options}
            />
          </div>
        </div>
      </div>
    )
  }

  renderLeaveScenario (key) {
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.leaveScenarioChecked}
                name="leaveScenarioChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Leave Scenarios
            </label>
          </div>
        </div>
        <div className="col-md-8">
          { this.renderDynamicMultiSelectPicker('leave_scenario_id') }
        </div>
      </div>
    )
  }

  renderLeaveCategory(key) {
    const { leaveCategoryChecked } = this.state
    const { leaveCategory } = this.props.data

    return(
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={leaveCategoryChecked}
                name="leaveCategoryChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Leave Category
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              name="leave_category_id"
              defaultValue={leaveCategory.default_value}
              disabled={!leaveCategoryChecked}
              optionsForSelect={leaveCategory.options}
            />
          </div>
        </div>
      </div>
    )
  }

  renderLeaveLocation (key) {
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.leaveLocationChecked}
                name="leaveLocationChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Leave Locations
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              name="leave_location_ids"
              defaultValue={this.props.data.leaveLocation.default_value}
              disabled={!this.state.leaveLocationChecked}
              multiSelect={true}
              optionsForSelect={this.props.data.leaveLocation.options}
            />
          </div>
        </div>
      </div>
    )
  }

  renderHealthPlan(key) {
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.healthPlanChecked}
                name="healthPlanChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Health Plan
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Selectpicker
              name="custom_filter_attributes[filters][health_plan]"
              defaultValue={this.props.data.healthPlan.default_value}
              disabled={!this.state.healthPlanChecked}
              optionsForSelect={this.props.data.healthPlan.options}
            />
          </div>
        </div>
      </div>
    )
  }
  isManagerTrainingItemModel () {

    return window.location.pathname.indexOf('manager_training_items') > -1
  }

  renderJobCategory (key) {
    const checkManagerTrainingItemModel = this.isManagerTrainingItemModel()
    return (
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.jobCategoryChecked}
                name="jobCategoryChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Job Categories
            </label>
          </div>
        </div>
        <div className="col-md-8">
          {
            checkManagerTrainingItemModel ?
              this.renderDynamicSingleSelectPicker('job_category_id') :
              this.renderDynamicMultiSelectPicker('job_category_ids')
          }
        </div>
      </div>
    )
  }

  renderRemainingBalance(key){
    return(
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.remainingBalanceChecked}
                name="remainingBalanceChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Show Potential Remaining Balance
            </label>
          </div>
        </div>
        <div className="col-md-8">
          <SwitchInput
            name="show_remaining_balances"
            disabled={!this.state.remainingBalanceChecked}
            checked={ this.state.showRemainingBalances }
            onChange={(checked) => this.handleSwitchChange(checked)}
            />
        </div>
      </div>
      )
  }

  renderDynamicSingleSelectPicker(name) {
    const dynamicFieldMapper = this.dynamicFieldMapper
    return(
      <div className="col-xs-6">
        <div className="form-group">
          <Selectpicker
            name={name}
            disabled={!(dynamicFieldMapper[name].checked)}
            defaultValue={dynamicFieldMapper[name].defaultValue}
            multiSelect={false}
            optionsForSelect={dynamicFieldMapper[name].options}
          />
        </div>
      </div>
    )
  }

  scenarioStatusOptions() {
    if(this.props.isBenefitLibrary) {
     return this.props.data.status.options.slice(0,-1)
    }
    return this.props.data.status.options
  }

  renderRetireScenario (key) {
    return (
      <div key={key}>
        <br/>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.retireScenarioChecked}
                name="retireScenarioChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Scenario Status
            </label>
          </div>
        </div>
        <div className="col-md-8">
          <div className="form-group">
            <div className="col-xs-3">
              <div className="form-group">
                <Selectpicker
                  className="select-picker form-control"
                  name="retire_scenario"
                  placeholder='Leave Scenario'
                  multiSelect={true}
                  disabled={!this.state.retireScenarioChecked}
                  optionsForSelect={this.props.data.retireScenario.options}
                />
              </div>
            </div>
            <div className="col-xs-3">
              <div className="form-group">
                <Selectpicker
                  className="select-picker form-control"
                  name="scenario_status"
                  placeholder='Status'
                  disabled={!this.state.retireScenarioChecked}
                  optionsForSelect={this.scenarioStatusOptions()}
                />
              </div>
            </div>
            <div className="col-xs-3">
              <div className="form-group">
                <Datepicker
                  name='effective_start_date'
                  placeholder='Effective Start Date'
                  disabled={!this.state.retireScenarioChecked}
                  onChange={date => this.onDataPickerChange('effective_start_date', date)}
                  defaultValue={this.state.effective_start_date}
                />
              </div>
            </div>
            <div className="col-xs-3">
              <div className="form-group">
                <Datepicker
                  name='effective_end_date'
                  placeholder='Effective End Date'
                  disabled={!this.state.retireScenarioChecked}
                  onChange={date => this.onDataPickerChange('effective_end_date', date)}
                  defaultValue={this.state.effective_end_date}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHardStopScenario(key){
    return(
      <div key={key}>
        <div className="col-md-4">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={this.state.hardStopScenarioChecked}
                name="hardStopScenarioChecked"
                onChange={this.handleCheckboxChange.bind(this)}
              />
              Scenario Hard Stop
            </label>
          </div>
        </div>
        <div className="col-md-8">
          <div className="form-group">
            <div className="col-xs-3">
              <div className="form-group">
                <Selectpicker
                  className="select-picker form-control"
                  name="benefit_scenario"
                  placeholder='Leave Scenario'
                  multiSelect={true}
                  disabled={!this.state.hardStopScenarioChecked}
                  optionsForSelect={this.props.data.retireScenario.options}
                />
              </div>
            </div>
            <div className="col-xs-3">
              <div className="form-group">
                <SwitchInput
                  name="hard_stop_scenario"
                  disabled={!this.state.hardStopScenarioChecked}
                  checked={ this.state.showHardStopScenarios }
                  onChange={(checked) => this.handleHardStopChange(checked)}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      )
  }

  render () {
    let modalClass = this.props.companyItem ? 'modal-content big-modal react-modal-content' : 'modal-content react-modal-content'
    return (
      <form className='bulk-edit-form' onSubmit={this.handleSubmit.bind(this)}>
        <div className="react-modal">
          <div className="back-shadow">
          </div>
          <div className={modalClass}>
            <div className="modal-header">
              <h4 className="modal-title">
                Bulk Edit Items
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-info">
                    <span>{this.props.itemsCount}</span> items will be edited.
                  </div>
                </div>
                {
                  Object.keys(this.props.data).map(key =>
                    this[`render${key.camelize()}`](key)
                  )
                }
              </div>
            </div>
            <div className="modal-footer">
              <fieldset className="confirmation-buttons">
                <button className="btn btn-danger pull-left" onClick={this.props.handleBulkEditModal.bind(this)}>
                  Cancel
                </button>
                <button className="btn btn-primary" type='submit' disabled={!this.formSubmittable()}>
                  Apply
                </button>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default BulkEditModal;
