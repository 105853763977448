import React from 'react'

class DashboardBenefit extends React.Component {
  constructor () {
    super ()
    this.detailViewBenefits = [
      gon.company_variables.job_protected_leave || 'Job Protected Leave',
      gon.company_variables.paid_leave || 'Paid Leave'
    ];
    this.startDate = '';
    this.maximumExtendableDaysOnLeft = 0;
    this.endDate = '';
    this.maximumExtendableDaysOnRight = 0;
    this.resizeElementOriginalWidth = 0;
    this.oldMousePosition = 0;
    this.resizeElementOriginalLeft = '';
    this.resizeDirection = '';
    this.state = {
      selectedBenefit: {}
    }
  }

  buildClassName (benefit) {
    return `dashboard-benefit ${this.presenceClass(benefit)} ${this.additionalClass(benefit)}`
  }

  presenceClass (benefit) {
    return benefit.type !== 'empty' ?
      'present' : ''
  }

  additionalClass (benefit) {
    return(['elimination', 'holiday', 'pending-benefit', 'extending-benefit-range', 'unpaid-leave', 'extended-waiting-period'].indexOf(benefit.type) >= 0 ?
      benefit.type
    : '')
   }

  showBenefitName (e) {
    let currentTarget = e.currentTarget
    let targetDiv = $(currentTarget).find('.hovered-benefit-name');
    let frame;
    if ($(currentTarget).closest('.admin-container').length){
      frame = '.admin-container'
    } else {
      frame = '#page-content-wrapper';
    }
    $.fitInFrame(frame, targetDiv)
  }

  openModalBox (benefit) { 
    if (!benefit.no_pop_up && benefit.type !== 'elimination') {
      if (benefit.just_looking && !this.props.isAdmin){
        this.props.handleJustLookingClick(true)
      } else {
        let endpoint = ''
        if (benefit.type === 'exhaustion') {
          let elements = document.getElementsByClassName('time_off_type')
          let ids = [];
          for(let i = 0; i < elements.length; i++) {
            ids.push(elements[i].firstElementChild.id)
          }
          endpoint = `/exhaustion_periods/${benefit.id}/fetch_details/?time_off_leave_type_ids=[${ids}]`
        } else {
          let benefit_id = benefit.id
          if(benefit.records && benefit.records.length) {
            benefit_id = benefit.records[0].id
            this.setState({
              selectedBenefit: benefit.records[0]
            })
          }
          endpoint = `/leave_plan_benefits/${benefit_id}/fetch_details`
        }
        $.getJSON(endpoint, (data) => {
          gon.company_variables = {...gon.company_variables, ...data.statutory_substitutions_options}
          this.props.handleBenefitDetail({
            display_modal: true,
            data: data,
            selectedBenefit: this.state.selectedBenefit,
            name: data.name,
            description: data.description,
            image_url: data.image_url,
            isAdmin: data.isAdmin,
            displayBenefit: benefit,
            time_off_enabled: data.time_off_enabled,
            pending: data.pending,
            timelineItems: data.timeline_items,
          })
        })
      }
    }
  }

  // componentDidMount () {
    // this.initializeBenefitProperties()
    // this.initializeResizable();
    // this.initializeDraggable();
  // }

  // componentDidUpdate () {
    // let spinnerDiv = $(`#spinner-div-${this.props.benefit.id}`)
    // spinnerDiv.removeClass('show')
    // $.removeSpinner()
    // this.initializeBenefitProperties()
    // this.reInitializeResizable()
    // this.reInitializeDraggable()
  // }

  initializeBenefitProperties () {
    this.startDate = this.props.benefit.start_date;
    this.maximumExtendableDaysOnLeft = moment(this.startDate).diff(moment(this.props.benefit.eligible_leave_start_date), 'days')
    this.endDate = this.props.benefit.end_date;
    this.maximumExtendableDaysOnRight = moment(this.props.benefit.eligible_leave_end_date).diff(moment(this.endDate), 'days')
  }

  componentWillUnmount () {
    let element = $(`#dashboard-benefit-${this.props.benefit.id}`)
    if (element.resizable('instance')) {
      element.resizable('destroy')
    }
    if (element.draggable('instance')) {
      element.draggable('destroy')
    }
  }

  reInitializeResizable () {
    let resizableElement = $(`#dashboard-benefit-${this.props.benefit.id}`)
    if (resizableElement.resizable('instance')) {
      resizableElement.resizable('destroy')
      resizableElement.removeAttr('style')
    }
    this.initializeResizable()
  }

  reInitializeDraggable () {
    let draggableElement = $(`#dashboard-benefit-${this.props.benefit.id}`)
    if (draggableElement.draggable('instance')) {
      draggableElement.draggable('destroy')
    }
    this.initializeDraggable() 
  }

  initializeDraggable () {
    if (this.props.benefit.draggable) {
      let draggableElement = $(`#dashboard-benefit-${this.props.benefit.id}`)
      draggableElement.draggable({
        axis: 'x',
        containment: $('.dashboard-benefits-row'),
        opacity: 0.7,
        helper: '',
        start: this.dragOnStartedHandler.bind(this),
        drag: this.dragOnDragginHandler.bind(this),
        stop: this.dragOnStopHandler.bind(this)
      })
    }
  }

  dragOnStartedHandler (event, ui) {
    this.oldMousePosition = event.originalEvent.pageX
    this.resizeElementOriginalLeft = Number(event.target.style.left.split('px')[0])
  }

  dragOnDragginHandler (event, ui) {
    let draggableHelperDiv = ui.helper.find('.draggable-helper')
    let previousPosition = this.oldMousePosition
    let currentPosition = event.originalEvent.pageX
    let draggedWidth = currentPosition - previousPosition
    let numberOfChangedDays = Math.round(draggedWidth / gon.resizableDayWidth)
    let leftStyle = this.resizeElementOriginalLeft + numberOfChangedDays * gon.resizableDayWidth
    let startDate = moment(this.startDate).add(numberOfChangedDays, 'days').format('MM/DD/YYYY')
    let endDate = moment(this.endDate).add(numberOfChangedDays, 'days').format('MM/DD/YYYY')
    let helperSpan = `<span class='left'>${startDate}</span><span class='right'>${endDate}</span>`
    draggableHelperDiv.html(helperSpan)
  }

  dragOnStopHandler (event, ui) {
    let previousPosition = this.oldMousePosition
    let currentPosition = event.originalEvent.pageX
    let draggedWidth = currentPosition - previousPosition
    let numberOfChangedDays = Math.round(draggedWidth / gon.resizableDayWidth)
    if (numberOfChangedDays < 0) {
      numberOfChangedDays = (-Math.min(Math.abs(numberOfChangedDays), this.maximumExtendableDaysOnLeft))
    } else {
      numberOfChangedDays = Math.min(numberOfChangedDays, this.maximumExtendableDaysOnRight)
    }
    this.maximumExtendableDaysOnLeft = this.maximumExtendableDaysOnLeft + numberOfChangedDays
    this.maximumExtendableDaysOnRight = this.maximumExtendableDaysOnRight - numberOfChangedDays
    let leftStyle = this.resizeElementOriginalLeft + numberOfChangedDays * gon.resizableDayWidth
    this.startDate = moment(this.startDate).add(numberOfChangedDays, 'days').format('YYYY-MM-DD')
    this.endDate = moment(this.endDate).add(numberOfChangedDays, 'days').format('YYYY-MM-DD')
    event.target.style.left = `${leftStyle}px`
    $(event.target).find('.draggable-helper').html('')
    this.saveChanges()
  }

  initializeResizable () {
    if (this.props.benefit.draggable) {
      let resizableElement = $(`#dashboard-benefit-${this.props.benefit.id}`)
      resizableElement.resizable({
        handles: 'e, w',
        helper: 'ui-resizable-helper',
        start: this.resizeOnStartedHandler.bind(this),
        resize: this.resizeOnResizingHandler.bind(this),
        stop: this.resizeOnStoppedHandler.bind(this)
      })
    }
  }

  resizeOnStartedHandler (event, ui) {
    let eventClassName = event.toElement.className
    let leftDirectionClass = 'ui-resizable-w'
    let leftDirected = (eventClassName.indexOf(leftDirectionClass) > -1)
    let rightDirectionClass = 'ui-resizable-e'
    let rightDirected = (eventClassName.indexOf(rightDirectionClass) > -1)
    if (leftDirected || rightDirected) {
      this.resizeElementOriginalWidth = event.target.getBoundingClientRect().width
      this.resizeElementOriginalLeft = Number(event.target.style.left.split('px')[0])
      this.resizeDirection = leftDirected ? 'left' : 'right'
    }
  }

  resizeOnResizingHandler (event, ui) {
    let resizeHelperDiv = ui.helper
    let gridWidth = resizeHelperDiv[0].getBoundingClientRect().width
    let numberOfChangedDays = Math.round((gridWidth - this.resizeElementOriginalWidth) / gon.resizableDayWidth)
    let helperDate = ''
    if (this.resizeDirection === 'left') {
      helperDate = moment(this.startDate).add(-numberOfChangedDays, 'days')
    } else {
      helperDate = moment(this.endDate).add(numberOfChangedDays, 'days')
    }
    let helperText = `<span class=${this.resizeDirection}>${helperDate.format('MM/DD/YYYY')}</span>`
    resizeHelperDiv.html(helperText)
  }

  resizeOnStoppedHandler (event, ui) {
    let gridWidth = ui.helper[0].getBoundingClientRect().width
    let numberOfChangedDays = Math.round((gridWidth - this.resizeElementOriginalWidth) / gon.resizableDayWidth)
    let leftStyle = this.resizeElementOriginalLeft
    let startDate = moment(this.startDate).startOf('day')
    let endDate = moment(this.endDate).startOf('day')
    if (this.resizeDirection === 'left') {
      numberOfChangedDays = Math.min(numberOfChangedDays, this.maximumExtendableDaysOnLeft)
      let resizedStartDate = moment(this.startDate).add(-numberOfChangedDays, 'days').startOf('day')
      if ( endDate.toDate().less_than(resizedStartDate.toDate()) ) {
        numberOfChangedDays = startDate.diff(endDate, 'days')
        startDate = endDate
      } else {
        startDate = resizedStartDate
      }
      this.maximumExtendableDaysOnLeft = this.maximumExtendableDaysOnLeft - numberOfChangedDays
      leftStyle = leftStyle - numberOfChangedDays * gon.resizableDayWidth
    } else {
      numberOfChangedDays = Math.min(numberOfChangedDays, this.maximumExtendableDaysOnRight)

      let resizedEndDate = moment(this.endDate).add(numberOfChangedDays, 'days')
      if ( startDate.toDate().greater_than(resizedEndDate.toDate()) ) {
        numberOfChangedDays = startDate.diff(endDate, 'days')
        endDate = startDate
      } else {
        endDate = resizedEndDate
      }
      this.maximumExtendableDaysOnRight = this.maximumExtendableDaysOnRight - numberOfChangedDays
    }
    let currentWidth = this.resizeElementOriginalWidth + numberOfChangedDays * gon.resizableDayWidth
    event.target.style.width = `${currentWidth}px`
    event.target.style.left = `${leftStyle}px`
    this.startDate = startDate.format('YYYY-MM-DD')
    this.endDate = endDate.format('YYYY-MM-DD')
    this.saveChanges()
  }

  saveChanges () {
    if (this.startDate !== this.props.benefit.start_date || this.endDate !== this.props.benefit.end_date) {
      const params = {
        id: this.props.benefit.calendar_event_id,
        start_date: this.startDate,
        end_date: this.endDate
      }
      let spinnerDiv = $(`#spinner-div-${this.props.benefit.id}`)
      spinnerDiv.addClass('show')
      $.displaySpinner(spinnerDiv)

      $.ajax({
        url: '/schedules/update_leave_plan_benefit',
        method: 'PUT',
        dataType: 'JSON',
        data: params,
        success: (response) => {
          if (response.status === 'passed') {
            PubSub.publish('fetchDashboardBenefits')
            toastr.success(response.success_message)
          } else {
            let spinnerDiv = $(`#spinner-div-${this.props.benefit.id}`)
            spinnerDiv.removeClass('show')
            $.removeSpinner()
            this.initializeBenefitProperties()
            this.reInitializeResizable()
            toastr.error(response.error_message)
          }
        }
      })
    }
  }

  detailViewHandler (benefit) {
    if (this.detailViewBenefits.indexOf(benefit.name) > -1) {
      this.props.toggleDetailView('graphView', 'detail')
    }
  }
  buildHoverName() {
    return `${this.props.benefit.hover_name || ''}${this.props.benefit.extendable_hover_name || ''}`
  }

  customColor() {
    const { customColor } = this.props
    return customColor && customColor.length > 0 ? customColor : ''
  }

  shadeColor(color, amount) {
    return (
      '#' + color.replace(/^#/, '').replace(/../g, color => (
          '0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
    )
  }

  render () {
    let color = this.customColor();

    const benefitDivStyle = {
      backgroundColor: color,
      borderLeft: color ? `1px solid ${this.shadeColor(`${color}`, -40)}`: '',
      borderRight: color ? `1px solid ${this.shadeColor(`${color}`, -40)}` : ''
    };

    const benefitHoverStyle = {
      color: color,
      border: color ? `1px solid ${this.shadeColor(`${color}`, -40)}` : ''
    };

    return (
      <div className='dashboard-benefit-div' style={{ width: this.props.benefit.width }}>
        <div
          className={this.buildClassName(this.props.benefit)}
          id={`dashboard-benefit-${this.props.benefit.id}`}
          style={benefitDivStyle}
          onClick={() => this.detailViewHandler(this.props.benefit)}
        >
          <div className='draggable-helper' />
          <div className='benefit-name-div' onMouseEnter={(e) => this.showBenefitName(e)}>
            <span className='benefit-name' onClick={() => this.openModalBox(this.props.benefit)}>
              {this.props.benefit.name}
            </span>
            <div className='hovered-benefit-name'
              style={benefitHoverStyle}
              dangerouslySetInnerHTML={{__html: this.buildHoverName()}}
              onClick={() => this.openModalBox(this.props.benefit)}
            />
          </div>
        </div>
        <div className='spinner-div' id={`spinner-div-${this.props.benefit.id}`} />
      </div>
    )
  }
}

export default DashboardBenefit