import React from 'react'

class BundleCategoryForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: props.isEdit ? props.bundleCategory.name : '',
      description: props.isEdit ? props.bundleCategory.description : '',
      disableButton: false,
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value })
  }

  submitBundleCategory() {
    const { bundleCategory, isEdit } = this.props
    const { name, description, disableButton } = this.state
    this.setState({ disableButton: true })
    const params = {
      name: name,
      description: description,
    }
    let method = 'POST'
    let url = '/admin/bundle_categories'
    if(isEdit) {
      method = 'PUT'
      url = `/admin/bundle_categories/${bundleCategory.id}`
    }
    $.ajax({
      method: method,
      url: url,
      data: params,
      success: (response) => {
        if (response.success) {
          this.props.onSubmit(response.data)
          toastr.success(response.message)
        } else {
          this.setState({ disableButton: false })
          toastr.error(response.message)
        }
      }
    })
  }

  render() {
    const { name, description, disableButton } = this.state
    const { onModalClose, isEdit } = this.props
    return (
      <div className="react-modal">
        <div className="back-shadow">
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <button
              className="close error-modal-close"
              type="button"
              onClick={ onModalClose }
            >×</button>
            <h4 className="modal-title">Bundle Category</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Name</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="bundle-category-name"
                  name="name"
                  value={ name }
                  onChange={ (e) => this.handleNameChange(e) }
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                className="form-control"
                name="description"
                value={ description }
                onChange={ (e) => this.handleDescriptionChange(e) }
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button className="btn btn-primary"
                      onClick={ () => this.submitBundleCategory() }
                      disabled={ disableButton }>
                { isEdit ? 'Update' : 'Save' }
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BundleCategoryForm
