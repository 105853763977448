import React from 'react'
import PropTypes from 'prop-types'
import TableHead from '../shared_leave_plans/components/tableHead'

class BrokenLinkTable extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      itemsPerpage: 30,
      pageNumber: 1,
      sortBy: {
        column: 'company_name',
        reverse: false
      }
    }
  }

  renderHead(head, cssClass, i) {
    return (
      <TableHead
        head={head}
        cssClass={cssClass}
        key={i}
        sort={this.props.sort}
        sortBy={this.props.sortBy}
      />
    )
  }

  renderRow(link, cssClasses, i) {
    return (
      <tr className='tr benefit-items-row fixed-table-header' key={i} onClick={(e) => this.props.gotoLink(link.edit_url)}>
        { cssClasses.map( (cssClass, i) => this.renderData(link, cssClass, i) ) }
      </tr>
    )
  }

  renderData(link, cssClass, i) {
    if (cssClass.element === "is_fixed")
    {
      return(
        <td className={`table-data ${cssClass.class}`} key={i}>
          {
            link.is_fixed ? (
              <span>
                <i className="fa fa-check fixed_link"></i>
              </span>
            ):
            (
              <span className={`btn btn-primary ${link[cssClass.element]}`} onClick={(e) => this.props.fixBrokenLink(e, link)}>
                Fix Link
              </span>
            )
            }
        </td>
      )
    }
    else if(['company_name', 'artifactable_type', 'artifact_id', 'artifact_name'].indexOf(cssClass.element) >= 0){
      return(
        <td className={`table-data ${cssClass.class}`} key={i}>
          <span onClick={(e) => this.props.gotoLink(link.edit_url)}>
            { link[cssClass.element] }
          </span>
        </td>
      )
    }
    else if(cssClass.element === 'category'){
      return(
        <td className={`table-data ${cssClass.class}`} key={i}>
          { this.renderIcon(link) }
        </td>
      )
    }
    else{
      return(
        <td className={`table-data ${cssClass.class}`} key={i}>
            { link[cssClass.element] }
        </td>
      )
    }
  }

  renderIcon(link) {
    const categoryCss = {
      fontSize: 48,
      textAlign: 'center',
      flex: 1
    }
    const category = link.template_category
    if (category === 'statutory') {
      return <i className={`material-icons ${category}`} style={categoryCss}>security</i>
    } else if (category === 'locked') {
      return <i className={`material-icons ${category}`} style={categoryCss}>lock</i>
    } else {
      return <i className={`material-icons ${category}`} style={categoryCss}>lock_open</i>
    }
    // return this.props.children;
  }

  renderTable () {
    return this.props.items.length ?
    (
      <div>
        <table className="shared-benefit-table">
          <thead className="thead fixed-table-header">
            <tr className="tr">
              {this.props.heads.map ( (head, i) => this.renderHead(head, this.props.classes[i], i))}
            </tr>
          </thead>
          <tbody className="leave-benefits-checklist">
            {this.props.items.map( (broken_link, i) => this.renderRow(broken_link, this.props.classes, i))}
          </tbody>
      </table>
      </div>
    ) :
    <span className="no-data">
      No data to show
    </span>
  }

  render () {
    return this.renderTable()
  }
}

BrokenLinkTable.propTypes = {
  gotoLink: PropTypes.func
}

export default BrokenLinkTable;
