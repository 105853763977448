import React from 'react'
import DashboardBenefitRow from './DashboardBenefitRow'
import DashboardBenefitsPeriod from './DashboardBenefitsPeriod'
import HelperTooltip from '../helper_tooltip'

class DashboardPaidBenefits extends React.Component {
  renderAdditional () {
    const showAdditional = (this.props.benefit_counts.additional_benefit > 0 && !this.props.isAdmin && !this.props.isLeavePlanPartner)
    const addEditBenefitsText = this.props.add_edit_benefits_text || 'Add/Edit Additional Paid/Unpaid Leaves'
    if (showAdditional) {
      return (
        <div className='dashboard-additional-count'>
          <div className='exclamation-icon'>
            <i className='fa fa-edit' />
          </div>
          <a className='additional-count-text standard-link' href='/employees/time_off_types' >
            {addEditBenefitsText}
          </a>
        </div>
      )
    }
  }

  renderBenefitRow (rowBenefits, index) {
    return (
      <DashboardBenefitRow
        key={index}
        rowBenefits={rowBenefits}
        onHelpfulCheck={this.props.onHelpfulCheck}
        handleJustLookingClick={this.props.handleJustLookingClick}
        isAdmin={this.props.isAdmin}
        isLeavePlanPartner={this.props.isLeavePlanPartner}
        toggleDetailView={this.props.toggleDetailView}
        showExtendableBenefit={this.props.showExtendableBenefit}
        customColors= {this.props.customColors}
      />
    )
  }

  renderPeriodSpan (periodSpan, index, lastToShow = false) {
    if (lastToShow) {
      return (
        <DashboardBenefitsPeriod
          key={periodSpan.name}
          periodSpan={periodSpan}
          index={index}
          onlyLast
          lastIndex={this.props.periodSpans.length - 1}
        />
      )
    }
    else {
      return (
        <DashboardBenefitsPeriod
          key={periodSpan.name}
          periodSpan={periodSpan}
          index={index}
        />
      )
    }
  }

  handleExhaustionBtnOnClick(e) {
    if (this.props.isAdmin) {
      $('[data-href="#std-work-sheet-tab"]').trigger('click')
      e.preventDefault()
    }
  }

  renderHelperTooltip () {
    if (this.props.paid_leave_helper_text) {
      return <HelperTooltip helper_text={this.props.paid_leave_helper_text} />
    } else {
      return null
    }
  }

  render () {
    const paidLeaveHeader = gon.company_variables.paid_leave || 'Paid Leave'
    return (
      <div className='dashboard-benefit-container'>
        <div className='dashboard-paid-benefit-count-row'>
          <div className='dashboard-non-additional-count'>
            <div className='paid-benefit-icon' />
            <span className='count-label'>
              {`${paidLeaveHeader}: `}
            </span>
            <span className='count-number'>
              {`${this.props.benefit_counts.paid_benefit} ${'benefit'.pluralize(this.props.benefit_counts.paid_benefit, 'noun')}`}
            </span>
            {this.renderHelperTooltip()}
          </div>
          {this.renderAdditional()}
        </div>

        <div className='dashboard-benefit-table-container'>
          <table className='dashboard-paid-benefits-div'>
            <thead />
            <tbody>
              <tr>
                <td>
                  <div className='dashboard-paid-benefits'>
                    { this.props.benefit_items.map((rowBenefits, index) => this.renderBenefitRow(rowBenefits, index)) }
                    <div className='period-container'>
                      {
                        this.props.periodSpans.map((periodSpan, index) => this.renderPeriodSpan(periodSpan, index))
                      }
                    </div>
                  </div>
                  <div className='base-line' />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DashboardPaidBenefits