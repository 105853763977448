import React from 'react'
import PropTypes from 'prop-types'
import ExtraList from './ExtraList'

class List extends React.Component {
  renderList (data) {
    if (data.length > 3 ) {
      return (
        [
          ...this.renderList(data.slice(0,2)),
          <ExtraList key={3} data={data} />
        ]
      )
    } else {
      return (
        data.map( (datum, index) => {
          let text = datum.length == 3 ? datum[1] : datum
          text = this.props.avoidWordify ? text : text.wordify(" ", true)
          return (
            <li key={index}> {text} </li>
          )
        })
      )
    }
  }

  render () {
    return (
      <ul className="react-list">
        {this.renderList(this.props.data)}
      </ul>
    )
  }
}

List.propTypes = {
  data: PropTypes.array
}

export default List;
