import React from 'react'
import TableHead from '../../shared_leave_plans/components/tableHead'
import LeavePlanHistoryTableRow from './tableRow'
import Pagination from '../../pagination/pagination'

class LeavePlanHistoryTable extends React.Component {
  constructor () {
    super ()
    this.tableHeads = [
      "Update Name",
      "Description",
      "Updated By",
      "Updated On"
    ]
  }

  renderHead (head, cssClass, i) {
    return <TableHead
      key={i}
      head={head}
      cssClass={cssClass} />
  }

  renderRow (history, cssClasses, i) {
    return <LeavePlanHistoryTableRow
      key={i}
      history={history}
      cssClasses={cssClasses} />
  }

  render () {
    return this.props.leavePlanHistories.length ?
    (
      <div>
        <table className="shared-benefit-table">
          <thead className="thead fixed-table-header">
            <tr className="tr">
              {this.tableHeads.map ( (head, i) => this.renderHead(head, this.props.classes[i], i))}
            </tr>
          </thead>
          <tbody className="tbody">
            {this.props.leavePlanHistories.map( (history, i) => this.renderRow(history, this.props.classes, i))}
          </tbody>
      </table>
      <Pagination 
        totalItems={ this.props.totalHistoriesCount } 
        changePageNumber={ this.props.changePageNumber } 
        pageNumber={ this.props.pageNumber } 
        itemsPerPage={ 20 }
      />
      </div>
    ) :
    <span className="no-data">
      No data to show
    </span>

  }
}

export default LeavePlanHistoryTable;
