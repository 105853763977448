import React from "react";
import { TableContainer, TableHead, TableBody, TableRow, TableCell, Table, Paper, styled, makeStyles, TableSortLabel } from '@material-ui/core'
import AssociatedLinkedWrappers from '../associated_linked_wrappers';
import List from '../../shared/Table/components/List'

function AssociatedLinkedBenefits (props) {
  const classes = useStyles();

  const headers = [
    { key: 'linkName', label: 'Link Name' },
    { key: 'linkedBenefitTemplates', label: 'Linked Leave Benefit Templates' },
    { key: 'scenarios', label: 'Scenarios' },
    { key: 'maxDuration', label: 'Shared Max Duration' },
    { key: 'updated', label: 'Link Updated' }
  ];

  const processData = (rows, classes) => {
    return (
      <>
        {rows.map((row, index) => {
          const date = moment(row.updated_at).tz('America/Los_Angeles')
          const scenarios = row.scenarios || []
          return (
            <TableRow key={index} className={index % 2 === 0 ? classes.rowGrey : ''}>
              <TableCell className={classes.cell} component="th" scope="row">
                <a href={`/admin/linked_benefit_templates/${row.id}/edit`}>
                  <u className={classes.content}>
                    {row.name}
                  </u>
                </a>
              </TableCell>

              <TableCell key={index} className={classes.cell} component="th" scope="row">
                <ul className={classes.list}>
                  {(row.benefits || []).filter(benefit => benefit != null).map((benefit, index) => {
                    return (
                      <li className={classes.content}>
                        {benefit.includes(props.id) ? <b>{benefit}</b> : <>{benefit}</>}
                      </li>
                    )
                  })}
                </ul>
              </TableCell>

              <TableCell key={index} className={classes.cell} component="th" scope="row">
                <List data={scenarios} avoidWordify={true} />
              </TableCell>

              <TableCell key={index} className={classes.cell} component="th" scope="row">
                <ul className={classes.list}>
                  {(row.wrapper_scenarios || []).filter(wrapper_scenario => wrapper_scenario != null).map((wrapper_scenario, index) => {
                    return (
                      <li className={classes.content}>
                        {wrapper_scenario}
                      </li>
                    )
                  })}
                </ul>
              </TableCell>
              <TableCell className={classes.cell}> {date.format("MM/DD/YYYY, hh:mm A")}</TableCell>
            </TableRow>
          )
        })}
      </>
    )
  };

  return (
    <div className={classes.main}>
      <AssociatedLinkedWrappers
        emptyMessage="Benefit is not linked to any other Leave Benefit Template"
        fetchUrl={props.fetch_url}
        headers={headers}
        processData={processData}
      />
    </div>
  );
}

const useStyles = makeStyles({
  main: {
    marginTop: 40,
  }
})





export default AssociatedLinkedBenefits;
