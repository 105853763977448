import React from 'react'
import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/addon/comment/comment'
import 'codemirror/addon/comment/continuecomment'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/display/autorefresh'

class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.editorId = `editor-${this.props.editorMode}`
    this.codeMirrorEditor = null
  }

  componentDidMount() {
    const editor = document.getElementById(this.editorId)
    this.codeMirrorEditor = CodeMirror.fromTextArea(editor, {
      lineNumbers: true,
      mode: this.props.editorMode,
      theme: 'seti',
      tabSize: 2,
      indentWithTabs: true,
      autoCloseBrackets: true,
      autoRefresh: true,
      readOnly: this.props.readOnly
    })
    if(this.props.height) { this.codeMirrorEditor.setSize("100%", this.props.height) }
    this.codeMirrorEditor.on('update', (instance) => {
      const content = instance.doc.getValue()
      this.props.onChange && this.props.onChange(content)
    });
  }

  componentWillUnmount() {
    this.codeMirrorEditor.toTextArea()
  }

  componentDidUpdate(){
    this.codeMirrorEditor.setValue(this.props.content)
  }

  render() {
    return (
      <textarea
        className='editor'
        id={this.editorId}
        defaultValue={this.props.content}
        style={{height: '800px', width: '80%', border: '1px solid #eee'}}
      />
    )
  }
}

export default Editor;
