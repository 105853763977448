import React from 'react'

class FixBrokenLinkModal extends React.Component {
  constructor (props) {
    super (props)

    const broken_link = props.data.broken_link
    this.state = {
      show: props.data.show,
      id: broken_link.id,
      broken_url: broken_link.url,
      new_url: broken_link.url,
      update_title: false,
      old_title: broken_link.title,
      new_title: broken_link.title
    }
  }

  submitForm (e) {
    e.preventDefault()
    this.props.submitForm(this.state)
  }

  updateAll(e){
    e.preventDefault()
    const params = {link_url: this.state.new_url,
              broken_url: this.state.broken_url}
    this.props.updateAllLinks(params)
  }

  addClass (element, newClass) {
    element.className += ` ${newClass}`;
  }

  closePrompt () {
    const modalContainer = document.getElementsByClassName("react-modal");
    const modal = document.getElementsByClassName("react-modal-content modal-content");
    const backShadow = document.getElementsByClassName("back-shadow");
    this.addClass(modalContainer[0], "remove")
    this.addClass(modal[0], "remove slideOutPosition")
    this.addClass(backShadow[0], "remove")
    setTimeout ( (() => {
      this.setState ({
        show: false
      })
      this.props.cancel()
    }).bind(this), 500 )
  }

  handleTextChange (key, value) {
    let temp = {}
    temp[key] = value
    this.setState(temp)
  }

  toggleUpdateTitle(){
    this.setState({
      update_title: !this.state.update_title
    })
  }

  render () {
    return this.state.show ? (
      <div className="react-modal">
        <div className="back-shadow" onClick={() => this.closePrompt()}>
        </div>
        <div className="modal-content react-modal-content">
          <div className="modal-header">
            <div className='item-header-container'>
              <div className='item-header'>
                <div className='name'>
                  Fix Broken Link
                  <i className='fa fa-window-close fa-lg pull-right' onClick={() => this.closePrompt()}>
                    </i>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body broken-link-modal">
            <strong>Broken Url :</strong>
            <div id="broken_url">
              {this.state.broken_url}
            </div>
            <hr/>
            <div className="edit-content">
            </div>
            <form className='edit-broken-link-form' action='#' onSubmit={this.submitForm.bind(this)}>
              <div className='form-group'>
                <label className='label-control'> New Url: </label>
                <input
                  type='text'
                  className='form-control'
                  value={this.state.new_url}
                  onChange={(e) => this.handleTextChange('new_url', e.target.value)}
                />
                <label className='label-control'> Title: </label>
                <input
                  disabled={!this.state.update_title}
                  type='text'
                  className='form-control'
                  value={this.state.new_title}
                  onChange={(e) => this.handleTextChange('new_title', e.target.value)}
                />
                <div className="pull-right">
                  <input className="custom-control-input" id="update_title_togle" type="checkbox" onChange={this.toggleUpdateTitle.bind(this)}/>
                  <label className="custom-control-label" for="update_title_togle"> Update Title</label>
                </div>
              </div>
              <input name="utf8" type="hidden" value="✓"></input>
              <hr/>
              <div className="buttons">
                <a data-disable-with="Verifying Url..." className="btn btn-primary btn-darkgray" id="update_all_btn" data-remote="true" onClick={this.updateAll.bind(this)} disabled={this.state.update_title} href="">Update All</a>
                <input type="submit" name="commit" value="Update" data-disable-with="Verifying Url..." className="btn btn-primary pull-right btn-darkgray" id="update_link_btn"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    ) : null
  }

}

export default FixBrokenLinkModal;